import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import _ from "lodash";

const isValidRoleToken = (requiredRoleList) => {
  const roleList = JSON.parse(localStorage.getItem("CLASHMATE_ROLE_LIST"));
  const accessToken = localStorage.getItem("CLASHMATE_ACCESS_TOKEN");
  return (
    accessToken !== null &&
    roleList &&
    _.difference(roleList, requiredRoleList).length === 0
  );
};

const isValidPermissionAssigned = (requiredPermissionList) => {
  const assignedPermissionList = JSON.parse(
    localStorage.getItem("CLASHMATE_PERMISSION_LIST")
  );
  if (assignedPermissionList && requiredPermissionList) {
    return (
      _.difference(requiredPermissionList, assignedPermissionList).length === 0
    );
  } else {
    return true;
  }
};

const ProtectedRouteComponent = ({
  redirectToPath,
  requiredRoleList,
  requiredPermissionList,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isValidRoleToken(requiredRoleList) ? (
        isValidPermissionAssigned(requiredPermissionList) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/status/403",
              state: { from: props.location },
            }}
          />
        )
      ) : (
        <Redirect
          to={{
            pathname: redirectToPath,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

ProtectedRouteComponent.propTypes = {
  requiredRoleList: PropTypes.array.isRequired,
  redirectToPath: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  location: PropTypes.shape({
    /** Current exact location of page */
    pathname: PropTypes.string,
  }),
};

ProtectedRouteComponent.defaultProps = {
  location: {},
};

export default ProtectedRouteComponent;
