const DataTypeConstant = {
  BOOLEAN: "BOOLEAN",
  INTEGER: "INTEGER",
  BIG_DECIMAL: "BIG_DECIMAL",
  FLOAT: "FLOAT",
  DATE: "DATE",
  STRING: "STRING",
  BOOLEAN_TRUE: "BOOLEAN_TRUE",
  BOOLEAN_FALSE: "BOOLEAN_FALSE",
  YES: "YES",
  NO: "NO",
};

export { DataTypeConstant };
