/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import React from "react";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Form, Row, Table,} from "reactstrap";
import CourseSectionSubjectStudentService from "../../../services/courseSectionSubjectStudentService";
import _ from "lodash";
import UtilService from "../../../services/utilService";
import AttendanceService from "../../../services/attendanceService";
import FormInput from "../../../components/common/form/FormInput";
import NotificationAlert from "react-notification-alert";
import moment from "moment";

class AttendanceStudentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            studentUid: null,
            dateStart: null,
            dateEnd: null,
            errorData: {},
            courseSectionSubjectStudentList: [],
            displayList: [],
            courseSectionList: [],
            mappedSubjectsList: [],
            dateList: [],
            subjectList: []
        };
    }

    onChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    populateStudentSubjects = async () => {
        await CourseSectionSubjectStudentService.fetchCourseSectionSubjectsForCurrentStudent().then(response => {
            let subjectList = _.uniqBy(
                _.map(response.data, "subject"),
                "uniqueId"
            );
            this.setState({subjectList: subjectList});
        });
    };

    populateDisplayList = async () => {
        let allOptionsNotSelected = false
        if (allOptionsNotSelected === true) {
            console.error("All options are not selected!")
        } else {
            this.setState({dateList: []});
            let formData = {
                studentUid: this.state.studentUid,
                dateStart: this.state.dateStart,
                dateEnd: this.state.dateEnd,
            };
            await AttendanceService.filteredStudentAttendanceList(formData).then(response => {
                this.setState({displayList: response.data});
                let dateList = this.populateDateList();
                this.setState({dateList: dateList});
            });
        }
    };

    populateDateList = () => {
        let startDate = moment(this.state.dateStart);
        let endDate = moment(this.state.dateEnd);
        var now = startDate, dates = [];

        while (now.isBefore(endDate) || now.isSame(endDate)) {
            dates.push(now.format('YYYY-MM-DD'));
            now.add(1, 'days');
        }
        return dates;
    };

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };


    componentDidMount() {
        this.populateStudentSubjects();
    };


    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    fetchAttendance = (subject, date) => {
        let attendance = _.find(this.state.displayList, attendance => attendance.subject.uniqueId === subject.uniqueId && attendance.date === date);
        if (attendance) {
            return attendance.isPresent ? "P" : "A";
        } else {
            return "N/A";
        }
    };

    attendanceDisplay = () => {
        if (this.state.displayList.length === 0) {
            return (
                <>
                    <CardBody>
                        <Alert color="warning">
                            <strong>There is no data to display!</strong> Please select
                            course, section and subject from the dropdown!
                        </Alert>
                    </CardBody>
                </>
            );
        } else {
            let self = this;
            return (
                <>
                    <CardBody>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                            <tr>
                                <th className="sort" data-sort="status" scope="col">
                                    #
                                </th>
                                <th className="sort" data-sort="status" scope="col">
                                    Student Name
                                </th>
                                {this.state.dateList.map(function (date, index) {
                                    return (
                                        <th className="sort" key={UtilService.uuidv4()} data-sort="status" scope="col">
                                            {date}
                                        </th>);
                                })}
                            </tr>
                            </thead>
                            <tbody className="list">
                            {this.state.subjectList.map(function (subject, index) {
                                return (
                                    <tr key={index}>
                                        <td className="budget">{index + 1}</td>
                                        <td className="budget">{subject.title}</td>
                                        {self.state.dateList.map(function (date, index) {
                                            return (<td className="budget" key={UtilService.uuidv4()}>
                                                <p className="h6"> {self.fetchAttendance(subject, date)} </p>
                                            </td>);
                                        })}
                                    </tr>
                                );
                            })}

                            </tbody>
                        </Table>
                    </CardBody>
                </>
            );
        }

    };

    render() {
        return (
            <>
                <SimpleHeader
                    parentName="Attendance"
                    name="List"
                    sectionName="Attendance"
                    heading="ClashMate"
                    // newBtnText={"Assign Attendance"}
                    // newBtnUrl={"/teacher/attendance/assign"}
                />
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <Container className="mt--6" fluid>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <Col sm="12">
                                            <h3 className="mb-0">List Attendance</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form role="form">
                                        <Row>
                                            <Col md="6" sm="6">
                                                <FormInput
                                                    name="dateStart"
                                                    className="form-control-label"
                                                    id="dateStart"
                                                    placeholder="inputPlaceHolder.attendance.dateStart"
                                                    type="date"
                                                    onChange={this.handleChange}
                                                    label="inputLabel.attendance.dateStart"
                                                    value={this.state.dateStart || ""}
                                                    errorMsg={this.state.errorData.dateStart || ""}
                                                />
                                            </Col>
                                            <Col md="6" sm="6">
                                                <FormInput
                                                    name="dateEnd"
                                                    className="form-control-label"
                                                    id="dateEnd"
                                                    placeholder="inputPlaceHolder.attendance.dateEnd"
                                                    type="date"
                                                    onChange={this.handleChange}
                                                    label="inputLabel.attendance.dateEnd"
                                                    value={this.state.dateEnd || ""}
                                                    errorMsg={this.state.errorData.dateEnd || ""}
                                                />
                                            </Col>
                                        </Row>
                                        <div className="text-center">
                                            <Button className="my-4" color="info" type="button"
                                                    onClick={this.populateDisplayList}>
                                                Display
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <Row className="align-items-center">
                                        <Col sm="12">
                                            <h3 className="mb-0">Attendance List</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {this.attendanceDisplay()}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    };
}

export default AttendanceStudentList;
