import axios from "axios";

const BASE_URL = new URL(`${window.location.origin.toString()}`);

let client = axios.create({
  baseURL: BASE_URL.protocol + "//" + BASE_URL.hostname ,
});

let setAuthToken = () => {
  client.defaults.headers.common[
      "Authorization"
      ] = `Bearer ${localStorage.getItem("CLASHMATE_ACCESS_TOKEN")}`;
  return client;
};

//Request Interceptor
client.interceptors.request.use(req => {
      return req;
    }, error => {
      return Promise.reject(error);
    }
);

/**
 * Request Wrapper with default success/error actions
 */

const onSuccess = function (response) {
  console.debug("Request Successful!", response);
  return response.data;
};

const onError = function (error) {
  console.error("Request Failed:", error.config);

  if (error.response) {
    // Request was made but server responded with something
    // other than 2xx
    console.error("Status:", error.response.status);
    console.error("Data:", error.response.data);
    console.error("Headers:", error.response.headers);
  } else {
    // Something else happened while setting up the request
    // triggered the error
    console.error("Error Message:", error.message);
  }
  return Promise.reject(error.response || error.message);
};

const axiosInstance = function (options) {
  setAuthToken();
  return client(options).then(onSuccess).catch(onError);
};

export default axiosInstance;
