const visibleForRoles = (allowedRoles) => {
    // Get the roles from localStorage and parse them into an array
    const storedRoles = JSON.parse(localStorage.getItem('CLASHMATE_ROLE_LIST')) || [];
    // Check if any of the allowedRoles are present in storedRoles
    const hasRole = allowedRoles.some(role => storedRoles.includes(role));
    const className = hasRole ? "" : "hidden"
    // Return "" if any role is found, otherwise "hidden"
    return className;
}

export default visibleForRoles;