import React from "react";
import { Col, Row } from "reactstrap";
import FormInput from "../../../components/common/form/FormInput";
import FormInputSelect from "../../../components/common/form/FormInputSelect";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "assets/css/draft-js-style.css";

class LessonPlanForm extends React.Component {
  showField() {
    return this.props.isEdit === true ? "hidden" : "";
  }

  render() {
    return (
      <>
        <Row>
          <Col md="8">
            <FormInput
              name="title"
              className="form-control-label"
              id="title"
              placeholder="inputPlaceHolder.examination.title"
              type="text"
              onChange={this.props.onChange}
              label="inputLabel.examination.title"
              value={this.props.valueObj.title || ""}
              errorMsg={this.props.errorObj.title || ""}
            />
          </Col>
          <Col md="4">
            <FormInputSelect
              name="courseSectionSubjectUID"
              className="form-control-label"
              id="courseSectionSubjectUID"
              onChange={this.props.onChange}
              label="inputLabel.examination.courseSectionSubjectUID"
              optionList={this.props.valueObj.subjectList}
              optionKey="uniqueId"
              optionValue="title"
              value={this.props.valueObj.courseSectionSubjectUID}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Editor
              editorState={this.props.valueObj.editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={this.props.obj.handleContentChange}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default LessonPlanForm;
