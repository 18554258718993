import axiosInstance from "../api/api";

const StudentParentService = {
  fetchStudents: function (uniqueId) {
    return axiosInstance({
      url: `/api/studentParent/parent/${uniqueId}`,
      method: "GET",
    });
  },
  fetchParents: function (uniqueId) {
    return axiosInstance({
      url: `/api/studentParent/student/${uniqueId}`,
      method: "GET",
    });
  },
  fetch: function () {
    return axiosInstance({
      url: "/api/studentParent/roles",
      method: "POST",
    });
  },
  delete: function (formData) {
    return axiosInstance({
      url: "/api/studentParent/",
      method: "DELETE",
      data: formData,
    });
  },
  create: function (formData) {
    return axiosInstance({
      url: "/api/studentParent/",
      method: "POST",
      data: formData,
    });
  },
};
export default StudentParentService;
