import React from "react";
import { Col, Row } from "reactstrap";
import FormInput from "../../../components/common/form/FormInput";
import FormReactSelect from "../../../components/common/form/FormReactSelect";
import UtilService from "../../../services/utilService";
import {meetingType} from "../../../util/meetingType";

class MeetingForm extends React.Component {
  showField() {
    return this.props.isEdit === true ? "" : "hidden";
  }

  render() {
    return (
      <>
        <Row>
          <Col md="4">
            <FormInput
                name="date"
                className="form-control-label"
                id="date"
                placeholder="inputPlaceHolder.meetingReport.date"
                type="date"
                onChange={this.props.onChange}
                label="inputLabel.meetingReport.date"
                value={this.props.valueObj.date || ""}
                errorMsg={this.props.errorObj.date || ""}
                required
            />
          </Col>
          <Col md="4" sm="6">
            <FormReactSelect
                selectKey={this.props.companySelectKey}
                isMulti={false}
                className="form-control-label"
                isSearchable={true}
                label="inputLabel.meetingReport.company"
                name={"company"}
                onChange={this.props.handleCompanyChange}
                optionValue={"uniqueId"}
                defaultValue={this.props.selectedCompany}
                optionList={this.props.companyList}
                optionLabel={"legalName"}
                placeHolder="inputPlaceHolder.meetingReport.company"
                errorMsg={this.props.errorObj.companyUID || ""}
                required
            />
          </Col>
          <Col md="4" sm="6">
            <FormReactSelect
                selectKey={this.props.attendeesSelectkey}
                isMulti={true}
                className="form-control-label"
                isSearchable={true}
                label="inputLabel.meetingReport.attendees"
                name={"attendees"}
                onChange={this.props.handleAttendeesChange}
                optionValue={"uniqueId"}
                defaultValue={this.props.selectedAttendees}
                optionList={this.props.attendeesForSelection}
                optionLabel={"fullName"}
                placeHolder="inputPlaceHolder.meetingReport.attendees"
                errorMsg={this.props.errorObj.attendees || ""}
                required
            />
          </Col>

        </Row>
        <Row>
          <Col md={4} sm={6}>
            {<FormReactSelect
                selectKey={UtilService.uuidv4()}
                isMulti={false}
                className="form-control-label"
                isSearchable={true}
                label="inputLabel.meetingReport.eventType"
                name={"eventType"}
                onChange={this.props.handleEventTypeChange}
                optionValue={"value"}
                defaultValue={this.props.selectedEventType}
                optionList={meetingType}
                optionLabel={"key"}
                placeHolder="inputPlaceHolder.meetingReport.eventType"
                errorMsg={this.props.errorObj.eventType || ""}
                required
            />}
          </Col>
        </Row>
      </>
    );
  }
}

export default MeetingForm;
