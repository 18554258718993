import types from "../actions/actionTypes";

const initialState = {
  userInfo: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_USER_INFO:
    case types.DELETE_USER_INFO:
      return { ...state, userInfo: action.payload };
    default:
      return state;
  }
};
