import axiosInstance from "../api/api";

const ContentService = {
    list: function (studyMaterialUid) {
        return axiosInstance({
            url: `/api/content/?studyMaterialUid=${studyMaterialUid}`,
            method: "GET",
        });
    },
    fetch: function (uniqueId) {
        return axiosInstance({
            url: `/api/content/${uniqueId}`,
            method: "GET",
        });
    },
    delete: function (uniqueId) {
        return axiosInstance({
            url: `/api/content/${uniqueId}`,
            method: "DELETE",
        });
    },
    create: function (formData) {
        return axiosInstance({
            url: `/api/content/`,
            method: "POST",
            data: formData,
        });
    },
    update: function (formData) {
        return axiosInstance({
            url: `/api/content/`,
            method: "PATCH",
            data: formData,
        });
    },
};
export default ContentService;
