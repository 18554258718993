/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import StudentParentService from "../../../../services/studentParentService";

class MappingStudentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parentUid: this.props.uniqueId,
      list: [],
    };
    this.delete = this.delete.bind(this);
    this.notify = this.notify.bind(this);
  }

  componentDidMount() {
    StudentParentService.fetchStudents(this.state.parentUid).then(
      (response) => {
        this.setState({ list: response.data });
      }
    );
  }

  delete(uniqueId) {
    var formData = {
      parentUid: this.state.parentUid,
      studentUid: uniqueId,
    };
    StudentParentService.delete(formData).then((response) => {
      //TODO: Move Alerts to global Alert
      /*this.notify(
        response.responseStatus.toLowerCase(),
        response.responseStatus,
        response.message
      );*/
      //TODO: TO be fixed with redux implementation
      //   this.props.history.push("/admin/dashboard");
      StudentParentService.fetchStudents(this.state.parentUid).then(
        (response) => {
          this.setState({ list: response.data });
        }
      );
    });
  }

  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    let obj = this;
    return (
      <>
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col lg="6" xs="7">
                      <span className="mb-0 h3">Student List</span>
                    </Col>
                    <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                      <Button
                        className="btn-neutral text-md-right"
                        color="default"
                        size="sm"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push(
                            `/admin/studentParent/assign/student/${this.state.parentUid}`
                          );
                        }}
                      >
                        Assign Student
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        #
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Date Created
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Name
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Email Address
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {this.state.list.map(function (value, index) {
                      return (
                        <tr key={index}>
                          <td className="budget">{index + 1}</td>
                          <td className="budget">{value.dateCreated}</td>
                          <td className="budget">{value.fullName}</td>
                          <td className="budget">{value.emailAddress}</td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color=""
                                size="sm"
                                className="btn-icon-only text-light"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    obj.props.history.push(
                                      `/admin/student/show/${value.uniqueId}`
                                    );
                                  }}
                                >
                                  Details
                                </DropdownItem>
                                <DropdownItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    obj.delete(`${value.uniqueId}`);
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default MappingStudentList;
