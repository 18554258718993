/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Card, CardBody, CardHeader, Col, Container, Row,} from "reactstrap";
import NoticeService from "../../../../services/noticeBoardService";
import Moment from "react-moment";
import AuthService from "../../../../services/authService";
import _ from "lodash";
import DocumentService from "../../../../services/documentService";
import AppRedirect from "../../../../util/appRedirect";

class NoticeDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            noticeData: {
                createdBy: {},
                documentList: [],
                likedBy: [],
                viewedBy: []
            },
        };
    }

    likeNotice = (uniqueId) => {
        NoticeService.likePost({noticeUID: uniqueId}).then((response) => {
            console.log(response.data);
            this.setState({noticeData: response.data});
        })
    };

    fetchNoticeData = () => {
        const {uniqueId} = this.props.match.params;

        NoticeService.fetch(uniqueId).then((response) => {
            console.log(response.data);
            this.setState({noticeData: response.data});
        });
    };

    componentDidMount() {
        this.fetchNoticeData();
    }

    download = (blob, doc) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', doc.originalFileName); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    downloadDocument = async (uniqueId) => {
        let doc = _.find([...this.state.noticeData.documentList], (value) => value.uniqueId === uniqueId)
        DocumentService.fetch(uniqueId).then(response => {
            // console.log(response)
            this.download(response, doc);
        })
    };

    async delete() {
        let uniqueId = this.state.noticeData.uniqueId;
        let self = this;
        await NoticeService.delete(uniqueId).then((response) => {
            console.log(response);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            AppRedirect.redirectWithWait(self, `/${AuthService.getBaseURLByRole()}/notice/`)
        });
    }


    showEditAndDelete = (value) => {
        let obj = this;
        if (value.createdByUID === value.currentUserUid) {
            return (
                <>
                    <button onClick={() => obj.delete()}
                            className="badge badge-pill badge-default fa-pull-right">Delete
                    </button>
                    <button onClick={() => {
                        obj.props.history.push(`/${AuthService.getBaseURLByRole()}/notices/edit/${value.uniqueId}`)
                    }}
                            className="badge badge-pill badge-default fa-pull-right">Edit
                    </button>
                </>
            );
        } else {

        }
    };


    render() {
        const {noticeData} = this.state;
        let obj = this;
        return (
            <>
                <SimpleHeader
                    name="Details"
                    parentName="Notice"
                    heading="ClashMate"
                    newBtnUrl={`/${AuthService.getBaseURLByRole()}/notice/`}
                    newBtnText="Notices"
                />
                <Container className="mt--6" fluid>
                    <Row>
                        <Col sm="6">
                            <Card className="mb-4">
                                <CardHeader>
                                    <h3 className="mb-0">{noticeData.createdBy.fullName}
                                        <small> at <Moment
                                            format="YYYY/MM/DD HH:MM">{noticeData.dateCreated}</Moment></small>
                                    </h3>
                                </CardHeader>
                                <CardBody>
                                    <div className="card text-left">
                                        <div className="card-body">
                                            <Row>
                                                <Col md="4">
                                                    <a href="#!" className="avatar avatar-xl rounded-circle">
                                                        <img alt="Image placeholder"
                                                             src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?r=pg"/>
                                                    </a>
                                                    <h5 className="card-title">{noticeData.createdBy.fullName}</h5>
                                                    <span
                                                        className="badge badge-pill badge-default">{noticeData.course ? noticeData.course.title : ''}</span>
                                                    <span
                                                        className="badge badge-pill badge-primary">{noticeData.section ? noticeData.section.title : ''}</span>
                                                    <span
                                                        className="badge badge-pill badge-secondary">{noticeData.subject ? noticeData.subject.title : ''}</span>
                                                    <span
                                                        className="badge badge-pill badge-warning">{noticeData.course === null && noticeData.section === null && noticeData.subject === null ? "All" : ''}</span>

                                                    <p className="card-text"><small className="text-muted">Last
                                                        updated &nbsp;
                                                        <Moment fromNow
                                                                ago>{noticeData.lastUpdated}</Moment> ago.</small>
                                                    </p>
                                                </Col>
                                                <Col md="8">
                                                    <p className="card-text h2 font-weight-bold">{noticeData.title}</p>
                                                    <p className="card-text font-weight-bold">{noticeData.text}</p>
                                                    {obj.showEditAndDelete(noticeData)}
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="6">
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0">Attachments - {this.state.noticeData.documentList.length}
                                    </h3>
                                </CardHeader>
                                <CardBody>
                                    <Col sm="12">
                                        <ul className="list-group list-group-flush" data-toggle="checklist">
                                            {[...this.state.noticeData.documentList].map((value, index) => {
                                                return (
                                                    <>
                                                        <li key={value.uniqueId}
                                                            className="checklist-entry list-group-item flex-column align-items-start py-4 px-4">
                                                            <div
                                                                className="checklist-item checklist-item-danger">
                                                                <div className="checklist-info">
                                                                    <h5
                                                                        className="checklist-title mb-0">{value.originalFileName}</h5>
                                                                    <small><Moment
                                                                        format="LL">{value.startTime}</Moment></small>
                                                                </div>
                                                                <div>
                                                                <span
                                                                    onClick={() => obj.downloadDocument(value.uniqueId)}
                                                                    className="badge badge-primary">Download</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </>
                                                )
                                            })}
                                        </ul>
                                    </Col>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default NoticeDetails;
