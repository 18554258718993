/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import StudentOnlineExaminationService from "../../../../services/studentOnlineExaminationService";
import { ExaminationStatusConstant } from "../../../../util/examinationStatus";
import _ from "lodash";

class StudentOnlineExaminationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeOnlineExaminationList: [],
      archiveOnlineExaminationList: [],
    };
  }

  componentDidMount() {
    StudentOnlineExaminationService.list(ExaminationStatusConstant.ACTIVE).then(
      (response) => {
        console.log(response);
        this.setState({ activeOnlineExaminationList: response.data });
      }
    );
    StudentOnlineExaminationService.list(
      ExaminationStatusConstant.COMPLETED
    ).then((response) => {
      console.log(response);
      this.setState({ archiveOnlineExaminationList: response.data });
    });
  }

  render() {
    let obj = this;
    return (
      <>
        <SimpleHeader
          name="List"
          parentName="Online Examination"
          heading="ClashMate"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Online Examination</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {_.isEmpty(this.state.activeOnlineExaminationList) ? (
                    <Alert color="info">
                      <strong>Info!</strong> There are no examinations scheduled
                      for now!
                    </Alert>
                  ) : (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th className="sort" data-sort="name" scope="col">
                            #
                          </th>
                          <th className="sort" data-sort="budget" scope="col">
                            Start Date
                          </th>
                          <th className="sort" data-sort="status" scope="col">
                            Title
                          </th>
                          <th className="sort" data-sort="budget" scope="col">
                            End Date
                          </th>
                          <th className="sort" data-sort="budget" scope="col">
                            Duration(min)
                          </th>
                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody className="list">
                        {this.state.activeOnlineExaminationList.map(function (
                          value,
                          index
                        ) {
                          return (
                            <tr key={index}>
                              <td className="budget">{index + 1}</td>
                              <td className="budget">{value.startDateTime}</td>
                              <td className="budget">
                                {value.examinationTitle} - {value.title}
                              </td>
                              <td className="budget">{value.endDateTime}</td>
                              <td className="budget">{value.duration}</td>
                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    color=""
                                    size="sm"
                                    className="btn-icon-only text-light"
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();
                                        obj.props.history.push(
                                          `/student/examination/instructionPage/${value.examinationUID}/${value.uniqueId}`
                                        );
                                      }}
                                    >
                                      Start
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}
                </CardBody>
              </Card>
            </Col>

            {_.isEmpty(this.state.archiveOnlineExaminationList) || (
              <Col xl="12">
                <Card>
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Online Examination (Archive)</h3>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th className="sort" data-sort="name" scope="col">
                            #
                          </th>
                          <th className="sort" data-sort="budget" scope="col">
                            Date Created
                          </th>
                          <th className="sort" data-sort="status" scope="col">
                            Title
                          </th>
                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody className="list">
                        {this.state.archiveOnlineExaminationList.map(function (
                          value,
                          index
                        ) {
                          return (
                            <tr key={index}>
                              <td className="budget">{index + 1}</td>
                              <td className="budget">{value.dateCreated}</td>
                              <td className="budget">
                                {value.examinationTitle} - {value.title}
                              </td>
                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    color=""
                                    size="sm"
                                    className="btn-icon-only text-light"
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();
                                        obj.props.history.push(
                                          `/student/onlineExamination/result/${value.uniqueId}`
                                        );
                                      }}
                                    >
                                      <i className="ni ni-chart-bar-32"></i>
                                      &nbsp;See Result
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </>
    );
  }
}

export default StudentOnlineExaminationList;
