/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
  Card,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import SectionService from "../../../services/sectionService";
import QuestionService from "../../../services/questionService";

class QuestionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
    this.delete = this.delete.bind(this);
    this.notify = this.notify.bind(this);
  }

  componentDidMount() {
    QuestionService.list().then((response) => {
      console.log(response);
      this.setState({ list: response });
    });
  }

  delete(uniqueId) {
    SectionService.delete(uniqueId).then((response) => {
      console.log(response);
      this.notify(
        response.responseStatus.toLowerCase(),
        response.responseStatus,
        response.message
      );
      //TODO: TO be fixed with redux implementation
      //   this.props.history.push("/admin/dashboard");
      SectionService.list().then((response) => {
        console.log(response);
        this.setState({ list: response });
      });
    });
  }

  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    let obj = this;
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <SimpleHeader
          name="List"
          parentName="Question"
          heading="ClashMate"
          newBtnUrl="/teacher/question/create"
          newBtnText="Create Question"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Question List</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        #
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Date Created
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Question Text
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Class
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {this.state.list.map(function (value, index) {
                      return (
                        <tr key={index}>
                          <td className="budget">{index + 1}</td>
                          <td className="budget">{value.dateCreated}</td>
                          <td className="budget" id={`questionText_${index}`}>
                            {value.abbreviatedQuestionText}
                          </td>
                          <td className="budget">
                            {value.courseSectionSubjectTitle}
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color=""
                                size="sm"
                                className="btn-icon-only text-light"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    obj.props.history.push(
                                      `/teacher/question/show/${value.uniqueId}`
                                    );
                                  }}
                                >
                                  Details
                                </DropdownItem>
                                <DropdownItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    obj.props.history.push(
                                      `/teacher/question/edit/${value.uniqueId}`
                                    );
                                  }}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    obj.delete(`${value.uniqueId}`);
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                          <UncontrolledTooltip
                            delay={0}
                            placement="bottom"
                            target={`questionText_${index}`}
                          >
                            {value.questionText}
                          </UncontrolledTooltip>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default QuestionList;
