/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileAlt, faFileAudio, faFilePdf, faFileVideo, faImage} from "@fortawesome/free-regular-svg-icons";
import DownloadUtil from "../../../util/downloadUtil";

class ContentTypeIconHandler extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            doc: this.props.doc,
            size: this.props.size ? this.props.size : 2,
            icon: null,
        };
    };

    componentDidMount() {
        this.fetchIcon();
    };

    imageHandler = (event) => {
        event.preventDefault();
    };

    pdfHandler = (event) => {
        event.preventDefault();
    };

    videoHandler = (event) => {
        event.preventDefault();
    };

    audioHandler = (event) => {
        event.preventDefault();
    };

    downloadHandler = (event) => {
        event.preventDefault();
        DownloadUtil.download(this.state.doc);
    };

    fetchIcon = () => {
        let icon = null;
        let contentType = this.state.doc.contentType;
        let {imageHandler, videoHandler, audioHandler, pdfHandler, downloadHandler} = this.props;
        if (/image\/*/.test(contentType)) {
            icon = <FontAwesomeIcon onClick={imageHandler ? imageHandler : this.downloadHandler} icon={faImage}
                                    size={`${this.state.size}x`}
                                    color="#11cdef"/>
        } else if (/video\/*/.test(contentType)) {
            icon = <FontAwesomeIcon onClick={videoHandler ? videoHandler : this.downloadHandler} icon={faFileVideo}
                                    size={`${this.state.size}x`}
                                    color="#11cdef"/>
        } else if (/audio\/*/.test(contentType)) {
            icon = <FontAwesomeIcon onClick={audioHandler ? audioHandler : this.downloadHandler} icon={faFileAudio}
                                    size={`${this.state.size}x`}
                                    color="red"/>
        } else if (/application\/pdf/.test(contentType)) {
            icon = <FontAwesomeIcon onClick={pdfHandler ? pdfHandler : this.downloadHandler} icon={faFilePdf}
                                    size={`${this.state.size}x`}
                                    color="crimson"/>
        } else if (/application\/*/.test(contentType)) {
            icon = <FontAwesomeIcon onClick={downloadHandler ? downloadHandler : this.downloadHandler} icon={faFileAlt}
                                    size={`${this.state.size}x`}
                                    color="cadetblue"/>
        } else {
            icon = <FontAwesomeIcon onClick={downloadHandler ? downloadHandler : this.downloadHandler} icon={faFileAlt}
                                    size={`${this.state.size}x`}
                                    color="#11cdef"/>
        }
        return icon;
    };

    render() {
        return (
            <>{this.fetchIcon()} </>
        );
    }
}

export default ContentTypeIconHandler;
