/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Container, Row,} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import Col from "reactstrap/es/Col";
import StudyMaterialService from "../../../../services/studyMaterialService";
import Card from "reactstrap/es/Card";
import CardHeader from "reactstrap/es/CardHeader";
import CardBody from "reactstrap/es/CardBody";
import ContentService from "../../../../services/contentService";
import _ from "lodash"
import Collapse from "reactstrap/es/Collapse";
import ContentDocumentService from "../../../../services/contentDocumentService";
import MarketAttachment from "./MarketAttachment";

class MarketPlaceCourse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            studyMaterial: null,
            contentList: [],
            alert: null,
            parentContentList: [],
            openedCollapses: [],
            content: null,
            contentDocumentList: [],
            uniqueId: this.props.match.params.uniqueId
        };
        this.notify = this.notify.bind(this);
    }

    setContent = uniqueId => {
        let content = _.find(this.state.contentList, content => content?.uniqueId === uniqueId);
        this.setState({content: content});
        this.setContentDocumentList(uniqueId);
    };

    setContentDocumentList = (uniqueId) => {
        ContentDocumentService.list(uniqueId).then(response => {
            this.setState({contentDocumentList: response.data});
        })
    };

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: [], content: null
            });

        } else {
            this.setContent(collapse);
            this.setState({
                openedCollapses: [collapse]
            });
        }
    };

    setParentContents = () => {
        let parentContentList = _.filter(this.state.contentList, content => content?.parentUID === null);
        this.setState({parentContentList: parentContentList});
    };

    componentDidMount() {
        let {uniqueId} = this.props.match.params;
        StudyMaterialService.fetch(uniqueId).then((response) => {
            this.setState({studyMaterial: response.data});
        });
        ContentService.list(uniqueId).then((response) => {
            this.setState({contentList: response.data});
            this.setParentContents();
        });
    }

    getChildContents = parentContent => {
        return _.filter(this.state.contentList, content => content?.parentUID === parentContent?.uniqueId);
    };

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    render() {
        let obj = this;
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <SimpleHeader
                    name="Course"
                    parentName="Market"
                    heading="ClashMate"
                    // newBtnUrl={`/${AuthService.getBaseURLByRole()}/studyMaterial/create`}
                    // newBtnText="Create Study Material"
                />
                {this.state.alert}
                <Container className="mt--6" fluid>
                    <Row>
                        {/*Description Pane*/}
                        <Col md="7">
                            <Card className="mb-4">
                                <CardHeader>
                                    <h3 className="mb-0">Title - {this.state?.studyMaterial?.title}</h3>
                                </CardHeader>
                                <CardBody>
                                    {this.state.contentDocumentList?.length > 0 ? <Row>
                                        <Col sm="12">
                                            <p className="h3">Attachments - {this.state.contentDocumentList?.length}</p>
                                            {this.state.contentDocumentList.map(contentDocument => {
                                                return (
                                                    <>
                                                    <MarketAttachment contentDocument={contentDocument}
                                                                      onIconClick={() => alert("Hello")}
                                                    />
                                                    </>
                                                );
                                            })}
                                        </Col>
                                    </Row> : ""}

                                    <Row>
                                        <Col sm="12">
                                            <p className="h3">{this.state?.content?.order ?? ""} {this.state?.content?.title ?? this.state?.studyMaterial?.title}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <p className="h5">{this.state?.content?.description ?? this.state?.studyMaterial?.description}</p>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        {/*ContentList Pane*/}
                        <Col md="5">
                            <Card className="mb-4">
                                <CardHeader>
                                    <h3 className="mb-0">Contents</h3>
                                </CardHeader>
                                <CardBody>
                                    {this.state.parentContentList.map(parentContent => {
                                        return (
                                            <>
                                                <Card className="card-plain" key={parentContent?.uniqueId}>
                                                    <CardHeader
                                                        role="tab"
                                                        onClick={() => obj.collapsesToggle(parentContent.uniqueId)}
                                                        aria-expanded={obj.state.openedCollapses.includes(
                                                            parentContent.uniqueId
                                                        )}
                                                    >
                                                        <h5 className="mb-0"
                                                            stylename={{cursor: "pointer"}}>{parentContent.title}</h5>
                                                    </CardHeader>
                                                    <Collapse
                                                        role="tabpanel"
                                                        isOpen={obj.state.openedCollapses.includes(parentContent.uniqueId)}
                                                    >
                                                        <CardBody>
                                                            {
                                                                obj.getChildContents(parentContent)?.map(
                                                                    childContent => {
                                                                        if (childContent) {
                                                                            return (
                                                                                <>
                                                                                    <Card className="card-frame"
                                                                                          key={childContent?.uniqueId}
                                                                                          onClick={() => obj.setContent(childContent.uniqueId)}>
                                                                                        <CardBody
                                                                                        >{childContent.title}</CardBody>
                                                                                    </Card>
                                                                                </>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <>
                                                                                    <Card className="card-frame">
                                                                                        <CardBody
                                                                                        >{"No sub contents"}</CardBody>
                                                                                    </Card>
                                                                                </>
                                                                            );
                                                                        }
                                                                    }
                                                                )}
                                                        </CardBody>
                                                    </Collapse>
                                                </Card>
                                            </>
                                        );
                                    })}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </>
        );
    }
}

export default MarketPlaceCourse;
