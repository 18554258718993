import {
  Card,
  CardHeader,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import securityConstant from "../../../util/securityConstant";

const AdminListTable = ({
  adminList,
  adminComponentObj,
  cardTitle,
  tenantUID,
  userRole,
  ...props
}) => {
  return (
    <Card>
      <CardHeader className="border-0">
        <Row className="align-items-center">
          <div className="col-6">
            <h3 className="mb-0">{cardTitle}</h3>
          </div>
          <div className={"col-6 " + (tenantUID ? "" : "hidden")}>
            <Button
              color="primary"
              type="button"
              size="sm"
              onClick={(e) => {
                e.preventDefault();
                adminComponentObj.props.history.push(
                  `/superAdmin/tenant/admin/create/${tenantUID}`
                );
              }}
              className="fa-pull-right"
            >
              Create Tenant Admin
            </Button>
          </div>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
        <tr>
          <th className="sort" data-sort="name" scope="col">
            #
          </th>
          <th className="sort" data-sort="budget" scope="col">
            Date Created
          </th>
          <th className="sort" data-sort="status" scope="col">
            Name
          </th>
          <th className="sort" data-sort="status" scope="col">
            Email Address
          </th>
          <th className="sort" data-sort="status" scope="col">
            Role
          </th>
          <th scope="col"/>
        </tr>
        </thead>
        <tbody className="list">
        {adminList.map(function (value, index) {
            return (
              <tr key={index}>
                <td className="budget">{index + 1}</td>
                <td className="budget">{value.dateCreated}</td>
                <td className="budget">{value.fullName}</td>
                <td className="budget">{value.emailAddress}</td>
                <td className="budget">{value.role}</td>
                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color=""
                      size="sm"
                      className="btn-icon-only text-light"
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem
                        onClick={(e) => {
                          e.preventDefault();
                          userRole === securityConstant.ROLE_ADMIN
                            ? adminComponentObj.props.history.push(
                                `/admin/show/${value.uniqueId}`
                              )
                            : adminComponentObj.props.history.push(
                                `/superadmin/tenant/admin/show/${value.uniqueId}`
                              );
                        }}
                      >
                        Details
                      </DropdownItem>
                      {userRole === securityConstant.ROLE_SUPER_ADMIN || (
                        <>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault();
                              adminComponentObj.props.history.push(
                                `/admin/edit/${value.uniqueId}`
                              );
                            }}
                          >
                            Edit
                          </DropdownItem>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault();
                              adminComponentObj.delete(`${value.uniqueId}`);
                            }}
                          >
                            Delete
                          </DropdownItem>
                        </>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Card>
  );
};

AdminListTable.propTypes = {
  adminList: PropTypes.array.isRequired,
  adminComponentObj: PropTypes.object.isRequired,
  cardTitle: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default AdminListTable;
