import SimpleHeader from "../../../../components/Headers/SimpleHeader";
import "file-upload-with-preview/dist/file-upload-with-preview.min.css";
import React from "react";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, Row,} from "reactstrap";
import CourseSectionSubjectService from "../../../../services/courseSectionSubjectService";
import _ from "lodash";
import FormReactSelect from "../../../../components/common/form/FormReactSelect";
import CourseSectionService from "../../../../services/courseSectionService";
import UtilService from "../../../../services/utilService";
import FormInput from "../../../../components/common/form/FormInput";
import NotificationAlert from "react-notification-alert";
import PostService from "../../../../services/postService";
import AuthService from "../../../../services/authService";
import Moment from "react-moment";
import DocumentService from "../../../../services/documentService";
import AppRedirect from "../../../../util/appRedirect";

class PostEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            valueObj: {
                documentList: [],
            },
            files: [],
            upload: null,
            text: null,
            selectedSection: {},
            selectedCourse: {},
            selectedSubject: {},
            dateStart: null,
            dateEnd: null,
            errorData: {},
            courseSectionSubjectList: [],
            displayList: [],
            courseSectionList: [],
            mappedCoursesList: [],
            mappedSectionsList: [],
            mappedSubjectsList: [],
            mappedStudentsList: [],
            sectionSelectUUID: UtilService.uuidv4(),
            subjectSelectUUID: UtilService.uuidv4(),
            fileSelectUUID: UtilService.uuidv4(),
            dateList: [],
            studentList: [],
            deletedDocuments: [],
            uniqueId: this.props.match.params.uniqueId
        };
        this.handleCourseChange = this.handleCourseChange.bind(this);
        this.handleSectionChange = this.handleSectionChange.bind(this);
    }

    setPost = () => {
        const {uniqueId} = this.props.match.params;
        this.setState({uniqueId: uniqueId});
        PostService.fetch(uniqueId).then(response => {
            console.log(response.data);
            console.log(response.data.subject);
            this.setState({valueObj: response.data});
            this.setState({selectedCourse: response.data.course});
            this.setState({text: response.data.text});
            this.setState({selectedSection: response.data.section});
            this.setState({selectedSubject: response.data.subject});
            this.setState({uniqueId: uniqueId});
            this.handleCourseChange(response.data.course);
            this.handleSectionChange(response.data.section);
            this.handleSubjectChange(response.data.subject);
        });
    }

    onChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }

    fileChange = (event) => {
        this.setState({files: [...event.target.files]})
    };

    handleSectionChange = (value) => {
        this.setState({subjectSelectUUID: UtilService.uuidv4()});
        this.setState({selectedSubject: {}});
        if (_.isNull(value)) {
            this.setState({selectedSection: {}});
        } else {
            this.setState({selectedSection: value});
        }
        this.setState({displayList: []});
        this.populateSubjects(this.state.selectedCourse, value);
    }

    handleCourseChange = (value) => {
        this.setState({sectionSelectUUID: UtilService.uuidv4()});
        this.setState({subjectSelectUUID: UtilService.uuidv4()});
        if (_.isNull(value)) {
            this.setState({selectedCourse: {}});
        } else {
            this.setState({selectedCourse: value});
        }
        this.setState({selectedSection: {}});
        this.setState({selectedSubject: {}});
        this.setState({mappedSectionsList: []});
        this.setState({displayList: []});
        this.populateSections(value);
    }

    handleSubjectChange = (value) => {
        if (_.isNull(value)) {
            this.setState({selectedSubject: {}});
        } else {
            this.setState({selectedSubject: value});
        }
        this.setState({date: null})
    };

    populateSections = (course) => {
        let sectionsList = _.filter(
            this.state.courseSectionList,
            (courseSection) => {
                return courseSection.course.uniqueId === course.uniqueId;
            }
        );
        let mappedSectionsList = _.uniqBy(_.map(sectionsList, "section"), "title");
        this.setState({
            mappedSectionsList: mappedSectionsList,
        });
    };

    populateSubjects = (course, section) => {
        let mappedCourseSectionSubjects = _.filter(
            this.state.courseSectionSubjectList,
            (courseSectionSubject) => {
                return (
                    courseSectionSubject.course.uniqueId === course.uniqueId &&
                    courseSectionSubject.section.uniqueId === section.uniqueId
                );
            }
        );

        let mappedSubjectsList = _.map(mappedCourseSectionSubjects, "subject");
        this.setState({mappedSubjectsList: mappedSubjectsList});
    };

    populateCourseSectionSubjects = async () => {
        await CourseSectionSubjectService.fetchCourseSectionSubjects().then(
            (res) => {
                this.setState({courseSectionSubjectList: res.data});
            }
        );
    };

    populateCourseSections = async () => {
        await CourseSectionService.fetchCourseSections().then((res) => {
            this.setState({courseSectionList: res.data});
            let mappedCoursesList = _.uniqBy(
                _.map(this.state.courseSectionList, "course"),
                "title"
            );
            this.setState({
                mappedCoursesList: mappedCoursesList,
            });
        });
    };

    editPost = async () => {
        let self = this;
        let allOptionsNotSelected = _.isEmpty(this.state.text) && (_.isEmpty(this.state.files));
        if (allOptionsNotSelected === true) {
            console.error("All options are not selected!");
            this.notify(
                "error",
                "Error",
                "Cannot create empty post!"
            );
        } else {
            const formData = new FormData();
            if (this.state.files !== null) {
                for (var i = 0; i < this.state.files.length; i++) {
                    formData.append('files', this.state.files[i]);
                }
            }
            formData.append('documentDeletionUIDs', this.state.deletedDocuments);
            formData.append('uniqueId', this.state.valueObj.uniqueId);
            formData.append('courseUID', this.state.selectedCourse.uniqueId ? this.state.selectedCourse.uniqueId : "");
            formData.append('sectionUID', this.state.selectedSection.uniqueId ? this.state.selectedSection.uniqueId : "");
            formData.append('subjectUID', this.state.selectedSubject.uniqueId ? this.state.selectedSubject.uniqueId : "");
            formData.append('text', this.state.text ? this.state.text : null);
            console.log(formData);
            await PostService.update(formData).then(response => {
                if (response.responseStatus === "SUCCESS") {
                    // this.setPost();
                    this.setState({files: []});
                    this.notify(
                        response.responseStatus.toLowerCase(),
                        response.responseStatus,
                        response.message
                    );
                    AppRedirect.redirectWithWait(self, `/${AuthService.getBaseURLByRole()}/post/show/${self.state.valueObj.uniqueId}`, 3000);
                } else {
                    this.setState({errorData: response.data});
                    console.error("Error    ==>     " + response.message);
                }

            });

        }

    };

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    componentDidMount() {
        this.populateCourseSectionSubjects();
        this.populateCourseSections();
        this.setPost();
    };


    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    toggle = (array, item) => _.xor(array, [item]);

    markDelete = (uniqueId) => {
        console.log(uniqueId);
        let deletedDocuments = [...this.state.deletedDocuments];
        this.setState({deletedDocuments: this.toggle(deletedDocuments, uniqueId)});
    };

    download = (blob, doc) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', doc.originalFileName); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    downloadDocument = async (uniqueId) => {
        let doc = _.find([...this.state.valueObj.documentList], (value) => value.uniqueId === uniqueId)
        DocumentService.fetch(uniqueId).then(response => {
            // console.log(response)
            this.download(response, doc);
        })
    };

    render() {
        let self = this;
        return (
            <>
                <SimpleHeader
                    parentName="Edit"
                    name="Post"
                    sectionName=""
                    heading="ClashMate"
                    newBtnText={"Feeds"}
                    newBtnUrl={`/${AuthService.getBaseURLByRole()}/post/feeds`}
                />
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <Container className="mt--6" fluid>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <Col sm="12">
                                            <h3 className="mb-0">Edit Post</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form role="form">
                                        <Row>
                                            <Col sm="12">
                                                <FormInput
                                                    name="text"
                                                    className="form-control-label"
                                                    id="text"
                                                    placeholder="inputPlaceHolder.post.create"
                                                    type="textarea"
                                                    onChange={self.onChange}
                                                    label="inputLabel.post.create"
                                                    value={this.state.text || ""}
                                                    // errorMsg={this.props.errorObj.text || ""}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center">
                                            <Col sm="12">
                                                <h3 className="mb-0">Manage attachments</h3>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center">
                                            <Col sm="12">
                                                <h3 className="mb-0">&nbsp;</h3>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl="6">
                                                <h5 className="mb-0">Add attachments</h5>
                                                <FormInput
                                                    name="files"
                                                    className="form-control-label custom-file-input"
                                                    id="files"
                                                    placeholder="Select File"
                                                    type="file"
                                                    onChange={this.fileChange}
                                                    label="Browse"
                                                    multiple={true}
                                                    key={this.state.fileSelectUUID}
                                                />
                                                {this.state.files.map(function (value, index) {
                                                    return (
                                                        <>
                                                            <span
                                                                className="badge badge-pill badge-primary">{value.name}</span>
                                                        </>
                                                    );
                                                })}
                                            </Col>
                                            <Col xl="6">
                                                <h5 className="mb-0">Delete attachments</h5>
                                                <ul className="list-group list-group-flush" data-toggle="checklist">
                                                    {[...this.state.valueObj.documentList].map((value, index) => {
                                                        return (
                                                            <>
                                                                <li key={value.uniqueId}
                                                                    className="checklist-entry list-group-item flex-column align-items-start py-4 px-4">
                                                                    <div
                                                                        className="checklist-item checklist-item-danger">
                                                                        <div className="checklist-info">
                                                                            <h5
                                                                                className="checklist-title mb-0">{value.originalFileName}</h5>
                                                                            <small><Moment
                                                                                format="LL">{value.startTime}</Moment></small>
                                                                            <span
                                                                                onClick={() => self.downloadDocument(value.uniqueId)}
                                                                                className="badge badge-primary">Download</span>
                                                                        </div>
                                                                        <div>
                                                                            <div
                                                                                className="custom-control custom-checkbox custom-checkbox-danger">
                                                                                <input className="custom-control-input"
                                                                                       id={"chk-todo-task-".concat(index)}
                                                                                       type="checkbox"
                                                                                       onClick={() => {
                                                                                           self.markDelete(value.uniqueId)
                                                                                       }}/>
                                                                                <label className="custom-control-label"
                                                                                       htmlFor={"chk-todo-task-".concat(index)}>Delete</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </>
                                                        )
                                                    })}
                                                </ul>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center">
                                            <Col sm="12">
                                                <h3 className="mb-0">Choose Visibility</h3>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="4">
                                                <FormReactSelect
                                                    isMulti={false}
                                                    className="form-control-label"
                                                    isSearchable={true}
                                                    name={"courseUid"}
                                                    placeHolder="inputPlaceHolder.post.edit.select.course"
                                                    onChange={this.handleCourseChange}
                                                    optionValue={"uniqueId"}
                                                    // value={this.state.valueObj.course ? this.state.valueObj.course.title :""}
                                                    optionList={this.state.mappedCoursesList}
                                                    optionLabel={"title"}
                                                    label="inputLabel.post.select.course"
                                                    errorMsg={this.state.errorData.courseUid || ""}
                                                />
                                                <span
                                                    className="badge badge-primary">Current Course : {this.state.valueObj.course ? this.state.valueObj.course.title : "N/A"}</span>
                                            </Col>
                                            <Col md="4">
                                                <FormReactSelect
                                                    selectKey={`section_select_${this.state.sectionSelectUUID}`}
                                                    isMulti={false}
                                                    className="form-control-label"
                                                    isSearchable={true}
                                                    label="inputLabel.post.edit.select.section"
                                                    name={"sectionUid"}
                                                    onChange={this.handleSectionChange}
                                                    optionValue={"uniqueId"}
                                                    optionList={this.state.mappedSectionsList}
                                                    optionLabel={"title"}
                                                    placeHolder="inputPlaceHolder.post.edit.select.section"
                                                    errorMsg={this.state.errorData.sectionUid || ""}
                                                />
                                                <span
                                                    className="badge badge-primary">Current Section : {this.state.valueObj.section ? this.state.valueObj.section.title : "N/A"}</span>
                                            </Col>
                                            <Col md="4">
                                                <FormReactSelect
                                                    selectKey={`subject_select_${this.state.subjectSelectUUID}`}
                                                    isMulti={false}
                                                    className="form-control-label"
                                                    isSearchable={true}
                                                    label="inputLabel.post.edit.select.subject"
                                                    name={"subjectUid"}
                                                    onChange={this.handleSubjectChange}
                                                    optionValue={"uniqueId"}
                                                    optionList={this.state.mappedSubjectsList}
                                                    optionLabel={"title"}
                                                    placeHolder="inputPlaceHolder.post.edit.select.subject"
                                                    errorMsg={this.state.errorData.subjectUid || ""}
                                                />
                                                <span
                                                    className="badge badge-primary">Current Subject : {this.state.valueObj.subject ? this.state.valueObj.subject.title : "N/A"}</span>
                                            </Col>
                                        </Row>

                                        <div className="text-center">
                                            <Button className="my-4" color="info" type="button"
                                                    onClick={this.editPost}>
                                                Update Post
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    };
}

export default PostEdit;
