import axiosInstance from "../api/api";

const OnlineExaminationService = {
  listByExamination: function (examinationUID) {
    return axiosInstance({
      url: `/api/onlineExamination/examination/${examinationUID}`,
      method: "GET",
    });
  },
  fetch: function (uniqueId) {
    return axiosInstance({
      url: `/api/onlineExamination/${uniqueId}`,
      method: "GET",
    });
  },
  deleteQuestion: function (uniqueId, questionUID) {
    return axiosInstance({
      url: `/api/onlineExamination/examination/${uniqueId}/${questionUID}`,
      method: "DELETE",
    });
  },
  delete: function (uniqueId) {
    return axiosInstance({
      url: `/api/onlineExamination/${uniqueId}`,
      method: "DELETE",
    });
  },
  create: function (formData) {
    return axiosInstance({
      url: `/api/onlineExamination/`,
      method: "POST",
      data: formData,
    });
  },
  update: function (formData) {
    return axiosInstance({
      url: `/api/onlineExamination/`,
      method: "PATCH",
      data: formData,
    });
  },
  assignedStudentList: function (uniqueId) {
    return axiosInstance({
      url: `/api/onlineExamination/student/${uniqueId}`,
      method: "GET",
    });
  }
};
export default OnlineExaminationService;
