/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  faCompress,
  faDesktop,
  faExpand,
  faMicrophoneAltSlash,
  faPhoneSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import ReactFullScreeen from "react-easyfullscreen";
import Jitsi from "react-jitsi";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import AuthService from "../../../../services/authService";
import MeetingService from "../../../../services/meetingService";
import ParticipantService from "../../../../services/participantService";
import SecurityRole from "../../../../util/securityConstant";

class MeetingStart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVideoFullScreen: false,
      meetingData: {
        userDTOList: [],
      },
      JitsiObj: null,
      totalParticipantCount: 0,
      isScreenShareEnabled: false,
      isModerator: false,
    };
    this.videoHangUp = this.videoHangUp.bind(this);
    this.videoConferenceJoined = this.videoConferenceJoined.bind(this);
    this.syncTimeActivity = this.syncTimeActivity.bind(this);
    this.toggleScreenShare = this.toggleScreenShare.bind(this);
    this.muteEveryone = this.muteEveryone.bind(this);
  }

  componentDidMount() {
    const { uniqueId } = this.props.match.params;
    MeetingService.fetch(uniqueId).then((response) => {
      console.log(response.data);
      this.setState({
        meetingData: response.data,
        isModerator: AuthService.isCurrentUserRoleMatch(
          SecurityRole.ROLE_TEACHER
        ),
      });
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.timeActivitySync);
  }

  videoConferenceJoined(data) {
    const totalParticipantCount = this.state.JitsiObj.getNumberOfParticipants();
    this.setState({
      meetingStartTime: new Date(),
      timeActivitySync: setInterval(() => this.syncTimeActivity(), 20000),
      totalParticipantCount: totalParticipantCount,
    });
    console.log("Conference Joined" + data.roomName);
  }

  screenShareEvent(data) {
    this.setState({ isScreenShareEnabled: data.on });
  }

  syncTimeActivity(isCallEnded = false) {
    const formData = {
      meetingStartTime: this.state.meetingStartTime,
      meetingEndTime: new Date(),
      meetingUID: this.state.meetingData.uniqueId,
    };
    ParticipantService.videoHangup(formData).then((response) => {
      console.log(response.data);
      this.setState({ meetingStartTime: new Date() });
      if (response.responseStatus === "SUCCESS") {
        console.log("Video Call Data Successfully Synced");
        if (isCallEnded) {
          this.state.JitsiObj.executeCommand("hangup");
          this.props.history.push(
            `/${AuthService.getBaseURLByRole()}/meeting/list`
          );
        }
      } else {
        this.setState({ errorData: response.data });
        console.error("Error    ==>     " + response.message);
      }
    });
  }

  videoHangUp(event) {
    event.preventDefault();
    this.syncTimeActivity(true);
  }

  muteEveryone(event) {
    event.preventDefault();
    this.state.JitsiObj.executeCommand("muteEveryone");
  }

  toggleScreenShare(event) {
    event.preventDefault();
    this.state.JitsiObj.executeCommand("toggleShareScreen");
  }

  render() {
    const obj = this;
    const config = {
      defaultLanguage: "en",
      prejoinPageEnabled: false,
      remoteVideoMenu: {
        disableKick: true,
      },
      disableRemoteMute: true,
      enableWelcomePage: false,
    };

    const interfaceConfig = {
      LANG_DETECTION: false,
      lang: "en",
      APP_NAME: "ClassTrek",
      DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
      HIDE_INVITE_MORE_HEADER: true,
      MOBILE_APP_PROMO: false,
      SHOW_CHROME_EXTENSION_BANNER: false,
      SHOW_PROMOTIONAL_CLOSE_PAGE: false,
      JITSI_WATERMARK_LINK: "https://classtrek.in",
      TOOLBAR_BUTTONS: [
        "microphone",
        "camera",
        "fodeviceselection",
        "videoquality",
        "tileview",
        "videobackgroundblur",
        "raisehand",
        // "security",
      ],
    };

    const handleAPI = (JitsiMeetAPI) => {
      obj.setState({ JitsiObj: JitsiMeetAPI });
      JitsiMeetAPI.addListener("videoConferenceJoined", function (result) {
        obj.videoConferenceJoined(result);
      });
      JitsiMeetAPI.addListener("screenSharingStatusChanged", function (result) {
        obj.screenShareEvent(result);
      });
    };

    const { meetingData } = this.state;

    return (
      <>
        <SimpleHeader name="Start" parentName="Meeting" heading="ClashMate" />
        <Container className="mt--6" fluid>
          <ReactFullScreeen>
            {({ ref, onRequest, onExit }) => (
              <Row>
                <Col md="12">
                  <div ref={ref}>
                    <Card
                      className="mb-4"
                      style={{
                        width: "100%",
                        height: this.state.isVideoFullScreen
                          ? "950px"
                          : "600px",
                      }}
                    >
                      <CardHeader>
                        <Row>
                          <Col md="6">
                            <h3 className="mb-0">{meetingData.title}</h3>
                            <h5 className="mb-0">
                              {this.state.totalParticipantCount}
                              &nbsp;participants joined
                            </h5>
                          </Col>
                          <Col md="6">
                            <div className="float-right">
                              <Button
                                className="btn-icon-only rounded-circle hidden"
                                color="danger"
                                style={{
                                  display: this.state.isModerator ? "" : "none",
                                }}
                                onClick={(e) => {
                                  obj.muteEveryone(e);
                                }}
                              >
                                <FontAwesomeIcon icon={faMicrophoneAltSlash} />
                              </Button>
                              <Button
                                className="btn-icon-only rounded-circle"
                                color={
                                  this.state.isScreenShareEnabled
                                    ? "danger"
                                    : "primary"
                                }
                                style={{
                                  display: this.state.isModerator ? "" : "none",
                                }}
                                onClick={(e) => {
                                  obj.toggleScreenShare(e);
                                }}
                              >
                                <FontAwesomeIcon icon={faDesktop} />
                              </Button>
                              <Button
                                className="btn-icon-only rounded-circle"
                                color="danger"
                                onClick={(e) => {
                                  obj.videoHangUp(e);
                                }}
                              >
                                <FontAwesomeIcon icon={faPhoneSlash} />
                              </Button>
                              <Button
                                className="btn-icon-only rounded-circle"
                                color="info"
                                onClick={() => {
                                  obj.state.isVideoFullScreen
                                    ? onExit()
                                    : onRequest();
                                  obj.setState({
                                    isVideoFullScreen: !obj.state
                                      .isVideoFullScreen,
                                  });
                                }}
                              >
                                {this.state.isVideoFullScreen ? (
                                  <FontAwesomeIcon icon={faCompress} />
                                ) : (
                                  <FontAwesomeIcon icon={faExpand} />
                                )}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {this.state.meetingData.title != null ? (
                          <Jitsi
                            domain="meet.jit.si"
                            onAPILoad={handleAPI}
                            roomName={meetingData.title}
                            displayName={"demo"}
                            subject={meetingData.title}
                            interfaceConfig={interfaceConfig}
                            config={config}
                            containerStyle={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        ) : (
                          "Loading..."
                        )}
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              </Row>
            )}
          </ReactFullScreeen>
        </Container>
      </>
    );
  }
}

export default MeetingStart;
