import SimpleHeader from "../../../components/Headers/SimpleHeader";
import React from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "reactstrap";
import CourseSectionSubjectStudentService from "../../../services/courseSectionSubjectStudentService";
import StudentParentService from "../../../services/studentParentService";
import _ from "lodash";
import UtilService from "../../../services/utilService";
import AttendanceService from "../../../services/attendanceService";
import FormInput from "../../../components/common/form/FormInput";
import NotificationAlert from "react-notification-alert";
import moment from "moment";
import FormReactSelect from "../../../components/common/form/FormReactSelect";

class AttendanceParentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studentUid: null,
      dateStart: null,
      dateEnd: null,
      errorData: {},
      courseSectionSubjectStudentList: [],
      displayList: [],
      courseSectionList: [],
      mappedSubjectsList: [],
      mappedStudentsList: [],
      dateList: [],
      subjectList: [],
      studentList: [],
    };
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  populateStudents = async () => {
    await StudentParentService.fetch().then((response) => {
      let studentList = _.uniqBy(_.map(response.data, "student"), "uniqueId");
      console.log(response);
      this.setState({ studentList: studentList });
    });
  };

  populateDisplayList = async () => {
    if (this.state.studentUid === null) {
      this.notify("Warning", "Warning", "Please select Student");
    } else {
      this.setState({ dateList: [] });
      let formData = {
        studentUid: this.state.studentUid,
        dateStart: this.state.dateStart,
        dateEnd: this.state.dateEnd,
      };
      await AttendanceService.filteredStudentAttendanceList(formData).then(
        (response) => {
          this.setState({ displayList: response.data });
          let dateList = this.populateDateList();
          this.setState({ dateList: dateList });
        }
      );
    }
  };

  populateDateList = () => {
    let startDate = moment(this.state.dateStart);
    let endDate = moment(this.state.dateEnd);
    var now = startDate,
      dates = [];

    while (now.isBefore(endDate) || now.isSame(endDate)) {
      dates.push(now.format("YYYY-MM-DD"));
      now.add(1, "days");
    }
    return dates;
  };

  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  componentDidMount() {
    this.populateStudents();
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  fetchAttendance = (subject, date) => {
    let attendance = _.find(
      this.state.displayList,
      (attendanceObj) =>
        attendanceObj.subject.uniqueId === subject.uniqueId &&
        attendanceObj.date === date
    );
    if (attendance) {
      return attendance.isPresent ? "P" : "A";
    } else {
      return "N/A";
    }
  };

  handleStudentChange = async (value) => {
    if (_.isNull(value)) {
      this.setState({ studentUid: {} });
    } else {
      this.setState({ studentUid: value.uniqueId });
      await CourseSectionSubjectStudentService.fetchCourseSectionSubjects(
        value.uniqueId
      ).then((response) => {
        let subjectList = _.uniqBy(_.map(response.data, "subject"), "uniqueId");
        console.log(response);
        this.setState({ subjectList: subjectList });
      });
    }
  };

  attendanceDisplay = () => {
    if (this.state.displayList.length === 0) {
      return (
        <>
          <CardBody>
            <Alert color="warning">
              <strong>There is no data to display!</strong> Please select
              course, section and subject from the dropdown!
            </Alert>
          </CardBody>
        </>
      );
    } else {
      let self = this;
      return (
        <>
          <CardBody>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th className="sort" data-sort="status" scope="col">
                    #
                  </th>
                  <th className="sort" data-sort="status" scope="col">
                    Student Name
                  </th>
                  {this.state.dateList.map(function (date, index) {
                    return (
                      <th
                        className="sort"
                        key={UtilService.uuidv4()}
                        data-sort="status"
                        scope="col"
                      >
                        {date}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="list">
                {this.state.subjectList.map(function (subject, index) {
                  return (
                    <tr key={index}>
                      <td className="budget">{index + 1}</td>
                      <td className="budget">{subject.title}</td>
                      {self.state.dateList.map(function (date) {
                        return (
                          <td className="budget" key={UtilService.uuidv4()}>
                            <p className="h6">
                              {" "}
                              {self.fetchAttendance(subject, date)}{" "}
                            </p>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>
        </>
      );
    }
  };

  render() {
    return (
      <>
        <SimpleHeader
          parentName="Attendance"
          name="List"
          sectionName="Attendance"
          heading="ClashMate"
          // newBtnText={"Assign Attendance"}
          // newBtnUrl={"/teacher/attendance/assign"}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col sm="12">
                      <h3 className="mb-0">List Attendance</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form role="form">
                    <Row>
                      <Col md="4" sm="6">
                        <FormReactSelect
                          selectKey={`student_select_${this.state.studentSelectUUID}`}
                          isMulti={false}
                          className="form-control-label"
                          isSearchable={true}
                          label="inputLabel.attendance.student"
                          name={"studentUid"}
                          onChange={this.handleStudentChange}
                          optionValue={"uniqueId"}
                          optionList={this.state.studentList}
                          optionLabel={"fullName"}
                          placeHolder="inputPlaceHolder.attendance.select.student"
                          errorMsg={this.state.errorData.studentUid || ""}
                        />
                      </Col>
                      <Col md="4" sm="6">
                        <FormInput
                          name="dateStart"
                          className="form-control-label"
                          id="dateStart"
                          placeholder="inputPlaceHolder.attendance.dateStart"
                          type="date"
                          onChange={this.handleChange}
                          label="inputLabel.attendance.dateStart"
                          value={this.state.dateStart || ""}
                          errorMsg={this.state.errorData.dateStart || ""}
                        />
                      </Col>
                      <Col md="4" sm="6">
                        <FormInput
                          name="dateEnd"
                          className="form-control-label"
                          id="dateEnd"
                          placeholder="inputPlaceHolder.attendance.dateEnd"
                          type="date"
                          onChange={this.handleChange}
                          label="inputLabel.attendance.dateEnd"
                          value={this.state.dateEnd || ""}
                          errorMsg={this.state.errorData.dateEnd || ""}
                        />
                      </Col>
                    </Row>
                    <div className="text-center">
                      <Button
                        className="my-4"
                        color="info"
                        type="button"
                        onClick={this.populateDisplayList}
                      >
                        Display
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <Row className="align-items-center">
                    <Col sm="12">
                      <h3 className="mb-0">Attendance List</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>{this.attendanceDisplay()}</CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AttendanceParentList;
