/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Card, CardBody, CardHeader, Col, Container, FormGroup, Row,} from "reactstrap";
import ContactService from "../../../services/contactService";

class ContactDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contactData: {},
        };
    }

    componentDidMount() {
        const {uniqueId} = this.props.match.params;
        ContactService.fetch(uniqueId).then((response) => {
            console.log(response.data);
            this.setState({contactData: response.data});
        });
    }

    render() {
        const {contactData} = this.state;
        return (
            <>
                <SimpleHeader
                    name="Details"
                    parentName="Contact"
                    heading="XareGrowth"
                    newBtnUrl="/admin/contact/create"
                    newBtnText="Create Contact"
                />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">{contactData.fullName}</h3>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            Title
                                        </label>
                                        <p>{contactData.title}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols2Input"
                                        >
                                            First Name
                                        </label>
                                        <p>
                                            {contactData.firstName ? contactData.firstName : "N/A"}
                                        </p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols3Input"
                                        >
                                            Last Name
                                        </label>
                                        <p>{contactData.lastName}</p>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example4cols1Input"
                                        >
                                            Company Name
                                        </label>
                                        <p>{contactData.company && contactData.company.legalName}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example4cols2Input"
                                        >
                                            Date Created
                                        </label>
                                        <p>{contactData.dateCreated}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example4cols3Input"
                                        >
                                            Contact Role
                                        </label>
                                        <p>{contactData.contactRole}</p>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example4cols3Input"
                                        >
                                            Email Address
                                        </label>
                                        <p>{contactData.email || "N/A"}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example4cols3Input"
                                        >
                                            Contact Number
                                        </label>
                                        <p>{contactData.number || "N/A"}</p>
                                    </FormGroup>
                                </Col>

                            </Row>
                        </CardBody>
                    </Card>
                </Container>
                <br/>
                <br/>
                <br/>
                <br/>
            </>
        );
    }
}

export default ContactDetails;
