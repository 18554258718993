import axiosInstance from "../api/api";

const ContactUsService = {

    contactUs: function (formData) {
        return axiosInstance({
            url: `/api/public/contactUs`,
            method: "POST",
            data: formData,
        });
    }
};
export default ContactUsService;
