import axiosInstance from "../api/api";

const TenantConfigurationService = {
  list: function () {
    return axiosInstance({
      url: "/api/tenantConfiguration/",
      method: "GET",
    });
  },
  update: function (formData) {
    return axiosInstance({
      url: `/api/tenantConfiguration/`,
      method: "PATCH",
      data: formData,
    });
  },
};
export default TenantConfigurationService;
