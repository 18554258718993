import React from "react";
import PropTypes from "prop-types";
import UtilService from "../../../services/utilService";

const ChatMessage = ({ time, messageText, messageClass, participantName }) => {
	return (
		<li class={messageClass} key={UtilService.uuidv4()}>
			{messageClass === "chat-left" && (
				<div class="chat-avatar">
					<img
						src="https://www.bootdey.com/img/Content/avatar/avatar3.png"
						alt="Retail Admin"
					/>
					<div class="chat-name">{participantName}</div>
				</div>
			)}
			<div class="chat-text">
				<div className="text-right">{time}</div>
				<span>{messageText}</span>
			</div>
			{messageClass === "chat-right" && (
				<div class="chat-avatar">
					<img
						src="https://www.bootdey.com/img/Content/avatar/avatar3.png"
						alt="Retail Admin"
					/>
					<div class="chat-name">{participantName}</div>
				</div>
			)}
		</li>
	);
};

ChatMessage.propTypes = {
	time: PropTypes.string,
	messageText: PropTypes.string,
	messageClass: PropTypes.string,
	participantName: PropTypes.string,
};

export default ChatMessage;
