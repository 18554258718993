/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {Card, CardHeader, Container, Row,} from "reactstrap";
import ContentService from "../../../../services/contentService";
import NotificationAlert from "react-notification-alert";
import ContentDocumentService from "../../../../services/contentDocumentService";
import Table from "reactstrap/es/Table";
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import _ from "lodash"
import Button from "reactstrap/es/Button";
import Col from "reactstrap/es/Col";
import FormInput from "../../../../components/common/form/FormInput";
import Form from "reactstrap/es/Form";
import Modal from "reactstrap/es/Modal";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DownloadUtil from "../../../../util/downloadUtil";
import ContentTypeIconHandler from "../../util/ContentTypeIconHandler";
import Lightbox from "react-image-lightbox";
import ReactPlayer from "react-player";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileVideo} from "@fortawesome/free-solid-svg-icons";

class ContentDocument extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contentData: {},
            contentDocList: [],
            description: "",
            name: "",
            videoUrl: "",
            file: null,
            isEditable: false,
            errorData: {},
            isLightBoxOpen: false,
            lightBoxUrl: "",
            isVideoPlayerOpen: false,
            videoPlayerUrl: "",
            videoContentType: ""
        };
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    };

    fetchContentDocList = () => {
        const uniqueId = this.props.contentUid;
        ContentDocumentService.list(uniqueId).then((response) => {
            console.log(response.data);
            this.setState({contentDocList: response.data});
        });
    };

    componentDidMount() {
        const uniqueId = this.props.contentUid;
        ContentService.fetch(uniqueId).then((response) => {
            console.log(response.data);
            this.setState({contentData: response.data});
        });
        this.fetchContentDocList();
    };

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    deleteContentDocument = async (uniqueId) => {
        let self = this;
        this.setState({alert: null});
        await ContentDocumentService.delete(uniqueId).then((response) => {
            console.log(response);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            self.updateContentDocumentList(response.data, true)
        });
    };

    updateContentDocumentList = (contentDocument, isDeleted) => {
        let contentDocListFiltered = _.filter(this.state.contentDocList, contentDoc => contentDoc.uniqueId !== contentDocument.uniqueId);
        if (isDeleted) {
            this.setState({
                contentDocList: contentDocListFiltered, name: "",
                description: "",
                file: null, uniqueId: ""
            });
        } else {
            this.setState({
                contentDocList: [...contentDocListFiltered, contentDocument], name: "",
                description: "",
                file: null, uniqueId: ""
            });
        }
    };

    //Modal Related Methods
    toggleModal = (state, isEditable) => {
        if (isEditable === false) {
            this.setState({
                name: "", description: "", videoUrl: ""
            });
        }
        this.setState({
            [state]: !this.state[state], isEditable: isEditable,
        });
    };

    handleFileChange = (event) => {
        console.log("File Changed");
        this.setState({file: event.target.files[0]});
    };

    imageHandler = (doc) => {
        this.setState({
            isLightBoxOpen: true,
            lightBoxUrl: DownloadUtil.generateUrl(doc.uniqueId),
            isVideoPlayerOpen: false,
            videoName: null,
        });
    };

    videoHandler = (doc) => {
        this.setState({
            isLightBoxOpen: false,
            videoPlayerUrl: DownloadUtil.generateUrl(doc.uniqueId),
            isVideoPlayerOpen: true,
            videoContentType: doc.contentType,
            videoName: doc.originalFileName,
        });
        this.toggleModal("videoModal", false);
    };

    attachResource = async (uniqueId) => {
        let self = this;
        if (this.state.isEditable === true) {
            let allOptionsNotSelected = _.isEmpty(this.state.name) || (_.isEmpty(this.state.description));
            if (allOptionsNotSelected === true) {
                this.notify(
                    "error",
                    "Error",
                    "Name and Description Needed!"
                );
            } else {
                let formData = {
                    'name': this.state.name ? this.state.name : "",
                    'description': this.state.description ? this.state.description : "",
                    'contentUID': this.props.contentUid ? this.props.contentUid : "",
                    'uniqueId': this.state.uniqueId ? this.state.uniqueId : null,
                    'videoUrl': this.state.videoUrl ? this.state.videoUrl : null,
                };
                await ContentDocumentService.update(formData).then(response => {
                    if (response.responseStatus === "SUCCESS") {
                        self.updateContentDocumentList(response.data, false);
                        this.notify(
                            response.responseStatus.toLowerCase(),
                            response.responseStatus,
                            response.message
                        );
                    } else {
                        self.setState({errorData: response.data});
                        console.error("Error    ==>     " + response.message);
                    }
                });
            }
        } else {
            let allOptionsNotSelected = _.isEmpty(this.state.name) || (_.isEmpty(this.state.description));
            const formData = new FormData();
            formData.append('name', this.state.name ? this.state.name : "");
            formData.append('description', this.state.description ? this.state.description : "");
            formData.append('contentUID', this.props.contentUid ? this.props.contentUid : "");
            formData.append('uniqueId', uniqueId ? uniqueId : null);
            if (_.isEmpty(this.state.videoUrl)) {
                formData.append('file', this.state.file ? this.state.file : null);
            } else {
                formData.append('videoUrl', this.state.videoUrl ? this.state.videoUrl : null);
            }
            if (allOptionsNotSelected === true) {
                this.notify(
                    "error",
                    "Error",
                    "Name, Description and File/URL Needed!"
                );
            } else {
                await ContentDocumentService.create(formData).then(response => {
                    if (response.responseStatus === "SUCCESS") {
                        self.setState({
                            contentDocList: [...self.state.contentDocList, response.data],
                            name: "",
                            description: "",
                            file: null,
                            videoUrl: ""
                        });
                        this.notify(
                            response.responseStatus.toLowerCase(),
                            response.responseStatus,
                            response.message
                        );
                        console.log(response)
                    } else {
                        self.setState({errorData: response.data});
                        console.error("Error    ==>     " + response.message);
                    }
                });

            }
        }
        self.toggleModal("contentDocumentModal", false);
    };

    videoUrlHandler = (doc) => {
        this.setState({
            isLightBoxOpen: false,
            videoPlayerUrl: doc.videoUrl,
            isVideoPlayerOpen: true,
            videoName: doc.originalFileName,
        });
        this.toggleModal("videoModal", false);

    };

    showEditContentDocumentModal = (value) => {
        let prefilledData = ({name: value.name, description: value.description});
        this.setState({
            name: value.name,
            description: value.description,
            videoUrl: value.videoUrl,
            uniqueId: value.uniqueId
        });
        this.toggleModal("contentDocumentModal", true)
    };


    render() {
        let obj = this;
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <Container className="mt--10" fluid>
                    <Card>
                        <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0">Content Documents - {this.state.contentDocList.length}</h3>
                                </div>
                                <Button
                                    className="my-4"
                                    color="info"
                                    type="button"
                                    onClick={() => this.toggleModal("contentDocumentModal", false)}
                                >
                                    Add Resources
                                </Button>
                            </Row>
                        </CardHeader>
                        <Modal
                            className="modal-dialog-centered"
                            onOpened={() => {
                                ;
                            }}
                            isOpen={this.state.contentDocumentModal}
                            toggle={() => this.toggleModal("contentDocumentModal", false)}
                        >
                            <div className="modal-header">
                                <h6 className="modal-title" id="modal-title-default">
                                    Add resource
                                </h6>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => this.toggleModal("contentDocumentModal", false)}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Form role="form">
                                    <Row>
                                        <Col md="12">
                                            <FormInput
                                                name="name"
                                                className="form-control-label"
                                                id="name"
                                                placeholder="inputPlaceHolder.contentDoc.name"
                                                type="text"
                                                onChange={this.handleChange}
                                                label="inputLabel.contentDoc.name"
                                                value={this.state.name || ""}
                                                errorMsg={this.state.errorData.name || ""}
                                            />
                                        </Col>
                                        <Col md="12">
                                            <FormInput
                                                name="description"
                                                className="form-control-label"
                                                id="description"
                                                placeholder="inputPlaceHolder.contentDoc.description"
                                                type="text"
                                                onChange={this.handleChange}
                                                label="inputLabel.contentDoc.description"
                                                value={this.state.description || ""}
                                                errorMsg={this.state.errorData.description || ""}
                                            />
                                        </Col>
                                        <Col md="12">
                                            <FormInput
                                                name="videoUrl"
                                                className="form-control-label"
                                                id="videoUrl"
                                                placeholder="inputPlaceHolder.contentDoc.videoUrl"
                                                type="text"
                                                onChange={this.handleChange}
                                                label="inputLabel.contentDoc.videoUrl"
                                                value={this.state.videoUrl || ""}
                                                errorMsg={this.state.errorData.videoUrl || ""}
                                            />
                                        </Col>
                                        <Col md="12" className={this.state.isEditable ? "hidden" : ""}>
                                            <FormInput
                                                name="file"
                                                className="form-control-label"
                                                id="file"
                                                placeholder="inputPlaceHolder.contentDoc.selectedFile"
                                                type="file"
                                                onChange={this.handleFileChange}
                                                label="inputLabel.contentDoc.selectedFile"
                                                // value={this.state.selectedFile || ""}
                                                errorMsg={this.state.errorData.file || ""}
                                            />
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                            <div className="modal-footer">
                                <Button color="primary" type="button"
                                        style={{display: this.state.isEditable === true ? "none" : "block"}}
                                        onClick={() => this.attachResource(null)}>
                                    Attach Resource
                                </Button>
                                <Button color="primary" type="button"
                                        style={{display: this.state.isEditable === true ? "block" : "none"}}
                                        onClick={() => this.attachResource()}>
                                    Update Resource
                                </Button>
                                <Button
                                    className="ml-auto"
                                    color="link"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => this.toggleModal("contentDocumentModal", false)}
                                >
                                    Close
                                </Button>
                            </div>
                        </Modal>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                            <tr>
                                <th className="sort" data-sort="name" scope="col">
                                    #
                                </th>
                                <th className="sort" data-sort="name" scope="col">
                                    Type
                                </th>
                                <th className="sort" data-sort="budget" scope="col">
                                    Date Created
                                </th>
                                <th className="sort" data-sort="status" scope="col">
                                    Name
                                </th>
                                <th className="sort" data-sort="status" scope="col">
                                    Description
                                </th>
                                <th className="sort" data-sort="status" scope="col">
                                    Created By
                                </th>
                                <th scope="col"/>
                            </tr>
                            </thead>
                            <tbody className="list">
                            {this.state.contentDocList.map(function (value, index) {
                                return (
                                    <tr key={index}>
                                        <td className="budget">{index + 1}</td>
                                        <td className="budget">{value.videoUrl ?
                                            <FontAwesomeIcon onClick={() => obj.videoUrlHandler(value)}
                                                             icon={faFileVideo}
                                                             size={`2x`}
                                                             color="#11cdef"/> :
                                            <ContentTypeIconHandler doc={value.document} size={2}
                                                                    imageHandler={() => obj.imageHandler(value.document)}
                                                                    videoHandler={() => obj.videoHandler(value.document)}
                                            />}</td>
                                        <td className="budget">{value.dateCreated}</td>
                                        <td className="budget">{value.name}</td>
                                        <td className="budget">{value.description}</td>
                                        <td className="budget">{value.createdBy.fullName}</td>
                                        <td className="text-right">
                                            <UncontrolledDropdown>
                                                <DropdownToggle
                                                    color=""
                                                    size="sm"
                                                    className="btn-icon-only text-light"
                                                >
                                                    <i className="fas fa-ellipsis-h"/>
                                                </DropdownToggle>
                                                <DropdownMenu
                                                    className="dropdown-menu-arrow"
                                                    right
                                                >
                                                    <DropdownItem
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            DownloadUtil.download(value.document);
                                                        }}
                                                    >
                                                        Download
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            obj.showEditContentDocumentModal(value);
                                                        }}
                                                    >
                                                        Edit
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            obj.deleteContentDocument(`${value.uniqueId}`);
                                                        }}
                                                    >
                                                        Delete
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    </Card>
                    {this.state.isLightBoxOpen && (
                        <Lightbox
                            mainSrc={this.state.lightBoxUrl}
                            onCloseRequest={() => this.setState({isLightBoxOpen: false, lightBoxUrl: ""})}
                        />
                    )}
                    {this.state.isVideoPlayerOpen && (
                        <Modal
                            className="modal-dialog-centered"
                            isOpen={this.state.videoModal}
                            toggle={() => this.toggleModal("videoModal", false)}
                        >
                            <div className="modal-header">
                                <h6 className="modal-title" id="modal-title-default">
                                    {obj.state.videoName}
                                </h6>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => this.toggleModal("videoModal", false)}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='player-wrapper' style={{
                                    position: 'relative',
                                    paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */
                                }}>
                                    <ReactPlayer url={this.state.videoPlayerUrl} playing={true}
                                                 height='100%'
                                                 width='100%'
                                                 controls={true} className='react-player'
                                                 config={{
                                                     file: {
                                                         attributes: {
                                                             controlsList: 'nodownload'  //<- this is the important bit
                                                         }
                                                     }
                                                 }}
                                                 style={{
                                                     position: 'absolute',
                                                     top: 0,
                                                     left: 0,
                                                 }}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <Button
                                    className="ml-auto"
                                    color="link"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => this.toggleModal("videoModal", false)}
                                >
                                    Close
                                </Button>
                            </div>
                        </Modal>
                    )}
                </Container>
            </>
        );
    };

}

export default ContentDocument;
