import axiosInstance from "../api/api";

const NotificationQueueService = {
    list: function (notificationType) {
        return axiosInstance({
            url: `/api/notificationQueue/list/${notificationType}`,
            method: "GET",
        });
    },
    listForUser: function (notificationType) {
        return axiosInstance({
            url: `/api/notificationQueue/list/user/${notificationType}`,
            method: "GET",
        });
    },
    fetch: function (uniqueId) {
        return axiosInstance({
            url: `/api/notificationQueue/${uniqueId}`,
            method: "GET",
        });
    },
};
export default NotificationQueueService;
