/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// javascipt plugin for creating charts
import Chart from "chart.js";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
import React from "react";
// react plugin used to create charts
import { Bar, Line } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Row,
  Table,
} from "reactstrap";
import AdminCardsHeader from "../../../components/Headers/AdminCardsHeader";
import {
  chartExample1,
  chartExample2,
  chartOptions,
  parseOptions,
} from "../../../variables/adminCharts";

import '../../../assets/css/heroBanner.css';
import ContentService from "../../../services/xgContentService";
import _ from "lodash";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';

const items = [
  {
    src: 'https://picsum.photos/id/123/1200/400',
    altText: 'Slide 1',
    caption: 'Slide 1',
    key: 1,
  },
  {
    src: 'https://picsum.photos/id/456/1200/400',
    altText: 'Slide 2',
    caption: 'Slide 2',
    key: 2,
  },
  {
    src: 'https://picsum.photos/id/678/1200/400',
    altText: 'Slide 3',
    caption: 'Slide 3',
    key: 3,
  },
];


class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNav: 1,
      chartExample1Data: "data1",
      activeIndex: 0,
      animating: false

    };
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
    this.filterByContentType = this.filterByContentType.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex=this.goToIndex.bind(this)
  }

   filterByContentType = (data, type) => {
    return _.filter(data, { contentType: type });
  };


   next = () => {
    if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({activeIndex : nextIndex})
  };

   previous = () => {
    if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
     this.setState({activeIndex : nextIndex})
  };

   goToIndex = (newIndex) => {
    if (this.state.animating) return;
     this.setState({activeIndex : newIndex})
  };

  componentDidMount() {
    ContentService.list().then((response) => {
      console.log(response);
      this.setState({completeContentList: response});
      this.setState({audio: this.filterByContentType(this.state.completeContentList, "AUDIO")});
      this.setState({video: this.filterByContentType(this.state.completeContentList, "VIDEO")});
      this.setState({text: this.filterByContentType(this.state.completeContentList, "TEXT")});
      console.log(this.state)
    });
  }

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
  };




  render() {
    return (
        <>
          <div className="hero-banner">
            <Container fluid>
              <Row>
                <Col>
                  <div className="hero-banner-content">
                    <p>A collaborative approach to </p>
                    <h1>International Expansion</h1>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/*<AdminCardsHeader name="Admin" parentName="Dashboard"/>*/}
          {/*<Container className="mt-6" fluid>
            <Row>
              <Col xl="4">
                <Card>
                  <CardHeader>
                    <h5 className="h3 mb-0">ARTICLES</h5>
                  </CardHeader>

                  <CardBody className="p-0">
                    <ListGroup data-toggle="checklist" flush>
                      {this.state?.text?.map((article, index) => (
                          <ListGroupItem
                              key={index} // Add a key prop for each item in the list
                              className="checklist-entry flex-column align-items-start py-4 px-4"
                          >
                            <div className="checklist-item checklist-item-info">
                              <div className="checklist-info">
                                <h5 className="checklist-title mb-0">
                                  {article.title || 'Meeting with teachers.'}  Use article title
                                </h5>
                                <small>{article.dateCreated || '3:30 PM'}</small>  Use article time if available
                              </div>
                              <Button color="secondary" size="sm" type="button">
                                <i className="ni ni-button-play float-right" onClick={(e) => {
                                  e.preventDefault();
                                  this.props.history.push(
                                      `/admin/content/show/${article.uniqueId}`
                                  );
                                }}></i>
                              </Button>

                            </div>
                          </ListGroupItem>
                      ))}


                    </ListGroup>

                  </CardBody>
                </Card>
              </Col>
              <Col xl="4">
                <Card>
                  <CardHeader>
                    <h5 className="h3 mb-0">AUDIO</h5>
                  </CardHeader>

                  <CardBody className="p-0">
                    <ListGroup data-toggle="checklist" flush>
                      {this.state?.audio?.map((article, index) => (
                          <ListGroupItem
                              key={index} // Add a key prop for each item in the list
                              className="checklist-entry flex-column align-items-start py-4 px-4"
                          >
                            <div className="checklist-item checklist-item-info">
                              <div className="checklist-info">
                                <h5 className="checklist-title mb-0">
                                  {article.title || 'Meeting with teachers.'}  Use article title
                                </h5>
                                <small>{article.dateCreated || '3:30 PM'}</small>  Use article time if available
                              </div>
                              <Button color="secondary" size="sm" type="button" >
                                <i className="ni ni-button-play float-right" onClick={(e) => {
                                  e.preventDefault();
                                  this.props.history.push(
                                      `/admin/content/show/${article.uniqueId}`
                                  );
                                }}></i>
                              </Button>

                            </div>
                          </ListGroupItem>
                      ))}

                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4">
                <Card>
                  <CardHeader>
                    <h5 className="h3 mb-0">VIDEO</h5>
                  </CardHeader>

                  <CardBody className="p-0">
                    <ListGroup data-toggle="checklist" flush>
                      {this.state?.video?.map((article, index) => (
                          <ListGroupItem
                              key={index} // Add a key prop for each item in the list
                              className="checklist-entry flex-column align-items-start py-4 px-4"
                          >
                            <div className="checklist-item checklist-item-info">
                              <div className="checklist-info">
                                <h5 className="checklist-title mb-0">
                                  {article.title || 'Meeting with teachers.'}  Use article title
                                </h5>
                                <small>{article.dateCreated || '3:30 PM'}</small>  Use article time if available
                              </div>
                              <Button color="secondary" size="sm" type="button" >
                                <i className="ni ni-button-play float-right" onClick={(e) => {
                                  e.preventDefault();
                                  this.props.history.push(
                                      `/admin/content/show/${article.uniqueId}`
                                  );
                                }}></i>
                              </Button>

                            </div>
                          </ListGroupItem>
                      ))}

                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="float-center">
              <Col xl="!2">
                <Carousel
                    activeIndex={this.state.activeIndex}
                    next={this.next}
                    previous={this.previous}
                >
                  <CarouselIndicators
                      items={items}
                      activeIndex={this.state.activeIndex}
                      onClickHandler={this.goToIndex}
                  />
                  {items.map((item) => {
                    return (
                        <CarouselItem
                            onExiting={() => this.setState({animating:true})}
                            onExited={() => this.setState({animating:false})}
                            key={item.src}
                        >
                          <img src={item.src} alt={item.altText} />
                          <CarouselCaption
                              captionText={item.caption}
                              captionHeader={item.caption}
                          />
                        </CarouselItem>
                    );
                  })}
                  <CarouselControl
                      direction="prev"
                      directionText="Previous"
                      onClickHandler={this.previous}
                  />
                  <CarouselControl
                      direction="next"
                      directionText="Next"
                      onClickHandler={this.next}
                  />
                </Carousel>
              </Col>
            </Row>
          </Container>*/}
        </>
    );
  }
}

export default AdminDashboard;
