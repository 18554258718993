/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import MappingStudentList from "../student/studentParent/MappingStudentList";
import React from "react";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Modal,
    Row,
    Table, UncontrolledCollapse,
    UncontrolledDropdown,
} from "reactstrap";
import ParentService from "../../../services/parentService";
import ProjectService from "../../../services/projectService";
import SummaryForm from "../summary/Form";
import {companyType} from "../../../util/companyType";
import CompanyForm from "../company/Form";
import _ from "lodash";
import CompanyService from "../../../services/companyService";
import NotificationAlert from "react-notification-alert";
import AttachCompanyForm from "./AttachCompanyForm";
import UtilService from "../../../services/utilService";
import ContactService from "../../../services/contactService";
import ProjectCompanyService from "../../../services/projectCompanyService";
import {ContactPermissions, ProjectCompanyPermissions} from "../../../services/permissions";
import ReactBSAlert from "react-bootstrap-sweetalert";
import FormReactSelect from "../../../components/common/form/FormReactSelect";
import {highMediumLow} from "../../../util/highMediumLow";

class ProjectDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectData: {sharedWith: []},
            errorData: {},
            selectKey: UtilService.uuidv4(),
            contactKey: UtilService.uuidv4(),
            contactList: [],
            lastContact: "",
            nextContact: "",
            filteredCompanies: [],
            projectCompanies: [],
            open: null,
            alert: null,
            editableCompany: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleCompanySubmit = this.handleCompanySubmit.bind(this);
        this.handleCompanyTypeChange = this.handleCompanyTypeChange.bind(this);
        this.handleAttachCompanySubmit = this.handleAttachCompanySubmit.bind(this);
        this.handleEditCompanySubmit = this.handleEditCompanySubmit.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handlePotentialChange = this.handlePotentialChange.bind(this);
        this.handleInterestChange = this.handleInterestChange.bind(this);
        this.handlePriorityChange = this.handlePriorityChange.bind(this);
        this.handleMainContactChange = this.handleMainContactChange.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }

    toggle = (id) => {
        this.setState((prevState) => ({
            open: prevState.open === id ? null : id, // Toggles open state
        }));
    };

    handleCompanyChange = (valueObj, event) => {
        console.log(valueObj);
        if (_.isNull(valueObj)) {
            this.setState({selectedCompany: {}, companyUID: ""});
        } else {
            this.setState({selectedCompany: valueObj, companyUID: valueObj.uniqueId});
            ContactService.fetchAllByCompany(valueObj.uniqueId).then(response => {
                this.setState({contactList: response, contactKey: UtilService.uuidv4(), mainContact: ""})
            })
        }
    };

    async componentDidMount() {
        const {uniqueId} = this.props.match.params;
        this.setState({projectUID: uniqueId})
        ProjectService.fetch(uniqueId).then((response) => {
            console.log(response.data);
            this.setState({projectData: response.data});
        });
        CompanyService.list().then(response => {
            this.setState({allCompanies: response});
            console.log(this.state.allCompanies)
            ProjectCompanyService.listByProject(uniqueId).then(response => {
                this.setState({projectCompanies: response})
                console.log(this.state.projectCompanies)
            });
        })
        this.setState({selectKey: UtilService.uuidv4()});
        console.log(this.state);
    }

    handleCompanySubmit(event) {
        event.preventDefault()
        console.log(this.state);
        CompanyService.create(this.state).then(response => {
            console.log(response.data);
            this.notify(response.responseStatus.toLowerCase(), response.responseStatus, response.message);
            if (response.responseStatus === "SUCCESS") {
                this.toggleModal("companyModal")
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    }

    handleAttachCompanySubmit(event) {
        event.preventDefault()
        console.log(this.state);
        ProjectCompanyService.create(this.state).then(response => {
            console.log(response.data);
            this.notify(response.responseStatus.toLowerCase(), response.responseStatus, response.message);
            if (response.responseStatus === "SUCCESS") {
                this.toggleModal("attachCompanyModal")
                const {uniqueId} = this.props.match.params;
                CompanyService.list().then(response => {
                    this.setState({allCompanies: response});
                    console.log(this.state.allCompanies)
                    ProjectCompanyService.listByProject(uniqueId).then(response => {
                        this.setState({projectCompanies: response})
                        console.log(this.state.projectCompanies)
                    });
                })
                this.setState({selectKey: UtilService.uuidv4()});
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    }

    handleEditCompanySubmit(event) {
        event.preventDefault()
        console.log(this.state);
        ProjectCompanyService.update(this.state).then(response => {
            console.log(response.data);
            this.notify(response.responseStatus.toLowerCase(), response.responseStatus, response.message);
            if (response.responseStatus === "SUCCESS") {
                this.toggleModal("editCompanyModal")
                this.clearEditableProperties();
                const {uniqueId} = this.props.match.params;
                CompanyService.list().then(response => {
                    this.setState({allCompanies: response});
                    console.log(this.state.allCompanies)
                    ProjectCompanyService.listByProject(uniqueId).then(response => {
                        this.setState({projectCompanies: response})
                        console.log(this.state.projectCompanies)
                    });
                })
                this.setState({selectKey: UtilService.uuidv4()});
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    }

    handleEditPrioritySubmit = (event) => {
        event.preventDefault()
        console.log(this.state);
        ProjectCompanyService.update(this.state).then(response => {
            console.log(response.data);
            this.notify(response.responseStatus.toLowerCase(), response.responseStatus, response.message);
            if (response.responseStatus === "SUCCESS") {
                this.toggleModal("editPriorityModal")
                this.clearEditableProperties();
                const {uniqueId} = this.props.match.params;
                CompanyService.list().then(response => {
                    this.setState({allCompanies: response});
                    console.log(this.state.allCompanies)
                    ProjectCompanyService.listByProject(uniqueId).then(response => {
                        this.setState({projectCompanies: response})
                        console.log(this.state.projectCompanies)
                    });
                })
                this.setState({selectKey: UtilService.uuidv4()});
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    }


    toggleModal = state => {
        if (state === "attachCompanyModal")
            this.clearEditableProperties();
        this.setState({
            [state]: !this.state[state]
        });
    };

    handleCompanyTypeChange = (valueObj, event) => {
        if (_.isNull(valueObj)) {
            this.setState({companyTypeList: []});
        } else {
            this.setState({companyTypeList: valueObj});
            const keys = _.map(valueObj, "key")
            this.setState({companyTypeList: keys});
        }
    };

    notify = (type, title, message) => {
        let options = {
            place: "tc", message: (<div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                <span data-notify="message">{message}</span>
            </div>), type: type, icon: "ni ni-bell-55", autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    }

    hideDeleteAlert = () => {
        this.setState({
            alert: null
        });
    };

    confirmDeleteAlert = (uniqueId) => {
        console.log("inside confirm delete alert", uniqueId);
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{display: "block"}}
                    title="Are you sure?"
                    onConfirm={() => this.delete(uniqueId)}
                    onCancel={this.hideDeleteAlert}
                    showCancel
                    confirmBtnBsStyle="danger"
                    confirmBtnText="Yes, delete it!"
                    cancelBtnBsStyle="secondary"
                    cancelBtnText="Cancel"
                    btnSize=""
                >
                    You won't be able to revert this!
                </ReactBSAlert>
            )
        });
    };

    delete(uniqueId) {
        const projectUniqueId = this.props.match.params.uniqueId;
        this.setState({alert: null})
        ProjectCompanyService.delete(uniqueId).then((response) => {
            console.log(response);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            //TODO: TO be fixed with redux implementation
            //   this.props.history.push("/admin/dashboard");
            ProjectCompanyService.listByProject(projectUniqueId).then(response => {
                this.setState({projectCompanies: response})
                console.log(this.state.projectCompanies)
            });
        });
    }


    handlePotentialChange(valueObj) {
        if (_.isNull(valueObj)) {
            this.setState({selectedPotential: {}, potential: ""});
        } else {
            this.setState({selectedPotential: valueObj, potential: valueObj.key});
        }
    }

    handleMainContactChange(valueObj) {
        if (_.isNull(valueObj)) {
            this.setState({selectedMainContact: {}, mainContact: ""});
        } else {
            this.setState({selectedMainContact: valueObj, mainContact: valueObj.uniqueId});
        }
    }

    handleInterestChange(valueObj) {
        if (_.isNull(valueObj)) {
            this.setState({selectedInterest: {}, interest: ""});
        } else {
            this.setState({selectedInterest: valueObj, interest: valueObj.key});
        }
    }

    handlePriorityChange(valueObj) {
        if (_.isNull(valueObj)) {
            this.setState({selectedPriority: {}, priority: ""});
        } else {
            this.setState({selectedPriority: valueObj, priority: valueObj.key});
        }
    }

    setEditableProperties = (projectCompany) => {
        this.setState({
            selectedCompany: projectCompany?.company,
            companyUID: projectCompany?.company?.uniqueId,
            selectedMainContact: projectCompany?.mainContact,
            mainContact: projectCompany?.mainContact?.uniqueId,
            selectedInterest: {key: projectCompany?.interest, value: projectCompany?.interest},
            interest: projectCompany?.interest,
            selectedPotential: {key: projectCompany?.potential, value: projectCompany?.potential},
            potential: projectCompany?.potential,
            selectedPriority: {key: projectCompany?.priority, value: projectCompany?.priority},
            priority: projectCompany?.priority,
            lastContact: projectCompany?.lastContact,
            nextContact: projectCompany?.nextContact,
            uniqueId: projectCompany?.uniqueId,
        })
    }

    clearEditableProperties = () => {
        this.setState({
            selectedCompany: {},
            companyUID: "",
            selectedMainContact: {},
            mainContact: "",
            selectedInterest: {},
            interest: null,
            selectedPotential: {},
            potential: null,
            selectedPriority: {},
            priority: null,
            lastContact: null,
            nextContact: null,
        })
    }


    render() {
        const {projectData} = this.state;
        let obj = this;
        return (<>
            <div className="rna-wrapper">
                <NotificationAlert ref="notificationAlert"/>
            </div>
            {this.state.alert}
            <SimpleHeader
                name="Details"
                parentName="Project"
                heading="XareGrowth"
                newBtnUrl="/admin/project/list"
                newBtnText="List All Project"
            />
            <Container className="mt--6" fluid>
                <Card className="mb-4">
                    <CardHeader>
                        <h3 className="mb-0">{projectData?.name}</h3>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md="4">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example3cols1Input"
                                    >
                                        Project Name
                                    </label>
                                    <p>{projectData?.name}</p>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example3cols2Input"
                                    >
                                        Shared With
                                    </label>
                                    <p>
                                        {projectData.sharedWith.map((value, index) => {
                                            return <Badge size="lg" key={UtilService.uuidv4()}
                                                          color="primary">{value?.fullName}</Badge>
                                        })}
                                    </p>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            {/*<Col md="2">
                                    <Button
                                        outline
                                        color="default"
                                        type="button"
                                        onClick={() => this.toggleModal("companyModal")}
                                    >
                                        New Company
                                    </Button>
                                    <Modal
                                        className="modal-dialog-centered"
                                        size="lg"
                                        isOpen={this.state.companyModal}
                                        toggle={() => this.toggleModal("companyModal")}
                                    >
                                        <div className="modal-body p-0">
                                            <Card className="bg-secondary shadow border-0">
                                                <CardHeader className="bg-transparent pb-5">
                                                    <div className="text-muted text-center mt-2 mb-3">
                                                        <small>Create new Company</small>
                                                    </div>
                                                </CardHeader>
                                                <CardBody className="px-lg-5 py-lg-5">
                                                    <Form role="form" onSubmit={this.handleCompanySubmit}>


                                                        <CompanyForm
                                                            onChange={this.handleChange}
                                                            valueObj={this.state}
                                                            errorObj={this.state.errorData}
                                                            isEdit={false}
                                                            companyType={companyType}
                                                            onCompanyTypeChange={this.handleCompanyTypeChange}
                                                        />
                                                        <div className="text-center">
                                                            <Button className="my-4" color="info" type="submit">
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </Modal>
                                </Col>*/}
                            <Col md="2">
                                <Button style={{display: ProjectCompanyPermissions.create(projectData?.sharedWith)}}
                                        outline
                                        color="default"
                                        type="button"
                                        onClick={() => this.toggleModal("attachCompanyModal")}
                                >
                                    Add Company to Project
                                </Button>
                                <Modal
                                    className="modal-dialog-centered"
                                    size="lg"
                                    isOpen={this.state.attachCompanyModal}
                                    toggle={() => this.toggleModal("attachCompanyModal")}
                                >
                                    <div className="modal-body p-0">
                                        <Card className="bg-secondary shadow border-0">
                                            <CardHeader className="bg-transparent pb-5">
                                                <div className="text-muted text-center mt-2 mb-3">
                                                    <h3>Attach Company Details</h3>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="px-lg-5 py-lg-5">
                                                <Form role="form" onSubmit={this.handleAttachCompanySubmit}>


                                                    <AttachCompanyForm
                                                        onChange={this.handleChange}
                                                        valueObj={this.state}
                                                        errorObj={this.state.errorData}
                                                        isEdit={false}
                                                        companyType={companyType}
                                                        onCompanyTypeChange={this.handleCompanyTypeChange}

                                                        onCompanyChange={this.handleCompanyChange}
                                                        selectedCompany={this.state.selectedCompany}
                                                        companies={this.state.allCompanies}
                                                        selectedPriority={this.state.selectedPriority}
                                                        handlePriorityChange={this.handlePriorityChange}
                                                        selectedPotential={this.state.selectedPotential}
                                                        handlePotentialChange={this.handlePotentialChange}
                                                        selectedInterest={this.state.selectedInterest}
                                                        handleInterestChange={this.handleInterestChange}
                                                        contactKey={this.state.contactKey}
                                                        contacts={this.state.contactList}
                                                        handleMainContactChange={this.handleMainContactChange}
                                                        selectedMainContact={this.state.selectedMainContact}


                                                    />
                                                    <div className="text-center">
                                                        <Button className="my-4" color="default" outline
                                                                type="submit">
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Modal>
                            </Col>
                            {/*<Col md="2">
                                    <Button
                                        outline
                                        color="default"
                                        type="button"
                                        onClick={(event) => {
                                            obj.props.history.push(
                                                `/admin/create`
                                            );
                                        }}
                                    >
                                        New User
                                    </Button>

                                </Col>
                                <Col md="2">
                                    <Button
                                        outline
                                        color="default"
                                        type="button"
                                        onClick={(event) => {
                                            obj.props.history.push(
                                                `/admin/project/create`
                                            );
                                        }}
                                    >
                                        New Project
                                    </Button>
                                </Col>
                                <Col md="2">
                                    <Button
                                        outline
                                        color="default"
                                        type="button"
                                        onClick={(event) => {
                                            obj.props.history.push(
                                                `/admin/contact/create`
                                            );
                                        }}
                                    >
                                        New Contact
                                    </Button>
                                </Col>*/}
                        </Row>

                    </CardBody>
                </Card>
            </Container>
            <br/>
            <br/>
            <br/>
            <Modal
                className="modal-dialog-centered"
                size="lg"
                isOpen={this.state.projectCompanyDetailsModal}
                toggle={() => this.toggleModal("projectCompanyDetailsModal")}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent pb-5">
                            <div className="text-muted text-center mt-2 mb-3">
                                <h3>{this.state?.detailsObject?.mainContact?.company?.legalName} Details for {projectData?.name}</h3>
                            </div>
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            Date Created
                                        </label>
                                        <p>{this.state?.detailsObject?.dateCreated}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols2Input"
                                        >
                                            Company Name
                                        </label>
                                        <p>
                                            {this.state?.detailsObject?.mainContact?.company?.legalName}
                                        </p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols3Input"
                                        >
                                            Potential
                                        </label>
                                        <p>{this.state?.detailsObject?.potential}</p>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            Priority
                                        </label>
                                        <p>{this.state?.detailsObject?.priority}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols2Input"
                                        >
                                            Interest
                                        </label>
                                        <p>
                                            {this.state?.detailsObject?.interest}
                                        </p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols3Input"
                                        >
                                            Key Contact
                                        </label>
                                        <p>{this.state?.detailsObject?.mainContact?.fullName}</p>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            Date of Last Contact
                                        </label>
                                        <p>{this.state?.detailsObject?.lastContact}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols2Input"
                                        >
                                            Date of Next Contact
                                        </label>
                                        <p>
                                            {this.state?.detailsObject?.nextContact}
                                        </p>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
            <br/>
            <br/>
            <Modal
                className="modal-dialog-centered"
                size="lg"
                isOpen={this.state.editCompanyModal}
                toggle={() => this.toggleModal("editCompanyModal")}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent pb-5">
                            <div className="text-muted text-center mt-2 mb-3">
                                <h3>Edit Company Details for Project</h3>
                            </div>
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <Form role="form" onSubmit={this.handleEditCompanySubmit}>


                                <AttachCompanyForm
                                    onChange={this.handleChange}
                                    valueObj={this.state}
                                    errorObj={this.state.errorData}
                                    isEdit={false}
                                    companyType={companyType}
                                    onCompanyTypeChange={this.handleCompanyTypeChange}

                                    onCompanyChange={this.handleCompanyChange}
                                    selectedCompany={this.state.selectedCompany}
                                    companies={this.state.allCompanies}
                                    selectedPriority={this.state.selectedPriority}
                                    handlePriorityChange={this.handlePriorityChange}
                                    selectedPotential={this.state.selectedPotential}
                                    handlePotentialChange={this.handlePotentialChange}
                                    selectedInterest={this.state.selectedInterest}
                                    handleInterestChange={this.handleInterestChange}
                                    contactKey={this.state.contactKey}
                                    contacts={this.state.contactList}
                                    handleMainContactChange={this.handleMainContactChange}
                                    selectedMainContact={this.selectedMainContact}


                                />
                                <div className="text-center">
                                    <Button className="my-4" color="default" outline
                                            type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
            <br/>
            <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={this.state.editPriorityModal}
                toggle={() => this.toggleModal("editPriorityModal")}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent pb-5">
                            <div className="text-muted text-center mt-2 mb-3">
                                <h3>Edit Priority</h3>
                            </div>
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <Form role="form" onSubmit={this.handleEditPrioritySubmit}>
                                <Row>
                                    <Col md="12" sm="12">
                                        <FormReactSelect
                                            selectKey={UtilService.uuidv4()}
                                            isMulti={false}
                                            className="form-control-label"
                                            isSearchable={true}
                                            label="Priority"
                                            name={"priority"}
                                            onChange={this.handlePriorityChange}
                                            optionValue={"value"}
                                            defaultValue={this.state.selectedPriority}
                                            optionList={highMediumLow}
                                            optionLabel={"key"}
                                            placeHolder="Select Priority"
                                            errorMsg={this.props?.errorObj?.priority || ""}
                                        />
                                    </Col>
                                </Row>

                                <div className="text-center">
                                    <Button className="my-4" color="default" outline
                                            type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
            <Container className="mt--6" fluid>
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">Companies</h3>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Table className="align-items-center table-flush" style={{height: "auto"}} responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th className="sort" data-sort="name" scope="col">
                                            #
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Legal Name
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Country
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Website
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Priority
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Date of last Meeting report
                                        </th>
                                        <th scope="col"/>
                                    </tr>
                                    </thead>
                                    <tbody className="list" style={{overflow: "visible"}}>
                                    {this.state.projectCompanies.map(function (value, index) {
                                        return (<tr key={index}>
                                            <td className="budget">{index + 1}</td>
                                            <td className="budget">{value?.company?.legalName}</td>
                                            <td className="budget">{value?.company?.country}</td>
                                            <td className="budget">{value?.company?.website}</td>
                                            <td className="budget">{value?.priority}</td>
                                            <td className="budget">{value?.lastContact}</td>
                                            <td className="text-right">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        color=""
                                                        size="sm"
                                                        className="btn-icon-only text-light"
                                                    >
                                                        <i className="fas fa-ellipsis-v"/>
                                                    </DropdownToggle>
                                                    <DropdownMenu
                                                        className="dropdown-menu-arrow"
                                                        right
                                                    >
                                                        <DropdownItem
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                obj.setState({detailsObject : value})
                                                                obj.toggleModal("projectCompanyDetailsModal")
                                                            }}
                                                        >
                                                            Details
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            style={{display: ProjectCompanyPermissions.update(projectData?.sharedWith)}}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                obj.setEditableProperties(value)
                                                                obj.toggleModal("editCompanyModal")
                                                            }}
                                                        >
                                                            Edit
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            style={{display: ProjectCompanyPermissions.updatePriority(projectData?.sharedWith)}}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                obj.setEditableProperties(value)
                                                                obj.toggleModal("editPriorityModal")
                                                            }}
                                                        >
                                                            Edit Priority
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            style={{display: ProjectCompanyPermissions.delete(projectData?.sharedWith)}}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                obj.confirmDeleteAlert(`${value.uniqueId}`);
                                                            }}
                                                        >
                                                            Delete
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>);
                                    })}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>);
    }
}

export default ProjectDetails;
