const companyType = [
    { key: "MANUFACTURER_BRAND", value: "MANUFACTURER_BRAND" },
    { key: "IMPORTER_DISTRIBUTOR", value: "IMPORTER_DISTRIBUTOR" },
    { key: "TECH_SOLUTION_PROVIDER", value: "TECH_SOLUTION_PROVIDER" },
    { key: "ENGINEERING_SERVICES", value: "ENGINEERING_SERVICES" },
    { key: "SYSTEM_INTEGRATORS", value: "SYSTEM_INTEGRATORS" },
    { key: "CONSULTING_ADVISORY", value: "CONSULTING_ADVISORY" },
    { key: "HR_ACCOUNTING_LEGAL", value: "HR_ACCOUNTING_LEGAL" },
    { key: "ASSOCIATION", value: "ASSOCIATION" },
];

export { companyType };