/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import React from "react";
import {
  Card,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import ExaminationInstructionService from "../../../services/examinationInstructionService";
import ExaminationService from "../../../services/examinationService";
import SectionService from "../../../services/sectionService";
import CreateExaminationInstructionModal from "../examinationInstruction/CreateModal";
import StudentListModal from "../student/StudentListModal";
import CourseSectionSubjectStudentService from "../../../services/courseSectionSubjectStudentService";
import _ from "lodash";

class ExaminationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      isModalOpen: false,
      editorState: undefined,
      isStudentListModalOpen: false,
      studentList: [],
      studentUIDList: [],
    };
    this.delete = this.delete.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onModalOpened = this.onModalOpened.bind(this);
    this.handleExaminationInstructionChange = this.handleExaminationInstructionChange.bind(
      this
    );
    this.handleExaminationInstructionSubmit = this.handleExaminationInstructionSubmit.bind(
      this
    );
    this.toggleStudentListModal = this.toggleStudentListModal.bind(this);
    this.onStudentListModalOpened = this.onStudentListModalOpened.bind(this);
    this.handleStudentAssignmentChange = this.handleStudentAssignmentChange.bind(
      this
    );
    this.handleStudentAssignmentSubmit = this.handleStudentAssignmentSubmit.bind(
      this
    );
  }

  componentDidMount() {
    ExaminationService.list().then((response) => {
      console.log(response);
      this.setState({ list: response });
    });
  }

  delete(uniqueId) {
    SectionService.delete(uniqueId).then((response) => {
      console.log(response);
      this.notify(
        response.responseStatus.toLowerCase(),
        response.responseStatus,
        response.message
      );
      //TODO: TO be fixed with redux implementation
      //   this.props.history.push("/admin/dashboard");
      SectionService.list().then((response) => {
        console.log(response);
        this.setState({ list: response });
      });
    });
  }

  handleExaminationInstructionChange(editorState) {
    this.setState({ editorState });
  }

  toggleModal() {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  toggleStudentListModal() {
    this.setState({
      isStudentListModalOpen: !this.state.isStudentListModalOpen,
    });
  }

  onStudentListModalOpened() {
    console.log("Student List Assignment Modal Opened");
    const courseSectionSubjectUID = this.state.courseSectionSubjectUID;
    CourseSectionSubjectStudentService.fetchStudents(
      courseSectionSubjectUID
    ).then((response) => {
      console.log(response);
      let studentList = [];
      _.forEach(response.data, function (courseSectionSubjectStudentData) {
        studentList.push(courseSectionSubjectStudentData.student);
      });
      this.setState({ studentList: studentList });
    });
  }

  onModalOpened() {
    this.setState({
      editorState: EditorState.createEmpty(),
    });
    const examimationUID = this.state.examinationUID;
    ExaminationInstructionService.fetchByExamination(examimationUID).then(
      (response) => {
        console.log(response);
        this.setState({ examinationInstructionUID: response.data.uniqueId });
        let instructionTextHMTL = response.data.instructionText || "";
        const blocksFromHTML = convertFromHTML(instructionTextHMTL);
        const content = ContentState.createFromBlockArray(blocksFromHTML);
        this.setState({
          editorState: EditorState.createWithContent(content),
        });
      }
    );
  }

  handleExaminationInstructionSubmit(event) {
    event.preventDefault();
    const { editorState } = this.state;
    const examinationInstructionData = {
      uniqueId: this.state.examinationInstructionUID,
      instructionText: draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      ),
      examinationUID: this.state.examinationUID,
    };
    const obj = this;
    ExaminationInstructionService.update(examinationInstructionData).then(
      (response) => {
        console.log(response.data);
        obj.setState({
          isModalOpen: false,
          instructionText: "",
          examinationUID: "",
          examinationInstructionUID: "",
        });
      }
    );
  }

  handleStudentAssignmentChange(event) {
    console.log("CHANGED");
    const value = event.target.value;
    const isChecked = event.target.checked;
    let studentUIDList = this.state.studentUIDList;
    if (isChecked) {
      studentUIDList.push(value);
    } else {
      studentUIDList = studentUIDList.filter((item) => item !== value);
    }
    this.setState({ studentUIDList: studentUIDList });
    console.log(studentUIDList);
  }

  handleStudentAssignmentSubmit(event) {
    event.preventDefault();
    console.log("SUBMITTED");
    const obj = this;
    const assignStudentData = {
      uniqueId: this.state.examinationUID,
      studentUIDList: this.state.studentUIDList,
    };
    ExaminationService.assignStudent(assignStudentData).then((response) => {
      console.log(response.data);
      obj.setState({
        isStudentListModalOpen: false,
        examinationUID: "",
        studentUIDList: [],
      });
    });
  }

  render() {
    let obj = this;
    return (
      <>
        <StudentListModal
          isOpen={this.state.isStudentListModalOpen}
          toggleModal={this.toggleStudentListModal}
          onModalOpened={this.onStudentListModalOpened}
          size="lg"
          studentList={this.state.studentList}
          handleStudentAssignmentChange={this.handleStudentAssignmentChange}
          handleStudentAssignmentSubmit={this.handleStudentAssignmentSubmit}
        />

        <CreateExaminationInstructionModal
          isOpen={this.state.isModalOpen}
          toggleModal={this.toggleModal}
          onModalOpened={this.onModalOpened}
          size="lg"
          valueObj={this.state}
          obj={this}
        />
        <SimpleHeader
          name="List"
          parentName="Examination"
          heading="ClashMate"
          newBtnUrl="/teacher/examination/create"
          newBtnText="Create Examination"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Examination List</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        #
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Date Created
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Title
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Class
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Type
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {this.state.list.map(function (value, index) {
                      return (
                        <tr key={index}>
                          <td className="budget">{index + 1}</td>
                          <td className="budget">{value.dateCreated}</td>
                          <td className="budget">{value.title}</td>
                          <td className="budget">
                            {value.courseSectionSubjectTitle}
                          </td>
                          <td className="budget">{value.examinationType}</td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color=""
                                size="sm"
                                className="btn-icon-only text-light"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={() =>
                                    obj.setState({
                                      examinationUID: value.uniqueId,
                                      isModalOpen: true,
                                    })
                                  }
                                >
                                  Instruction Page
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    obj.setState({
                                      courseSectionSubjectUID:
                                        value.courseSectionSubjectUID,
                                      examinationUID: value.uniqueId,
                                      isStudentListModalOpen: true,
                                    })
                                  }
                                >
                                  Assign Students
                                </DropdownItem>
                                <DropdownItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    obj.props.history.push(
                                      `/teacher/onlineExamination/list/${value.uniqueId}`
                                    );
                                  }}
                                >
                                  Question Assignment
                                </DropdownItem>
                                <DropdownItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    obj.props.history.push(
                                      `/teacher/examination/show/${value.uniqueId}`
                                    );
                                  }}
                                >
                                  Details
                                </DropdownItem>
                                <DropdownItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    obj.props.history.push(
                                      `/teacher/examination/edit/${value.uniqueId}`
                                    );
                                  }}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    obj.delete(`${value.uniqueId}`);
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ExaminationList;
