import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {Button, UncontrolledTooltip} from "reactstrap";

const createTextWithTooltip = (content) => {
    if (typeof content !== 'string') {
        return <span>Invalid content</span>;
    }

    const isLongText = content.length > 15;
    const truncatedText = isLongText ? `${content.substring(0, 15)}` : content;
    const tooltipId = uuidv4(); // Unique ID for the tooltip

    return (
        <span>
      {truncatedText}
            {isLongText && (
                <>
                    <Button
                        className="btn-white"
                        color="white"
                        data-placement="bottom"
                        id={`tooltip-${tooltipId}`}
                        size="sm"
                        type="button"
                    >
                        ...
                    </Button>
                    <UncontrolledTooltip delay={0} placement="bottom" target={`tooltip-${tooltipId}`}>
                        {content}
                    </UncontrolledTooltip>
                </>
            )}
    </span>
    );
};

export default createTextWithTooltip;
