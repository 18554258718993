import React from "react";
import {
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import FormInput from "../../../components/common/form/FormInput";
import FormInputSelect from "../../../components/common/form/FormInputSelect";
import { DIFFICULTY_LEVEL_LIST_MAP } from "../../../util/QuestionConstant";

class QuestionForm extends React.Component {
  showField() {
    return this.props.isEdit === true ? "hidden" : "";
  }

  render() {
    const propObj = this.props;
    return (
      <>
        <Row>
          <Col md="12">
            <FormInput
              name="questionText"
              className="form-control-label"
              id="questionText"
              placeholder="inputPlaceHolder.question.questionText"
              type="textarea"
              onChange={this.props.onChange}
              label="inputLabel.question.questionText"
              value={this.props.valueObj.questionText || ""}
              errorMsg={this.props.errorObj.questionText || ""}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormInputSelect
              name="courseSectionSubjectUID"
              className="form-control-label"
              id="courseSectionSubjectUID"
              onChange={this.props.onChange}
              label="inputLabel.question.courseSectionSubjectUID"
              optionList={this.props.valueObj.subjectList}
              optionKey="uniqueId"
              optionValue="title"
            />
          </Col>
          <Col md="6">
            <FormInputSelect
              name="difficultyLevel"
              className="form-control-label"
              id="difficultyLevel"
              onChange={this.props.onChange}
              label="inputLabel.question.difficultyLevel"
              optionList={DIFFICULTY_LEVEL_LIST_MAP}
              optionKey="key"
              optionValue="value"
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Label>{`inputLabel.question.answerText1`}</Label>
            <InputGroup>
              <Input
                name="answerText1"
                className="form-control-label"
                id="answerText1"
                placeholder="inputPlaceHolder.question.answerText1"
                type="textarea"
                onChange={propObj.onChange}
                value={propObj.valueObj.answerTextMap["answerText1"] || ""}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <input
                    type="radio"
                    name="correctAnswer"
                    value="answerText1"
                    onChange={propObj.onChange}
                  />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col md="6">
            <Label>{`inputLabel.question.answerText2`}</Label>
            <InputGroup>
              <Input
                name="answerText2"
                className="form-control-label"
                id="answerText2"
                placeholder={`inputPlaceHolder.question.answerText2`}
                type="textarea"
                onChange={propObj.onChange}
                value={propObj.valueObj.answerTextMap["answerText2"] || ""}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <input
                    type="radio"
                    name="correctAnswer"
                    value="answerText2"
                    onChange={propObj.onChange}
                  />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col md="6">
            <Label>{`inputLabel.question.answerText3`}</Label>
            <InputGroup>
              <Input
                name="answerText3"
                className="form-control-label"
                id="answerText3"
                placeholder={`inputPlaceHolder.question.answerText3`}
                type="textarea"
                onChange={propObj.onChange}
                value={propObj.valueObj.answerTextMap["answerText3"] || ""}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <input
                    type="radio"
                    name="correctAnswer"
                    value="answerText3"
                    onChange={propObj.onChange}
                  />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col md="6">
            <Label>{`inputLabel.question.answerText4`}</Label>
            <InputGroup>
              <Input
                name="answerText4"
                className="form-control-label"
                id="answerText4"
                placeholder={`inputPlaceHolder.question.answerText4`}
                type="textarea"
                onChange={propObj.onChange}
                value={propObj.valueObj.answerTextMap["answerText4"] || ""}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <input
                    type="radio"
                    name="correctAnswer"
                    value="answerText4"
                    onChange={propObj.onChange}
                  />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default QuestionForm;
