/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row} from "reactstrap";
import TenantService from "../../../services/tenantService";
import AdminListTable from "../admin/ListTable";
import AdminService from "../../../services/adminService";
import SecurityConstant from "../../../util/securityConstant";
import FormInput from "../../../components/common/form/FormInput";
import BulkUploadList from "./BulkUploadList";
import NotificationAlert from "react-notification-alert";

class TenantDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: undefined,
            tenantData: {},
            bulkUploadList: [],
            tenantAdminList: [],
        };
    }

    componentDidMount() {
        const {uniqueId} = this.props.match.params;
        TenantService.fetch(uniqueId).then((response) => {
            console.log(response.data);
            this.setState({tenantData: response.data});
        });
        TenantService.fetchBulkUploadData(uniqueId).then((response) => {
            console.log(response.data);
            this.setState({bulkUploadList: response.data});
        });
        AdminService.list(SecurityConstant.ROLE_TENANT_ADMIN).then((response) => {
            console.log(response);
            this.setState({tenantAdminList: response.data});
        });
    }

    fileChange = (event) => {
        this.setState({file: event.target.files[0]})
    };

    deleteBulkUpload = (uniqueId) => {
        TenantService.deleteBulkUpload(uniqueId).then((response) => {
            console.log(response);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            //TODO: TO be fixed with redux implementation
            //   this.props.history.push("/admin/dashboard");
            TenantService.fetchBulkUploadData().then((response) => {
                console.log(response);
                this.setState({bulkUploadList: response.data});
            });
        });
    };

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    uploadFile = (event) => {
        const data = new FormData()
        data.append('file', this.state.file)
        data.append('uniqueId', this.state.tenantData.uniqueId);
        console.log(data);
        TenantService.uploadBootstrapData(data).then((response) => {
            console.log(response);
        });
    };

    render() {
        const {tenantData} = this.state;
        let obj = this;
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <SimpleHeader
                    name="Details"
                    parentName="Tenant"
                    heading="ClashMate"
                    newBtnUrl="/superAdmin/tenant/create"
                    newBtnText="Create Tenant"
                />
                <Container className="mt--6" fluid>
                    <Row>
                        <Col xl="12">
                            <Card className="mb-4">
                                <CardHeader>
                                    <h3 className="mb-0">{tenantData.fullName}</h3>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md="4">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="example3cols1Input"
                                                >
                                                    First Name
                                                </label>
                                                <p>{tenantData.firstName}</p>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="example3cols2Input"
                                                >
                                                    Middle Name
                                                </label>
                                                <p>{tenantData.middleName}</p>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="example3cols3Input"
                                                >
                                                    Last Name
                                                </label>
                                                <p>{tenantData.lastName}</p>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4" sm="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="example4cols1Input"
                                                >
                                                    Email Address
                                                </label>
                                                <p>{tenantData.emailAddress}</p>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4" sm="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="example4cols2Input"
                                                >
                                                    Date Created
                                                </label>
                                                <p>{tenantData.dateCreated}</p>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4" sm="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="example4cols3Input"
                                                >
                                                    Phone Number
                                                </label>
                                                <p>{tenantData.phoneNumber}</p>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4" sm="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="example4cols2Input"
                                                >
                                                    Domain Name
                                                </label>
                                                <p>{tenantData.domainName}</p>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4" sm="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="example4cols3Input"
                                                >
                                                    Secondary Domain Name
                                                </label>
                                                <p>{tenantData.secondaryDomainName}</p>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4" sm="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="example4cols1Input"
                                                >
                                                    Address
                                                </label>
                                                <p>{tenantData.address}</p>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="12">
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0">Upload Bootstrap Data</h3>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col xl="8">
                                            <FormInput
                                                name="file"
                                                className="form-control-label custom-file-input"
                                                id="file"
                                                placeholder="Select File"
                                                type="file"
                                                onChange={this.fileChange}
                                                label="Browse"
                                                // value={this.props.valueObj.firstName || ""}
                                                // errorMsg={this.props.errorObj.firstName || ""}
                                            />
                                        </Col>
                                        <Col xl="4">
                                            <Button
                                                color="primary"
                                                type="button"
                                                size="md"
                                                onClick={(e) => {
                                                    obj.uploadFile();
                                                }}
                                                className="fa-pull-right"
                                            >
                                                Upload Files
                                            </Button>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="12">
                            <AdminListTable
                                adminList={this.state.tenantAdminList}
                                adminComponentObj={obj}
                                cardTitle="Tenant Admin List"
                                tenantUID={tenantData.uniqueId}
                                userRole={SecurityConstant.ROLE_SUPER_ADMIN}
                            />
                        </Col>
                        <Col xl="12">
                            <BulkUploadList
                                bulkUploadList={this.state.bulkUploadList}
                                adminComponentObj={obj}
                                cardTitle="Bulk Upload List"
                                tenantUID={tenantData.uniqueId}
                            />
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default TenantDetails;
