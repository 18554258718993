const PermissionRole = {
  ADMIN: "ADMIN",
  TENANT_ADMIN: "TENANT_ADMIN",
  SUPER_ADMIN: "SUPER_ADMIN",
  TEACHER: "TEACHER",
  PARENT: "PARENT",
  STUDENT: "STUDENT",
};

const AdminPermissionType = {
  CREATE_ADMIN: "CREATE_ADMIN",
  LIST_ADMIN: "LIST_ADMIN",
  DELETE_ADMIN: "DELETE_ADMIN",
  READ_ADMIN: "READ_ADMIN",
  UPDATE_ADMIN: "UPDATE_ADMIN",
};

export { PermissionRole, AdminPermissionType };
