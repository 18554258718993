/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import LessonPlanService from "../../../services/lessonPlanService";
import SecurityService from "../../../services/securityService";
import SecurityConstant from "../../../util/securityConstant";

class LessonPlanDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lessonPlanData: {},
    };
  }

  componentDidMount() {
    const { uniqueId } = this.props.match.params;
    LessonPlanService.fetch(uniqueId).then((response) => {
      console.log(response.data);
      this.setState({ lessonPlanData: response.data });
    });
  }

  render() {
    const { lessonPlanData } = this.state;
    return (
      <>
        <SimpleHeader
          name="Details"
          parentName="Lesson Plan"
          heading="ClashMate"
          newBtnUrl={
            SecurityService.hasRole(SecurityConstant.ROLE_TEACHER)
              ? "/teacher/lessonPlan/create"
              : undefined
          }
          newBtnText={
            SecurityService.hasRole(SecurityConstant.ROLE_TEACHER)
              ? "Create Lesson Plan"
              : undefined
          }
        />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col-8">
                  <h3 className="mb-0">{lessonPlanData.title}</h3>
                </div>
                <div className="col-4">
                  <h3 className="mb-0 text-right">
                    {lessonPlanData.courseSectionSubjectTitle}
                  </h3>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="12">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: lessonPlanData.content,
                    }}
                  ></p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

export default LessonPlanDetail;
