import axiosInstance from "../api/api";

const CourseSectionSubjectTeacherService = {
  list: function () {
    return axiosInstance({
      url: `/api/courseSectionSubjectTeacher/`,
      method: "GET",
    });
  },
  fetchTeachers: function (uniqueId) {
    return axiosInstance({
      url: `/api/courseSectionSubjectTeacher/courseSectionSubject/${uniqueId}`,
      method: "GET",
    });
  },
  fetchCourseSectionSubjects: function (uniqueId) {
    return axiosInstance({
      url: `/api/courseSectionSubjectTeacher/teacher/${uniqueId}`,
      method: "GET",
    });
  },
  delete: function (formData) {
    return axiosInstance({
      url: `/api/courseSectionSubjectTeacher/`,
      method: "DELETE",
      data: formData,
    });
  },
  create: function (formData) {
    return axiosInstance({
      url: `/api/courseSectionSubjectTeacher/`,
      method: "POST",
      data: formData,
    });
  },
};
export default CourseSectionSubjectTeacherService;
