import axiosInstance from "../api/api";

const NoticeService = {
    list: function () {
        return axiosInstance({
            url: "/api/notice/",
            method: "GET",
        });
    },
    fetch: function (uniqueId) {
        return axiosInstance({
            url: `/api/notice/${uniqueId}`,
            method: "GET",
        });
    },
    delete: function (uniqueId) {
        return axiosInstance({
            url: `/api/notice/${uniqueId}`,
            method: "DELETE",
        });
    },
    create: function (formData) {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return axiosInstance({
            url: `/api/notice/`,
            method: "POST",
            data: formData,
            config
        });
    },
    update: function (formData) {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return axiosInstance({
            url: `/api/notice/`,
            method: "PATCH",
            data: formData,
            config
        });
    },
    updateView: function (formData) {
        return axiosInstance({
            url: `/api/notice/updateView/`,
            method: "POST",
            data: formData
        });
    },
    likePost: function (formData) {
        return axiosInstance({
            url: `/api/notice/like/`,
            method: "POST",
            data: formData
        });
    }
};
export default NoticeService;
