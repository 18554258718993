import axiosInstance from "../api/api";

const PermissionService = {
  list: function () {
    return axiosInstance({
      url: "/api/permission/",
      method: "GET",
    });
  },
  listByAssignee: function (assignee) {
    return axiosInstance({
      url: `/api/permission/?assignee=${assignee}`,
      method: "GET",
    });
  },
  fetch: function (uniqueId) {
    return axiosInstance({
      url: `/api/permission/${uniqueId}`,
      method: "GET",
    });
  },
  delete: function (uniqueId) {
    return axiosInstance({
      url: `/api/permission/${uniqueId}`,
      method: "DELETE",
    });
  },
  create: function (formData) {
    return axiosInstance({
      url: `/api/permission/`,
      method: "POST",
      data: formData,
    });
  },
  update: function (formData) {
    return axiosInstance({
      url: `/api/permission/`,
      method: "PATCH",
      data: formData,
    });
  },
};
export default PermissionService;
