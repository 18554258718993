import axiosInstance from "../api/api";

const ContactService = {
    list: function () {
        return axiosInstance({
            url: "/api/contact/",
            method: "GET",
        });
    },
    fetch: function (uniqueId) {
        return axiosInstance({
            url: `/api/contact/${uniqueId}`,
            method: "GET",
        });
    },
    fetchAllByCompany: function (uniqueId){
        return axiosInstance({
            url: `/api/contact/fetchAllByCompany/${uniqueId}`,
            method: "GET",
        });
    },
    delete: function (uniqueId) {
        return axiosInstance({
            url: `/api/contact/${uniqueId}`,
            method: "DELETE",
        });
    },
    create: function (formData) {
        return axiosInstance({
            url: `/api/contact/`,
            method: "POST",
            data: formData,
        });
    },
    update: function (formData) {
        return axiosInstance({
            url: `/api/contact/`,
            method: "PATCH",
            data: formData,
        });
    },
};
export default ContactService;
