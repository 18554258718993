/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Button, Card, CardBody, CardHeader, Container, Form} from "reactstrap";
import ContactService from "../../../services/contactService";
import CompanyService from "../../../services/companyService";
import ContactForm from "./Form";
import {contactRole} from "../../../util/contactRole";
import _ from "lodash";
import UtilService from "../../../services/utilService";

class ContactEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorData: {},
            selectKey: UtilService.uuidv4()
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleContactRoleChange = this.handleContactRoleChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
    }

    async componentDidMount() {
        const {uniqueId} = this.props.match.params;
        await CompanyService.list().then(response => {
            console.log("Executing companies ")
            console.log(response);
            this.setState({allCompanies: response});
            console.log("All COmpanies")
            console.log(this.state.allCompanies)
        });
        ContactService.fetch(uniqueId).then(response => {
            console.log(response.data);
            this.setState(response.data);
            this.setState({})
            this.setState({uniqueId: uniqueId});
            this.setState({selectedContactRole: { key:response.data.contactRole, value : response.data.contactRole}});
            this.setState({selectedCompany: response.data.company});
            this.setState({companyId:response.data.company.uniqueId});
            this.setState({contactRole: response.data.contactRole});
            this.setState({selectKey: UtilService.uuidv4()});
            console.log(this.state);
        });
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }

    handleSubmit(event) {
        event.preventDefault();
        ContactService.update(this.state).then(response => {
            console.log(response.data);
            if (response.responseStatus === "SUCCESS") {
                this.props.history.push("/admin/contact/list");
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    }

    handleContactRoleChange(valueObj) {
        if (_.isNull(valueObj)) {
            this.setState({selectedContactRole: {}, contactRole:""});
        } else {
            this.setState({selectedContactRole: valueObj, contactRole: valueObj.key});
        }
    }

    handleCompanyChange = (valueObj, event) => {
        console.log(valueObj);
        if (_.isNull(valueObj)) {
            this.setState({selectedCompany: {}, companyId :""});
        } else {
            this.setState({selectedCompany: valueObj, companyId : valueObj.uniqueId});
            this.setState({selectKey: UtilService.uuidv4()});
            console.log(this.state.companyId)
            console.log(this.state)
        }
    };

    render() {
        return (
            <>
                <SimpleHeader
                    name="Edit"
                    parentName="Contact"
                    heading="XareGrowth"
                    newBtnUrl="/admin/contact/create"
                    newBtnText="Create Contact"
                />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">Edit Contact</h3>
                        </CardHeader>
                        <CardBody>
                            <Form role="form" onSubmit={this.handleSubmit}>
                                <ContactForm
                                    selectKey={this.state.selectKey}
                                    onChange={this.handleChange}
                                    valueObj={this.state}
                                    errorObj={this.state.errorData}
                                    isEdit={false}
                                    onContactRoleChange={this.handleContactRoleChange}
                                    selectedContact={this.state.selectedContactRole}
                                    contactRoles={contactRole}
                                    onCompanyChange={this.handleCompanyChange}
                                    selectedCompany={this.state.selectedCompany}
                                    companies={this.state.allCompanies}
                                />
                                <div className="text-center">
                                    <Button className="my-4" color="default" outline type="submit">
                                        Update
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}

export default ContactEdit;
