/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// core components
import React from "react";
import StatusPageHeader from "../../../components/Headers/StatusPageHeader";

class Status403Page extends React.Component {
  render() {
    return (
      <>
        <StatusPageHeader
          title="403!"
          lead="Unauthorised! You are not authorised to access this page."
          btnURL="/"
        />
      </>
    );
  }
}

export default Status403Page;
