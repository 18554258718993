/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
  Card,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import OnlineExaminationService from "../../../services/onlineExaminationService";

class OnlineExaminationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onlineExaminationList: [],
    };
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    const { examinationUID } = this.props.match.params;
    this.setState({ examinationUID: examinationUID });
    OnlineExaminationService.listByExamination(examinationUID).then(
      (response) => {
        console.log(response);
        this.setState({ onlineExaminationList: response.data });
      }
    );
  }

  delete(uniqueId) {
    const { examinationUID } = this.state;
    OnlineExaminationService.delete(uniqueId).then((response) => {
      console.log(response);
      //TODO: TO be fixed with redux implementation
      //   this.props.history.push("/admin/dashboard");
      OnlineExaminationService.listByExamination(examinationUID).then(
        (responseList) => {
          console.log(responseList);
          this.setState({ onlineExaminationList: responseList.data });
        }
      );
    });
  }

  render() {
    let obj = this;
    return (
      <>
        <SimpleHeader
          name="List"
          parentName="Online Examination"
          heading="ClashMate"
          newBtnUrl={`/teacher/onlineExamination/create/${this.state.examinationUID}`}
          newBtnText="Create Online Examination"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Online Examination List</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        #
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Date Created
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Title
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Question Count
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {this.state.onlineExaminationList.map(function (
                      value,
                      index
                    ) {
                      return (
                        <tr key={index}>
                          <td className="budget">{index + 1}</td>
                          <td className="budget">{value.dateCreated}</td>
                          <td className="budget">{value.title}</td>
                          <td className="budget">{value.questionCount}</td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color=""
                                size="sm"
                                className="btn-icon-only text-light"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    obj.props.history.push(
                                      `/teacher/onlineExamination/show/${obj.state.examinationUID}/${value.uniqueId}`
                                    );
                                  }}
                                >
                                  Details
                                </DropdownItem>
                                <DropdownItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    obj.delete(`${value.uniqueId}`);
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default OnlineExaminationList;
