import axiosInstance from "../api/api";

const TeacherService = {
  list: function () {
    return axiosInstance({
      url: "/api/teacher/",
      method: "GET",
    });
  },
  fetch: function (uniqueId) {
    return axiosInstance({
      url: `/api/teacher/${uniqueId}`,
      method: "GET",
    });
  },
  delete: function (uniqueId) {
    return axiosInstance({
      url: `/api/teacher/${uniqueId}`,
      method: "DELETE",
    });
  },
  create: function (formData) {
    return axiosInstance({
      url: `/api/teacher/`,
      method: "POST",
      data: formData,
    });
  },
  update: function (formData) {
    return axiosInstance({
      url: `/api/teacher/`,
      method: "PATCH",
      data: formData,
    });
  },
  subject: function () {
    return axiosInstance({
      url: "/api/teacher/subject",
      method: "GET",
    });
  },
};
export default TeacherService;
