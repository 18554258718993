/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap";
import TenantSettingService from "../../../services/tenantSettingService";
import { DataTypeConstant } from "../../../util/TenantConstant";

class TenantSettingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    TenantSettingService.list().then((response) => {
      console.log(response);
      this.setState({ list: response });
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    TenantSettingService.update({ key: target.name, value: value }).then(
      (response) => {
        TenantSettingService.list().then((responseList) => {
          console.log(responseList);
          this.setState({ list: responseList });
        });
        console.log(response);
      }
    );
  }

  render() {
    let obj = this;
    return (
      <>
        <SimpleHeader
          name="List"
          parentName="Tenant Setting"
          heading="ClashMate"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Tenant Setting</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        #
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Name
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {this.state.list.map(function (value, index) {
                      return (
                        <tr key={index}>
                          <td className="budget">{index + 1}</td>
                          <td className="budget">{value.description}</td>
                          <td>
                            {value.dataType === DataTypeConstant.BOOLEAN ? (
                              <label className="custom-toggle">
                                <Input
                                  defaultChecked={value.value === "true"}
                                  name={value.key}
                                  type="checkbox"
                                  onChange={obj.handleChange}
                                />
                                <span
                                  className="custom-toggle-slider rounded-circle"
                                  data-label-off="No"
                                  data-label-on="Yes"
                                />
                              </label>
                            ) : (
                              <Input
                                name={value.key}
                                type="text"
                                onChange={obj.handleChange}
                                defaultValue={value.value || ""}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default TenantSettingList;
