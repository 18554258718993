/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Row,
} from "reactstrap";
import ExaminationService from "../../../services/examinationService";
import StudentListTable from "./StudentList";

class ExaminationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      examinationDeta: {
        studentExaminationDTOList: [],
      },
    };
  }

  componentDidMount() {
    const { uniqueId } = this.props.match.params;
    ExaminationService.fetch(uniqueId).then((response) => {
      console.log(response.data);
      this.setState({ examinationDeta: response.data });
    });
  }

  render() {
    const { examinationDeta } = this.state;
    return (
      <>
        <SimpleHeader
          name="Details"
          parentName="Examination"
          heading="ClashMate"
          newBtnUrl="/teacher/examination/list"
          newBtnText="Examination List"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col md="12">
              <Card className="mb-4">
                <CardHeader>
                  <h3 className="mb-0">{examinationDeta.title}</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          Start Date
                        </label>
                        <p>{examinationDeta.startDateTime}</p>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          End Date
                        </label>
                        <p>{examinationDeta.endDateTime}</p>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols3Input"
                        >
                          Class
                        </label>
                        <p>{examinationDeta.courseSectionSubjectTitle}</p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example4cols1Input"
                        >
                          Date Created
                        </label>
                        <p>{examinationDeta.dateCreated}</p>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example4cols2Input"
                        >
                          Examination Type
                        </label>
                        <p>{examinationDeta.examinationType}</p>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example4cols2Input"
                        >
                          Duration
                        </label>
                        <p>{examinationDeta.duration} minutes</p>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <StudentListTable
                cardTitle="Student List"
                studentList={examinationDeta.studentExaminationDTOList}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ExaminationDetails;
