/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import React from "react";
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Row,
    Table,
    UncontrolledDropdown,
} from "reactstrap";
import CourseSectionSubjectService from "../../../services/courseSectionSubjectService";
import _ from "lodash";
import FormReactSelect from "../../../components/common/form/FormReactSelect";
import CourseSectionService from "../../../services/courseSectionService";
import UtilService from "../../../services/utilService";
import AttendanceService from "../../../services/attendanceService";
import FormInput from "../../../components/common/form/FormInput";
import NotificationAlert from "react-notification-alert";
import moment from "moment";

class AttendanceTeacherList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSection: {},
            selectedCourse: {},
            selectedSubject: {},
            dateStart: null,
            dateEnd: null,
            errorData: {},
            courseSectionSubjectList: [],
            displayList: [],
            courseSectionList: [],
            mappedCoursesList: [],
            mappedSectionsList: [],
            mappedSubjectsList: [],
            mappedStudentsList: [],
            sectionSelectUUID: UtilService.uuidv4(),
            subjectSelectUUID: UtilService.uuidv4(),
            dateList: [],
            studentList: []
        };
        this.handleCourseChange = this.handleCourseChange.bind(this);
        this.handleSectionChange = this.handleSectionChange.bind(this);
    }

    onChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }

    handleSectionChange = (value) => {
        this.setState({subjectSelectUUID: UtilService.uuidv4()});
        this.setState({selectedSubject: {}});
        if (_.isNull(value)) {
            this.setState({selectedSection: {}});
        } else {
            this.setState({selectedSection: value});
        }
        this.setState({displayList: []});
        this.populateSubjects(this.state.selectedCourse, value);
    }

    handleCourseChange = (value) => {
        this.setState({sectionSelectUUID: UtilService.uuidv4()});
        this.setState({subjectSelectUUID: UtilService.uuidv4()});
        if (_.isNull(value)) {
            this.setState({selectedCourse: {}});
        } else {
            this.setState({selectedCourse: value});
        }
        this.setState({selectedSection: {}});
        this.setState({selectedSubject: {}});
        this.setState({mappedSectionsList: []});
        this.setState({displayList: []});
        this.populateSections(value);
    }

    handleSubjectChange = (value) => {
        if (_.isNull(value)) {
            this.setState({selectedSubject: {}});
        } else {
            this.setState({selectedSubject: value});
        }
        this.setState({date: null})
    };

    populateSections = (course) => {
        let sectionsList = _.filter(
            this.state.courseSectionList,
            (courseSection) => {
                return courseSection.course.uniqueId === course.uniqueId;
            }
        );
        let mappedSectionsList = _.uniqBy(_.map(sectionsList, "section"), "title");
        this.setState({
            mappedSectionsList: mappedSectionsList,
        });
    };

    populateSubjects = (course, section) => {
        let mappedCourseSectionSubjects = _.filter(
            this.state.courseSectionSubjectList,
            (courseSectionSubject) => {
                return (
                    courseSectionSubject.course.uniqueId === course.uniqueId &&
                    courseSectionSubject.section.uniqueId === section.uniqueId
                );
            }
        );

        let mappedSubjectsList = _.map(mappedCourseSectionSubjects, "subject");
        this.setState({mappedSubjectsList: mappedSubjectsList});
    };

    populateCourseSectionSubjects = async () => {
        await CourseSectionSubjectService.fetchCourseSectionSubjects().then(
            (res) => {
                this.setState({courseSectionSubjectList: res.data});
            }
        );
    };

    populateCourseSections = async () => {
        await CourseSectionService.fetchCourseSections().then((res) => {
            this.setState({courseSectionList: res.data});
            let mappedCoursesList = _.uniqBy(
                _.map(this.state.courseSectionList, "course"),
                "title"
            );
            this.setState({
                mappedCoursesList: mappedCoursesList,
            });
        });
    };

    populateDisplayList = async () => {
        let allOptionsNotSelected = _.isEmpty(this.state.selectedCourse) || _.isEmpty(this.state.selectedSection) || _.isEmpty(this.state.selectedSubject)
        if (allOptionsNotSelected === true) {
            console.error("All options are not selected!")
        } else {
            this.setState({dateList: []});
            this.setState({studentList: []});
            let formData = {
                courseUid: this.state.selectedCourse.uniqueId,
                sectionUid: this.state.selectedSection.uniqueId,
                subjectUid: this.state.selectedSubject.uniqueId,
                dateStart: this.state.dateStart,
                dateEnd: this.state.dateEnd,
            };
            console.log(formData);
            await AttendanceService.filteredList(formData).then(response => {
                this.setState({displayList: response.data});
                let dateList = this.populateDateList();
                this.setState({dateList: dateList});
                let studentList = _.uniqBy(
                    _.map(response.data, "student"),
                    "uniqueId"
                );
                this.setState({studentList: studentList});
            });
        }
    };

    populateDateList = () => {
        let startDate = moment(this.state.dateStart);
        let endDate = moment(this.state.dateEnd);
        var now = startDate, dates = [];

        while (now.isBefore(endDate) || now.isSame(endDate)) {
            dates.push(now.format('YYYY-MM-DD'));
            now.add(1, 'days');
        }
        console.log(dates);
        return dates;
    };

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    markPresent = (uniqueId) => {
        let formData = {
            uniqueId: uniqueId
        };
        AttendanceService.markPresent(formData).then((response) => {
            console.log(response);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            let attendance = _.find(this.state.displayList, attendance => attendance.uniqueId === uniqueId);
            attendance.isPresent = true;
            let displayList = _.filter(this.state.displayList, attendance => attendance.uniqueId !== uniqueId);
            this.setState({displayList: [...displayList, attendance]});
        })
    };

    markAbsent = (uniqueId) => {
        let formData = {
            uniqueId: uniqueId
        };
        AttendanceService.markAbsent(formData).then((response) => {
            console.log(response);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            let attendance = _.find(this.state.displayList, attendance => attendance.uniqueId === uniqueId)
            attendance.isPresent = false;
            let displayList = _.filter(this.state.displayList, attendance => attendance.uniqueId !== uniqueId)
            this.setState({displayList: [...displayList, attendance]});
        })
    };

    delete = (uniqueId) => {
        AttendanceService.delete(uniqueId).then((response) => {
            console.log(response);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            let displayList = _.filter(this.state.displayList, attendance => attendance.uniqueId !== uniqueId);
            this.setState({displayList: displayList});
        })
    };

    componentDidMount() {
        this.populateCourseSectionSubjects();
        this.populateCourseSections();
    };


    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    fetchAttendance = (student, date) => {
        let attendance = _.find(this.state.displayList, attendance => attendance.student.uniqueId === student.uniqueId && attendance.date === date);
        if (attendance) {
            return attendance.isPresent ? "P" : "A";
        } else {
            return "N/A";
        }
    };

    attendanceDisplay = () => {
        if (this.state.displayList.length === 0) {
            return (
                <>
                    <CardBody>
                        <Alert color="warning">
                            <strong>There is no data to display!</strong> Please select
                            course, section and subject from the dropdown!
                        </Alert>
                    </CardBody>
                </>
            );
        } else {
            let self = this;
            return (
                <>
                    <CardBody>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                            <tr>
                                <th className="sort" data-sort="status" scope="col">
                                    #
                                </th>
                                <th className="sort" data-sort="status" scope="col">
                                    Student Name
                                </th>
                                {this.state.dateList.map(function (date, index) {
                                    return (
                                        <th className="sort" key={UtilService.uuidv4()} data-sort="status" scope="col">
                                            {date}
                                        </th>);
                                })}
                            </tr>
                            </thead>
                            <tbody className="list">
                            {this.state.studentList.map(function (student, index) {
                                return (
                                    <tr key={index}>
                                        <td className="budget">{index + 1}</td>
                                        <td className="budget">{student.fullName}</td>
                                        {self.state.dateList.map(function (date, index) {
                                            return (<td className="budget" key={UtilService.uuidv4()}>
                                                {self.fetchAttendance(student, date)}
                                            </td>);
                                        })}
                                    </tr>
                                );
                            })}

                            </tbody>
                        </Table>
                    </CardBody>
                </>
            );
        }

    };

    displayDetails = () => {
        if (this.state.displayList.length === 0) {
            return (
                <>
                    <CardBody>
                        <Alert color="warning">
                            <strong>There is no data to display!</strong> Please select
                            course, section and subject from the dropdown!
                        </Alert>
                    </CardBody>
                </>
            );
        } else {
            let self = this;
            return (
                <>
                    <CardBody>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                            <tr>
                                <th className="sort" data-sort="name" scope="col">
                                    #
                                </th>
                                <th className="sort" data-sort="budget" scope="col">
                                    Date Created
                                </th>
                                <th className="sort" data-sort="status" scope="col">
                                    Student Name
                                </th>
                                <th className="sort" data-sort="status" scope="col">
                                    Email
                                </th>
                                <th className="sort" data-sort="status" scope="col">
                                    Marked By
                                </th>
                                <th className="sort" data-sort="status" scope="col">
                                    Course
                                </th>
                                <th className="sort" data-sort="status" scope="col">
                                    Section
                                </th>
                                <th className="sort" data-sort="status" scope="col">
                                    Subject
                                </th>
                                <th className="sort" data-sort="status" scope="col">
                                    Present
                                </th>
                                <th scope="col"/>
                            </tr>
                            </thead>
                            <tbody className="list">
                            {this.state.displayList.map(function (attendance, index) {
                                return (
                                    <tr key={index}>
                                        <td className="budget">{index + 1}</td>
                                        <td className="budget">{attendance.dateCreated}</td>
                                        <td className="budget">{attendance.student.fullName}</td>
                                        <td className="budget">{attendance.student.emailAddress}</td>
                                        <td className="budget">{attendance.teacher.fullName}</td>
                                        <td className="budget">{attendance.course.title}</td>
                                        <td className="budget">{attendance.section.title}</td>
                                        <td className="budget">{attendance.subject.title}</td>
                                        <td className="budget">{attendance.isPresent ? "YES" : "NO"}</td>
                                        <td className="text-right">
                                            <UncontrolledDropdown>
                                                <DropdownToggle
                                                    color=""
                                                    size="sm"
                                                    className="btn-icon-only text-light"
                                                >
                                                    <i className="fas fa-ellipsis-v"/>
                                                </DropdownToggle>
                                                <DropdownMenu
                                                    className="dropdown-menu-arrow"
                                                    right
                                                >
                                                    <DropdownItem
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            self.markPresent(attendance.uniqueId);
                                                        }}
                                                    >
                                                        Mark Present
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            self.markAbsent(attendance.uniqueId);
                                                        }}
                                                    >
                                                        Mark Absent
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            self.delete(`${attendance.uniqueId}`);
                                                        }}
                                                    >
                                                        Delete
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    </CardBody>
                </>
            );
        }
    };

    render() {
        return (
            <>
                <SimpleHeader
                    parentName="Attendance"
                    name="List"
                    sectionName="Attendance"
                    heading="ClashMate"
                    newBtnText={"Assign Attendance"}
                    newBtnUrl={"/teacher/attendance/assign"}
                />
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <Container className="mt--6" fluid>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <Col sm="12">
                                            <h3 className="mb-0">List Attendance</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form role="form">
                                        <Row>
                                            <Col md="6">
                                                <FormReactSelect
                                                    isMulti={false}
                                                    className="form-control-label"
                                                    isSearchable={true}
                                                    name={"courseUid"}
                                                    placeHolder="inputPlaceHolder.timeTable.select.course"
                                                    onChange={this.handleCourseChange}
                                                    optionValue={"uniqueId"}
                                                    // value={this.state.selectedCourse}
                                                    optionList={this.state.mappedCoursesList}
                                                    optionLabel={"title"}
                                                    label="inputLabel.timeTable.select.course"
                                                    errorMsg={this.state.errorData.courseUid || ""}
                                                />
                                            </Col>
                                            <Col md="6">
                                                <FormReactSelect
                                                    selectKey={`section_select_${this.state.sectionSelectUUID}`}
                                                    isMulti={false}
                                                    className="form-control-label"
                                                    isSearchable={true}
                                                    label="inputLabel.timeTable.select.section"
                                                    name={"sectionUid"}
                                                    onChange={this.handleSectionChange}
                                                    optionValue={"uniqueId"}
                                                    optionList={this.state.mappedSectionsList}
                                                    optionLabel={"title"}
                                                    placeHolder="inputPlaceHolder.timeTable.select.section"
                                                    errorMsg={this.state.errorData.sectionUid || ""}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <FormReactSelect
                                                    selectKey={`subject_select_${this.state.subjectSelectUUID}`}
                                                    isMulti={false}
                                                    className="form-control-label"
                                                    isSearchable={true}
                                                    label="inputLabel.timeTable.select.subject"
                                                    name={"subjectUid"}
                                                    onChange={this.handleSubjectChange}
                                                    optionValue={"uniqueId"}
                                                    optionList={this.state.mappedSubjectsList}
                                                    optionLabel={"title"}
                                                    placeHolder="inputPlaceHolder.timeTable.select.subject"
                                                    errorMsg={this.state.errorData.subjectUid || ""}
                                                />
                                            </Col>
                                            <Col md="4" sm="6">
                                                <FormInput
                                                    name="dateStart"
                                                    className="form-control-label"
                                                    id="dateStart"
                                                    placeholder="inputPlaceHolder.attendance.dateStart"
                                                    type="date"
                                                    onChange={this.handleChange}
                                                    label="inputLabel.attendance.dateStart"
                                                    value={this.state.dateStart || ""}
                                                    errorMsg={this.state.errorData.dateStart || ""}
                                                />
                                            </Col>
                                            <Col md="4" sm="6">
                                                <FormInput
                                                    name="dateEnd"
                                                    className="form-control-label"
                                                    id="dateEnd"
                                                    placeholder="inputPlaceHolder.attendance.dateEnd"
                                                    type="date"
                                                    onChange={this.handleChange}
                                                    label="inputLabel.attendance.dateEnd"
                                                    value={this.state.dateEnd || ""}
                                                    errorMsg={this.state.errorData.dateEnd || ""}
                                                />
                                            </Col>
                                        </Row>
                                        <div className="text-center">
                                            <Button className="my-4" color="info" type="button"
                                                    onClick={this.populateDisplayList}>
                                                Display
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <Row className="align-items-center">
                                        <Col sm="12">
                                            <h3 className="mb-0">Attendance List</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {this.attendanceDisplay()}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    };
}

export default AttendanceTeacherList;
