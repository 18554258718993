/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
} from "reactstrap";
import TenantService from "../../../services/tenantService";
import NotificationAlert from "react-notification-alert";

class TenantList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
        };
        this.delete = this.delete.bind(this);
        this.notify = this.notify.bind(this);
    }

    componentDidMount() {
        TenantService.list().then((response) => {
            console.log(response);
            this.setState({list: response});
        });
    }

    delete(uniqueId) {
        TenantService.delete(uniqueId).then((response) => {
            console.log(response);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            //TODO: TO be fixed with redux implementation
            //   this.props.history.push("/admin/dashboard");
            TenantService.list().then((response) => {
                console.log(response);
                this.setState({list: response});
            });
        });
    }

    deletePermanently(uniqueId) {
        TenantService.deletePermanently(uniqueId).then((response) => {
            console.log(response);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            //TODO: TO be fixed with redux implementation
            //   this.props.history.push("/admin/dashboard");
            TenantService.list().then((response) => {
                console.log(response);
                this.setState({list: response});
            });
        });
    }

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    render() {
        let obj = this;
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <SimpleHeader
                    name="List"
                    parentName="Tenant"
                    heading="ClashMate"
                    newBtnUrl="/superAdmin/tenant/create"
                    newBtnText="Create Tenant"
                />
                <Container className="mt--6" fluid>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Tenant List</h3>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                        <tr>
                                            <th className="sort" data-sort="name" scope="col">
                                                #
                                            </th>
                                            <th className="sort" data-sort="budget" scope="col">
                                                Date Created
                                            </th>
                                            <th className="sort" data-sort="status" scope="col">
                                                Name
                                            </th>
                                            <th className="sort" data-sort="status" scope="col">
                                                Email Address
                                            </th>
                                            <th className="sort" data-sort="status" scope="col">
                                                Phone Number
                                            </th>
                                            <th className="sort" data-sort="status" scope="col">
                                                Domain Name
                                            </th>
                                            <th scope="col"/>
                                        </tr>
                                        </thead>
                                        <tbody className="list">
                                        {this.state.list.map(function (value, index) {
                                            return (
                                                <tr key={index}>
                                                    <td className="budget">{index + 1}</td>
                                                    <td className="budget">{value.dateCreated}</td>
                                                    <td className="budget">{value.fullName}</td>
                                                    <td className="budget">{value.emailAddress}</td>
                                                    <td className="budget">{value.phoneNumber}</td>
                                                    <td className="budget">
                                                        {value.domainName || value.secondaryDomainName}
                                                    </td>
                                                    <td className="text-right">
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle
                                                                color=""
                                                                size="sm"
                                                                className="btn-icon-only text-light"
                                                            >
                                                                <i className="fas fa-ellipsis-v"/>
                                                            </DropdownToggle>
                                                            <DropdownMenu
                                                                className="dropdown-menu-arrow"
                                                                right
                                                            >
                                                                <DropdownItem
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        obj.props.history.push(
                                                                            `/superAdmin/tenant/show/${value.uniqueId}`
                                                                        );
                                                                    }}
                                                                >
                                                                    Details
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        obj.props.history.push(
                                                                            `/superAdmin/tenant/configuration/${value.uniqueId}`
                                                                        );
                                                                    }}
                                                                >
                                                                    Configuration
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        obj.props.history.push(
                                                                            `/superAdmin/tenant/edit/${value.uniqueId}`
                                                                        );
                                                                    }}
                                                                >
                                                                    Edit
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        obj.delete(`${value.uniqueId}`);
                                                                    }}
                                                                >
                                                                    Delete
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        obj.deletePermanently(`${value.uniqueId}`);
                                                                    }}
                                                                >
                                                                    Delete Permanently
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default TenantList;
