import websocketInstance from "../api/websocket";
import _ from "lodash";

const WebsocketService = {
	subscribe: function (topicList, callback) {
		websocketInstance(false)
			.then(function (client) {
				_.forEach(topicList, function (topic) {
					client.subscribe(topic, callback);
				});
			})
			.catch(function (err) {
				console.error("Websocket connection error: ", err);
			});
	},

	send: function (topic, message, optHeaders = {}) {
		websocketInstance(false)
			.then(function (client) {
				client.send(topic, optHeaders, message);
			})
			.catch(function (err) {
				console.error("Websocket connection error: ", err);
			});
	},
};

export default WebsocketService;
