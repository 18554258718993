/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Card, Container, Row,} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";
import StudyMaterialService from "../../../../services/studyMaterialService";
import CardText from "reactstrap/es/CardText";
import CardBody from "reactstrap/es/CardBody";
import CardTitle from "reactstrap/es/CardTitle";
import CardImg from "reactstrap/es/CardImg";
import CardFooter from "reactstrap/es/CardFooter";
import studyMaterialImage from "../../../../assets/img/theme/img-1-1000x600.jpg"
import Badge from "reactstrap/es/Badge";

class MarketPlace extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            alert: null
        };
        this.delete = this.delete.bind(this);
        this.notify = this.notify.bind(this);
    }

    componentDidMount() {
        StudyMaterialService.list().then((response) => {
            console.log(response);
            this.setState({list: response.data});
        });
    }

    hideDeleteAlert = () => {
        this.setState({
            alert: null
        });
    };

    confirmDeleteAlert = (uniqueId) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{display: "block"}}
                    title="Are you sure?"
                    onConfirm={() => this.delete(uniqueId)}
                    onCancel={this.hideDeleteAlert}
                    showCancel
                    confirmBtnBsStyle="danger"
                    confirmBtnText="Yes, delete it!"
                    cancelBtnBsStyle="secondary"
                    cancelBtnText="Cancel"
                    btnSize=""
                >
                    You won't be able to revert this!
                </ReactBSAlert>
            )
        });
    };

    delete(uniqueId) {
        this.setState({alert: null})
        StudyMaterialService.delete(uniqueId).then((response) => {
            console.log(response);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            //TODO: TO be fixed with redux implementation
            //   this.props.history.push("/admin/dashboard");
            StudyMaterialService.list().then((response) => {
                console.log(response);
                this.setState({list: response.data});
            });
        });
    };

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    render() {
        let obj = this;
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <SimpleHeader
                    name="Market Place"
                    parentName="Market"
                    heading="ClashMate"
                    // newBtnUrl={`/${AuthService.getBaseURLByRole()}/studyMaterial/create`}
                    // newBtnText="Create Study Material"
                />
                {this.state.alert}
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="card-columns">
                            {this.state.list.map(function (value, index) {
                                return (
                                    <>
                                        <Card key={value.uniqueId}
                                              onClick={() => {
                                                  obj.showDetails(value);
                                              }}
                                              style={{cursor: "pointer"}}>
                                            <CardImg
                                                alt="..."
                                                src={studyMaterialImage}
                                                top
                                            />
                                            <CardBody>
                                                <CardTitle><h2>{value.title}</h2></CardTitle>
                                                <CardText>
                                                    <h4>Author - {value.createdBy.fullName}</h4>
                                                    {value.isPublishedGlobally ?
                                                        <Badge color="primary" pill>
                                                            Globally Published
                                                        </Badge> : ""}
                                                </CardText>
                                            </CardBody>
                                            <CardFooter>
                                                <CardText>
                                                    Added : {value.dateCreated}
                                                </CardText>
                                            </CardFooter>
                                        </Card>
                                    </>
                                )
                            })}
                        </div>
                    </Row>
                </Container>
            </>
        );
    }

    showDetails = (value) => {
        console.log(`/student/marketplace/course/${value.uniqueId}`);
        this.props.history.push(`/student/market/course/${value.uniqueId}`);
    }
}

export default MarketPlace;
