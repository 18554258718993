/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// react library for routing
// reactstrap components
import {
    Button,
    Card,
    CardImg,
    CardSubtitle,
    CardTitle,
    Col,
    Collapse,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    Nav,
    Navbar,
    NavbarBrand,
    NavItem,
    NavLink,
    Row
} from 'reactstrap';
// core components
import ContentService from "../services/xgContentService";
import _ from "lodash";
import UtilService from "../services/utilService";
import Login from "./pages/auth/Login";
import Paginations from "./Paginations";
import FormReactSelect from "../components/common/form/FormReactSelect";
import {contentType} from "../util/contentType";

class KnowledgeCentre2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            term: "",
            collapseSearchServices: false,
            collapseSearchArticles: false,
            completeContentList: [],
            searchList: [],
            articleKey: UtilService.uuidv4(),
            videoKey: UtilService.uuidv4(),
            audioKey: UtilService.uuidv4(),
            loginModal: false,
            currentPage: 1,
            pageSize: 18, // 8 items to display 4 per row (2 rows per page)
            selectedContentType: {key: "ALL", value: "ALL"}
        };
        this.filterByContentType = this.filterByContentType.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    }

    onPageChange(pageNumber) {
        this.setState({currentPage: pageNumber});
    }

    icon = (item) => {
        let icon;
        switch (item.contentType) {
            case 'TEXT':
                icon = "fa fa-file-alt";
                break;
            case 'VIDEO':
                icon = "fa fa-video";
                break;
            case 'AUDIO':
                icon = "fa fa-headphones";
                break;
            default:
                icon = "fa fa-file-alt"; // Default icon if type is unknown
        }
        return icon;
    }

    componentDidMount() {
        ContentService.list().then((response) => {
            console.log(response);
            this.setState({completeContentList: response});
            this.setState({searchList: response});
            this.setState({audio: this.filterByContentType(this.state.completeContentList, "AUDIO")});
            this.setState({video: this.filterByContentType(this.state.completeContentList, "VIDEO")});
            this.setState({text: this.filterByContentType(this.state.completeContentList, "TEXT")});
            console.log(this.state)
            this.toggleSearchArticles();
            this.setState({
                articleKey: UtilService.uuidv4(),
                videoKey: UtilService.uuidv4(),
                audioKey: UtilService.uuidv4(),
            })
        });
    }

    onPageChange(pageNumber) {
        this.setState({currentPage: pageNumber});
    }

    renderCards(items) {
        return items.map((item) => (
            <Card key={UtilService.uuidv4()} style={{margin: "10px", width: "31%", position: "relative"}}>
                <CardImg
                    style={{width: '100%', height: '200px', objectFit: 'cover'}}
                    src={require("assets/img/brand/knowledge.jpeg")}
                    alt="Card image cap"
                />
                <div style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "50%",
                    padding: "5px",
                    color: "white",
                    fontSize: "1.2rem"
                }}>
                    <span className={this.icon(item)}/>
                </div>
                <div style={{
                    position: "absolute",
                    bottom: "0",
                    width: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    color: "white",
                    padding: "10px",
                    textAlign: "center"
                }}>
                    <CardTitle onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/blog/${item.uniqueId}`);
                    }} style={{cursor: "pointer", fontSize: "1.2rem", fontWeight: "bold"}}>
                        {item.title}
                    </CardTitle>
                    <CardSubtitle style={{fontSize: "1rem"}}>
                        {item.category}
                    </CardSubtitle>
                </div>
            </Card>
        ));
    }

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    filterByContentType = (data, type) => {
        return _.filter(data, {contentType: type});
    };

    toggleSearchServices = (event) => {
        event.preventDefault();
        this.props.history.push("/");
    }

    toggleSearchArticles = () => {
        this.setState({
            collapseSearchArticles: !this.state.collapseSearchArticles,
            collapseSearchServices: false // Close the services section when articles is toggled
        });
    }

    displaySearchArticles = () => {
        return this.state.searchList.length === 0 ? "Display Search Articles" : <></>
    }

    onSearchInputChange = (event) => {
        let term = event.target.value;
        this.setState({term: event.target.value})
        console.log(this.state.term)
    }

    onSearch = event => {
        const filteredItems = _.filter(this.state.completeContentList, (item) => {
            return _.includes(item.title.toLowerCase(), this.state.term.toLowerCase()) ||
                _.includes(item.category.toLowerCase(), this.state.term.toLowerCase());
        });
        console.log(filteredItems)
        this.setState({audio: this.filterByContentType(filteredItems, "AUDIO")});
        this.setState({searchList: filteredItems});
        this.setState({video: this.filterByContentType(filteredItems, "VIDEO")});
        this.setState({text: this.filterByContentType(filteredItems, "TEXT")});
        this.setState({
            articleKey: UtilService.uuidv4(),
            videoKey: UtilService.uuidv4(),
            audioKey: UtilService.uuidv4(),
        })
    }

    handleContentTypeChange = (valueObj, event) => {
        if (_.isNull(valueObj)) {
            this.setState({selectedContentType: [], contentType: ""});
        } else {
            this.setState({selectedContentType: valueObj, contentType: valueObj.key});
            if (valueObj.key === "TEXT") {
                this.setState({searchList: this.filterByContentType(this.state.completeContentList, "TEXT")});
            } else if (valueObj.key === "AUDIO") {
                this.setState({searchList: this.filterByContentType(this.state.completeContentList, "AUDIO")});
            } else if (valueObj.key === "VIDEO") {
                this.setState({searchList: this.filterByContentType(this.state.completeContentList, "VIDEO")});
            } else {
                this.setState({searchList: this.state.completeContentList});
            }
        }
    };


    render() {
        const {completeContentList, currentPage, pageSize, searchList} = this.state;
        const startIndex = (currentPage - 1) * pageSize;
        const paginatedItems = searchList.slice(startIndex, startIndex + pageSize);

        return (
            <div>
                {/* Navbar */}
                <Navbar color="white" light expand="md" className={"bg-white"}>
                    <NavbarBrand href="/"><img height={"auto"} width={"170px"}
                                               src={require("assets/img/brand/xareLogo.png")}/></NavbarBrand>
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <span outline color="default" className="mb-3 xgnav-link"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.props.history.push("/");
                                    }}
                            >
                                Services
                            </span> &nbsp;&nbsp;&nbsp;
                            <>
                                {localStorage.getItem("userId") ? (
                                    <span
                                        color="default"
                                        className="mb-3 xgnav-link"
                                        outline
                                        onClick={(event) => {
                                            event.preventDefault();
                                            this.props.history.push("/admin/dashboard"); // Navigate to CRM if logged in
                                        }}
                                    >
                                        Switch to CRM
                                    </span>
                                ) : (
                                    <span
                                        color="default"
                                        className="mb-3 xgnav-link"
                                        outline
                                        onClick={(event) => {
                                            event.preventDefault();
                                            this.toggleModal("loginModal")
                                            // this.props.history.push("/auth/login"); // Navigate to login if not logged in
                                        }}
                                    >
                                        Login
                                    </span>
                                )}
                            </>

                        </NavItem>
                    </Nav>
                </Navbar>

                {/* Hero Banner */}
                <div className="hero-banner">
                    <Container fluid>
                        <Row>
                            <Col>
                                <div className="hero-banner-content">
                                    <p style={{textAlign:"start"}}>Welcome to </p>
                                    <h1 style={{textAlign:"start"}}>XareGrowth Knowledge Centre</h1>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <br/>
                <br/>
                <br/>
                {/* Collapsible Sections */}
                <Container className={"mt-10"}
                           style={{
                               border: "2px solid #4E5F7A",
                               padding: "1.5rem",
                               borderRadius: "2px",
                               marginTop: "2.5rem", // Adjust to divide the buttons in half
                               position: "relative",
                               zIndex: "1"
                           }}>
                    {/* Search Services */}
                    <div className="text-center" style={{    marginTop: "-4rem"}}>
                        <Button color="default" onClick={this.toggleSearchServices} className="mb-3"
                                style={{
                                    width: "30%",
                                    fontSize: "1.5rem",
                                    padding: "1.5%"
                                }}>
                            Search Services
                        </Button>
                        {/* Search Articles */}
                        <Button color="white" onClick={this.toggleSearchArticles} className="mb-3"
                                style={{
                                    width: "30%",
                                    fontSize: "1.5rem",
                                    padding: "1.5%",
                                    marginLeft: "-0.5rem"
                                }}>
                            Search Articles
                        </Button>
                    </div>
                    <Collapse isOpen={this.state.collapseSearchServices}>
                    </Collapse>


                    <Collapse isOpen={this.state.collapseSearchArticles}>
                        <Form>
                            <FormGroup>
                                <Label for="searchText">Search Article</Label>
                                <Input onChange={this.onSearchInputChange} type="text" name="searchText"
                                       id="searchText"/>
                            </FormGroup>
                            <div className="text-center">
                                <Button color="default" outline onClick={this.onSearch} type="button">{"Search"}</Button>
                            </div>
                        </Form>
                    </Collapse>
                </Container>
                <br/>
                <br/>
                <br/>
                <Container>
                    <Row>
                        <Col md={"9"}/>
                        <Col md={"3"}>
                            <FormReactSelect
                                selectKey={UtilService.uuidv4()}
                                isMulti={false}
                                className="form-control-label"
                                isSearchable={true}
                                label="Filter By Type"
                                name={"contentType"}
                                onChange={this.handleContentTypeChange}
                                optionValue={"value"}
                                defaultValue={this.state.selectedContentType}
                                optionList={[...contentType, {key: "ALL", value: "ALL"}]}
                                optionLabel={"key"}
                                placeHolder="inputPlaceHolder.content.contentType"
                                errorMsg={this.errorObj?.contentType || ""}
                                // required
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={"9"}><br/></Col>
                    </Row>
                    <Row>
                        {this.renderCards(paginatedItems)}
                    </Row>
                    <Paginations
                        totalItems={completeContentList.length}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onPageChange={this.onPageChange}
                    />
                </Container>

                <Modal
                    // size={"lg"}
                    className="modal-dialog-centered"
                    isOpen={this.state.loginModal}
                    toggle={() => this.toggleModal("loginModal")}
                >
                    <Login/>
                </Modal>
                <footer className="py-5" id="footer-main">
                    <Container>
                        <Row className="align-items-center justify-content-xl-between">
                            <Col xl="6">
                                <div className="copyright text-center text-xl-left text-muted">
                                    © {new Date().getFullYear()}{" "}
                                    <a
                                        className="font-weight-bold ml-1"
                                        href="https://sekim-international.com/"
                                        target="_blank"
                                    >
                                        Sekim International
                                    </a>
                                </div>
                            </Col>
                            <Col xl="6">
                                <Nav className="nav-footer justify-content-center justify-content-xl-end">
                                    <NavItem>
                                        <NavLink
                                            href="https://xaregrowth.com"
                                            target="_blank"
                                        >
                                            XareGrowth
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href=""
                                            target="_blank"
                                        >
                                            About Us
                                        </NavLink>
                                    </NavItem>
                                    {/*<NavItem>
                                        <NavLink
                                            href=""
                                            target="_blank"
                                        >
                                            Blog
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href=""
                                            target="_blank"
                                        >
                                            License
                                        </NavLink>
                                    </NavItem>*/}
                                </Nav>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </div>
        );
    }
}

export default KnowledgeCentre2;
