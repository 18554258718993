/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
} from "reactstrap";
import ExaminationService from "../../../services/examinationService";
import TeacherService from "../../../services/teacherService";
import ExaminationForm from "./Form";

class ExaminationCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorData: {},
      answerTextMap: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    TeacherService.subject().then((response) => {
      this.setState({ subjectList: response });
    });
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    console.log(this.state);
  }

  handleSubmit(event) {
    event.preventDefault();
    ExaminationService.create(this.state).then((response) => {
      console.log(response.data);
      if (response.responseStatus === "SUCCESS") {
        this.props.history.push("/teacher/examination/list");
      } else {
        this.setState({ errorData: response.data });
        console.error("Error    ==>     " + response.message);
      }
    });
  }

  render() {
    return (
      <>
        <SimpleHeader
          name="Create"
          parentName="Examination"
          heading="ClashMate"
          newBtnUrl="/teacher/examination/list"
          newBtnText="Examination List"
        />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <h3 className="mb-0">Create Examination</h3>
            </CardHeader>
            <CardBody>
              <Form role="form" onSubmit={this.handleSubmit}>
                <ExaminationForm
                  onChange={this.handleChange}
                  valueObj={this.state}
                  errorObj={this.state.errorData}
                  isEdit={false}
                  obj={this}
                />
                <div className="text-center">
                  <Button className="my-4" color="info" type="submit">
                    Submit
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

export default ExaminationCreate;
