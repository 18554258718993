import axiosInstance from "../api/api";

const MeetingService = {
  list: function (eventStatus) {
    return axiosInstance({
      url: `/api/meeting/list/${eventStatus}`,
      method: "GET",
    });
  },
  fetch: function (uniqueId) {
    return axiosInstance({
      url: `/api/meeting/${uniqueId}`,
      method: "GET",
    });
  },
  delete: function (uniqueId) {
    return axiosInstance({
      url: `/api/meeting/${uniqueId}`,
      method: "DELETE",
    });
  },
  create: function (formData) {
    return axiosInstance({
      url: `/api/meeting/`,
      method: "POST",
      data: formData,
    });
  },
  update: function (formData) {
    return axiosInstance({
      url: `/api/meeting/`,
      method: "PATCH",
      data: formData,
    });
  },
};
export default MeetingService;
