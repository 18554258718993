/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {faBell} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React from "react";
import Moment from "react-moment";
import {Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown,} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import ListGroup from "reactstrap/lib/ListGroup";
import ListGroupItem from "reactstrap/lib/ListGroupItem";
import AuthService from "../../../services/authService";
import NotificationQueueService from "../../../services/notificationQueueService";
import WebsocketService from "../../../services/websocketService";

class NotificationListBell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationQueueList: [],
        };
        this.handleNotification = this.handleNotification.bind(this);
        this.handleWSMessage = this.handleWSMessage.bind(this);
    }

    handleWSMessage(message) {
        const messageBody = JSON.parse(message.body);
        this.handleNotification(
            messageBody.subject,
            messageBody.abbreviatedContentText,
            messageBody.dateCreated
        );
    }

    componentDidMount() {
        NotificationQueueService.listForUser("PUSH_WEB").then((response) => {
            this.setState({notificationQueueList: response.data});
        });
        WebsocketService.subscribe(
            ["/user/topic/notification"],
            this.handleWSMessage
        );
    }

    handleNotification(subject, content, dateCreated) {
        let notificationList = this.state.notificationQueueList;
        if (notificationList.length > 2) {
            notificationList = _.dropRight(notificationList);
        }
        notificationList.unshift({
            abbreviatedContentText: content,
            subject: subject,
            dateCreated: new Date(dateCreated),
        });
        this.setState({notificationList: notificationList});
    }

    render() {
        return (
            <>
                <UncontrolledDropdown nav>
                    <DropdownToggle className="nav-link" color="" tag="a">
                        <i className="ni ni-bell-55"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl py-0 overflow-hidden" right>
                        <div className="px-3 py-3">
                            <h6 className="text-sm text-muted m-0">Notification Center</h6>
                        </div>

                        <ListGroup flush>
                            {_.isEmpty(this.state.notificationQueueList) ? (
                                <Alert color="warning">
                                    <strong>Info!</strong> No notifications.
                                </Alert>
                            ) : (
                                this.state.notificationQueueList.map(function (value, index) {
                                    return (
                                        <ListGroupItem
                                            className="list-group-item-action"
                                            href="#pablo"
                                            onClick={(e) => e.preventDefault()}
                                            tag="a"
                                            key={index}
                                        >
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <FontAwesomeIcon icon={faBell}/>
                                                </Col>
                                                <div className="col ml--2">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <h4 className="mb-0 text-sm">{value.subject}</h4>
                                                        </div>
                                                        <div className="text-right text-muted">
                                                            <small>
                                                                <Moment fromNow>{value.dateCreated}</Moment>
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <p className="text-sm mb-0">{value.abbreviatedContentText}</p>
                                                </div>
                                            </Row>
                                        </ListGroupItem>
                                    );
                                })
                            )}
                        </ListGroup>

                        <DropdownItem
                            className="text-center text-info font-weight-bold py-3"
                            href={`/${AuthService.getBaseURLByRole()}/user/notificationQueue/list`}
                        >
                            View all
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </>
		);
	}
}

export default NotificationListBell;
