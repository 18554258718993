/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import {Bar, Line} from "react-chartjs-2";
// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Input,
    ListGroup,
    ListGroupItem,
    Media,
    Nav,
    NavItem,
    NavLink,
    Progress,
    Row,
    Table,
    UncontrolledTooltip
} from "reactstrap";
import {chartExample1, chartExample2, chartOptions, parseOptions} from "../../../variables/studentCharts";
import StudentCardsHeader from "../../../components/Headers/StudentCardsHeader";

// core components

class StudentDashBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeNav: 1,
            chartExample1Data: "data1"
        };
        if (window.Chart) {
            parseOptions(Chart, chartOptions());
        }
    }

    toggleNavs = (e, index) => {
        e.preventDefault();
        this.setState({
            activeNav: index,
            chartExample1Data:
                this.state.chartExample1Data === "data1" ? "data2" : "data1"
        });
    };

    render() {
        return (
            <>
                <StudentCardsHeader name="Student" parentName="Dashboard"/>
                <Container className="mt--6" fluid>
                    <Row>
                        <Col xl="8">
                            <Card className="bg-default">
                                <CardHeader className="bg-transparent">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h6 className="text-light text-uppercase ls-1 mb-1">
                                                Overview
                                            </h6>
                                            <h5 className="h3 text-white mb-0">Attendance</h5>
                                        </div>
                                        <div className="col">
                                            <Nav className="justify-content-end" pills>
                                                <NavItem className="mr-2 mr-md-0">
                                                    <NavLink
                                                        className={classnames("py-2 px-3", {
                                                            active: this.state.activeNav === 1
                                                        })}
                                                        href="#pablo"
                                                        onClick={e => this.toggleNavs(e, 1)}
                                                    >
                                                        <span className="d-none d-md-block">Month</span>
                                                        <span className="d-md-none">M</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames("py-2 px-3", {
                                                            active: this.state.activeNav === 2
                                                        })}
                                                        data-toggle="tab"
                                                        href="#pablo"
                                                        onClick={e => this.toggleNavs(e, 2)}
                                                    >
                                                        <span className="d-none d-md-block">Week</span>
                                                        <span className="d-md-none">W</span>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart">
                                        <Line
                                            data={chartExample1[this.state.chartExample1Data]}
                                            options={chartExample1.options}
                                            id="chart-sales-dark"
                                            className="chart-canvas"
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="4">
                            <Card>
                                <CardHeader className="bg-transparent">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h6 className="text-uppercase text-muted ls-1 mb-1">
                                                Performance
                                            </h6>
                                            <h5 className="h3 mb-0">Assignment Marks</h5>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart">
                                        <Bar
                                            data={chartExample2.data}
                                            options={chartExample2.options}
                                            className="chart-canvas"
                                            id="chart-bars"
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="4">
                            <Card>
                                <CardHeader>
                                    <h5 className="h3 mb-0">Classmates</h5>
                                </CardHeader>

                                <CardBody>
                                    <ListGroup className="list my--3" flush>
                                        <ListGroupItem className="px-0">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <a
                                                        className="avatar rounded-circle"
                                                        href="#pablo"
                                                        onClick={e => e.preventDefault()}
                                                    >
                                                        <img
                                                            alt="..."
                                                            src={require("assets/img/theme/team-1.jpg")}
                                                        />
                                                    </a>
                                                </Col>
                                                <div className="col ml--2">
                                                    <h4 className="mb-0">
                                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                                            John Michael
                                                        </a>
                                                    </h4>
                                                    <span className="text-success">●</span>{" "}
                                                    <small>Online</small>
                                                </div>
                                                <Col className="col-auto">
                                                    <Button color="primary" size="sm" type="button">
                                                        Chat
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                        <ListGroupItem className="px-0">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <a
                                                        className="avatar rounded-circle"
                                                        href="#pablo"
                                                        onClick={e => e.preventDefault()}
                                                    >
                                                        <img
                                                            alt="..."
                                                            src={require("assets/img/theme/team-2.jpg")}
                                                        />
                                                    </a>
                                                </Col>
                                                <div className="col ml--2">
                                                    <h4 className="mb-0">
                                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                                            Alex Smith
                                                        </a>
                                                    </h4>
                                                    <span className="text-warning">●</span>{" "}
                                                    <small>In a meeting</small>
                                                </div>
                                                <Col className="col-auto">
                                                    <Button color="primary" size="sm" type="button">
                                                        Chat
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                        <ListGroupItem className="px-0">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <a
                                                        className="avatar rounded-circle"
                                                        href="#pablo"
                                                        onClick={e => e.preventDefault()}
                                                    >
                                                        <img
                                                            alt="..."
                                                            src={require("assets/img/theme/team-3.jpg")}
                                                        />
                                                    </a>
                                                </Col>
                                                <div className="col ml--2">
                                                    <h4 className="mb-0">
                                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                                            Samantha Ivy
                                                        </a>
                                                    </h4>
                                                    <span className="text-danger">●</span>{" "}
                                                    <small>Offline</small>
                                                </div>
                                                <Col className="col-auto">
                                                    <Button color="primary" size="sm" type="button">
                                                        Chat
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                        <ListGroupItem className="px-0">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <a
                                                        className="avatar rounded-circle"
                                                        href="#pablo"
                                                        onClick={e => e.preventDefault()}
                                                    >
                                                        <img
                                                            alt="..."
                                                            src={require("assets/img/theme/team-4.jpg")}
                                                        />
                                                    </a>
                                                </Col>
                                                <div className="col ml--2">
                                                    <h4 className="mb-0">
                                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                                            John Michael
                                                        </a>
                                                    </h4>
                                                    <span className="text-success">●</span>{" "}
                                                    <small>Online</small>
                                                </div>
                                                <Col className="col-auto">
                                                    <Button color="primary" size="sm" type="button">
                                                        Chat
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                    </ListGroup>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="4">
                            <Card>
                                <CardHeader>
                                    <h5 className="h3 mb-0">To do list</h5>
                                </CardHeader>

                                <CardBody className="p-0">
                                    <ListGroup data-toggle="checklist" flush>
                                        <ListGroupItem
                                            className="checklist-entry flex-column align-items-start py-4 px-4">
                                            <div
                                                className="checklist-item checklist-item-success checklist-item-checked">
                                                <div className="checklist-info">
                                                    <h5 className="checklist-title mb-0">
                                                        Upload History Assignment
                                                    </h5>
                                                    <small>10:30 AM</small>
                                                </div>
                                                <div>
                                                    <div
                                                        className="custom-control custom-checkbox custom-checkbox-success">
                                                        <input
                                                            className="custom-control-input"
                                                            defaultChecked
                                                            id="chk-todo-task-1"
                                                            type="checkbox"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="chk-todo-task-1"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </ListGroupItem>
                                        <ListGroupItem
                                            className="checklist-entry flex-column align-items-start py-4 px-4">
                                            <div className="checklist-item checklist-item-warning">
                                                <div className="checklist-info">
                                                    <h5 className="checklist-title mb-0">
                                                        Prepare Lab File for Science
                                                    </h5>
                                                    <small>11:30 AM</small>
                                                </div>
                                                <div>
                                                    <div
                                                        className="custom-control custom-checkbox custom-checkbox-warning">
                                                        <input
                                                            className="custom-control-input"
                                                            id="chk-todo-task-2"
                                                            type="checkbox"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="chk-todo-task-2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </ListGroupItem>
                                        <ListGroupItem
                                            className="checklist-entry flex-column align-items-start py-4 px-4">
                                            <div className="checklist-item checklist-item-info">
                                                <div className="checklist-info">
                                                    <h5 className="checklist-title mb-0">
                                                        Practice Paper - English
                                                    </h5>
                                                    <small>12:30 PM</small>
                                                </div>
                                                <div>
                                                    <div
                                                        className="custom-control custom-checkbox custom-checkbox-info">
                                                        <input
                                                            className="custom-control-input"
                                                            id="chk-todo-task-3"
                                                            type="checkbox"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="chk-todo-task-3"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </ListGroupItem>
                                        <ListGroupItem
                                            className="checklist-entry flex-column align-items-start py-4 px-4">
                                            <div
                                                className="checklist-item checklist-item-danger checklist-item-checked">
                                                <div className="checklist-info">
                                                    <h5 className="checklist-title mb-0">
                                                        Meeting with Managing Director
                                                    </h5>
                                                    <small>2:30 PM</small>
                                                </div>
                                                <div>
                                                    <div
                                                        className="custom-control custom-checkbox custom-checkbox-danger">
                                                        <input
                                                            className="custom-control-input"
                                                            defaultChecked
                                                            id="chk-todo-task-4"
                                                            type="checkbox"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="chk-todo-task-4"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </ListGroupItem>
                                    </ListGroup>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="4">
                            <Card>
                                <CardHeader>
                                    <h5 className="h3 mb-0">Syllabus progress track</h5>
                                </CardHeader>

                                <CardBody>
                                    <ListGroup className="list my--3" flush>
                                        <ListGroupItem className="px-0">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <a
                                                        className="avatar rounded-circle"
                                                        href="#pablo"
                                                        onClick={e => e.preventDefault()}
                                                    >
                                                        <img
                                                            alt="..."
                                                            src={require("assets/img/theme/bootstrap.jpg")}
                                                        />
                                                    </a>
                                                </Col>
                                                <div className="col">
                                                    <h5>History</h5>
                                                    <Progress
                                                        className="progress-xs mb-0"
                                                        color="orange"
                                                        max="100"
                                                        value="60"
                                                    />
                                                </div>
                                            </Row>
                                        </ListGroupItem>
                                        <ListGroupItem className="px-0">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <a
                                                        className="avatar rounded-circle"
                                                        href="#pablo"
                                                        onClick={e => e.preventDefault()}
                                                    >
                                                        <img
                                                            alt="..."
                                                            src={require("assets/img/theme/angular.jpg")}
                                                        />
                                                    </a>
                                                </Col>
                                                <div className="col">
                                                    <h5>Science</h5>
                                                    <Progress
                                                        className="progress-xs mb-0"
                                                        color="success"
                                                        max="100"
                                                        value="100"
                                                    />
                                                </div>
                                            </Row>
                                        </ListGroupItem>
                                        <ListGroupItem className="px-0">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <a
                                                        className="avatar rounded-circle"
                                                        href="#pablo"
                                                        onClick={e => e.preventDefault()}
                                                    >
                                                        <img
                                                            alt="..."
                                                            src={require("assets/img/theme/sketch.jpg")}
                                                        />
                                                    </a>
                                                </Col>
                                                <div className="col">
                                                    <h5>English</h5>
                                                    <Progress
                                                        className="progress-xs mb-0"
                                                        color="danger"
                                                        max="100"
                                                        value="72"
                                                    />
                                                </div>
                                            </Row>
                                        </ListGroupItem>
                                        <ListGroupItem className="px-0">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <a
                                                        className="avatar rounded-circle"
                                                        href="#pablo"
                                                        onClick={e => e.preventDefault()}
                                                    >
                                                        <img
                                                            alt="..."
                                                            src={require("assets/img/theme/react.jpg")}
                                                        />
                                                    </a>
                                                </Col>
                                                <div className="col">
                                                    <h5>Mathematics</h5>
                                                    <Progress
                                                        className="progress-xs mb-0"
                                                        color="info"
                                                        max="100"
                                                        value="90"
                                                    />
                                                </div>
                                            </Row>
                                        </ListGroupItem>
                                    </ListGroup>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="5">
                            <Card>
                                <CardHeader>
                                    <h5 className="h3 mb-0">Activity feed</h5>
                                </CardHeader>
                                <CardHeader className="d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <img
                                                alt="..."
                                                className="avatar"
                                                src={require("assets/img/theme/team-1.jpg")}
                                            />
                                        </a>
                                        <div className="mx-3">
                                            <a
                                                className="text-dark font-weight-600 text-sm"
                                                href="#pablo"
                                                onClick={e => e.preventDefault()}
                                            >
                                                Lucifer Morningstar
                                            </a>
                                            <small className="d-block text-muted">3 days ago</small>
                                        </div>
                                    </div>
                                    <div className="text-right ml-auto">
                                        <Button
                                            className="btn-icon"
                                            color="primary"
                                            size="sm"
                                            type="button"
                                        >
                      <span className="btn-inner--icon mr-1">
                        <i className="ni ni-fat-add"/>
                      </span>
                                            <span className="btn-inner--text">Follow</span>
                                        </Button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <p className="mb-4">
                                        Online aptitude series for IIT-JEE Batch 2020 is uploaded. Requesting students to please have a look and go through the demo examination by tomorrow 12:00 PM.
                                        The examination will not be available after the scheduled time.
                                    </p>
                                    <img
                                        alt="..."
                                        className="img-fluid rounded"
                                        src={require("assets/img/theme/img-1-1000x600.jpg")}
                                    />
                                    <Row className="align-items-center my-3 pb-3 border-bottom">
                                        <Col sm="6">
                                            <div className="icon-actions">
                                                <a
                                                    className="like active"
                                                    href="#pablo"
                                                    onClick={e => e.preventDefault()}
                                                >
                                                    <i className="ni ni-like-2"/>
                                                    <span className="text-muted">150</span>
                                                </a>
                                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                                    <i className="ni ni-chat-round"/>
                                                    <span className="text-muted">36</span>
                                                </a>
                                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                                    <i className="ni ni-curved-next"/>
                                                    <span className="text-muted">12</span>
                                                </a>
                                            </div>
                                        </Col>
                                        <Col className="d-none d-sm-block" sm="6">
                                            <div className="d-flex align-items-center justify-content-sm-end">
                                                <div className="avatar-group">
                                                    <a
                                                        className="avatar avatar-xs rounded-circle"
                                                        href="#pablo"
                                                        id="tooltip36177092"
                                                        onClick={e => e.preventDefault()}
                                                    >
                                                        <img
                                                            alt="..."
                                                            src={require("assets/img/theme/team-1.jpg")}
                                                        />
                                                    </a>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target="tooltip36177092"
                                                    >
                                                        Jessica Rowland
                                                    </UncontrolledTooltip>
                                                    <a
                                                        className="avatar avatar-xs rounded-circle"
                                                        href="#pablo"
                                                        id="tooltip857639221"
                                                        onClick={e => e.preventDefault()}
                                                    >
                                                        <img
                                                            alt="..."
                                                            className="rounded-circle"
                                                            src={require("assets/img/theme/team-2.jpg")}
                                                        />
                                                    </a>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target="tooltip857639221"
                                                    >
                                                        Audrey Love
                                                    </UncontrolledTooltip>
                                                    <a
                                                        className="avatar avatar-xs rounded-circle"
                                                        href="#pablo"
                                                        id="tooltip260223080"
                                                        onClick={e => e.preventDefault()}
                                                    >
                                                        <img
                                                            alt="..."
                                                            className="rounded-circle"
                                                            src={require("assets/img/theme/team-3.jpg")}
                                                        />
                                                    </a>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target="tooltip260223080"
                                                    >
                                                        Michael Lewis
                                                    </UncontrolledTooltip>
                                                </div>
                                                <small className="pl-2 font-weight-bold">
                                                    and 30+ more
                                                </small>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="mb-1">
                                        <Media className="media-comment">
                                            <img
                                                alt="..."
                                                className="avatar avatar-lg media-comment-avatar rounded-circle"
                                                src={require("assets/img/theme/team-1.jpg")}
                                            />
                                            <Media>
                                                <div className="media-comment-text">
                                                    <h6 className="h5 mt-0">Michael Lewis</h6>
                                                    <p className="text-sm lh-160">
                                                        Wo-hoo I was waiting for this exam from so long. Thanks for sharing this.
                                                    </p>
                                                    <div className="icon-actions">
                                                        <a
                                                            className="like active"
                                                            href="#pablo"
                                                            onClick={e => e.preventDefault()}
                                                        >
                                                            <i className="ni ni-like-2"/>
                                                            <span className="text-muted">3 likes</span>
                                                        </a>
                                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                                            <i className="ni ni-curved-next"/>
                                                            <span className="text-muted">2 shares</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </Media>
                                        </Media>
                                        <Media className="media-comment">
                                            <img
                                                alt="..."
                                                className="avatar avatar-lg media-comment-avatar rounded-circle"
                                                src={require("assets/img/theme/team-2.jpg")}
                                            />
                                            <Media>
                                                <div className="media-comment-text">
                                                    <h6 className="h5 mt-0">Jessica Stones</h6>
                                                    <p className="text-sm lh-160">
                                                        I was expecting much more time to be given for this examination. Anyways Thanks for uploading it.
                                                    </p>
                                                    <div className="icon-actions">
                                                        <a
                                                            className="like active"
                                                            href="#pablo"
                                                            onClick={e => e.preventDefault()}
                                                        >
                                                            <i className="ni ni-like-2"/>
                                                            <span className="text-muted">10 likes</span>
                                                        </a>
                                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                                            <i className="ni ni-curved-next"/>
                                                            <span className="text-muted">1 share</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </Media>
                                        </Media>
                                        <hr/>
                                        <Media className="align-items-center">
                                            <img
                                                alt="..."
                                                className="avatar avatar-lg rounded-circle mr-4"
                                                src={require("assets/img/theme/team-3.jpg")}
                                            />
                                            <Media body>
                                                <Form>
                                                    <Input
                                                        placeholder="Write your comment"
                                                        rows="1"
                                                        type="textarea"
                                                    />
                                                </Form>
                                            </Media>
                                        </Media>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="7">
                            <div className="card-deck">
                                <Card className="bg-gradient-default">
                                    <CardBody>
                                        <div className="mb-2">
                                            <sup className="text-white">₹</sup>{" "}
                                            <span className="h2 text-white">3,300</span>
                                            <div className="text-light mt-2 text-sm">
                                                Your current balance
                                            </div>
                                            <div>
                                                <span className="text-success font-weight-600">
                                                    + 15%
                                                </span>{" "}
                                                <span className="text-light">(₹250)</span>
                                            </div>
                                        </div>
                                        <Button
                                            block
                                            className="btn-neutral"
                                            color="default"
                                            size="sm"
                                        >
                                            Add credit
                                        </Button>
                                    </CardBody>
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <small className="text-light">Remaining: 60%</small>
                                                <Progress
                                                    className="progress-xs my-2"
                                                    max="100"
                                                    value="60"
                                                    color="success"
                                                />
                                            </div>
                                            <div className="col">
                                                <small className="text-light">Spent: 40%</small>
                                                <Progress
                                                    className="progress-xs my-2"
                                                    max="100"
                                                    value="40"
                                                    color="warning"
                                                />
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card className="bg-gradient-danger">
                                    <CardBody>
                                        <Row className="justify-content-between align-items-center">
                                            <div className="col">
                                                <img
                                                    alt="..."
                                                    src={require("assets/img/icons/cards/visa.png")}
                                                />
                                            </div>
                                            <Col className="col-auto">
                                                <Badge className="badge-lg" color="success">
                                                    Active
                                                </Badge>
                                            </Col>
                                        </Row>
                                        <div className="my-4">
                                            <span className="h6 surtitle text-light">Virtual Card Number</span>
                                            <div className="h1 text-white">7676-5445-8780-2341</div>
                                        </div>
                                        <Row>
                                            <div className="col">
                                                <span className="h6 surtitle text-light">Name</span>
                                                <span className="d-block h3 text-white">Fran J Hancock</span>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                            <Card>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Today's Classes</h3>
                                        </div>
                                        <div className="col text-right">
                                            <Button
                                                color="primary"
                                                href="#pablo"
                                                onClick={e => e.preventDefault()}
                                                size="sm"
                                            >
                                                See all
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Class name</th>
                                        <th scope="col">Scheduling Status</th>
                                        <th scope="col">Time Start</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th scope="row">History</th>
                                        <td>Completed</td>
                                        <td>10:30 PM</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Science</th>
                                        <td>In-Progress</td>
                                        <td>11:45 AM</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Mathematics</th>
                                        <td>Scheduled</td>
                                        <td>1:15 PM</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">English</th>
                                        <td>Scheduled</td>
                                        <td>2:35 PM</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Aptitude</th>
                                        <td>Cancelled</td>
                                        <td>3:30 PM</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default StudentDashBoard;
