const SecurityRole = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_TENANT_ADMIN: "ROLE_TENANT_ADMIN",
  ROLE_SUPER_ADMIN: "ROLE_SUPER_ADMIN",
  ROLE_TEACHER: "ROLE_TEACHER",
  ROLE_PARENT: "ROLE_PARENT",
  ROLE_STUDENT: "ROLE_STUDENT",
};

export default SecurityRole;
