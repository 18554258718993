import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";

const hostName = window.location.hostname;
const protocol = window.location.protocol;
const loadUrl = `${protocol}//${hostName}/api/message/translations/`;

let language = i18next.language || "en";

const backendOptions = {
  type: "backend",
  crossDomain: false,
  allowMultiLoading: false,
  loadPath: loadUrl,
};

const options = {
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  initImmediate: false,
  debug: false,
  lng: language,
  keySeparator: false,
  fallbackLng: language,
  react: {
    wait: true,
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: "default",
    defaultTransParent: "div",
    useSuspense: false,
  },
};

options["backend"] = backendOptions;

i18next.use(initReactI18next).use(XHR).init(options);

export default i18next;
