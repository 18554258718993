// Helper functions
const getUserRoles = () => JSON.parse(localStorage.getItem("CLASHMATE_ROLE_LIST")) || [];
const getUserId = () => localStorage.getItem("userId") || "";

const checkRole = (allowedRoles) => {
    const userRoles = getUserRoles();
    return allowedRoles.some(role => userRoles.includes(role)) ? "" : "none";
};

const checkRoleBoolean = (allowedRoles) => {
    const userRoles = getUserRoles();
    return allowedRoles.some(role => userRoles.includes(role));
};

// Contact Permissions
const ContactPermissions = {
    create: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_LEVEL3"]),
    read: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_LEVEL3"]),
    update: (createdBy) => {
        const userId = getUserId();
        const userRoles = getUserRoles();
        if (["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"].some(role => userRoles.includes(role))) return "";
        return userRoles.includes("ROLE_LEVEL3") && createdBy === userId ? "" : "none";
    },
    delete: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
};

// Project Permissions
const ProjectPermissions = {
    create: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
    read: (sharedWith) => {
        const userId = getUserId();
        const userRoles = getUserRoles();
        if (["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"].some(role => userRoles.includes(role))) return "";
        else if ((userRoles.includes("ROLE_LEVEL3") || userRoles.includes("ROLE_LEVEL2")) && sharedWith.some(person => person.uniqueId === userId)) return "";
        else return "none"
    },
    update: (sharedWith) => {
        const userId = getUserId();
        const userRoles = getUserRoles();
        if (["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"].some(role => userRoles.includes(role))) return "";
        // else if ((userRoles.includes("ROLE_LEVEL3") || userRoles.includes("ROLE_LEVEL2")) && sharedWith.some(person => person.uniqueId === userId)) return "";
        else return "none"
    },
    delete: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
};

// Company Permissions
const CompanyPermissions = {
    create: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
    read: (createdBy) => {
        const userId = getUserId();
        const userRoles = getUserRoles();
        if (["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"].some(role => userRoles.includes(role))) return "";
        return createdBy === userId ? "" : "none";
    },
    update: (createdBy) => {
        const userId = getUserId();
        const userRoles = getUserRoles();
        if (["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"].some(role => userRoles.includes(role))) return "";
        return checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN",]) && createdBy === userId ? "" : "none";
    },
    delete: (createdBy) => {
        const userId = getUserId();
        const userRoles = getUserRoles();
        if (["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"].some(role => userRoles.includes(role))) return "";
        return createdBy === userId ? "" : "none";
    },
};

// Meeting Report Permissions
const MeetingReportPermissions = {
    create: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
    read: (createdBy) => {
        const userId = getUserId();
        if (checkRoleBoolean(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"])) return "";
        else if (checkRoleBoolean(["ROLE_LEVEL3"]) && createdBy === userId) return "";
        else return "none"
    },
    update: (createdBy) => {
        const userId = getUserId();
        if (checkRoleBoolean(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"])) return "";
        else if (checkRoleBoolean(["ROLE_LEVEL3"]) && createdBy === userId) return "";
        else return "none"
    },
    delete: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
};

// Summary Permissions
const SummaryPermissions = {
    create: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_LEVEL3"]),
    read: (sharedWith, createdBy) => {
        const userId = getUserId();
        if (checkRoleBoolean(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"])) return "";
        else if (checkRoleBoolean(["ROLE_LEVEL3"]) && createdBy === userId) return "";
        else if (checkRoleBoolean(["ROLE_LEVEL2"]) && sharedWith.some(person => person.uniqueId === userId)) return "";
        else return "none"
    },
    update: (sharedWith, createdBy) => {
        const userId = getUserId();
        if (checkRoleBoolean(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"])) return "";
        else if (checkRoleBoolean(["ROLE_LEVEL3"]) && createdBy === userId) return "";
        else if (checkRoleBoolean(["ROLE_LEVEL2"]) && sharedWith.some(person => person.uniqueId === userId)) return "";
        else return "none"
    },
    delete: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
};

// Task Permissions
const TaskPermissions = {
    create: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_LEVEL_3"]),
    read: (assignedUser, createdBy) => {
        console.log(assignedUser, createdBy);
        const userId = getUserId();
        if(checkRoleBoolean(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]))
            return ""
        return checkRoleBoolean(["ROLE_LEVEL1", "ROLE_LEVEL2", "ROLE_LEVEL3"]) &&
        (assignedUser === userId || createdBy === userId) ? "" : "none";
    },
    update: (assignedUser, createdBy) => {
        const userId = getUserId();
        if(checkRoleBoolean(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]))
            return ""
        return checkRoleBoolean(["ROLE_LEVEL1", "ROLE_LEVEL2", "ROLE_LEVEL3"]) &&
        (assignedUser === userId || createdBy === userId) ? "" : "none";
    },
    delete: (createdBy) => {
        const userId = getUserId();
        return checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]) || createdBy === userId ? "" : "none";
    },
};

// Project Event Permissions
const ProjectEventPermissions = {
    create: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_LEVEL_3"]),
    read: (createdBy) => {
        const userId = getUserId();
        return checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]) || createdBy === userId ? "" : "none";
    },
    update: (createdBy) => {
        const userId = getUserId();
        return checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]) || createdBy === userId ? "" : "none";
    },
    delete: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
};

// Users Permissions
const UsersPermissions = {
    create: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
    read: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
    update: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
    delete: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
};

// Project Company Permissions
const ProjectCompanyPermissions = {
    create: (sharedWith) => {
        const userId = getUserId();
        const userRoles = getUserRoles();
        if (["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"].some(role => userRoles.includes(role))) return "";
        else if (userRoles.includes("ROLE_LEVEL3") && sharedWith.some(person => person.uniqueId === userId)) return "";
        else return "none"
    },
    read: (sharedWith) => {
        const userId = getUserId();
        const userRoles = getUserRoles();
        if (["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"].some(role => userRoles.includes(role))) return "";
        else if (userRoles.includes("ROLE_LEVEL3") && sharedWith.some(person => person.uniqueId === userId)) return "";
        else return "none"
    },
    update: (sharedWith) => {
        const userId = getUserId();
        const userRoles = getUserRoles();
        if (["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"].some(role => userRoles.includes(role))) return "";
        else if (userRoles.includes("ROLE_LEVEL3") && sharedWith.some(person => person.uniqueId === userId)) return "";
        else return "none"
    },
    updatePriority: (sharedWith) => {
        const userId = getUserId();
        const userRoles = getUserRoles();
        if (userRoles.includes("ROLE_LEVEL2") && sharedWith.some(person => person.uniqueId === userId)) return "";
        else return "none"
    },
    delete: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
};

// Content Permissions
const ContentPermissions = {
    create: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
    read: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_LEVEL_1", "ROLE_LEVEL_2", "ROLE_LEVEL_3"]),
    update: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
    delete: () => checkRole(["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
};

// Exporting all permissions
export {
    ContactPermissions,
    ProjectPermissions,
    CompanyPermissions,
    MeetingReportPermissions,
    SummaryPermissions,
    TaskPermissions,
    ProjectEventPermissions,
    UsersPermissions,
    ProjectCompanyPermissions,
    ContentPermissions
};
