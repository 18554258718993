import _ from "lodash";
import React from "react";
// reactstrap components
import {
  Alert,
  Button,
  CustomInput,
  Modal,
  Table,
  UncontrolledTooltip,
} from "reactstrap";

class ManualQuestionAssignmentModal extends React.Component {
  render() {
    const obj = this;
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.isOpen}
          toggle={this.props.toggleModal}
          size={this.props.size}
          onOpened={this.props.onModalOpened}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="createPermissionModalLabel">
              Question Assignment
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.props.toggleModal}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {_.size(this.props.questionList) > 0 ? (
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" />
                    <th className="sort" data-sort="name" scope="col">
                      #
                    </th>
                    <th className="sort" data-sort="budget" scope="col">
                      Difficulty Level
                    </th>
                    <th className="sort" data-sort="status" scope="col">
                      Question Text
                    </th>
                  </tr>
                </thead>
                <tbody className="list">
                  {this.props.questionList.map(function (value, index) {
                    return (
                      <tr key={index}>
                        <td>
                          <CustomInput
                            type="checkbox"
                            id={value.questionDTO.uniqueId}
                            value={value.questionDTO.uniqueId}
                            htmlFor={value.questionDTO.uniqueId}
                            onChange={obj.props.handleQuestionAssignment}
                            disabled={
                              !_.isEmpty(obj.props.assignedQuestionUIDList) &&
                              _.includes(
                                obj.props.assignedQuestionUIDList,
                                value.questionDTO.uniqueId
                              )
                            }
                          />
                        </td>
                        <td className="budget">{index + 1}</td>
                        <td className="budget">
                          {value.questionDTO.difficultyLevel}
                        </td>
                        <td className="budget" id={`questionText_${index}`}>
                          {value.questionDTO.abbreviatedQuestionText}
                        </td>
                        <UncontrolledTooltip
                          delay={0}
                          placement="bottom"
                          target={`questionText_${index}`}
                        >
                          {value.questionDTO.questionText}
                        </UncontrolledTooltip>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <Alert color="info">
                <strong>Info!</strong> No questions available in the library.
              </Alert>
            )}
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={this.props.handleQuestionAssignmentSubmit}
            >
              Save changes
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default ManualQuestionAssignmentModal;
