/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
// reactstrap components
import {
	Col,
	Collapse,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Form,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Media,
	Nav,
	Navbar,
	NavItem,
	NavLink,
	Row,
	UncontrolledDropdown,
} from "reactstrap";
import NotificationListBell from "../common/notification/NotificationListBell";
import {Link} from "react-router-dom";
import {
	CompanyPermissions,
	ContactPermissions,
	ProjectPermissions,
	SummaryPermissions,
	UsersPermissions
} from "../../services/permissions";

const mapStateToProps = (state) => {
	let authState = state.auth;
	return { fullName: authState.userInfo.fullName };
};

class AdminNavbar extends React.Component {
	constructor(props) {
		super(props);
		this.logout = this.logout.bind(this);
	}

	// function that on mobile devices makes the search open
	openSearch = () => {
		document.body.classList.add("g-navbar-search-showing");
		setTimeout(function () {
			document.body.classList.remove("g-navbar-search-showing");
			document.body.classList.add("g-navbar-search-show");
		}, 150);
		setTimeout(function () {
			document.body.classList.add("g-navbar-search-shown");
		}, 300);
	};
	// function that on mobile devices makes the search close
	closeSearch = () => {
		document.body.classList.remove("g-navbar-search-shown");
		setTimeout(function () {
			document.body.classList.remove("g-navbar-search-show");
			document.body.classList.add("g-navbar-search-hiding");
		}, 150);
		setTimeout(function () {
			document.body.classList.remove("g-navbar-search-hiding");
			document.body.classList.add("g-navbar-search-hidden");
		}, 300);
		setTimeout(function () {
			document.body.classList.remove("g-navbar-search-hidden");
		}, 500);
	};

	logout(event) {
		event.preventDefault();
		localStorage.clear();
		sessionStorage.clear();
		this.props.history.push("/");
	}

	render() {
		return (
			<>
				<Navbar
					className={classnames(
						"navbar-top navbar-expand border-bottom",
						{ "navbar-dark bg-light": this.props.theme === "dark" },
						{ "navbar-light bg-secondary": this.props.theme === "light" }
					)}
				>
					<Container fluid>
						<Collapse navbar isOpen={true}>
							<Form
								className={classnames(
									"navbar-search form-inline mr-sm-3",
									{ "navbar-search-light": this.props.theme === "dark" },
									{ "navbar-search-dark": this.props.theme === "light" }
								)}
							>
								<FormGroup className="mb-0">
									<InputGroup className="input-group-alternative input-group-merge">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="fas fa-search" />
											</InputGroupText>
										</InputGroupAddon>
										<Input placeholder="Search" type="text" />
									</InputGroup>
								</FormGroup>
								<button
									aria-label="Close"
									className="close"
									type="button"
									onClick={this.closeSearch}
								>
									<span aria-hidden={true}>×</span>
								</button>
							</Form>

							<Nav className="align-items-center ml-md-auto" navbar>
								<NavItem className="d-xl-none">
									<div
										className={classnames(
											"pr-3 sidenav-toggler",
											{ active: this.props.sidenavOpen },
											{ "sidenav-toggler-dark": this.props.theme === "dark" }
										)}
										onClick={this.props.toggleSidenav}
									>
										<div className="sidenav-toggler-inner">
											<i className="sidenav-toggler-line" />
											<i className="sidenav-toggler-line" />
											<i className="sidenav-toggler-line" />
										</div>
									</div>
								</NavItem>
								<NavItem className="d-sm-none">
									<NavLink onClick={this.openSearch}>
										<i className="ni ni-zoom-split-in" />
									</NavLink>
								</NavItem>
								<Link classsName={"xgnav-link"} style={{color: "white", display: SummaryPermissions.create() }} to="/admin/summary">My Summaries </Link>&nbsp;&nbsp;
								<Link style={{color: "white", display: UsersPermissions.create()}} to="/admin/create">New User </Link>&nbsp;&nbsp;
								<Link style={{color: "white", display: CompanyPermissions.create()}} to="/admin/company/create">New Company </Link>&nbsp;&nbsp;
								<Link style={{color: "white", display: ContactPermissions.create()}} to="/admin/contact/create">New Contact </Link>&nbsp;&nbsp;
								<Link style={{color: "white", display: ProjectPermissions.create()}} to="/admin/project/create">New Project </Link>&nbsp;&nbsp;
								{/*<NotificationListBell />*/}
								{/*<UncontrolledDropdown nav>
									<DropdownToggle className="nav-link" color="" tag="a">
										<i className="ni ni-ungroup" />
									</DropdownToggle>
									<DropdownMenu
										className="dropdown-menu-lg dropdown-menu-dark bg-default"
										right
									>
										<Row className="shortcuts px-4">
											<Col
												className="shortcut-item"
												href="#pablo"
												onClick={(e) => e.preventDefault()}
												xs="4"
												tag="a"
											>
												<span className="shortcut-media avatar rounded-circle bg-gradient-red">
													<i className="ni ni-calendar-grid-58" />
												</span>
												<small>Calendar</small>
											</Col>
											<Col
												className="shortcut-item"
												href="#pablo"
												onClick={(e) => e.preventDefault()}
												xs="4"
												tag="a"
											>
												<span className="shortcut-media avatar rounded-circle bg-gradient-orange">
													<i className="ni ni-email-83" />
												</span>
												<small>Email</small>
											</Col>
											<Col
												className="shortcut-item"
												href="#pablo"
												onClick={(e) => e.preventDefault()}
												xs="4"
												tag="a"
											>
												<span className="shortcut-media avatar rounded-circle bg-gradient-info">
													<i className="ni ni-credit-card" />
												</span>
												<small>Payments</small>
											</Col>
											<Col
												className="shortcut-item"
												href="#pablo"
												onClick={(e) => e.preventDefault()}
												xs="4"
												tag="a"
											>
												<span className="shortcut-media avatar rounded-circle bg-gradient-green">
													<i className="ni ni-books" />
												</span>
												<small>Reports</small>
											</Col>
											<Col
												className="shortcut-item"
												href="#pablo"
												onClick={(e) => e.preventDefault()}
												xs="4"
												tag="a"
											>
												<span className="shortcut-media avatar rounded-circle bg-gradient-purple">
													<i className="ni ni-pin-3" />
												</span>
												<small>Maps</small>
											</Col>
											<Col
												className="shortcut-item"
												href="#pablo"
												onClick={(e) => e.preventDefault()}
												xs="4"
												tag="a"
											>
												<span className="shortcut-media avatar rounded-circle bg-gradient-yellow">
													<i className="ni ni-basket" />
												</span>
												<small>Shop</small>
											</Col>
										</Row>
									</DropdownMenu>
								</UncontrolledDropdown>*/}
							</Nav>
							<Nav className="align-items-center ml-auto ml-md-0" navbar>
								<UncontrolledDropdown nav>
									<DropdownToggle className="nav-link pr-0" color="" tag="a">
										<Media className="align-items-center">
											<span className="avatar avatar-sm rounded-circle">
												<img alt="..." src={require("assets/img/theme/team-4.jpg")} />
											</span>
											<Media className="ml-2 d-none d-lg-block">
												<span className="mb-0 text-sm font-weight-bold">
													{this.props.fullName}
												</span>
											</Media>
										</Media>
									</DropdownToggle>
									<DropdownMenu right>
										<DropdownItem className="noti-title" header tag="div">
											<h6 className="text-overflow m-0">Welcome!</h6>
										</DropdownItem>
										{/*<DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
											<i className="ni ni-single-02" />
											<span>My profile</span>
										</DropdownItem>*/}
										<DropdownItem
											href="#pablo"
											onClick={(e) => this.props.history.push("/admin/settings")}
										>
											<i className="ni ni-settings-gear-65" />
											<span>Settings</span>
										</DropdownItem>
										{/*<DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
											<i className="ni ni-calendar-grid-58" />
											<span>Activity</span>
										</DropdownItem>
										<DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
											<i className="ni ni-support-16" />
											<span>Support</span>
										</DropdownItem>*/}
										<DropdownItem divider />
										<DropdownItem href="#pablo" onClick={this.logout}>
											<i className="ni ni-user-run" />
											<span>Logout</span>
										</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
							</Nav>
						</Collapse>
					</Container>
				</Navbar>
			</>
		);
	}
}

AdminNavbar.defaultProps = {
	toggleSidenav: () => {},
	sidenavOpen: false,
	theme: "dark",
};
AdminNavbar.propTypes = {
	toggleSidenav: PropTypes.func,
	sidenavOpen: PropTypes.bool,
	theme: PropTypes.oneOf(["dark", "light"]),
};

export default connect(mapStateToProps)(AdminNavbar);
