/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import Moment from "react-moment";
import moment from "moment";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Row,
} from "reactstrap";
import AuthService from "../../../../services/authService";
import MeetingService from "../../../../services/meetingService";
import UserListTable from "../../user/List";

class MeetingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meetingData: {
        userDTOList: [],
      },
    };
  }

  componentDidMount() {
    const { uniqueId } = this.props.match.params;
    MeetingService.fetch(uniqueId).then((response) => {
      console.log(response.data);
      this.setState({ meetingData: response.data });
    });
  }

  render() {
    const { meetingData } = this.state;
    return (
      <>
        <SimpleHeader
          name="Details"
          parentName="Meeting"
          heading="ClashMate"
          newBtnUrl={`/${AuthService.getBaseURLByRole()}/meeting/list`}
          newBtnText="Meeting List"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col md="12">
              <Card className="mb-4">
                <CardHeader>
                  <h3 className="mb-0">{meetingData.title}</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label className="form-control-label">Meeting ID</label>
                        <p>{meetingData.meetingID}</p>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="form-control-label">
                          Start Date Time
                        </label>
                        <p>{meetingData.startDateTime}</p>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="form-control-label">
                          End Date Time
                        </label>
                        <p>{meetingData.endDateTime}</p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label className="form-control-label">
                          Class - Section - Subject
                        </label>
                        <p>{meetingData.courseSectionSubjectTitle}</p>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="form-control-label">
                          Total Meeting Time
                        </label>
                        <p>
                          <Moment
                            diff={moment().subtract(
                              meetingData.totalMeetingTime,
                              "milliseconds"
                            )}
                            unit="minutes"
                          >
                            {moment()}
                          </Moment>
                          &nbsp;minutes
                        </p>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="form-control-label">
                          Participant Count
                        </label>
                        <p>{meetingData.userDTOList.length}</p>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <UserListTable
                cardTitle="Participant List"
                userList={meetingData.userDTOList}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default MeetingDetails;
