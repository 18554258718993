/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row,} from "reactstrap";
import ContentService from "../../../services/xgContentService";
import HtmlContent from "../util/HtmlContent";

class ContentDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentData: {},
        };
    }

    componentDidMount() {
        const {uniqueId} = this.props.match.params;
        ContentService.fetch(uniqueId).then((response) => {
            console.log(response.data);
            this.setState({contentData: response.data});
        });
    }

    render() {
        const {contentData} = this.state;
        return (
            <>
                <SimpleHeader
                    name="Details"
                    parentName="Content"
                    heading="XareGrowth"
                    newBtnUrl="/admin/content/create"
                    newBtnText="Create Content"
                />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <span className="h3 mb-0">{contentData.title}</span>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            Title
                                        </label>
                                        <p>{contentData.title}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example4cols3Input"
                                        >
                                            Article Types
                                        </label>
                                        <br/>
                                        {contentData.articleTypes && contentData.articleTypes.map(function (type, index) {
                                            return <h4 key={index} className="badge badge-lg badge-primary">{type}</h4>
                                        })}
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols3Input"
                                        >
                                            Content Type
                                        </label>
                                        <p>{contentData.contentType}</p>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example4cols1Input"
                                        >
                                            Article Category
                                        </label>
                                        <p>{contentData.category}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example4cols2Input"
                                        >
                                            Date Created
                                        </label>
                                        <p>{contentData.dateCreated}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <Button color="default" className="mb-3 sm" outline
                                            onClick={(event) => {
                                                event.preventDefault();
                                                this.props.history.push(`/blog/${contentData.uniqueId}`);
                                            }}
                                    >
                                        View Public Content
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols2Input"
                                        >
                                            Content
                                        </label>
                                        <p>
                                            <HtmlContent htmlString={contentData.content}/>
                                        </p>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
                <br/>
                <br/>
                <br/>
                <br/>
                {/*<MappingStudentList*/}
                {/*  uniqueId={this.props.match.params.uniqueId}*/}
                {/*  history={this.props.history}*/}
                {/*/>*/}
            </>
        );
    }
}

export default ContentDetails;
