import React from "react";
import { Col, Row } from "reactstrap";
import FormInput from "../../../components/common/form/FormInput";
import FormInputSelect from "../../../components/common/form/FormInputSelect";
import { EXAMINATION_TYPE_LIST_MAP } from "../../../util/ExaminationConstant";

class ExaminationForm extends React.Component {
  showField() {
    return this.props.isEdit === true ? "hidden" : "";
  }

  render() {
    return (
      <>
        <Row>
          <Col md="10">
            <FormInput
              name="title"
              className="form-control-label"
              id="title"
              placeholder="inputPlaceHolder.examination.title"
              type="text"
              onChange={this.props.onChange}
              label="inputLabel.examination.title"
              value={this.props.valueObj.title || ""}
              errorMsg={this.props.errorObj.title || ""}
            />
          </Col>
          <Col md="2">
            <FormInput
              name="duration"
              className="form-control-label"
              id="duration"
              placeholder="inputPlaceHolder.examination.duration"
              type="number"
              onChange={this.props.onChange}
              label="inputLabel.examination.duration"
              value={this.props.valueObj.duration || ""}
              errorMsg={this.props.errorObj.duration || ""}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormInputSelect
              name="courseSectionSubjectUID"
              className="form-control-label"
              id="courseSectionSubjectUID"
              onChange={this.props.onChange}
              label="inputLabel.examination.courseSectionSubjectUID"
              optionList={this.props.valueObj.subjectList}
              optionKey="uniqueId"
              optionValue="title"
            />
          </Col>
          <Col md="6">
            <FormInputSelect
              name="examinationType"
              className="form-control-label"
              id="examinationType"
              onChange={this.props.onChange}
              label="inputLabel.examination.examinationType"
              optionList={EXAMINATION_TYPE_LIST_MAP}
              optionKey="key"
              optionValue="value"
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Row>
              <Col md="8">
                <FormInput
                  name="startDate"
                  className="form-control-label"
                  id="startDate"
                  placeholder="inputPlaceHolder.examination.startDate"
                  type="date"
                  onChange={this.props.onChange}
                  label="inputLabel.examination.startDate"
                  value={this.props.valueObj.startDate || ""}
                  errorMsg={this.props.errorObj.startDate || ""}
                />
              </Col>
              <Col md="4">
                <FormInput
                  name="startTime"
                  className="form-control-label"
                  id="startTime"
                  placeholder="inputPlaceHolder.examination.startTime"
                  type="time"
                  onChange={this.props.onChange}
                  label="inputLabel.examination.startTime"
                  value={this.props.valueObj.startTime || ""}
                  errorMsg={this.props.errorObj.startTime || ""}
                />
              </Col>
            </Row>
          </Col>
          <Col md="6">
            <Row>
              <Col md="8">
                <FormInput
                  name="endDate"
                  className="form-control-label"
                  id="endDate"
                  placeholder="inputPlaceHolder.examination.endDate"
                  type="date"
                  onChange={this.props.onChange}
                  label="inputLabel.examination.endDate"
                  value={this.props.valueObj.endDate || ""}
                  errorMsg={this.props.errorObj.endDate || ""}
                />
              </Col>
              <Col md="4">
                <FormInput
                  name="endTime"
                  className="form-control-label"
                  id="endTime"
                  placeholder="inputPlaceHolder.examination.endTime"
                  type="time"
                  onChange={this.props.onChange}
                  label="inputLabel.examination.endTime"
                  value={this.props.valueObj.endTime || ""}
                  errorMsg={this.props.errorObj.endTime || ""}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default ExaminationForm;
