/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Form
} from "reactstrap";
import AdminService from "../../../services/adminService";
import AdminForm from "./Form";
import TenantService from "../../../services/tenantService";
import FormInput from "../../../components/common/form/FormInput";
import NotificationAlert from "react-notification-alert";

class AdminEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorData: {},
            file:undefined
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateBrandLogo = this.updateBrandLogo.bind(this);
    }

    componentDidMount() {
        const {uniqueId} = this.props.match.params;
        AdminService.fetch(uniqueId).then(response => {
            console.log(response.data);
            this.setState(response.data);
            this.setState({uniqueId: uniqueId});
        });
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }

    handleSubmit(event) {
        event.preventDefault();
        AdminService.update(this.state).then(response => {
            console.log(response.data);
            if (response.responseStatus === "SUCCESS") {
                this.props.history.push("/admin/list");
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    }

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };


    updateBrandLogo(event){
        event.preventDefault();
        const data = new FormData()
        data.append('file', this.state.file)
        data.append('uniqueId', this.props.match.params.uniqueId);
        console.log(data);
        AdminService.uploadLogo(data).then((response) => {
            console.log(response);
            if (response.responseStatus === "SUCCESS") {
                this.notify("success", "Upload Successful", "Brand Logo uploaded successfully!")
            } else {
                this.notify("danger", "Upload Unuccessful", "Brand Logo not uploaded successfully!")
                console.error("Error    ==>     " + response.message);
            }
        });
    }

    fileChange = (event) => {
        this.setState({file: event.target.files[0]})
        console.log(this.state)
    };


    render() {
        return (
            <>
                <SimpleHeader
                    name="Edit"
                    parentName="User"
                    heading="ClashMate"
                    newBtnUrl="/admin/create"
                    newBtnText="Create User"
                />
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert" />
                </div>
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">Edit User</h3>
                        </CardHeader>
                        <CardBody>
                            <Form role="form" onSubmit={this.handleSubmit}>
                                <AdminForm
                                    onChange={this.handleChange}
                                    valueObj={this.state}
                                    errorObj={this.state.errorData}
                                    isEdit={true}
                                />
                                <div className="text-center">
                                    <Button className="my-4" color="default"  outline type="submit">
                                        Update
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">Upload Brand Logo</h3>
                        </CardHeader>
                        <CardBody>
                            <Form role="form" onSubmit={this.updateBrandLogo}>
                                <FormInput
                                    name="file"
                                    className="form-control-label custom-file-input"
                                    id="file"
                                    placeholder="inputPlaceHolder.notice.files"
                                    type="file"
                                    onChange={this.fileChange}
                                    label="Browse"
                                    multiple={false}
                                    key={this.state.fileSelectUUID}
                                    isEdit
                                    // value={this.props.valueObj.firstName || ""}
                                    // errorMsg={this.props.errorObj.firstName || ""}
                                />
                                <span
                                    className="badge badge-pill badge-primary">{this.state?.file?.name}</span>
                                <div className="text-center">
                                    <Button className="my-4" color="default" outline type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}

export default AdminEdit;
