/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import AdminService from "../../../services/adminService";
import PermissionListTable from "../permission/ListTable";
import AdminDetailsDummy from "./DetailsDummy";
import UserPermissionService from "../../../services/userPermissionService";

class AdminDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adminUID: "",
      adminData: {},
      userPermissionDTOList: {},
    };
    this.loadUserPermissionList = this.loadUserPermissionList.bind(this);
  }

  componentDidMount() {
    const { uniqueId } = this.props.match.params;
    this.setState({ adminUID: uniqueId });
    AdminService.fetch(uniqueId).then((response) => {
      console.log(response.data);
      this.setState({ adminData: response.data });
      this.loadUserPermissionList();
    });
  }

  loadUserPermissionList() {
    UserPermissionService.listByUser(this.state.adminUID).then((response) => {
      console.log(response.data);
      this.setState({ userPermissionDTOList: response.data });
    });
  }

  render() {
    const { adminData, userPermissionDTOList } = this.state;
    let obj = this;
    return (
      <>
        <SimpleHeader
          name="Details"
          parentName="User"
          heading="ClashMate"
          newBtnUrl="/admin/create"
          newBtnText="Create User"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card className="mb-4">
                <CardHeader>
                  <h3 className="mb-0">{adminData.fullName}</h3>
                </CardHeader>
                <CardBody>
                  <AdminDetailsDummy adminData={adminData} />
                </CardBody>
              </Card>
            </Col>
            <Col xl="12">
              <PermissionListTable
                userPermissionList={userPermissionDTOList}
                adminComponentObj={obj}
                cardTitle="Permission List"
                adminUID={obj.state.adminUID}
                onFormSubmitSuccess={this.loadUserPermissionList}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AdminDetails;
