/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Form
} from "reactstrap";
import CompanyService from "../../../services/companyService";
import ParentForm from "./Form";
import CompanyForm from "./Form";
import _ from "lodash"
import {countries} from "../../../util/countries";
import {companyType} from "../../../util/companyType";
import UtilService from "../../../services/utilService";

class CompanyEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorData: {},
            selectedCountry : {},
            selectedCompanyTypes : [],
            selectCountryKey : UtilService.uuidv4(),
            selectCompanyTypeKey :UtilService.uuidv4()
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCompanyTypeChange = this.handleCompanyTypeChange.bind(this);
        this.handleCountrySelectChange = this.handleCountrySelectChange.bind(this);
    }

    componentDidMount() {
        const {uniqueId} = this.props.match.params;
        CompanyService.fetch(uniqueId).then(response => {
            console.log(response.data);
            this.setState(response.data);
            this.setState({uniqueId: uniqueId});
            let selectedCountry = _.find(countries, { key: response.data.country });
            const filteredCompanyTypes = _.filter(companyType, (company) => _.includes(response.data.companyTypeList, company.key));
            this.setState({selectedCompanyTypes:filteredCompanyTypes,selectedCountry: selectedCountry, selectCountryKey : UtilService.uuidv4(), selectCompanyTypeKey: UtilService.uuidv4()});
            console.log(this.state)
        });
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }

    handleSubmit(event) {
        event.preventDefault();
        CompanyService.update(this.state).then(response => {
            console.log(response.data);
            if (response.responseStatus === "SUCCESS") {
                this.props.history.push("/admin/company/list");
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    }

    handleCompanyTypeChange = (valueObj, event) => {
        if (_.isNull(valueObj)) {
            this.setState({companyTypeList: []});
        } else {
            this.setState({companyTypeList: valueObj});
            const keys = _.map(valueObj, "key")
            this.setState({companyTypeList: keys});
        }
    };

    handleCountrySelectChange(valueObj) {
        if (_.isNull(valueObj)) {
            this.setState({selectedCountry: {}, country: ""});
        } else {
            this.setState({selectedCountry: valueObj, country: valueObj.key});
        }
    }

    render() {
        return (
            <>
                <SimpleHeader
                    name="Edit"
                    parentName="Company"
                    heading="XareGrowth"
                    newBtnUrl="/admin/company/create"
                    newBtnText="Create Company"
                />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">Edit Company</h3>
                        </CardHeader>
                        <CardBody>
                            <Form role="form" onSubmit={this.handleSubmit}>
                                <CompanyForm
                                    selectCompanyTypeKey={this.state.selectCompanyTypeKey}
                                    selectCountryKey={this.state.selectCountryKey}
                                    onChange={this.handleChange}
                                    valueObj={this.state}
                                    errorObj={this.state.errorData}
                                    isEdit={true}
                                    selectedCountry = {this.state.selectedCountry}
                                    selectedCompanyTypes = {this.state.selectedCompanyTypes}
                                    companyType = {companyType}
                                    onCompanyTypeChange={this.handleCompanyTypeChange}
                                    handleCountrySelectChange={this.handleCountrySelectChange}
                                />
                                <div className="text-center">
                                    <Button className="my-4" color="default" outline type="submit">
                                        Update
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}

export default CompanyEdit;
