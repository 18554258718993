/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/pages/dashboards/StudentDashboard.jsx";
import StudentOnlineExaminationList from "../views/pages/onlineExamnation/student/List";
import StudentTimeTable from "../views/pages/timetable/student/StudentTimeTable";
import AttendanceStudentList from "../views/pages/attendance/AttendanceStudentList";
import LedgerListStudent from "../views/pages/finance/student/LedgerListStudent";
import LedgerDetailsStudent from "../views/pages/finance/student/LedgerDetailsStudent";
import StudentProfile from "../views/pages/profile/student/StudentProfile";
import StudentSettings from "../views/pages/settings/StudentSettings";
import StudentExaminationInstructionPage
    from "../views/pages/onlineExamnation/student/StudentExaminationInstructionPage";
import StudentOnlineExaminationPage from "../views/pages/onlineExamnation/student/StudentOnlineExamination";
import LessonPlanList from "../views/pages/lessonPlan/List";
import LessonPlanDetail from "../views/pages/lessonPlan/Details";
import StudentExaminationThankYouPage from "../views/pages/onlineExamnation/student/StudentExaminationThankYouPage";
import StudentExaminationResult from "../views/pages/onlineExamnation/student/StudentExaminationResult";
import MeetingList from "../views/pages/communication/video/List";
import MeetingDetails from "../views/pages/communication/video/Details";
import MeetingStart from "../views/pages/communication/video/Start";
import Feeds from "../views/pages/social/post/Feeds";
import PostDetails from "../views/pages/social/post/PostDetails";
import PostEdit from "../views/pages/social/post/Edit";
import CreatePost from "../views/pages/social/post/CreatePost";
import Notices from "../views/pages/social/noticeboard/Notices";
import NoticeDetails from "../views/pages/social/noticeboard/NoticeDetails";
import UserNotificationQueueList from "../views/pages/notification/queue/user/List";
import UserNotificationQueueDetails from "../views/pages/notification/queue/user/Details";
import ChatPage from "../views/pages/chat";
import MarketPlace from "../views/pages/marketplace/student/MarketPlace";
import MarketPlaceCourse from "../views/pages/marketplace/student/MarketPlaceCourse";

const studentRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "ni ni-shop text-primary",
        component: Dashboard,
        layout: "/student",
    },
    {
        path: "/examination/instructionPage/:examinationUID/:uniqueId",
        name: "Online Examination",
        icon: "ni ni-shop text-primary",
        component: StudentExaminationInstructionPage,
        layout: "/student",
        invisible: true,
    },
    {
        path: "/onlineExamination/start/:uniqueId",
        name: "Online Examination",
        icon: "ni ni-shop text-primary",
        component: StudentOnlineExaminationPage,
        layout: "/student",
        invisible: true,
    },
    {
        path: "/onlineExamination/finish/:uniqueId",
        name: "Online Examination",
        icon: "ni ni-shop text-primary",
        component: StudentExaminationThankYouPage,
        layout: "/student",
        invisible: true,
    },
    {
        path: "/timeTable",
        name: "Time Table",
        icon: "ni ni-shop text-primary",
        component: StudentTimeTable,
        layout: "/student",
    },
    {
        path: "/onlineExamination/result/:onlineExaminationUID",
        name: "Online Examination Result",
        icon: "ni ni-shop text-primary",
        component: StudentExaminationResult,
        layout: "/student",
        invisible: true,
    },
    {
        path: "/attendance",
        name: "Attendance",
        icon: "ni ni-shop text-primary",
        component: AttendanceStudentList,
        layout: "/student",
        invisible: false,
    },
    {
        path: "/ledger/list",
        name: "Ledger",
        icon: "ni ni-shop text-primary",
        component: LedgerListStudent,
        layout: "/student",
        invisible: false,
    },
    {
        path: "/ledger/show/:uniqueId",
        name: "Ledger",
        icon: "ni ni-shop text-primary",
        component: LedgerDetailsStudent,
        layout: "/student",
        invisible: true,
    },
    {
        path: "/profile",
        name: "Profile",
        icon: "ni ni-shop text-primary",
        component: StudentProfile,
        layout: "/student",
        invisible: true,
    },
    {
        path: "/settings",
        name: "Profile",
        icon: "ni ni-shop text-primary",
        component: StudentSettings,
        layout: "/student",
        invisible: true,
    },
    {
        path: "/onlineExamination/list",
        name: "Online Examination",
        icon: "ni ni-shop text-primary",
        component: StudentOnlineExaminationList,
        layout: "/student",
    },
    {
        path: "/lessonPlan/list",
        name: "Lesson Plan",
        icon: "ni ni-shop text-primary",
        component: LessonPlanList,
        layout: "/student",
    },
    {
        path: "/lessonPlan/show/:uniqueId",
        name: "Lesson Plan",
        icon: "ni ni-shop text-primary",
        component: LessonPlanDetail,
        layout: "/student",
        invisible: true,
    },
    {
        path: "/meeting/list",
        name: "Meeting",
        icon: "ni ni-shop text-primary",
        component: MeetingList,
        layout: "/student",
    },
    {
        path: "/meeting/show/:uniqueId",
        name: "Meeting",
        icon: "ni ni-shop text-primary",
        component: MeetingDetails,
        layout: "/student",
        invisible: true,
    },
    {
        path: "/meeting/start/:uniqueId",
        name: "Meeting",
        icon: "ni ni-shop text-primary",
        component: MeetingStart,
        layout: "/student",
        invisible: true,
    },
    {
        path: "/post/feeds",
        name: "Feeds",
        icon: "ni ni-shop text-primary",
        component: Feeds,
        layout: "/student",
        invisible: false,
    },
    {
        path: "/post/create",
        name: "Social",
        icon: "ni ni-shop text-primary",
        component: CreatePost,
        layout: "/student",
        invisible: true,
    },
    {
        path: "/post/show/:uniqueId",
        name: "View Post",
        icon: "ni ni-shop text-primary",
        component: PostDetails,
        layout: "/student",
        invisible: true,
    },
    {
        path: "/post/edit/:uniqueId",
        name: "EditPost",
        icon: "ni ni-shop text-primary",
        component: PostEdit,
        layout: "/student",
        invisible: true,
    },
    {
        path: "/notice/",
        name: "Notices",
        icon: "ni ni-shop text-primary",
        component: Notices,
        layout: "/student",
        invisible: false,
    },
    {
        path: "/notices/show/:uniqueId",
        name: "View notice",
        icon: "ni ni-shop text-primary",
        component: NoticeDetails,
        layout: "/student",
        invisible: true,
    },
    {
        path: "/user/notificationQueue/list",
        name: "Notification Queue",
        icon: "ni ni-shop text-primary",
        component: UserNotificationQueueList,
        layout: "/student",
    },
    {
        path: "/user/notificationQueue/show/:uniqueId",
        name: "Notification Queue",
        icon: "ni ni-shop text-primary",
        component: UserNotificationQueueDetails,
        layout: "/student",
        invisible: true,
    },
    {
        path: "/chat/list",
        name: "Chat Page",
        icon: "ni ni-shop text-primary",
        component: ChatPage,
        layout: "/student",
        invisible: false,
    },
    {
        path: "/marketplace/",
        name: "Market Place",
        icon: "ni ni-shop text-primary",
        component: MarketPlace,
        layout: "/student",
        invisible: false,
    },
    {
        path: "/market/course/:uniqueId",
        name: "Market Place Course Details",
        icon: "ni ni-shop text-primary",
        component: MarketPlaceCourse,
        layout: "/student",
        invisible: true,
    },
];

export default studentRoutes;
