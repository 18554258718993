/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";

class StudentExaminationThankYouPage extends React.Component {

  render() {
    return (
      <>
        <SimpleHeader
          name="Thank You"
          parentName="Examination"
          heading="ClashMate"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Examination</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="text-center">
                    <i className="ni ni-spaceship ni-5x"></i>
                  </div>
                  <p className="lead text-center">
                    Thank You for attempting the examination. You can see the
                    results once it's published by the teacher on Examination
                    list page. You can click on the below button to go back to
                    Examination list page.
                  </p>
                  <div className="text-center">
                    <Button
                      color="primary"
                      data-dismiss="modal"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(
                          `/student/onlineExamination/list`
                        );
                      }}
                    >
                      Examination List Page
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default StudentExaminationThankYouPage;
