/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Button, Card, CardBody, CardHeader, Container, Form,} from "reactstrap";
import StudyMaterialService from "../../../../services/studyMaterialService";
import AppRedirect from "../../../../util/appRedirect";
import NotificationAlert from "react-notification-alert/index";
import AuthService from "../../../../services/authService";
import ContentService from "../../../../services/contentService";
import Row from "reactstrap/es/Row";
import FormInput from "../../../../components/common/form/FormInput";
import Col from "reactstrap/es/Col";
import FormReactSelect from "../../../../components/common/form/FormReactSelect";
import _ from "lodash";
import UtilService from "../../../../services/utilService";


class ContentCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorData: {},
            studyMaterial: {},
            contentList: [],
            selectedContent: {},
            contentSelectUUID: UtilService.uuidv4(),
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    };

    handleContentChange = (value) => {
        if (_.isNull(value)) {
            this.setState({selectedContent: {}});
        } else {
            this.setState({selectedContent: value});
        }
    };

    fetchStudyMaterial = async () => {
        let self = this;
        const {uniqueId} = this.props.match.params;
        await StudyMaterialService.fetch(uniqueId).then((response) => {
            console.log(response.data);
            self.setState({studyMaterial: response.data});
        })
    };

    fetchContentList = async () => {
        let self = this;
        let studyMaterialUID = this.props.match.params.uniqueId;
        await ContentService.list(studyMaterialUID).then((response) => {
            let contentList = _.filter(response.data, content => content.parentUID === null);
            console.log(response.data);
            self.setState({contentList: contentList});
            self.setState({contentSelectUUID: UtilService.uuidv4()});
        })
    };

    handleSubmit(event) {
        event.preventDefault();
        let self = this;
        let studyMaterialUID = this.state.studyMaterial.uniqueId;
        let data = {
            studyMaterialUID: studyMaterialUID,
            title: this.state.title,
            description: this.state.description,
            order: this.state.order,
            parentUID: this.state.selectedContent.uniqueId
        };
        ContentService.create(data).then((response) => {
            console.log(response.data);
            if (response.responseStatus === "SUCCESS") {
                self.notify(
                    response.responseStatus.toLowerCase(),
                    response.responseStatus,
                    response.message
                );
                AppRedirect.redirectWithWait(self, `/${AuthService.getBaseURLByRole()}/content/show/${response.data.uniqueId}`, 3000);
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    };


    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    componentDidMount() {
        this.fetchStudyMaterial();
        this.fetchContentList();
    }


    render() {
        return (
            <>
                <SimpleHeader
                    name="Create"
                    parentName="Content"
                    heading="ClashMate"
                    newBtnUrl={`/${AuthService.getBaseURLByRole()}/studyMaterial/show/${this.state.studyMaterial.uniqueId}`}
                    newBtnText="Back to Study Material"
                />
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">Create Content</h3>
                        </CardHeader>
                        <CardBody>
                            <Form role="form" onSubmit={this.handleSubmit}>
                                <Row>
                                    <Col md="12">
                                        <FormReactSelect
                                            selectKey={`parent_select_${this.state.contentSelectUUID}`}
                                            isMulti={false}
                                            className="form-control-label"
                                            isSearchable={true}
                                            name={"parentUID"}
                                            placeHolder="inputPlaceHolder.content.select.parent"
                                            onChange={this.handleContentChange}
                                            optionValue={"uniqueId"}
                                            // value={this.state.selectedCourse}
                                            optionList={this.state.contentList}
                                            optionLabel={"title"}
                                            label="inputLabel.content.select.parent"
                                            errorMsg={this.state.errorData.parentUID || ""}
                                        />
                                    </Col>
                                    <Col md="12">
                                        <FormInput
                                            name="title"
                                            className="form-control-label"
                                            id="title"
                                            placeholder="inputPlaceHolder.content.title"
                                            type="text"
                                            onChange={this.handleChange}
                                            label="inputLabel.content.title"
                                            value={this.state.title || ""}
                                            errorMsg={this.state.errorData.title || ""}
                                        />
                                    </Col>
                                    <Col md="12">
                                        <FormInput
                                            name="description"
                                            className="form-control-label"
                                            id="title"
                                            placeholder="inputPlaceHolder.content.description"
                                            type="text"
                                            onChange={this.handleChange}
                                            label="inputLabel.content.description"
                                            value={this.state.description || ""}
                                            errorMsg={this.state.errorData.description || ""}
                                        />
                                    </Col>
                                    <Col md="12">
                                        <FormInput
                                            name="order"
                                            className="form-control-label"
                                            id="order"
                                            placeholder="inputPlaceHolder.content.order"
                                            type="number"
                                            min={"1"}
                                            max={"999"}
                                            step={"0.1"}
                                            onChange={this.handleChange}
                                            label="inputLabel.content.order"
                                            value={this.state.order || ""}
                                            errorMsg={this.state.errorData.order || ""}
                                        />
                                    </Col>
                                </Row>
                                <div className="text-center">
                                    <Button className="my-4" color="info" type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}

export default ContentCreate;
