/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import StudentOnlineExaminationService from "../../../../services/studentOnlineExaminationService";

class StudentExaminationInstructionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      instructionPageData: {},
    };
    this.createMarkup = this.createInstructionTextMarkup.bind(this);
  }

  componentDidMount() {
    const { examinationUID, uniqueId } = this.props.match.params;
    this.setState({ examinationUID: examinationUID, uniqueId: uniqueId });
    StudentOnlineExaminationService.instructionPage(examinationUID).then(
      (response) => {
        console.log(response);
        this.setState({ instructionPageData: response.data });
      }
    );
  }

  createInstructionTextMarkup() {
    return { __html: this.state.instructionPageData.instructionText };
  }

  render() {
    let obj = this;
    return (
      <>
        <SimpleHeader
          name="List"
          parentName="Online Examination"
          heading="ClashMate"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Examination Instruction</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="col">
                    <div
                      dangerouslySetInnerHTML={this.createInstructionTextMarkup()}
                    ></div>
                  </div>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="info"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        obj.props.history.push(
                          `/student/onlineExamination/start/${obj.state.uniqueId}`
                        );
                      }}
                    >
                      Start
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default StudentExaminationInstructionPage;
