/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Status500Page from "../views/pages/statusPage/Status500Page";
import Status404Page from "../views/pages/statusPage/Status404Page";
import Status403Page from "../views/pages/statusPage/Status403Page";

const statusPageRoutes = [
  {
    path: "/500",
    name: "500 Page",
    icon: "ni ni-shop text-primary",
    component: Status500Page,
    layout: "/status",
  },
  {
    path: "/404",
    name: "404 Page",
    icon: "ni ni-shop text-primary",
    component: Status404Page,
    layout: "/status",
  },
  {
    path: "/403",
    name: "403 Page",
    icon: "ni ni-shop text-primary",
    component: Status403Page,
    layout: "/status",
  },
];

export default statusPageRoutes;
