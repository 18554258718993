import axiosInstance from "../api/api";

const TenantSettingService = {
  list: function () {
    return axiosInstance({
      url: "/api/tenantSetting/",
      method: "GET",
    });
  },
  update: function (formData) {
    return axiosInstance({
      url: `/api/tenantSetting/`,
      method: "PATCH",
      data: formData,
    });
  },
};
export default TenantSettingService;
