import React from "react";
import {Col, Row} from "reactstrap";
import FormInput from "../../../../components/common/form/FormInput";

class StudyMaterialForm extends React.Component {
    render() {
        return (
            <>
                <Row>
                    <Col md="12">
                        <FormInput
                            name="title"
                            className="form-control-label"
                            id="title"
                            placeholder="inputPlaceHolder.studyMaterial.title"
                            type="text"
                            onChange={this.props.onChange}
                            label="inputLabel.studyMaterial.title"
                            value={this.props.valueObj.title || ""}
                            errorMsg={this.props.errorObj.title || ""}
                        />
                    </Col>
                    <Col md="12">
                        <FormInput
                            name="description"
                            className="form-control-label"
                            id="title"
                            placeholder="inputPlaceHolder.studyMaterial.description"
                            type="text"
                            onChange={this.props.onChange}
                            label="inputLabel.studyMaterial.description"
                            value={this.props.valueObj.description || ""}
                            errorMsg={this.props.errorObj.description || ""}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

export default StudyMaterialForm;
