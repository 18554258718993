import React from "react";
import { Col, Row } from "reactstrap";
import FormInput from "../../../components/common/form/FormInput";
import FormReactSelect from "../../../components/common/form/FormReactSelect";
import {countries} from "../../../util/countries";


class CompanyForm extends React.Component {
  showField() {
    return this.props.isEdit === true ? "hidden" : "";
  }

  render() {
    return (
      <>
        <Row>
          <Col md="4">
            <FormInput
              name="legalName"
              className="form-control-label"
              id="legalName"
              placeholder="inputPlaceHolder.company.legalName"
              type="text"
              onChange={this.props.onChange}
              label="inputLabel.company.legalName"
              value={this.props.valueObj.legalName || ""}
              errorMsg={this.props.errorObj.legalName || ""}
              required
            />
          </Col>
          <Col md="4">
            <FormReactSelect
                selectKey={this.props.selectCountryKey}
                isMulti={false}
                className="form-control-label"
                isSearchable={true}
                label="inputLabel.company.country"
                name={"country"}
                onChange={this.props.handleCountrySelectChange}
                optionValue={"value"}
                defaultValue={this.props.selectedCountry}
                optionList={countries}
                optionLabel={"key"}
                placeHolder="inputPlaceHolder.company.country"
                errorMsg={this.props.errorObj.country || ""}
                required
            />
          </Col>
          <Col md="4">
            <FormInput
              name="website"
              className="form-control-label"
              id="website"
              placeholder="inputPlaceHolder.company.website"
              type="text"
              onChange={this.props.onChange}
              label="inputLabel.company.website"
              value={this.props.valueObj.website || ""}
              errorMsg={this.props.errorObj.website || ""}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md="4" sm="6">
            <FormInput
              name="address"
              className="form-control-label"
              id="address"
              placeholder="inputPlaceHolder.company.address"
              type="text"
              onChange={this.props.onChange}
              label="inputLabel.company.address"
              value={this.props.valueObj.address || ""}
              errorMsg={this.props.errorObj.address || ""}
            />
          </Col>
          <Col md="4" sm="6" >
            <FormInput
                name="taxRefNumber"
                className="form-control-label"
                id="taxRefNumber"
                placeholder="inputPlaceHolder.company.taxRefNumber"
                type="text"
                onChange={this.props.onChange}
                label="inputLabel.company.taxRefNumber"
                errorMsg={this.props.errorObj.taxRefNumber || ""}
            />
          </Col>
          <Col md="4" sm="6">
            <FormReactSelect
                selectKey={this.props.selectCompanyTypeKey}
                isMulti={true}
                className="form-control-label"
                isSearchable={true}
                label="inputLabel.company.companyType"
                name={"companyTypeList"}
                onChange={this.props.onCompanyTypeChange}
                optionValue={"value"}
                defaultValue={this.props?.selectedCompanyTypes}
                optionList={this.props.companyType}
                optionLabel={"key"}
                placeHolder="inputPlaceHolder.company.companyType"
                errorMsg={this.props.errorObj.companyTypeList || ""}
                required
            />
          </Col>
        </Row>
        <Row>
          
        </Row>
      </>
    );
  }
}

export default CompanyForm;
