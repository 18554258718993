/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Button, Card, CardBody, CardHeader, Container, Form,} from "reactstrap";
import StudyMaterialService from "../../../../services/studyMaterialService";
import AppRedirect from "../../../../util/appRedirect";
import NotificationAlert from "react-notification-alert/index";
import StudyMaterialForm from "./Form";
import AuthService from "../../../../services/authService";

class StudyMaterialCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorData: {},
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }

    handleSubmit(event) {
        let self = this;
        event.preventDefault();
        StudyMaterialService.create(this.state).then((response) => {
            console.log(response.data);
            if (response.responseStatus === "SUCCESS") {
                self.notify(
                    response.responseStatus.toLowerCase(),
                    response.responseStatus,
                    response.message
                );
                AppRedirect.redirectWithWait(self, `/${AuthService.getBaseURLByRole()}/studyMaterial/list`, 3000);
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    }

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };


    render() {
        return (
            <>
                <SimpleHeader
                    name="Create"
                    parentName="StudyMaterial"
                    heading="ClashMate"
                    newBtnUrl={`/${AuthService.getBaseURLByRole()}/studyMaterial/list`}
                    newBtnText="Study Materials"
                />
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">Create Study Material</h3>
                        </CardHeader>
                        <CardBody>
                            <Form role="form" onSubmit={this.handleSubmit}>
                                <StudyMaterialForm
                                    onChange={this.handleChange}
                                    valueObj={this.state}
                                    errorObj={this.state.errorData}
                                />
                                <div className="text-center">
                                    <Button className="my-4" color="info" type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}

export default StudyMaterialCreate;
