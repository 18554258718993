/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Card, CardBody, CardHeader, Col, Container, FormGroup, Row,} from "reactstrap";
import AuthService from "../../../../services/authService";
import ContentService from "../../../../services/contentService";
import Button from "reactstrap/es/Button";
import AppRedirect from "../../../../util/appRedirect";
import NotificationAlert from "react-notification-alert";
import ContentDocument from "./ContentDocument";

class ContentDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentData: {},
        };
    }

    componentDidMount() {
        const {uniqueId} = this.props.match.params;
        ContentService.fetch(uniqueId).then((response) => {
            console.log(response.data);
            this.setState({contentData: response.data});
        });
    };

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    deleteContent = async (uniqueId) => {
        let self = this;
        this.setState({alert: null})
        await ContentService.delete(uniqueId).then((response) => {
            console.log(response);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            //TODO: TO be fixed with redux implementation
            //   this.props.history.push("/admin/dashboard");
            AppRedirect.redirectWithWait(self, `/${AuthService.getBaseURLByRole()}/studyMaterial/show/${self.state.contentData.studyMaterialUID}`, 3000)
        });
    };


    render() {
        const {contentData} = this.state;
        return (
            <>
                <SimpleHeader
                    name="Details"
                    parentName="Content"
                    heading="ClashMate"
                    newBtnUrl={`/${AuthService.getBaseURLByRole()}/studyMaterial/show/${contentData.studyMaterialUID}`}
                    newBtnText="Back to Study Material"
                />
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">{contentData.title}
                                <Button onClick={() => this.deleteContent(contentData.uniqueId)} color="danger" outline
                                        type="button" className="fa-pull-right">
                                    Delete
                                </Button>
                            </h3>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols2Input"
                                        >
                                            Date Created
                                        </label>
                                        <p>{contentData.dateCreated}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols2Input"
                                        >
                                            Order
                                        </label>
                                        <p>{contentData.order ? contentData.order : "N/A"}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            Title
                                        </label>
                                        <p>{contentData.title}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            Description
                                        </label>
                                        <p>{contentData.description}</p>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>

                <ContentDocument contentUid={this.props.match.params.uniqueId}/>
            </>
        );
    }
}

export default ContentDetails;
