import SockJS from "sockjs-client";
import Stomp from "stompjs";

const BASE_URL = new URL(`${window.location.origin.toString()}`);

const websocketInstance = function (debugEnabled) {
    if (websocketInstance.client && websocketInstance.client.ws.readyState === 1) {
        console.log(
            "reusing the socket connection [state = " +
            websocketInstance.client.ws.readyState +
            "]: " +
            websocketInstance.client.ws.url
        );
        return Promise.resolve(websocketInstance.client);
    }

    return new Promise(function (resolve, reject) {
        websocketInstance.client = Stomp.over(
            new SockJS(BASE_URL.protocol + "//" + BASE_URL.hostname + "/ws")
        );

        if (!debugEnabled) websocketInstance.client.debug = () => {
        };

        const myHeaders = {
            Authorization: `Bearer ${localStorage.getItem("CLASHMATE_ACCESS_TOKEN")}`,
        };

        websocketInstance.client.connect(
            myHeaders,
            () => {
                resolve(websocketInstance.client);
            },
            (error) => {
                if (error) {
                    console.error("Websocket connection error: ", error);
                    reject(error);
                }
            }
        );
    });
};

export default websocketInstance;
