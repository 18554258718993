import PropTypes from "prop-types";
import React from "react";
import { Card, CardHeader, Row, Table } from "reactstrap";

const StudentListTable = ({ studentList, cardTitle, ...props }) => {
  return (
    <Card>
      <CardHeader className="border-0">
        <Row className="align-items-center">
          <div className="col-12">
            <h3 className="mb-0">{cardTitle}</h3>
          </div>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th className="sort" data-sort="name" scope="col">
              #
            </th>
            <th className="sort" data-sort="status" scope="col">
              Name
            </th>
          </tr>
        </thead>
        <tbody className="list">
          {studentList.map(function (value, index) {
            return (
              <tr key={index}>
                <td className="budget">{index + 1}</td>
                <td className="budget">{value.fullName}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Card>
  );
};

StudentListTable.propTypes = {
  studentList: PropTypes.array.isRequired,
  cardTitle: PropTypes.string.isRequired,
};

export default StudentListTable;
