/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Form
} from "reactstrap";
import ContentService from "../../../services/xgContentService";
import ContentForm from "./Form";
import {contentCategory} from "../../../util/contentCategory";
import {contentArticleType} from "../../../util/contentArticleType";
import {contentType} from "../../../util/contentType";
import _ from "lodash";
import UtilService from "../../../services/utilService";
import {value} from "lodash/seq";
import {ContentState, convertFromHTML, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";

class ContentEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorData: {},
            selectKey: UtilService.uuidv4(),
            editorState: undefined,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleArticleTypeChange = this.handleArticleTypeChange.bind(this);
        this.handleContentTypeChange = this.handleContentTypeChange.bind(this);
        this.handleContentChange = this.handleContentChange.bind(this);
    }

    async componentDidMount() {
        const {uniqueId} = this.props.match.params;
        const response = await ContentService.fetch(uniqueId)
        console.log(response.data);
        const category = response.data.category;
        const contentType = response.data.contentType;
        this.setState({selectedCategory : {key:category, value:category}, selectedContentType :{key:contentType, value:contentType}});
        const selArticleType =response.data.articleTypes && response.data.articleTypes.map(type => ({key : type, value : type}))
        this.setState({selectedArticleType : selArticleType});
        this.setState(response.data);
        this.setState({uniqueId: uniqueId});
        this.setState({selectKey: UtilService.uuidv4()});
        let contentTextHMTL = response.data.content || "";
        const blocksFromHTML = convertFromHTML(contentTextHMTL);
        const content = ContentState.createFromBlockArray(blocksFromHTML);
        this.setState({
            editorState: EditorState.createWithContent(content),
        });
        console.log(this.state);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }

    handleContentChange(editorState) {
        this.setState({ editorState });
    }

    handleSubmit(event) {
        event.preventDefault();
        if(this.state.selectedContentType.key === "TEXT"){
            console.log(convertToRaw(this.state.editorState.getCurrentContent()))
            this.setState({content: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))})
        }
        ContentService.update(this.state).then(response => {
            console.log(response.data);
            if (response.responseStatus === "SUCCESS") {
                this.props.history.push("/admin/content/list");
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    }

    handleSelectChange(valueObj) {
        if (_.isNull(valueObj)) {
            this.setState({selectedCategory: {}, category: ""});
        } else {
            this.setState({selectedCategory: valueObj, category: valueObj.key});
        }
    }

    handleArticleTypeChange = (valueObj, event) => {
        if (_.isNull(valueObj)) {
            this.setState({selectedArticleType: []});
        } else {
            this.setState({selectedArticleType: valueObj});
            const keys = _.map(valueObj, "key")
            this.setState({articleTypes: keys});
        }
    };

    handleContentTypeChange = (valueObj, event) => {
        if (_.isNull(valueObj)) {
            this.setState({selectedContentType:  [], contentType :""});
        } else {
            this.setState({selectedContentType: valueObj, contentType : valueObj.key});
        }
    };

    render() {
        return (
            <>
                <SimpleHeader
                    name="Edit"
                    parentName="Content"
                    heading="XareGrowth"
                    newBtnUrl="/admin/content/create"
                    newBtnText="Create Content"
                />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">Edit Content</h3>
                        </CardHeader>
                        <CardBody>
                            <Form role="form" onSubmit={this.handleSubmit}>
                                <ContentForm
                                    onChange={this.handleChange}
                                    valueObj={this.state}
                                    errorObj={this.state.errorData}
                                    isEdit={true}
                                    selectKey={this.state.selectKey}
                                    contentCategories={contentCategory}
                                    onSelectChange={this.handleSelectChange}
                                    selectedCategory={this.state.selectedCategory}
                                    onArticleTypeChange={this.handleArticleTypeChange}
                                    selectedArticleType={this.state.selectedArticleType}
                                    articleTypes={contentArticleType}
                                    onContentTypeChange={this.handleContentTypeChange}
                                    selectedContentType={this.state.selectedContentType}
                                    contentTypes = {contentType}
                                    obj={this}
                                    uploadImageCallback = {this.uploadImageCallback}
                                />
                                <div className="text-center">
                                    <Button className="my-4" color="default" outline type="submit">
                                        Update
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}

export default ContentEdit;
