/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
} from "reactstrap";
import LessonPlanService from "../../../services/lessonPlanService";
import TeacherService from "../../../services/teacherService";
import LessonPlanForm from "./Form";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

class LessonPlanEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorData: {},
      editorState: undefined,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleContentChange = this.handleContentChange.bind(this);
  }

  componentDidMount() {
    const { uniqueId } = this.props.match.params;
    LessonPlanService.fetch(uniqueId).then((response) => {
      console.log(response.data);
      this.setState(response.data);
      let contentTextHMTL = response.data.content || "";
      const blocksFromHTML = convertFromHTML(contentTextHMTL);
      const content = ContentState.createFromBlockArray(blocksFromHTML);
      this.setState({
        editorState: EditorState.createWithContent(content),
      });
    });
    TeacherService.subject().then((response) => {
      this.setState({ subjectList: response });
    });
  }

  handleContentChange(editorState) {
    this.setState({ editorState });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    console.log(this.state);
  }

  handleSubmit(event) {
    event.preventDefault();
    const { editorState } = this.state;
    let formData = {
      content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      title: this.state.title,
      courseSectionSubjectUID: this.state.courseSectionSubjectUID,
      uniqueId: this.state.uniqueId,
    };
    LessonPlanService.update(formData).then((response) => {
      console.log(response.data);
      if (response.responseStatus === "SUCCESS") {
        this.props.history.push("/teacher/lessonPlan/list");
      } else {
        this.setState({ errorData: response.data });
        console.error("Error    ==>     " + response.message);
      }
    });
  }

  render() {
    return (
      <>
        <SimpleHeader
          name="Edit"
          parentName="Lesson Plan"
          heading="ClashMate"
          newBtnUrl="/teacher/lessonPlan/create"
          newBtnText="Create Lesson Plan"
        />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <h3 className="mb-0">Edit Lesson Plan</h3>
            </CardHeader>
            <CardBody>
              <Form role="form" onSubmit={this.handleSubmit}>
                <LessonPlanForm
                  onChange={this.handleChange}
                  valueObj={this.state}
                  errorObj={this.state.errorData}
                  isEdit={true}
                  obj={this}
                />
                <div className="text-center">
                  <Button className="my-4" color="info" type="submit">
                    Update
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

export default LessonPlanEdit;
