import PropTypes from "prop-types";
import React from "react";
import {FormFeedback, FormGroup, Input} from "reactstrap";
import {useTranslation} from "react-i18next";


const FormInput = ({
                       name,
                       id,
                       type,
                       placeholder,
                       onChange,
                       className,
                       value,
                       label,
                       errorMsg,
                       disabled,
                       multiple,
                       required,
                       formGroupClassName,
                       ...props
                   }) => {
    const {t} = useTranslation();
    return (
        <FormGroup className={formGroupClassName}>
            <label className={className} htmlFor={name}>
                {t(label)} {required && <sup style={{color: "red"}}>*</sup>}
            </label>
            <Input
                name={name}
                id={id}
                placeholder={t(placeholder)}
                type={type}
                onChange={onChange}
                value={value}
                invalid={!!errorMsg}
                disabled={disabled}
                multiple={multiple ? "multiple" : ""}
                {...props}
            />
            <FormFeedback>{t(errorMsg)}</FormFeedback>
        </FormGroup>
    );
};

FormInput.defaultProps = {
    type: "text",
    className: "form-control-label",
};

FormInput.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf([
            "text",
            "number",
            "password",
            "date",
            "email",
            "textarea",
            "time"
        ]),
    ]),
    id: PropTypes.string,
    errorMsg: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

export default FormInput;
