import axiosInstance from "../api/api";

const DocumentService = {
    fetch: function (uniqueId) {
        return axiosInstance({
            url: `/api/document/${uniqueId}`,
            method: "GET",
            responseType: 'blob'
        });
    },
    delete: function (uniqueId) {
        return axiosInstance({
            url: `/api/document/${uniqueId}`,
            method: "DELETE",
        });
    },
};
export default DocumentService;
