import DocumentService from "../services/documentService";


const DownloadUtil = {
    download(doc) {
        DocumentService.fetch(doc.uniqueId).then(response => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', doc.originalFileName); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
    },
    generateUrl(uniqueId) {
        const BASE_URL = new URL(`${window.location.origin.toString()}`);
        let baseURL = BASE_URL.protocol + "//" + BASE_URL.hostname;
        let documentUrl = baseURL + "/api/document/" + uniqueId;
        return documentUrl
    },
};

export default DownloadUtil;