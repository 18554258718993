import axiosInstance from "../api/api";

const CourseSectionSubjectService = {
	fetchCourseSectionSubjects: function () {
		return axiosInstance({
			url: `/api/courseSectionSubject/`,
			method: "GET",
		});
	},
	fetchSubjects: function (uniqueId) {
		return axiosInstance({
			url: `/api/courseSectionSubject/courseSection/${uniqueId}`,
			method: "GET",
		});
	},
	fetchCourseSections: function (uniqueId) {
		return axiosInstance({
			url: `/api/courseSectionSubject/subject/${uniqueId}`,
			method: "GET",
		});
	},
	delete: function (formData) {
		return axiosInstance({
			url: `/api/courseSectionSubject/`,
			method: "DELETE",
			data: formData,
		});
	},
	create: function (formData) {
		return axiosInstance({
			url: `/api/courseSectionSubject/`,
			method: "POST",
			data: formData,
		});
	},
	fetchCourseSectionSubjectsForCurrentUser: function () {
		return axiosInstance({
			url: `/api/courseSectionSubject/user`,
			method: "GET",
		});
	},
};
export default CourseSectionSubjectService;
