/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle, Modal,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import CompanyService from "../../../services/companyService";
import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ContactService from "../../../services/contactService";
import _ from "lodash";
import FormInput from "../../../components/common/form/FormInput";
import {CompanyPermissions, ContactPermissions, ContentPermissions} from "../../../services/permissions";

class CompanyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      filterName :null,
      filterCountry :null,
      filteredList :[],
      alert: null,
      contactModal: false,
      contactList :[],
      filteredContacts : [],

    };
    this.delete = this.delete.bind(this);
    this.notify = this.notify.bind(this);
  }

  onChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
    console.log(this.state);
  }


  componentDidMount() {
    CompanyService.list().then((response) => {
      console.log(response);
      this.setState({list: response, filteredList : response});
    });
    ContactService.list().then((response) => {
      console.log(response);
      this.setState({contactList: response});
    });
    console.log("COntact List")
    console.log(this.state.contactList)
  }

  hideDeleteAlert = () => {
    this.setState({
      alert: null
    });
  };

  confirmDeleteAlert = (uniqueId) => {
    this.setState({
      alert: (
          <ReactBSAlert
              warning
              style={{display: "block"}}
              title="Are you sure?"
              onConfirm={() => this.delete(uniqueId)}
              onCancel={this.hideDeleteAlert}
              showCancel
              confirmBtnBsStyle="danger"
              confirmBtnText="Yes, delete it!"
              cancelBtnBsStyle="secondary"
              cancelBtnText="Cancel"
              btnSize=""
          >
            You won't be able to revert this!
          </ReactBSAlert>
      )
    });
  };

  applyFilter = () => {
    const { list, filterName, filterCountry } = this.state;

    const filteredList = _.filter(list, (company) => {
      const nameMatches = filterName ? _.includes(_.toLower(company.legalName), _.toLower(filterName)) : true;
      const countryMatches = filterCountry ? _.includes(_.toLower(company.country), _.toLower(filterCountry)) : true;

      return nameMatches && countryMatches;
    });

    this.setState({ filteredList : filteredList });
  }

  clearFilter = () => {
    this.setState({filteredList : this.state.list, filterName : null, filterCountry : null })
  }

  displayContacts = (uniqueId) => {
    let filteredContacts = _.filter(this.state.contactList, value => value.company.uniqueId === uniqueId );
    this.setState({filteredContacts : filteredContacts})
    this.toggleModal("contactModal");
  }

  delete(uniqueId) {
    this.setState({alert: null})
    CompanyService.delete(uniqueId).then((response) => {
      console.log(response);
      this.notify(
          response.responseStatus.toLowerCase(),
          response.responseStatus,
          response.message
      );
      //TODO: TO be fixed with redux implementation
      //   this.props.history.push("/admin/dashboard");
      CompanyService.list().then((response) => {
        console.log(response);
        this.setState({list: response});
      });
    });
  }

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
          <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
            <span data-notify="message">{message}</span>
          </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    let obj = this;
    return (
        <>
          <div className="rna-wrapper">
            <NotificationAlert ref="notificationAlert"/>
          </div>
          <SimpleHeader
              name="List"
              parentName="Company"
              heading="XareGrwoth"
              // newBtnUrl="/admin/company/create"
              // newBtnText="Create Company"
              style= {{display:CompanyPermissions.create()}}
          />
          {this.state.alert}
          <Container className="mt--6" fluid>
            <Row>
              <Col xl="12">
                <Card>
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Companies List</h3>
                      </div>
                    </Row>
                    <Row>
                      <p style={{ fontStyle: "italic", color: "gray" }}>
                        Use the filters below to narrow down your search results.
                      </p>
                    </Row>
                    <Row>

                      {/*<Col sm={2}>
                        <FormInput
                            name="dateFrom"
                            className="form-control-label"
                            id="date"
                            placeholder="inputPlaceHolder.meetingReport.date"
                            type="date"
                            onChange={this.onChange}
                            value={this.state.dateFrom || ""}
                            label="Date From"
                        />
                      </Col>
                      <Col sm={2}>
                        <FormInput
                            name="dateTo"
                            className="form-control-label"
                            id="date"
                            placeholder="inputPlaceHolder.meetingReport.date"
                            type="date"
                            onChange={this.onChange}
                            value={this.state.dateTo || ""}
                            label="Date To"
                        />
                      </Col>*/}
                      <Col md="2">
                        <FormInput
                            name="filterName"
                            className="form-control-label"
                            id="companyName"
                            placeholder="Company Name"
                            type="text"
                            onChange={this.onChange}
                            label="Company"
                            value={this.state?.filterName || ""}
                            // errorMsg={this.props.errorObj?.name || ""}
                        />
                      </Col>
                      <Col md="2">
                        <FormInput
                            name="filterCountry"
                            className="form-control-label"
                            id="countryName"
                            placeholder="Country Name"
                            type="text"
                            onChange={this.onChange}
                            label="Country"
                            value={this.state.filterCountry || ""}
                            // errorMsg={this.props.errorObj?.name || ""}
                        />
                      </Col>
                      <Col md={"2"}></Col>
                      <Col md={"2"}></Col>
                      <Col sm={3} style={{marginTop: "30px"}}>
                        <Button  size="md" outline color="default" type="button"
                        onClick={this.applyFilter}
                        >
                          Apply Filter
                        </Button>
                        <Button  color="default"  size="md" outline type="button"
                        onClick={this.clearFilter}>
                          Clear Filter
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        #
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Date Created
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Legal Name
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Country
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Website
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Company Types
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Address
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Tax Ref Number
                      </th>
                      <th scope="col"/>
                    </tr>
                    </thead>
                    <tbody className="list">
                    {this.state.filteredList.map(function (value, index) {
                      return (
                          <tr key={index}>
                            <td className="budget">{index + 1}</td>
                            <td className="budget">{value.dateCreated}</td>
                            <td className="budget">{value.legalName}</td>
                            <td className="budget">{value.country}</td>
                            <td className="budget">{value.website}</td>
                            <td className="budget">{value.companyTypeList && value.companyTypeList.map(function (type, index) {
                              return <h4 key={index} className="badge badge-lg badge-primary">{type }</h4>
                            })}</td>
                            <td className="budget">{value.address}</td>
                            <td className="budget">{value.taxRefNumber}</td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                    color=""
                                    size="sm"
                                    className="btn-icon-only text-light"
                                >
                                  <i className="fas fa-ellipsis-v"/>
                                </DropdownToggle>
                                <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                >
                                  <DropdownItem
                                      style= {{display:ContactPermissions.read()}}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        obj.displayContacts(value.uniqueId);
                                      }}
                                  >
                                    View All Contacts
                                  </DropdownItem>
                                  <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();
                                        obj.props.history.push(
                                            `/admin/contact/create/${value.uniqueId}`
                                        );
                                      }}
                                  >
                                    Create Contact
                                  </DropdownItem>
                                  <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();
                                        obj.props.history.push(
                                            `/admin/meetingReport/create/${value.uniqueId}`
                                        );
                                      }}
                                  >
                                    Create Report
                                  </DropdownItem>
                                  <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();
                                        obj.props.history.push(
                                            `/admin/summary/${value.uniqueId}`
                                        );
                                      }}
                                  >
                                    All summaries
                                  </DropdownItem>
                                  <DropdownItem
                                      style= {{display:CompanyPermissions.read(value?.createdBy)}}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        obj.props.history.push(
                                            `/admin/company/show/${value.uniqueId}`
                                        );
                                      }}
                                  >
                                    Details
                                  </DropdownItem>
                                  <DropdownItem
                                      style= {{display:CompanyPermissions.update(value?.createdBy)}}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        obj.props.history.push(
                                            `/admin/company/edit/${value.uniqueId}`
                                        );
                                      }}
                                  >
                                    Edit
                                  </DropdownItem>
                                  <DropdownItem
                                      style= {{display:CompanyPermissions.delete()}}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        obj.confirmDeleteAlert(`${value.uniqueId}`);
                                      }}
                                  >
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                      );
                    })}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>

            <Modal
                className="modal-dialog-centered modal-lg"
                isOpen={this.state.contactModal}
                toggle={() => this.toggleModal("contactModal")}
            >
              <div className="modal-header">
                <h6 className="modal-title" id="modal-title-default">
                  Contacts
                </h6>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("contactModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                  <tr>
                    <th className="sort" data-sort="name" scope="col">
                      #
                    </th>
                    <th className="sort" data-sort="status" scope="col">
                      Full Name
                    </th>
                    <th className="sort" data-sort="status" scope="col">
                      Company Name
                    </th>
                    <th className="sort" data-sort="status" scope="col">
                      Contact Role
                    </th>
                    <th className="sort" data-sort="status" scope="col">
                      Email Address
                    </th>
                    <th className="sort" data-sort="status" scope="col">
                      Contact Number
                    </th>
                    <th scope="col"/>
                  </tr>
                  </thead>
                  <tbody className="list">
                  {this.state.filteredContacts.map(function (value, index) {
                    return (
                        <tr key={index}>
                          <td className="budget">{index + 1}</td>
                          <td className="budget">{value.fullName}</td>
                          <td className="budget">{value.company.legalName}</td>
                          <td className="budget">{value.contactRole}</td>
                          <td className="budget">{value.email || "N/A"}</td>
                          <td className="budget">{value.number || "N/A"}</td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                  color=""
                                  size="sm"
                                  className="btn-icon-only text-light"
                              >
                                <i className="fas fa-ellipsis-v"/>
                              </DropdownToggle>
                              <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                              >
                                <DropdownItem
                                    onClick={(e) => {
                                      e.preventDefault();
                                      obj.props.history.push(
                                          `/admin/contact/show/${value.uniqueId}`
                                      );
                                    }}
                                >
                                  Details
                                </DropdownItem>
                                <DropdownItem
                                    onClick={(e) => {
                                      e.preventDefault();
                                      obj.props.history.push(
                                          `/admin/contact/edit/${value.uniqueId}`
                                      );
                                    }}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                    onClick={(e) => {
                                      e.preventDefault();
                                      obj.confirmDeleteAlert(`${value.uniqueId}`);
                                    }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                    );
                  })}
                  </tbody>
                </Table>
              </div>
              <div className="modal-footer">
                <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("contactModal")}
                >
                  Close
                </Button>
              </div>
            </Modal>
          </Container>
        </>
    );
  }
}

export default CompanyList;
