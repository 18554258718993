/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Col, Container, Row,} from "reactstrap";
import PostService from "../../../../services/postService";
import Moment from "react-moment";
import AuthService from "../../../../services/authService";
import _ from "lodash";
import DocumentService from "../../../../services/documentService";
import {postActivityTypes} from "../../../../util/postActivityTypes";
import {objectStatus} from "../../../../util/objectStatus";
import CardText from "reactstrap/es/CardText";
import CardFooter from "reactstrap/es/CardFooter";
import NotificationAlert from "react-notification-alert/index";
import Media from "reactstrap/es/Media";
import Form from "reactstrap/es/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "reactstrap/es/Button";
import {faComment, faEdit, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import FormInput from "../../../../components/common/form/FormInput";

class PostDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            postData: {
                createdBy: {},
                documentList: [],
                postActivities: [],
                likedCounts: 0,
                viewedCounts: 0,
                commentCounts: 0
            },
            comment: "",
            commentUIDForEditing: null,
            editedComment: ""
        };
    }

    onChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    showEditComment = (comment) => {
        if (this.state.commentUIDForEditing === comment.uniqueId) {
            this.setState({commentUIDForEditing: null})
        } else {
            this.setState({commentUIDForEditing: comment.uniqueId});
            this.setState({editedComment: comment.comment});
        }
    };

    likePost = () => {
        const {uniqueId} = this.props.match.params;
        let obj = this;
        let data = {
            postUID: uniqueId,
            postActivityType: postActivityTypes.LIKE
        };
        PostService.createPostActivity(data).then((response) => {
            console.log(response.data);
            obj.updatePostActivityResponse(response.data);
        })
    };

    updatePostActivityResponse = (postActivity) => {
        let postData = this.state.postData;
        let postActivities = postData.postActivities;
        if (postActivity.status === objectStatus.CURRENT) {
            postData.postActivities = [...postActivities, postActivity]
        } else {
            postData.postActivities = _.filter(postActivities, (activity) => activity.uniqueId !== postActivity.uniqueId)
        }
        this.setState({postData: postData});
        this.populatePostActivityCounts();
    };

    populatePostActivityCounts = () => {
        let likedCounts = _.filter(this.state.postData.postActivities, (postActivity) => postActivity.postActivityType === postActivityTypes.LIKE).length;
        let viewedCounts = _.filter(this.state.postData.postActivities, (postActivity) => postActivity.postActivityType === postActivityTypes.VIEW).length;
        let commentCounts = _.filter(this.state.postData.postActivities, (postActivity) => postActivity.postActivityType === postActivityTypes.COMMENT).length;
        this.setState({likedCounts: likedCounts, viewedCounts: viewedCounts, commentCounts: commentCounts})
    };

    updateView = () => {
        const {uniqueId} = this.props.match.params;
        let obj = this;
        let data = {
            postUID: uniqueId,
            postActivityType: postActivityTypes.VIEW
        };
        PostService.createPostActivity(data).then((response) => {
            console.log(response.data);
            obj.updatePostActivityResponse(response.data);
        })
    };

    saveEditedComment = () => {
        let self = this;
        let comment = self.state.editedComment;
        let postActivityUID = self.state.commentUIDForEditing;
        if (comment === "") {
            this.notify(
                "error",
                "ERROR",
                "Comment Update cannot be empty!"
            );
        } else {
            let data = {
                postActivityUID: postActivityUID,
                postActivityType: postActivityTypes.COMMENT,
                comment: comment
            };
            PostService.updatePostActivity(data).then((response) => {
                console.log(response.data);
                self.updatePostActivityResponse(response.data);
                let postActivities = _.filter(self.state.postData.postActivities, postActivity => postActivity.uniqueId !== postActivityUID)
                postActivities = [...postActivities, response.data]
                let postData = self.state.postData;
                postData.postActivities = postActivities;
                self.setState({postData: postData, editedComment: "", commentUIDForEditing: null});
                self.populatePostActivityCounts();
                self.notify(
                    response.responseStatus.toLowerCase(),
                    response.responseStatus,
                    "Comment updated successfully!"
                );
            })
        }
    };

    postComment = () => {
        const {uniqueId} = this.props.match.params;
        let self = this;
        let comment = self.state.comment;
        if (comment === "") {
            this.notify(
                "error",
                "ERROR",
                "Comment cannot be empty!"
            );
        } else {
            let data = {
                postUID: uniqueId,
                postActivityType: postActivityTypes.COMMENT,
                comment: comment
            };
            PostService.createPostActivity(data).then((response) => {
                console.log(response.data);
                self.updatePostActivityResponse(response.data);
                self.setState({comment: ""});
                self.notify(
                    response.responseStatus.toLowerCase(),
                    response.responseStatus,
                    "Comment posted successfully!"
                );
            })
        }
    };

    fetchPost = () => {
        const {uniqueId} = this.props.match.params;
        let obj = this;
        PostService.fetch(uniqueId).then(response => {
            obj.setState({postData: response.data});
            obj.updateView();
        });
    };

    componentDidMount() {
        this.fetchPost();
        this.populatePostActivityCounts();
    }

    download = (blob, doc) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', doc.originalFileName); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    downloadDocument = async (uniqueId) => {
        let doc = _.find([...this.state.postData.documentList], (value) => value.uniqueId === uniqueId)
        DocumentService.fetch(uniqueId).then(response => {
            // console.log(response)
            this.download(response, doc);
        })
    };

    showEditAndDelete = (value) => {
        let obj = this;
        if (value.createdByUID === value.currentUserUid) {
            return (
                <>
                    <button onClick={() => {
                        obj.props.history.push(`/${AuthService.getBaseURLByRole()}/post/edit/${value.uniqueId}`)
                    }}
                            className="badge badge-pill badge-default">Edit
                        Post
                    </button>
                    <button onClick={() => {
                        obj.delete(value.uniqueId)
                    }}
                            className="badge badge-pill badge-default">Delete
                        Post
                    </button>
                </>
            );
        } else {

        }
    };

    deleteComment = (comment) => {
        PostService.deletePostActivity(comment.uniqueId).then((response) => {
            this.notify(
                "success",
                "SUCCESS",
                "Comment Deleted successfully!"
            );
            this.updatePostActivityResponse(response.data);
        });
    };

    editComment = (comment) => {

    };

    showEditAndDeleteComment = (comment) => {
        if (comment.createdBy.uniqueId === this.state.postData.currentUserUid) {
            return (<>
                <Button
                    /*style={{
                        display: this.state.commentUIDForEditing ? "none" : "",
                    }}*/
                    className="btn-icon-only rounded-circle right--3"
                    onClick={() => this.showEditComment(comment)}
                >
                    <FontAwesomeIcon icon={faEdit}/>
                </Button>
                <Button
                    className="btn-icon-only rounded-circle right--3"
                    onClick={() => this.deleteComment(comment)}
                >
                    <FontAwesomeIcon icon={faTrash}/>
                </Button>
            </>);
        }
    };

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };


    delete() {
        const {uniqueId} = this.props.match.params;
        let obj = this;
        PostService.delete(uniqueId).then((response) => {
            console.log(response);
            obj.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            setTimeout(() => obj.props.history.push(`/${AuthService.getBaseURLByRole()}/post/feeds`), 3000);
        });
    }

    commentList = () => {
        let commentList = _.filter(this.state.postData.postActivities, postActivity => postActivity.postActivityType === postActivityTypes.COMMENT);
        commentList = _.orderBy(commentList, 'dateCreated', 'desc');
        return commentList;
    };


    render() {
        const {postData} = this.state;
        let obj = this;
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <SimpleHeader
                    name="Details"
                    parentName="Post"
                    heading="ClashMate"
                    newBtnUrl={`/${AuthService.getBaseURLByRole()}/post/feeds`}
                    newBtnText="Feeds"
                />
                {this.state.editCommentPopUp}
                <Container className="mt--6" fluid>
                    <div className="card text-left">
                        <Row>
                            <Col md="1"></Col>
                            <Col md="10">
                                <div className="card-body">
                                    <CardText className="mb-4">
                                        <Row className="card-text font-weight-bold">
                                            <Col
                                                className="col-sm-8">
                                                <a
                                                    className="avatar avatar-xl rounded-circle">
                                                    <img alt="Image placeholder"
                                                         src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?r=pg"/>
                                                </a>{postData.createdBy.fullName} <span
                                                className="h5"><em>shared a post. </em></span>
                                                <br/>
                                                <small>
                                                    <Moment fromNow
                                                            ago>{postData.dateCreated}</Moment> ago.
                                                </small>
                                                &nbsp; <small>Visibility: </small>
                                                <span
                                                    className="badge badge-pill badge-default">{postData.course ? postData.course.title : ''}</span>
                                                <span
                                                    className="badge badge-pill badge-primary">{postData.section ? postData.section.title : ''}</span>
                                                <span
                                                    className="badge badge-pill badge-secondary">{postData.subject ? postData.subject.title : ''}</span>
                                                <span
                                                    className="badge badge-pill badge-warning">{postData.course === null && postData.section === null && postData.subject === null ? "All" : ''}</span>
                                                <h3 className="h4 text-justify">{postData.text}</h3>
                                            </Col>
                                            <Col className="sm-4">
                                                <span className="h4">Attachments
                                                    - {postData.documentList ? postData.documentList.length : 0} </span>
                                                <ul className="list-group list-group-flush"
                                                    data-toggle="checklist">
                                                    {[...postData.documentList].map((postData, index) => {
                                                        return (
                                                            <>
                                                                <li key={postData.uniqueId}
                                                                    className="checklist-entry list-group-item flex-column align-items-start py-4 px-4">
                                                                    <div
                                                                        className="checklist-item checklist-item-danger">
                                                                        <div
                                                                            className="checklist-info">
                                                                            <h5
                                                                                className="checklist-title mb-0">{postData.originalFileName}</h5>
                                                                        </div>
                                                                        <div>
                                                                <span
                                                                    onClick={() => obj.downloadDocument(postData.uniqueId)}
                                                                    className="badge badge-primary">Download</span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </>
                                                        )
                                                    })}
                                                </ul>
                                            </Col>
                                        </Row>
                                    </CardText>
                                    <CardFooter>
                                        <span onClick={() => obj.likePost()}
                                              className="badge badge-pill badge-secondary">
                                            <i className="far fa-thumbs-up fa-2x"></i> {obj.state.likedCounts}</span>
                                        <span className="badge badge-pill badge-primary">
                                            <i className="fas fa-eye fa-2x"></i> {obj.state.viewedCounts}</span>
                                        <span className="badge badge-pill badge-primary">
                                            <i className="fas fa-comment fa-2x"></i> {obj.state.commentCounts}</span>
                                        {/*<button type="button" className="btn btn-sm btn-info">
                                                                    <span>Attachments</span>
                                                                    <span
                                                                        className="badge badge-md badge-circle badge-floating badge-danger border-white">{postData.documentList.length}</span>
                                                                </button>*/}
                                        {obj.showEditAndDelete(postData)}

                                        <div>
                                            <Row>
                                                <Col md="12">
                                                    <div className="mb-1">
                                                        <Media className="align-items-center">
                                                            <img
                                                                alt="..."
                                                                className="avatar avatar-lg rounded-circle mr-4"
                                                                src={require("assets/img/theme/team-3.jpg")}
                                                            />
                                                            <Media body>
                                                                <Form>
                                                                    <FormInput
                                                                        placeholder="Write your comment"
                                                                        rows="1"
                                                                        type="textarea"
                                                                        name="comment"
                                                                        id="comment"
                                                                        value={this.state.comment}
                                                                        onChange={this.onChange}
                                                                    />
                                                                </Form>
                                                            </Media>
                                                            <Button ml="2"
                                                                    className="btn-icon-only rounded-circle right--1"
                                                                    color={
                                                                        this.state.comment
                                                                            ? "success"
                                                                            : "danger"
                                                                    }
                                                                    style={{
                                                                        display: this.state.comment ? "" : "none",
                                                                    }}
                                                                    onClick={(e) => {
                                                                        obj.postComment(e);
                                                                    }}
                                                            >
                                                                <FontAwesomeIcon icon={faComment}/>
                                                            </Button>
                                                        </Media>
                                                        <hr/>
                                                        {obj.commentList().map(comment => {
                                                            return <Media key={comment.uniqueId}
                                                                          className="media-comment">
                                                                <img
                                                                    alt="..."
                                                                    className="avatar avatar-lg media-comment-avatar rounded-circle"
                                                                    src={require("assets/img/theme/team-1.jpg")}
                                                                />
                                                                <Media>
                                                                    <div className="media-comment-text">
                                                                        <h6 className="h5 mt-0">{comment.createdBy.fullName}&nbsp;
                                                                            <small>
                                                                                <Moment fromNow
                                                                                        ago>{comment.dateCreated}</Moment> ago.
                                                                            </small>
                                                                        </h6>
                                                                        <h3 className="text-sm lh-160  text-justify"
                                                                            style={{
                                                                                display: this.state.commentUIDForEditing === comment.uniqueId ? "none" : "",
                                                                            }}>
                                                                            {comment.comment}
                                                                        </h3>
                                                                        <FormInput
                                                                            formGroupClassName={this.state.commentUIDForEditing === comment.uniqueId ? '' : 'hidden'}
                                                                            style={{
                                                                                display: this.state.commentUIDForEditing === comment.uniqueId ? "" : "none",
                                                                            }}
                                                                            placeholder="Write your comment"
                                                                            rows="3"
                                                                            type="textarea"
                                                                            name="editedComment"
                                                                            id="editedComment"
                                                                            value={this.state.editedComment}
                                                                            onChange={this.onChange}
                                                                        />
                                                                        {this.showEditAndDeleteComment(comment)}
                                                                        <Button
                                                                            style={{
                                                                                display: this.state.commentUIDForEditing === comment.uniqueId ? "" : "none",
                                                                            }}
                                                                            color={
                                                                                this.state.editedComment
                                                                                    ? "success"
                                                                                    : "danger"
                                                                            }
                                                                            className="btn-icon-only rounded-circle right--3"
                                                                            onClick={() => this.saveEditedComment()}
                                                                        >
                                                                            <FontAwesomeIcon icon={faSave}/>
                                                                        </Button>
                                                                    </div>
                                                                </Media>
                                                            </Media>
                                                        })}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardFooter>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </>
        );
    }
}

export default PostDetails;
