import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Button, Card, CardBody, CardHeader, Container, Form,} from "reactstrap";
import ChangePasswordForm from "./ChangePasswordForm";
import AuthService from "../../../services/authService";
import ReactBSAlert from "react-bootstrap-sweetalert";

class TeacherSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorData: {},
            alert: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }

    handleSubmit(event) {
        event.preventDefault();
        AuthService.changePassword(this.state).then((response) => {
            console.log(response.data);
            if (response.responseStatus === "SUCCESS") {
                this.logoutTimeAlert();
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    }

    logoutTimeAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    style={{display: "block", marginTop: "0px"}}
                    title="Password Changed!"
                    onConfirm={() => this.hideAlert()}
                    showConfirm={true}
                >
                    Password successfully Changed. You will be redirected to login Screen! Please login again.
                </ReactBSAlert>
            )
        });
        setTimeout(this.hideAlert, 5000);
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
        localStorage.clear();
        this.props.history.push("/")
    };

    render() {
        return (
            <>
                <SimpleHeader
                    name="Settings"
                    parentName="Settings"
                    heading="ClashMate"
                    /*newBtnUrl="/admin/student/create"
                    newBtnText="Create Student"*/
                />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">Change Password</h3>
                        </CardHeader>
                        {this.state.alert}
                        <CardBody>
                            <Form role="form" onSubmit={this.handleSubmit}>
                                <ChangePasswordForm
                                    onChange={this.handleChange}
                                    valueObj={this.state}
                                    errorObj={this.state.errorData}
                                    isEdit={false}
                                />
                                <div className="text-center">
                                    <Button className="my-4" color="info" type="submit">
                                        Change Password
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}

export default TeacherSettings;
