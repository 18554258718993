import axiosInstance from "../api/api";

const AttendanceService = {
    list: function () {
        return axiosInstance({
            url: "/api/attendance/",
            method: "GET",
        });
    },
    filteredList: function (formData) {
        return axiosInstance({
            url: "/api/attendance/filter/",
            method: "POST",
            data: formData,
        });
    },
    filteredStudentAttendanceList: function (formData) {
        return axiosInstance({
            url: "/api/attendance/studentFilter/",
            method: "POST",
            data: formData,
        });
    },
    fetch: function (uniqueId) {
        return axiosInstance({
            url: `/api/attendance/${uniqueId}`,
            method: "GET",
        });
    },
    delete: function (uniqueId) {
        return axiosInstance({
            url: `/api/attendance/${uniqueId}`,
            method: "DELETE",
        });
    },
    create: function (formData) {
        return axiosInstance({
            url: `/api/attendance/`,
            method: "POST",
            data: formData,
        });
    },
    update: function (formData) {
        return axiosInstance({
            url: `/api/attendance/`,
            method: "PATCH",
            data: formData,
        });
    },
    markPresent: function (formData) {
        return axiosInstance({
            url: `/api/attendance/present/`,
            method: "PATCH",
            data: formData,
        });
    },
    markAbsent: function (formData) {
        return axiosInstance({
            url: `/api/attendance/absent/`,
            method: "PATCH",
            data: formData,
        });
    },

};
export default AttendanceService;
