import { v4 as uuidv4, v1 as uuidv1 } from "uuid";

const UtilService = {
  uuidv4: function () {
    return uuidv4();
  },
  uuidv1: function () {
    return uuidv1();
  },
};

export default UtilService;
