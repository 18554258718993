/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  CardBody,
  Form,
} from "reactstrap";
import StudentOnlineExaminationService from "../../../../services/studentOnlineExaminationService";
import _ from "lodash";
import UtilService from "../../../../services/utilService";
import ReactTimer from "@xendora/react-timer";
import moment from "moment";
import ExaminationResultService from "../../../../services/examinationResultService";

class StudentOnlineExaminationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onlineExaminationData: {},
      questionData: [],
      questionUID: null,
      questionObj: {
        answerDTOList: [],
      },
    };
    this.navigateQuestion = this.navigateQuestion.bind(this);
    this.selectAnswer = this.selectAnswer.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitExamination = this.submitExamination.bind(this);
  }

  componentDidMount() {
    const { uniqueId } = this.props.match.params;
    this.setState({ uniqueId: uniqueId });
    StudentOnlineExaminationService.fetch(uniqueId).then((response) => {
      const questionData = response.data.questionDTOList;
      this.setState({
        onlineExaminationData: response.data,
        questionData: questionData,
        questionUID: questionData[0].uniqueId,
        questionObj: questionData[0],
      });
      console.log(this.state);
    });
  }

  navigateQuestion(questionUID) {
    this.setState({
      questionUID: questionUID,
      questionObj: _.filter(this.state.questionData, [
        "uniqueId",
        questionUID,
      ])[0],
    });
  }

  selectAnswer(questionUID, answerUID) {
    let questionData = this.state.questionData;
    console.log(questionData);
    let questionObjIndex = _.findIndex(questionData, ["uniqueId", questionUID]);
    let questionObj = _.remove(questionData, ["uniqueId", questionUID])[0];
    questionObj.selectedAnswerUID = answerUID;
    questionObj.questionNavBtnColor = "primary";
    questionData.splice(questionObjIndex, 0, questionObj);
    this.setState({ questionData: questionData });
    console.log(questionData);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.submitExamination();
  }

  submitExamination() {
    let questionAnswerData = {};
    _.forEach(this.state.questionData, function (questionObj) {
      questionAnswerData[questionObj.uniqueId] =
        questionObj.selectedAnswerUID || null;
    });
    let formData = {
      onlineExaminationUID: this.state.onlineExaminationData.uniqueId,
      questionAnswerData: questionAnswerData,
    };
    console.log(formData);
    ExaminationResultService.create(formData).then((response) => {
      console.log(response.data);
      if (response.responseStatus === "SUCCESS") {
        this.props.history.push(
          `/student/onlineExamination/finish/${formData.onlineExaminationUID}`
        );
      } else {
        this.setState({ errorData: response.data });
        console.error("Error    ==>     " + response.message);
      }
    });
  }

  render() {
    let obj = this;
    return (
      <>
        <SimpleHeader
          name="List"
          parentName="Online Examination"
          heading="ClashMate"
        />
        <Container className="mt--6" fluid>
          <Form role="form" onSubmit={this.handleSubmit}>
            <Row>
              <Col xl="12">
                <Card>
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col-10">
                        <h3 className="mb-0">
                          {this.state.onlineExaminationData.examinationTitle} -{" "}
                          {this.state.onlineExaminationData.title}
                        </h3>
                      </div>
                      <div className="col-2">
                        <div className="text-right">
                          <Button color="primary" type="submit">
                            End Exam
                          </Button>
                        </div>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xl="6">
                        <div className="col">
                          <div>
                            Total Question:{" "}
                            <span className="h3">
                              {this.state.onlineExaminationData.questionCount}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col xl="6">
                        <div className="col">
                          <div className="text-right">
                            {obj.state.onlineExaminationData.duration && (
                              <ReactTimer
                                start={
                                  obj.state.onlineExaminationData.duration * 60
                                }
                                end={(value) => value < 1}
                                onEnd={(value) => {
                                  console.log("ENDED WITH VALUE", value);
                                  obj.submitExamination();
                                }}
                                onTick={(value) => value - 1}
                              >
                                {(time) => (
                                  <div className="h3">
                                    {moment
                                      .utc(
                                        moment
                                          .duration(time, "seconds")
                                          .as("milliseconds")
                                      )
                                      .format("HH:mm:ss")}
                                  </div>
                                )}
                              </ReactTimer>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="8">
                <Card>
                  <CardBody>
                    <div className="col">
                      <div>Question</div>
                      <Row>
                        <Col md="12">
                          <h4>{obj.state.questionObj.questionText}</h4>
                        </Col>
                      </Row>
                      <Row>
                        {obj.state.questionObj.answerDTOList.map(function (
                          value,
                          index
                        ) {
                          return (
                            <Col md="6" key={UtilService.uuidv4()}>
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  className="custom-control-input"
                                  id={`answerText` + (index + 1)}
                                  name="answerText"
                                  type="radio"
                                  checked={
                                    obj.state.questionObj.selectedAnswerUID &&
                                    obj.state.questionObj.selectedAnswerUID ===
                                      value.uniqueId
                                  }
                                  onChange={(e) => {
                                    e.preventDefault();
                                    obj.selectAnswer(
                                      `${obj.state.questionUID}`,
                                      `${value.uniqueId}`
                                    );
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`answerText` + (index + 1)}
                                >
                                  {value.answerText}
                                </label>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4">
                <Card>
                  <CardBody>
                    <div className="col">
                      <div>Question Navigation</div>
                      <div className="row">
                        {this.state.questionData.map(function (value, index) {
                          return (
                            <div className="col-2" key={UtilService.uuidv4()}>
                              <Button
                                className="btn-sm"
                                color={value.questionNavBtnColor || "secondary"}
                                data-questionuid={value.uniqueId}
                                onClick={(e) => {
                                  e.preventDefault();
                                  obj.navigateQuestion(`${value.uniqueId}`);
                                }}
                              >
                                {index + 1}
                              </Button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </>
    );
  }
}

export default StudentOnlineExaminationPage;
