/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import _ from "lodash";
import {Col, Container, Row,} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import AuthService from "../../../../services/authService";
import Moment from "react-moment";
import DocumentService from "../../../../services/documentService";
import NoticeService from "../../../../services/noticeBoardService";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import CardTitle from "reactstrap/es/CardTitle";
import CardText from "reactstrap/es/CardText";
import Badge from "reactstrap/es/Badge";

class Notices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            documentList: []
        };
        this.delete = this.delete.bind(this);
        this.notify = this.notify.bind(this);
    }

    componentDidMount() {
        this.initNotices();
    }

    initNotices = () => {
        NoticeService.list().then((response) => {
            console.log(response);
            this.setState({list: response.data});
            let documentList = [];

            _.forEach(this.state.list, (post) => {
                documentList.push(...post.documentList);
            });
            this.setState({documentList: documentList})
        });
    }

    formatNoticeText = (text) => {
        if (!_.isEmpty(text)) {
            if (text.length >= 200) {
                return text.substring(0, 200) + "..."
            } else {
                return text;
            }
        }
    }

    async delete(uniqueId) {
        let self = this;
        await NoticeService.delete(uniqueId).then((response) => {
            console.log(response);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            let noticesList = _.filter(self.state.list, notice => notice.uniqueId === uniqueId);
            self.setState({list: noticesList});
        });
        this.initNotices();
    }

    download = (blob, doc) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', doc.originalFileName); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    downloadDocument = async (uniqueId) => {
        let doc = _.find([...this.state.documentList], (value) => value.uniqueId === uniqueId)
        DocumentService.fetch(uniqueId).then(response => {
            // console.log(response)
            this.download(response, doc);
        })
    };

    showEditAndDelete = (value) => {
        let obj = this;
        if (value.createdByUID === value.currentUserUid) {
            return (
                <>
                    <a href="javascript:void(0);" onClick={() => {
                        obj.props.history.push(`/${AuthService.getBaseURLByRole()}/notices/edit/${value.uniqueId}`)
                    }}
                       className="badge badge-pill badge-default">Edit
                    </a>
                    <a href="javascript:void(0);" onClick={() => {
                        obj.delete(value.uniqueId)
                    }}
                       className="badge badge-pill badge-default">Delete
                    </a>
                </>
            );
        } else {

        }
    };

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    render() {
        let obj = this;
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <SimpleHeader
                    name="Notices"
                    parentName="Notices"
                    heading="ClashMate"
                    newBtnUrl={`/${AuthService.getBaseURLByRole()}/notice/create/`}
                    newBtnText="Create Notice"
                />
                <Container className="mt--6" fluid>
                    <div className="card-columns">
                        {this.state.list.map(function (value, index) {
                            return (<Card>
                                <CardBody>
                                    <CardTitle className="h3">
                                        <a href="javascript:void(0)" onClick={() => {
                                            obj.props.history.push(`/${AuthService.getBaseURLByRole()}/notices/show/${value.uniqueId}`)
                                        }}>{value.title}</a>
                                        <p>
                                        <span
                                            className="badge badge-pill badge-default">{value.course ? value.course.title : ''}</span>
                                            <span
                                                className="badge badge-pill badge-primary">{value.section ? value.section.title : ''}</span>
                                            <span
                                                className="badge badge-pill badge-secondary">{value.subject ? value.subject.title : ''}</span>
                                            <span
                                                className="badge badge-pill badge-warning">{value.course === null && value.section === null && value.subject === null ? "All" : ''}</span>

                                        </p>
                                    </CardTitle>
                                    <CardText>
                                        {obj.formatNoticeText(value.text)}
                                    </CardText>
                                    <CardText>
                                        <Row>
                                            <Col md="8">
                                                <small className="text-muted">{value.createdBy.fullName}</small>
                                            </Col>
                                            <Col md="4">
                                                <small className="text-muted fa-pull-right">
                                                    <Moment fromNow
                                                            ago>{value.lastUpdated}</Moment> ago.
                                                </small>
                                            </Col>
                                            <Col md="12">
                                                <div className="fa-pull-right">
                                                    <Badge
                                                        color="primary">{value.documentList.length} attachment(s)</Badge>
                                                    {obj.showEditAndDelete(value)}
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>);
                        })}
                    </div>
                </Container>
            </>
        );
    }
}

export default Notices;
