import React from "react";
import { Col, Row } from "reactstrap";
import FormInput from "../../../components/common/form/FormInput";

class OnlineExaminationForm extends React.Component {
  showField() {
    return this.props.isEdit === true ? "hidden" : "";
  }

  render() {
    return (
      <>
        <Row>
          <Col md="12">
            <FormInput
              name="title"
              className="form-control-label"
              id="title"
              placeholder="inputPlaceHolder.onlineExamination.title"
              type="text"
              onChange={this.props.onChange}
              label="inputLabel.onlineExamination.title"
              value={this.props.valueObj.title || ""}
              errorMsg={this.props.errorObj.title || ""}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default OnlineExaminationForm;
