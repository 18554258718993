import React from "react";
import {Col, Row} from "reactstrap";
import FormInput from "../../../components/common/form/FormInput";

class ChangePasswordForm extends React.Component {
    showField() {
        return this.props.isEdit === true ? "hidden" : "";
    }

    render() {
        return (
            <>
                <Row>
                    <Col md="4" sm="6">
                        <FormInput
                            name="currentPassword"
                            className="form-control-label"
                            id="password"
                            placeholder="inputPlaceHolder.settings.currentPassword"
                            type="password"
                            onChange={this.props.onChange}
                            label="inputLabel.settings.currentPassword"
                            errorMsg={this.props.errorObj.currentPassword || ""}
                        />
                    </Col>
                    <Col md="4" sm="6">
                        <FormInput
                            name="password"
                            className="form-control-label"
                            id="password"
                            placeholder="inputPlaceHolder.settings.password"
                            type="password"
                            onChange={this.props.onChange}
                            label="inputLabel.settings.password"
                            errorMsg={this.props.errorObj.password || ""}
                        />
                    </Col>
                    <Col md="4" sm="6" className={this.showField()}>
                        <FormInput
                            name="confirmPassword"
                            className="form-control-label"
                            id="confirmPassword"
                            placeholder="inputPlaceHolder.settings.confirmPassword"
                            type="password"
                            onChange={this.props.onChange}
                            label="inputLabel.settings.confirmPassword"
                            errorMsg={this.props.errorObj.confirmPassword || ""}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

export default ChangePasswordForm;
