import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormFeedback, FormGroup, Input } from "reactstrap";

const FormInputSelect = ({
	name,
	id,
	onChange,
	className,
	value,
	label,
	errorMsg,
	optionList,
	optionKey,
	optionValue,
	inputClassName,
	isLabelShown,
	isErrorMsgShown,
	isNakedSelectInput,
	required,
	...props
}) => {
	const { t } = useTranslation();
	let renderOptionList = (optionList, optionKey, optionValue) => {
		if (!_.isUndefined(optionList)) {
			return optionList.map(function (value, index) {
				return (
					<option value={_.get(value, optionKey)} key={_.get(value, optionKey)}>
						{_.get(value, optionValue)}
					</option>
				);
			});
		}
	};
	return isNakedSelectInput ? (
		<Input
			id={id}
			type="select"
			name={name}
			onChange={onChange}
			value={value}
			invalid={!!errorMsg}
			className={inputClassName}
		>
			<option key="" value="">
				--- Select a value ---
			</option>
			{renderOptionList(optionList, optionKey, optionValue)}
		</Input>
	) : (
		<FormGroup>
			{!isLabelShown || (
				<label className={className} htmlFor={name}>
					{t(label)} {required && <sup style={{color: "red"}}>*</sup>}
				</label>
			)}
			<Input
				id={id}
				type="select"
				name={name}
				onChange={onChange}
				value={value}
				invalid={!!errorMsg}
				className={inputClassName}
			>
				<option key="" value="">
					--- Select a value ---
				</option>
				{renderOptionList(optionList, optionKey, optionValue)}
			</Input>
			{!isErrorMsgShown || <FormFeedback>{t(errorMsg)}</FormFeedback>}
		</FormGroup>
	);
};

FormInputSelect.defaultProps = {
	className: "form-control-label",
	inputClassName: "form-control",
	isLabelShown: true,
	isErrorMsgShown: true,
	isNakedSelectInput: false,
};

FormInputSelect.propTypes = {
	name: PropTypes.string.isRequired,
	id: PropTypes.string,
	errorMsg: PropTypes.string,
	className: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func.isRequired,
	optionList: PropTypes.array.isRequired,
	label: PropTypes.string,
	optionKey: PropTypes.string.isRequired,
	optionValue: PropTypes.string.isRequired,
};

export default FormInputSelect;
