/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Button, Card, CardBody, CardHeader, Col, Container, Row,} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";
import SummaryService from "../../../services/summaryService";
import _ from "lodash";
import routeVisibleForRole from "../../../util/routeVisibleForRole";
import SummaryDetails from "./Details";
import FormInput from "../../../components/common/form/FormInput";
import moment from "moment";

class SummaryList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            alert: null,
            filteredList: [],
            filterDateFrom: "",
            filterDateTo: "",
            filterCompany: "",
            filterSharedWith: "",
        };
        this.delete = this.delete.bind(this);
        this.notify = this.notify.bind(this);
    }

    componentDidMount() {
        console.log(this.props)
        this.populateSummaries();
    };

    onChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }

    applyFilter = () => {
      this.setState({filteredList:this.state.list})
      const { list, filterDateFrom, filterDateTo, filterCompany, filterSharedWith } = this.state;
      console.log("State - ", this.state)

      const filteredList = _.filter(list, (summary) => {
        // Filter by dateCreated
        const dateFromMatches = filterDateFrom
            ? moment(summary.dateCreated).isSameOrAfter(moment(filterDateFrom), 'day')
            : true;
        const dateToMatches = filterDateTo
            ? moment(summary.dateCreated).isSameOrBefore(moment(filterDateTo), 'day')
            : true;

        // Filter by company.legalName
        const companyMatches = filterCompany
            ? _.includes(_.toLower(summary.company.legalName), _.toLower(filterCompany))
            : true;

        // Filter by sharedWith fullName
        const sharedWithMatches = filterSharedWith
            ? _.some(summary.sharedWith, (sharedPerson) =>
                _.includes(_.toLower(sharedPerson.fullName), _.toLower(filterSharedWith))
            )
            : true;

        return dateFromMatches && dateToMatches && companyMatches && sharedWithMatches;
      });

      this.setState({ filteredList });
    }

    clearFilter = () => {
        this.setState({
            filteredList: this.state.list,
            filterDateTo: "",
            filterDateFrom: "",
            filterCompany: "",
            filterSharedWith: ""
        })
    }

    populateSummaries = () => {
        SummaryService.list().then((response) => {
            console.log(response);
            this.setState({list: response, filteredList: response});
            if (routeVisibleForRole(["ROLE_LEVEL1", "ROLE_LEVEL2", "ROLE_LEVEL3"])) {
                let currentUserId = localStorage.getItem('userId')
                console.log(currentUserId)
                let filteredList = this.filterSummariesByUserId(response, currentUserId)
                this.setState({list: filteredList, filteredList: filteredList})
                console.log(this.state.list)
                console.log(this.state.filteredList)
            }
            const {companyUid} = this.props.match.params;
            if (companyUid !== undefined) {
                console.log(`CompanyUid : ${companyUid}`)
                let filteredList = this.filterSummariesByCompany(response, companyUid)
                this.setState({list: filteredList, filteredList: filteredList})
                console.log(this.state.list)
            }
        });
    }

    filterSummariesByUserId = (data, userId) => {
        console.log(data)
        return _.filter(data, summary =>
            _.some(summary.sharedWith, user => user.uniqueId === userId)
        );
    };
    filterSummariesByCompany = (data, companyUid) => {
        console.log(`CompanyUid : ${companyUid}`)
        return _.filter(data, summary => summary.companyUid === companyUid);
    };

    hideDeleteAlert = () => {
        this.setState({
            alert: null
        });
    };

    confirmDeleteAlert = (uniqueId) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{display: "block"}}
                    title="Are you sure?"
                    onConfirm={() => this.delete(uniqueId)}
                    onCancel={this.hideDeleteAlert}
                    showCancel
                    confirmBtnBsStyle="danger"
                    confirmBtnText="Yes, delete it!"
                    cancelBtnBsStyle="secondary"
                    cancelBtnText="Cancel"
                    btnSize=""
                >
                    You won't be able to revert this!
                </ReactBSAlert>
            )
        });
    };

    delete(uniqueId) {
        this.setState({alert: null})
        SummaryService.delete(uniqueId).then((response) => {
            console.log(response);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            //TODO: TO be fixed with redux implementation
            //   this.props.history.push("/admin/dashboard");
            this.populateSummaries();
        });
    }

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    render() {
        let obj = this;
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <SimpleHeader
                    name="List"
                    parentName="Summary"
                    heading="XareGrwoth"
                    newBtnUrl="/admin/meetingReport/list"
                    newBtnText="List All Meeting Reports"
                />
                {this.state.alert}
                <Container className="" fluid>
                    <Card>
                        <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h1 className="mb-0">Summaries</h1>
                                </div>
                            </Row>
                            <Row>
                                <p style={{ fontStyle: "italic", color: "gray" }}>
                                    Use the filters below to narrow down your search results.
                                </p>
                            </Row>
                            <Row>
                                <Col md="2">
                                    <FormInput
                                        name="filterDateFrom"
                                        className="form-control-label"
                                        id="date"
                                        placeholder="inputPlaceHolder.meetingReport.date"
                                        type="date"
                                        onChange={this.onChange}
                                        value={this.state.filterDateFrom || ""}
                                        label="Date From"
                                    />
                                </Col>
                                <Col md="2">
                                    <FormInput
                                        name="filterDateTo"
                                        className="form-control-label"
                                        id="date"
                                        placeholder="inputPlaceHolder.meetingReport.date"
                                        type="date"
                                        onChange={this.onChange}
                                        value={this.state.filterDateTo || ""}
                                        label="Date To"
                                    />
                                </Col>
                                <Col md="2">
                                    <FormInput
                                        name="filterCompany"
                                        className="form-control-label"
                                        id="projectName"
                                        placeholder="Company"
                                        type="text"
                                        onChange={this.onChange}
                                        label="Company"
                                        value={this.state?.filterCompany || ""}
                                        // errorMsg={this.props.errorObj?.name || ""}
                                    />
                                </Col>
                                <Col md="2">
                                    <FormInput
                                        name="filterSharedWith"
                                        className="form-control-label"
                                        id="sharedWith"
                                        placeholder="Shared With"
                                        type="text"
                                        onChange={this.onChange}
                                        label="Shared With"
                                        value={this.state?.filterSharedWith || ""}
                                        // errorMsg={this.props.errorObj?.name || ""}
                                    />
                                </Col>
                                <Col sm={3} style={{marginTop: "30px"}}>
                                    <Button size="md" outline color="default" type="button"
                                            onClick={this.applyFilter}>
                                        Apply Filter
                                    </Button>
                                    <Button color="default" size="md" outline type="button"
                                            onClick={this.clearFilter}>
                                        Clear Filter
                                    </Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="12">
                                    {this.state.filteredList?.map((summary, index) => (
                                        <div key={index}>
                                            <SummaryDetails summary={summary} index={index + 1}
                                            />
                                        </div>
                                    ))}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}

export default SummaryList;
