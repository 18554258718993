/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import QuestionService from "../../../services/questionService";

class QuestionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questionData: {
        answerDTOList: [],
      },
    };
  }

  componentDidMount() {
    const { uniqueId } = this.props.match.params;
    QuestionService.fetch(uniqueId).then((response) => {
      console.log(response.data);
      this.setState({ questionData: response.data });
    });
  }

  render() {
    const { questionData } = this.state;
    return (
      <>
        <SimpleHeader
          name="Details"
          parentName="Question"
          heading="ClashMate"
          newBtnUrl="/teacher/question/create"
          newBtnText="Create Question"
        />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <h3 className="mb-0">{questionData.courseSectionSubjectTitle}</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="12">
                  <h4>{questionData.questionText}</h4>
                </Col>
              </Row>
              <Row>
                {questionData.answerDTOList.map(function (value, index) {
                  return (
                    <Col md="6">
                      <p>{value.answerText}</p>
                    </Col>
                  );
                })}
              </Row>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

export default QuestionDetails;
