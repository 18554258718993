/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/pages/dashboards/ParentDashboard.jsx";
import AttendanceParentList from "../views/pages/attendance/AttendanceParentList";
import LedgerListParent from "../views/pages/finance/parent/LedgerListParent";
import LedgerDetailsParent from "../views/pages/finance/parent/LedgerDetailsParent";
import ParentProfile from "../views/pages/profile/parent/ParentProfile";
import ParentSettings from "../views/pages/settings/ParentSettings";
import Notices from "../views/pages/social/noticeboard/Notices";
import NoticeDetails from "../views/pages/social/noticeboard/NoticeDetails";
import UserNotificationQueueList from "../views/pages/notification/queue/user/List";
import UserNotificationQueueDetails from "../views/pages/notification/queue/user/Details";

const parentRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "ni ni-shop text-primary",
        component: Dashboard,
        layout: "/parent",
    },
    {
        path: "/attendance",
        name: "Attendance",
        icon: "ni ni-shop text-primary",
        component: AttendanceParentList,
        layout: "/parent",
        invisible: false,
    },
    {
        path: "/ledger/list",
        name: "Ledger",
        icon: "ni ni-shop text-primary",
        component: LedgerListParent,
        layout: "/parent",
        invisible: false,
    },
    {
        path: "/ledger/show/:uniqueId",
        name: "Ledger",
        icon: "ni ni-shop text-primary",
        component: LedgerDetailsParent,
        layout: "/parent",
        invisible: true,
    },
    {
        path: "/profile",
        name: "Profile",
        icon: "ni ni-shop text-primary",
        component: ParentProfile,
        layout: "/parent",
        invisible: true,
    },
    {
        path: "/settings",
        name: "Profile",
        icon: "ni ni-shop text-primary",
        component: ParentSettings,
        layout: "/parent",
        invisible: true,
    },
    {
        path: "/notice/",
        name: "Notices",
        icon: "ni ni-shop text-primary",
        component: Notices,
        layout: "/parent",
        invisible: false,
    },
    {
        path: "/notices/show/:uniqueId",
        name: "View notice",
        icon: "ni ni-shop text-primary",
        component: NoticeDetails,
        layout: "/parent",
        invisible: true,
    },
    {
        path: "/user/notificationQueue/list",
        name: "Notification Queue",
        icon: "ni ni-shop text-primary",
        component: UserNotificationQueueList,
        layout: "/parent",
    },
    {
        path: "/user/notificationQueue/show/:uniqueId",
        name: "Notification Queue",
        icon: "ni ni-shop text-primary",
        component: UserNotificationQueueDetails,
        layout: "/parent",
        invisible: true,
    },
];

export default parentRoutes;
