import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  Card,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import LeadService from "../../../../services/leadService";
import LedgerService from "../../../../services/ledgerService";
import _ from "lodash";
import { ledgerType } from "../../../../util/ledgerType";

class LedgerListParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayList: [],
      alert: null,
      list: [],
      selectedFilter: {},
      salary: [],
      fees: [],
      invoice: [],
      paid: [],
      unpaid: [],
    };
    this.delete = this.delete.bind(this);
    this.notify = this.notify.bind(this);
  }

  componentDidMount() {
    LedgerService.list().then((response) => {
      this.setState({ list: response });
      this.setState({ displayList: response });
      let paid = _.filter(
        this.state.list,
        (ledger) => ledger.paymentStatus === "PAID"
      );
      let unpaid = _.filter(
        this.state.list,
        (ledger) => ledger.paymentStatus === "UN_PAID"
      );
      this.setState({ paid: paid });
      this.setState({ unpaid: unpaid });
    });
  }

  delete(uniqueId) {
    this.setState({ alert: null });
    LedgerService.delete(uniqueId).then((response) => {
      console.log(response);
      this.notify(
        response.responseStatus.toLowerCase(),
        response.responseStatus,
        response.message
      );
      //TODO: TO be fixed with redux implementation
      //   this.props.history.push("/admin/dashboard");
      LedgerService.list().then((responseList) => {
        this.setState({ list: responseList });
        if (this.state.selectedFilter in ledgerType) {
          let ledgers = _.filter(
            this.state.list,
            (ledger) => ledger.type === this.state.selectedFilter
          );
          this.setState({ displayList: ledgers });
        } else {
          this.setState({ displayList: response });
        }
        let paid = _.filter(
          this.state.list,
          (ledger) => ledger.paymentStatus === "PAID"
        );
        let unpaid = _.filter(
          this.state.list,
          (ledger) => ledger.paymentStatus === "UN_PAID"
        );
        this.setState({ paid: paid });
        this.setState({ unpaid: unpaid });
      });
    });
  }

  close(uniqueId) {
    LeadService.close(uniqueId).then((response) => {
      console.log(response);
      this.notify(
        response.responseStatus.toLowerCase(),
        response.responseStatus,
        response.message
      );
    });
  }

  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  onSelectChange = (valueObj) => {
    let filter = valueObj.value;
    this.setState({ selectedFilter: filter });
    let ledgers = _.filter(this.state.list, (ledger) => ledger.type === filter);
    this.setState({ displayList: ledgers });
  };

  hideDeleteAlert = () => {
    this.setState({
      alert: null,
    });
  };

  confirmDeleteAlert = (uniqueId) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => this.delete(uniqueId)}
          onCancel={this.hideDeleteAlert}
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="secondary"
          cancelBtnText="Cancel"
          btnSize=""
        >
          You won't be able to revert this!
        </ReactBSAlert>
      ),
    });
  };

  render() {
    let obj = this;
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        {/*<LedgerStats salary={this.state.salary.length} fees={this.state.fees.length}
                             invoice={this.state.invoice.length}
                             paid={this.state.paid.length} unpaid={this.state.unpaid.length}/>*/}
        {this.state.alert}
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col md="6">
                      <div className="h3">
                        <span className="mb-0">Ledger List</span>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        #
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Date Created
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Payer
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Payee
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Amount
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Type
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Due Date
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Payment Status
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {this.state.displayList.map(function (value, index) {
                      return (
                        <tr key={index}>
                          <td className="budget">{index + 1}</td>
                          <td className="budget">{value.dateCreated}</td>
                          <td className="budget">{value.payer}</td>
                          <td className="budget">{value.payee}</td>
                          <td className="budget">₹{value.amount}</td>
                          <td className="budget">{value.type}</td>
                          <td className="budget">{value.dueDate}</td>
                          <td className="budget">{value.paymentStatus}</td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color=""
                                size="sm"
                                className="btn-icon-only text-light"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    obj.props.history.push(
                                      `/parent/ledger/show/${value.uniqueId}`
                                    );
                                  }}
                                >
                                  Details
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default LedgerListParent;
