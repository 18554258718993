import axiosInstance from "../api/api";

const QuestionService = {
  list: function () {
    return axiosInstance({
      url: "/api/question/",
      method: "GET",
    });
  },
  fetch: function (uniqueId) {
    return axiosInstance({
      url: `/api/question/${uniqueId}`,
      method: "GET",
    });
  },
  delete: function (uniqueId) {
    return axiosInstance({
      url: `/api/question/${uniqueId}`,
      method: "DELETE",
    });
  },
  create: function (formData) {
    return axiosInstance({
      url: `/api/question/`,
      method: "POST",
      data: formData,
    });
  },
  update: function (formData) {
    return axiosInstance({
      url: `/api/question/`,
      method: "PATCH",
      data: formData,
    });
  },
  listByCourseSectionSubject: function (courseSectionSubjectUID) {
    return axiosInstance({
      url: `/api/question/courseSectionSubject/${courseSectionSubjectUID}`,
      method: "GET",
    });
  },
  listByExamination: function (examinationUID) {
    return axiosInstance({
      url: `/api/question/examination/${examinationUID}`,
      method: "GET",
    });
  },
};
export default QuestionService;
