import axiosInstance from "../api/api";

const LessonPlanService = {
  list: function () {
    return axiosInstance({
      url: "/api/lessonPlan/",
      method: "GET",
    });
  },
  fetch: function (uniqueId) {
    return axiosInstance({
      url: `/api/lessonPlan/${uniqueId}`,
      method: "GET",
    });
  },
  delete: function (uniqueId) {
    return axiosInstance({
      url: `/api/lessonPlan/${uniqueId}`,
      method: "DELETE",
    });
  },
  create: function (formData) {
    return axiosInstance({
      url: `/api/lessonPlan/`,
      method: "POST",
      data: formData,
    });
  },
  update: function (formData) {
    return axiosInstance({
      url: `/api/lessonPlan/`,
      method: "PATCH",
      data: formData,
    });
  },
};
export default LessonPlanService;
