import types from "./actionTypes";

export const addUserInfo = userInfo => ({
  type: types.ADD_USER_INFO,
  payload: userInfo
});

export const deleteUserInfo = userInfo => ({
  type: types.DELETE_USER_INFO,
  payload: userInfo
});
