/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import React from "react";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Form, Row, Table,} from "reactstrap";
import CourseSectionSubjectService from "../../../services/courseSectionSubjectService";
import _ from "lodash";
import FormReactSelect from "../../../components/common/form/FormReactSelect";
import CourseSectionService from "../../../services/courseSectionService";
import UtilService from "../../../services/utilService";
import CourseSectionSubjectStudentService from "../../../services/courseSectionSubjectStudentService";
import AttendanceService from "../../../services/attendanceService";

class AttendanceCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSection: {},
            selectedCourse: {},
            selectedSubject: {},
            errorData: {},
            courseSectionSubjectList: [],
            displayList: [],
            courseSectionList: [],
            mappedCoursesList: [],
            mappedSectionsList: [],
            mappedSubjectsList: [],
            mappedStudentsList: [],
            sectionSelectUUID: UtilService.uuidv4(),
            subjectSelectUUID: UtilService.uuidv4(),
        };
        this.handleCourseChange = this.handleCourseChange.bind(this);
        this.handleSectionChange = this.handleSectionChange.bind(this);
    }

    onChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }

    handleSectionChange = (value) => {
        this.setState({subjectSelectUUID: UtilService.uuidv4()});
        this.setState({selectedSubject: {}});
        if (_.isNull(value)) {
            this.setState({selectedSection: {}});
        } else {
            this.setState({selectedSection: value});
        }
        this.setState({displayList: []});
        this.populateSubjects(this.state.selectedCourse, value);
    }

    handleCourseChange = (value) => {
        this.setState({sectionSelectUUID: UtilService.uuidv4()});
        this.setState({subjectSelectUUID: UtilService.uuidv4()});
        if (_.isNull(value)) {
            this.setState({selectedCourse: {}});
        } else {
            this.setState({selectedCourse: value});
        }
        this.setState({selectedSection: {}});
        this.setState({selectedSubject: {}});
        this.setState({mappedSectionsList: []});
        this.setState({displayList: []});
        this.populateSections(value);
    }

    handleSubjectChange = (value) => {
        if (_.isNull(value)) {
            this.setState({selectedSubject: {}});
        } else {
            this.setState({selectedSubject: value});
        }
    };

    populateSections = (course) => {
        let sectionsList = _.filter(
            this.state.courseSectionList,
            (courseSection) => {
                return courseSection.course.uniqueId === course.uniqueId;
            }
        );
        let mappedSectionsList = _.uniqBy(_.map(sectionsList, "section"), "title");
        this.setState({
            mappedSectionsList: mappedSectionsList,
        });
    };

    populateSubjects = (course, section) => {
        let mappedCourseSectionSubjects = _.filter(
            this.state.courseSectionSubjectList,
            (courseSectionSubject) => {
                return (
                    courseSectionSubject.course.uniqueId === course.uniqueId &&
                    courseSectionSubject.section.uniqueId === section.uniqueId
                );
            }
        );

        let mappedSubjectsList = _.map(mappedCourseSectionSubjects, "subject");
        this.setState({mappedSubjectsList: mappedSubjectsList});
    };

    populateCourseSectionSubjects = async () => {
        await CourseSectionSubjectService.fetchCourseSectionSubjects().then(
            (res) => {
                this.setState({courseSectionSubjectList: res.data});
            }
        );
    };

    populateCourseSections = async () => {
        await CourseSectionService.fetchCourseSections().then((res) => {
            this.setState({courseSectionList: res.data});
            let mappedCoursesList = _.uniqBy(
                _.map(this.state.courseSectionList, "course"),
                "title"
            );
            this.setState({
                mappedCoursesList: mappedCoursesList,
            });
        });
    };

    populateStudents = async () => {
        let allOptionsNotSelected = _.isEmpty(this.state.selectedCourse) || _.isEmpty(this.state.selectedSection) || _.isEmpty(this.state.selectedSubject)
        if (allOptionsNotSelected === true) {
            console.error("All options are not selected!")
        } else {
            let courseSectionSubject = _.find(this.state.courseSectionSubjectList, (courseSectionSubject) => {
                return courseSectionSubject.course.uniqueId === this.state.selectedCourse.uniqueId &&
                    courseSectionSubject.section.uniqueId === this.state.selectedSection.uniqueId &&
                    courseSectionSubject.subject.uniqueId === this.state.selectedSubject.uniqueId;
            });
            await CourseSectionSubjectStudentService.fetchStudents(courseSectionSubject.uniqueId).then(response => {
                this.setState({mappedStudentsList: response.data});
            });
            let studentList = _.map(this.state.mappedStudentsList, "student");
            let displayList = studentList.map(student => ({...student, isPresent: true}));
            this.setState({displayList: displayList});
        }
    };

    componentDidMount() {
        this.populateCourseSectionSubjects();
        this.populateCourseSections();
    };

    handleToggleButtonChange = (uniqueId) => {
        let student = _.find(this.state.displayList, student => {
            return student.uniqueId === uniqueId;
        })
        student.isPresent = !student.isPresent;
    };

    submitAttendance = () => {
        let presentStudents = _.filter(this.state.displayList, student => {
            return student.isPresent === true;
        });
        let studentUidList = _.map(
            presentStudents,
            (student) => student.uniqueId
        );
        console.log(presentStudents);
        console.log(studentUidList);
        let formData = {
            courseUid: this.state.selectedCourse.uniqueId,
            sectionUid: this.state.selectedSection.uniqueId,
            subjectUid: this.state.selectedSubject.uniqueId,
            studentUid: studentUidList
        };
        AttendanceService.create(formData).then(response => {
            if (response.responseStatus === "SUCCESS") {
                alert(response.responseStatus);
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    };

    displayDetails = () => {
        if (this.state.displayList.length === 0) {
            return (
                <>
                    <CardBody>
                        <Alert color="warning">
                            <strong>There is no data to display!</strong> Please select
                            course, section and subject from the dropdown!
                        </Alert>
                    </CardBody>
                </>
            );
        } else {
            let self = this;
            return (
                <>
                    <CardBody>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                            <tr>
                                <th className="sort" data-sort="name" scope="col">
                                    #
                                </th>
                                <th className="sort" data-sort="budget" scope="col">
                                    Date Created
                                </th>
                                <th className="sort" data-sort="status" scope="col">
                                    Full Name
                                </th>
                                <th className="sort" data-sort="status" scope="col">
                                    Email
                                </th>
                                <th className="sort" data-sort="status" scope="col">
                                    Date of Birth
                                </th>
                                <th className="sort" data-sort="status" scope="col">
                                    Gender
                                </th>
                                <th className="sort" data-sort="status" scope="col">
                                    Present
                                </th>
                            </tr>
                            </thead>
                            <tbody className="list">
                            {this.state.displayList.map(function (value, index) {
                                return (
                                    <tr key={index}>
                                        <td className="budget">{index + 1}</td>
                                        <td className="budget">{value.dateCreated}</td>
                                        <td className="budget">{value.fullName}</td>
                                        <td className="budget">{value.emailAddress}</td>
                                        <td className="budget">{value.dateOfBirth}</td>
                                        <td className="budget">{value.gender}</td>
                                        <td className="budget"><label className="custom-toggle mr-1">
                                            <input defaultChecked type="checkbox"/>
                                            <span onClick={() => self.handleToggleButtonChange(value.uniqueId)}
                                                  className="custom-toggle-slider rounded-circle"
                                                  data-label-off="No"
                                                  data-label-on="Yes"
                                            />
                                        </label></td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    </CardBody>
                    <Row>
                        <Col sm="12">
                            <div className="text-center">
                                <Button color="primary" type="button" onClick={() => self.submitAttendance()}>
                                    Submit Attendance
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </>
            );
        }
    };

    render() {
        return (
            <>
                <SimpleHeader
                    parentName="Attendance"
                    name="Assign"
                    sectionName="Attendance"
                    heading="ClashMate"
                    // newBtnText={"Assign Students"}
                    // newBtnUrl={"/admin/courseSectionSubjectStudent/assign"}
                />
                <Container className="mt--6" fluid>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <Col sm="12">
                                            <h3 className="mb-0">Assign Attendance</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form role="form">
                                        <Row>
                                            <Col md="6">
                                                <FormReactSelect
                                                    isMulti={false}
                                                    className="form-control-label"
                                                    isSearchable={true}
                                                    name={"courseUid"}
                                                    placeHolder="inputPlaceHolder.timeTable.select.course"
                                                    onChange={this.handleCourseChange}
                                                    optionValue={"uniqueId"}
                                                    // value={this.state.selectedCourse}
                                                    optionList={this.state.mappedCoursesList}
                                                    optionLabel={"title"}
                                                    label="inputLabel.timeTable.select.course"
                                                    errorMsg={this.state.errorData.courseUid || ""}
                                                />
                                            </Col>
                                            <Col md="6">
                                                <FormReactSelect
                                                    selectKey={`section_select_${this.state.sectionSelectUUID}`}
                                                    isMulti={false}
                                                    className="form-control-label"
                                                    isSearchable={true}
                                                    label="inputLabel.timeTable.select.section"
                                                    name={"sectionUid"}
                                                    onChange={this.handleSectionChange}
                                                    optionValue={"uniqueId"}
                                                    optionList={this.state.mappedSectionsList}
                                                    optionLabel={"title"}
                                                    placeHolder="inputPlaceHolder.timeTable.select.section"
                                                    errorMsg={this.state.errorData.sectionUid || ""}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <FormReactSelect
                                                    selectKey={`subject_select_${this.state.subjectSelectUUID}`}
                                                    isMulti={false}
                                                    className="form-control-label"
                                                    isSearchable={true}
                                                    label="inputLabel.timeTable.select.subject"
                                                    name={"subjectUid"}
                                                    onChange={this.handleSubjectChange}
                                                    optionValue={"uniqueId"}
                                                    optionList={this.state.mappedSubjectsList}
                                                    optionLabel={"title"}
                                                    placeHolder="inputPlaceHolder.timeTable.select.subject"
                                                    errorMsg={this.state.errorData.subjectUid || ""}
                                                />
                                            </Col>
                                            <Col md="6">
                                            </Col>
                                        </Row>
                                        <div className="text-center">
                                            <Button className="my-4" color="info" type="button"
                                                    onClick={this.populateStudents}>
                                                Display Students
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <Row className="align-items-center">
                                        <Col sm="12">
                                            <h3 className="mb-0">Student List</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {this.displayDetails()}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    };
}

export default AttendanceCreate;
