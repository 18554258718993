import _ from "lodash";

const SecurityService = {
  hasRole: function (roleName) {
    const roleList = JSON.parse(localStorage.getItem("CLASHMATE_ROLE_LIST"));
    return _.includes(roleList, roleName);
  },
};

export default SecurityService;
