import {
    Card,
    CardHeader,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import ReactJson from "react-json-view";

const BulkUploadList = ({
                            bulkUploadList,
                            adminComponentObj,
                            cardTitle,
                            tenantUID,
                            userRole,
                            ...props
                        }) => {
    return (
        <Card>
            <CardHeader className="border-0">
                <Row className="align-items-center">
                    <div className="col-6">
                        <h3 className="mb-0">{cardTitle}</h3>
                    </div>
                </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                <tr>
                    <th className="sort" data-sort="name" scope="col">
                        #
                    </th>
                    <th className="sort" data-sort="name" scope="col">
                        File Name
                    </th>
                    <th className="sort" data-sort="budget" scope="col">
                        Date Created
                    </th>
                    <th className="sort" data-sort="status" scope="col">
                        Last Modified
                    </th>
                    <th className="sort" data-sort="status" scope="col">
                        Processing Status
                    </th>
                    <th className="sort" data-sort="status" scope="col">
                        Parsing Response
                    </th>
                    <th scope="col"/>
                </tr>
                </thead>
                <tbody className="list">
                {bulkUploadList.map(function (value, index) {
                    return (
                        <tr key={index}>
                            <td className="budget">{index + 1}</td>
                            <td className="budget">{value.document.fileName}</td>
                            <td className="budget">{value.dateCreated}</td>
                            <td className="budget">{value.lastUpdated}</td>
                            <td className="budget">{value.processingStatus}</td>
                            <td className="budget">{<ReactJson enableClipboard={true} collapsed={true}
                                                               src={JSON.parse(value.parsingResponse)}/>}</td>
                            <td className="text-right">
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        color=""
                                        size="sm"
                                        className="btn-icon-only text-light"
                                    >
                                        <i className="fas fa-ellipsis-v"/>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                        <DropdownItem
                                            onClick={(e) => {
                                                e.preventDefault();
                                                adminComponentObj.deleteBulkUpload(`${value.uniqueId}`);
                                            }}
                                        >
                                            Delete
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </Table>
        </Card>
    );
};

BulkUploadList.propTypes = {
    adminComponentObj: PropTypes.object.isRequired,
    cardTitle: PropTypes.string.isRequired,
};

export default BulkUploadList;
