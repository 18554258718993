import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// reactstrap components
import { Button, Col, Modal, Row } from "reactstrap";

class CreateExaminationInstructionModal extends React.Component {
  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.isOpen}
          toggle={this.props.toggleModal}
          size={this.props.size}
          onOpened={this.props.onModalOpened}
        >
          <div className="modal-header">
            <h5
              className="modal-title"
              id="createExaminationInstructionModalLabel"
            >
              Examination Instruction
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.props.toggleModal}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col md="12">
                <Editor
                  editorState={this.props.valueObj.editorState}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={
                    this.props.obj.handleExaminationInstructionChange
                  }
                />
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={this.props.obj.handleExaminationInstructionSubmit}
            >
              Save changes
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default CreateExaminationInstructionModal;
