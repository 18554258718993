import axiosInstance from "../api/api";

const CourseSectionService = {
  fetchCourseSections: function () {
    return axiosInstance({
      url: `/api/courseSection/`,
      method: "GET",
    });
  },
  fetchCourses: function (uniqueId) {
    return axiosInstance({
      url: `/api/courseSection/section/${uniqueId}`,
      method: "GET",
    });
  },
  fetchSections: function (uniqueId) {
    return axiosInstance({
      url: `/api/courseSection/course/${uniqueId}`,
      method: "GET",
    });
  },
  delete: function (formData) {
    return axiosInstance({
      url: `/api/courseSection/`,
      method: "DELETE",
      data: formData,
    });
  },
  create: function (formData) {
    return axiosInstance({
      url: `/api/courseSection/`,
      method: "POST",
      data: formData,
    });
  },
};
export default CourseSectionService;
