import _ from "lodash";
import React from "react";
// reactstrap components
import { Button, Col, CustomInput, Modal, Row } from "reactstrap";

class CreatePermissionModal extends React.Component {
  constructor(props) {
    super(props);
    this.isPermissionAssigned = this.isPermissionAssigned.bind(this);
  }

  isPermissionAssigned = (permissionUID) => {
    return _.includes(this.props.assignedPermissionUIDList, permissionUID);
  };

  render() {
    const obj = this;
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.isOpen}
          toggle={this.props.toggleModal}
          size={this.props.size}
          onOpened={this.props.onModalOpened}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="createPermissionModalLabel">
              Permission Configuration
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.props.toggleModal}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              {this.props.permissionList.map(function (value, index) {
                return (
                  <Col xl="4" key={index}>
                    {/* TODO: Updated code to show disabled checkbox as checked */}
                    <CustomInput
                      type="checkbox"
                      id={value.key}
                      label={value.value}
                      value={value.uniqueId}
                      onChange={obj.props.onCheckboxChange}
                      disabled={obj.isPermissionAssigned(value.uniqueId)}
                      defaultChecked={obj.isPermissionAssigned(value.uniqueId)}
                      htmlFor={value.key}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={this.props.toggleModal}
            >
              Close
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={this.props.onModalSubmit}
            >
              Save changes
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default CreatePermissionModal;
