/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import _ from "lodash";
import {Col, Container, Row,} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import PostService from "../../../../services/postService";
import AuthService from "../../../../services/authService";
import Moment from "react-moment";
import DocumentService from "../../../../services/documentService";
import CardText from "reactstrap/es/CardText";
import CardFooter from "reactstrap/es/CardFooter";
import {postActivityTypes} from "../../../../util/postActivityTypes";

class Feeds extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            documentList: []
        };
        this.delete = this.delete.bind(this);
        this.notify = this.notify.bind(this);
    }

    componentDidMount() {
        this.initPosts();
    }

    initPosts() {
        PostService.list().then((response) => {
            console.log(response);
            this.setState({list: response.data});
            let documentList = [];

            _.forEach(this.state.list, (post) => {
                documentList.push(...post.documentList);
            });
            this.setState({documentList: documentList})
        });
    }

    formatPostText = (text) => {
        if (!_.isEmpty(text)) {
            if (text.length >= 200) {
                return (<>
                    {text.substring(0, 200) + "..."}

                </>);
            } else {
                return text;
            }
        }
    };

    postActivityCounts = (postUID, postActivityType) => {
        let count = 0;
        let post = _.find(this.state.list, (post) => post.uniqueId === postUID);
        let viewedPostActivity = _.filter(post.postActivities, (postActivity) => postActivity.postActivityType === postActivityType);
        count = viewedPostActivity.length;
        return count;
    };


    delete(uniqueId) {
        PostService.delete(uniqueId).then((response) => {
            console.log(response);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            let list = _.filter(this.state.list, post => post.uniqueId !== uniqueId)
            this.setState({list: list});
            let documentList = [];

            _.forEach(this.state.list, (post) => {
                documentList.push(...post.documentList);
            });
            this.setState({documentList: documentList})
            //TODO: TO be fixed with redux implementation
            //   this.props.history.push("/admin/dashboard");
        });
        // this.initPosts()
    };

    download = (blob, doc) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', doc.originalFileName); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    downloadDocument = async (uniqueId) => {
        let doc = _.find([...this.state.documentList], (value) => value.uniqueId === uniqueId)
        DocumentService.fetch(uniqueId).then(response => {
            // console.log(response)
            this.download(response, doc);
        })
    };

    showEditAndDelete = (value) => {
        let obj = this;
        if (value.createdByUID === value.currentUserUid) {
            return (
                <>
                    <a href="javascript:void(0);" onClick={() => {
                        obj.props.history.push(`/${AuthService.getBaseURLByRole()}/post/edit/${value.uniqueId}`)
                    }}
                       className="badge badge-pill badge-default">Edit
                        Post
                    </a>
                    <a href="javascript:void(0);" onClick={() => {
                        obj.delete(value.uniqueId)
                    }}
                       className="badge badge-pill badge-default">Delete
                        Post
                    </a>
                </>
            );
        } else {

        }
    };

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    render() {
        let obj = this;
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <SimpleHeader
                    name="Feeds"
                    parentName="Feeds"
                    heading="ClashMate"
                    newBtnUrl={`/${AuthService.getBaseURLByRole()}/post/create`}
                    newBtnText="Create Post"
                />
                <Container className="mt--6" fluid>
                    {this.state.list.map(function (value, index) {
                        return (
                            <div className="card text-left">

                                <Row>
                                    <Col md="1"></Col>
                                    <Col md="10">
                                        <div className="card-body">
                                            <CardText className="mb-4">
                                                <p
                                                    className="card-text font-weight-bold">
                                                    <Row>
                                                        <Col
                                                            className="col-sm-8">
                                                            <a href="#!"
                                                               className="avatar avatar-xl rounded-circle">
                                                                <img alt="Image placeholder"
                                                                     src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?r=pg"/>
                                                            </a>{value.createdBy.fullName} <span
                                                            className="h5"><em>shared a post. </em></span>
                                                            <br/>
                                                            <small>
                                                                <Moment fromNow
                                                                        ago>{value.dateCreated}</Moment> ago.
                                                            </small>
                                                            &nbsp; <small>Visibility: </small>
                                                            <span
                                                                className="badge badge-pill badge-default">{value.course ? value.course.title : ''}</span>
                                                            <span
                                                                className="badge badge-pill badge-primary">{value.section ? value.section.title : ''}</span>
                                                            <span
                                                                className="badge badge-pill badge-secondary">{value.subject ? value.subject.title : ''}</span>
                                                            <span
                                                                className="badge badge-pill badge-warning">{value.course === null && value.section === null && value.subject === null ? "All" : ''}</span>
                                                            <p className="h3">{obj.formatPostText(value.text)}</p>
                                                        </Col>
                                                        <Col className="sm-4">
                                                            <p>Attachments
                                                                - {value.documentList ? value.documentList.length : 0} </p>
                                                            <ul className="list-group list-group-flush"
                                                                data-toggle="checklist">
                                                                {[...value.documentList].map((value, index) => {
                                                                    return (
                                                                        <>
                                                                            <li key={value.uniqueId}
                                                                                className="checklist-entry list-group-item flex-column align-items-start py-4 px-4">
                                                                                <div
                                                                                    className="checklist-item checklist-item-danger">
                                                                                    <div
                                                                                        className="checklist-info">
                                                                                        <h5
                                                                                            className="checklist-title mb-0">{value.originalFileName}</h5>
                                                                                    </div>
                                                                                    <div>
                                                                <span
                                                                    onClick={() => obj.downloadDocument(value.uniqueId)}
                                                                    className="badge badge-primary">Download</span>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </Col>
                                                    </Row>
                                                </p>
                                            </CardText>
                                            <CardFooter>
                                                                <span
                                                                    className="badge badge-pill badge-secondary"><i
                                                                    className="far fa-thumbs-up"></i> {obj.postActivityCounts(value.uniqueId, postActivityTypes.LIKE)}</span>
                                                <span
                                                    className="badge badge-pill badge-primary"><i
                                                    className="fas fa-eye"></i> {obj.postActivityCounts(value.uniqueId, postActivityTypes.VIEW)}</span>
                                                <span
                                                    className="badge badge-pill badge-primary"><i
                                                    className="fas fa-comment"></i> {obj.postActivityCounts(value.uniqueId, postActivityTypes.COMMENT)}</span>
                                                {/*<button type="button" className="btn btn-sm btn-info">
                                                                    <span>Attachments</span>
                                                                    <span
                                                                        className="badge badge-md badge-circle badge-floating badge-danger border-white">{value.documentList.length}</span>
                                                                </button>*/}
                                                <a href="javascript:void(0);" onClick={() => {
                                                    obj.props.history.push(`/${AuthService.getBaseURLByRole()}/post/show/${value.uniqueId}`)
                                                }}
                                                   className="badge badge-pill badge-default">View
                                                    Post
                                                </a>
                                                {obj.showEditAndDelete(value)}
                                            </CardFooter>
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                        );
                    })}

                </Container>
            </>
        );
    }
}

export default Feeds;
