/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/pages/dashboards/SuperAdminDashboard.jsx";
import TenantList from "views/pages/tenant/List.jsx";
import TenantDetails from "views/pages/tenant/Details.jsx";
import TenantCreate from "../views/pages/tenant/Create";
import TenantEdit from "../views/pages/tenant/Edit";
import TenantAdminCreate from "../views/pages/tenant/admin/Create";
import TenantAdminDetails from "../views/pages/tenant/admin/Details";
import TenantConfigurationList from "../views/pages/tenantConfiguration/List";

const superAdminRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-shop text-primary",
    component: Dashboard,
    layout: "/superAdmin",
  },
  {
    path: "/tenant/list",
    name: "Tenant List",
    icon: "ni ni-shop text-primary",
    component: TenantList,
    layout: "/superAdmin",
  },
  {
    path: "/tenant/configuration/:uniqueId",
    name: "Tenant Configuration",
    icon: "ni ni-shop text-primary",
    component: TenantConfigurationList,
    layout: "/superAdmin",
    invisible: true,
  },
  {
    path: "/tenant/show/:uniqueId",
    name: "Tenant Details",
    icon: "ni ni-shop text-primary",
    component: TenantDetails,
    layout: "/superAdmin",
    invisible: true,
  },
  {
    path: "/tenant/create",
    name: "Tenant Create",
    icon: "ni ni-shop text-primary",
    component: TenantCreate,
    layout: "/superAdmin",
    invisible: true,
  },
  {
    path: "/tenant/edit/:uniqueId",
    name: "Tenant Edit",
    icon: "ni ni-shop text-primary",
    component: TenantEdit,
    layout: "/superAdmin",
    invisible: true,
  },
  {
    path: "/tenant/admin/create/:tenantUID",
    name: "Tenant Admin Create",
    icon: "ni ni-shop text-primary",
    component: TenantAdminCreate,
    layout: "/superAdmin",
    invisible: true,
  },
  {
    path: "/tenant/admin/show/:adminUID",
    name: "Tenant Admin Details",
    icon: "ni ni-shop text-primary",
    component: TenantAdminDetails,
    layout: "/superAdmin",
    invisible: true,
  },
];

export default superAdminRoutes;
