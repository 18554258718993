import axiosInstance from "../api/api";

const StudentOnlineExaminationService = {
  list: function (examinationStatus) {
    return axiosInstance({
      url: `/api/studentOnlineExamination/list/${examinationStatus}`,
      method: "GET",
    });
  },
  fetch: function (uniqueId) {
    return axiosInstance({
      url: `/api/studentOnlineExamination/${uniqueId}`,
      method: "GET",
    });
  },
  instructionPage: function (uniqueId) {
    return axiosInstance({
      url: `/api/studentOnlineExamination/instructionPage/${uniqueId}`,
      method: "GET",
    });
  },
  deleteQuestion: function (uniqueId, questionUID) {
    return axiosInstance({
      url: `/api/studentOnlineExamination/examination/${uniqueId}/${questionUID}`,
      method: "DELETE",
    });
  },
  delete: function (uniqueId) {
    return axiosInstance({
      url: `/api/studentOnlineExamination/${uniqueId}`,
      method: "DELETE",
    });
  },
  create: function (formData) {
    return axiosInstance({
      url: `/api/studentOnlineExamination/`,
      method: "POST",
      data: formData,
    });
  },
  update: function (formData) {
    return axiosInstance({
      url: `/api/studentOnlineExamination/`,
      method: "PATCH",
      data: formData,
    });
  },
};
export default StudentOnlineExaminationService;
