import axiosInstance from "../api/api";

const ChatParticipantService = {
	currentUserParticipant: function () {
		return axiosInstance({
			url: "/api/chatParticipant/currentUser",
			method: "GET",
		});
	},
	create: function (formData) {
		return axiosInstance({
			url: `/api/chatParticipant/`,
			method: "POST",
			data: formData,
		});
	},
	list: function (courseSectionSubjectUID) {
		return axiosInstance({
			url: `/api/chatParticipant/${courseSectionSubjectUID}`,
			method: "GET",
		});
	},
};
export default ChatParticipantService;
