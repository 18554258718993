import axiosInstance from "../api/api";

const PostService = {
    list: function () {
        return axiosInstance({
            url: "/api/post/",
            method: "GET",
        });
    },
    fetch: function (uniqueId) {
        return axiosInstance({
            url: `/api/post/${uniqueId}`,
            method: "GET",
        });
    },
    delete: function (uniqueId) {
        return axiosInstance({
            url: `/api/post/${uniqueId}`,
            method: "DELETE",
        });
    },
    deletePostActivity: function (uniqueId) {
        return axiosInstance({
            url: `/api/post/activity/${uniqueId}`,
            method: "DELETE",
        });
    },
    create: function (formData) {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return axiosInstance({
            url: `/api/post/`,
            method: "POST",
            data: formData,
            config
        });
    },
    update: function (formData) {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return axiosInstance({
            url: `/api/post/`,
            method: "PATCH",
            data: formData,
            config
        });
    },
    updateView: function (formData) {
        return axiosInstance({
            url: `/api/post/updateView/`,
            method: "POST",
            data: formData
        });
    },
    createPostActivity: function (formData) {
        return axiosInstance({
            url: `/api/post/activity/`,
            method: "POST",
            data: formData
        });
    },
    updatePostActivity: function (formData) {
        return axiosInstance({
            url: `/api/post/activity/`,
            method: "PATCH",
            data: formData
        });
    }

};
export default PostService;
