import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import PropTypes from "prop-types";

const NotificationQueueCardsHeaderRow = ({
  totalCount,
  sentCount,
  errorCount,
}) => {
  return (
    <Row>
      <Col md="6" xl="4">
        <Card className="card-stats">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                  Total
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">{totalCount}</span>
              </div>
              <Col className="col-auto">
                <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                  <i className="ni ni-active-40" />
                </div>
              </Col>
            </Row>
            <p className="mt-3 mb-0 text-sm">
              <span className="text-nowrap">Since last month</span>
            </p>
          </CardBody>
        </Card>
      </Col>
      <Col md="6" xl="4">
        <Card className="card-stats">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                  Sent
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">{sentCount}</span>
              </div>
              <Col className="col-auto">
                <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                  <i className="ni ni-chart-pie-35" />
                </div>
              </Col>
            </Row>
            <p className="mt-3 mb-0 text-sm">
              <span className="text-nowrap">Since last month</span>
            </p>
          </CardBody>
        </Card>
      </Col>
      <Col md="6" xl="4">
        <Card className="card-stats">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                  ERROR
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">{errorCount}</span>
              </div>
              <Col className="col-auto">
                <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                  <i className="ni ni-money-coins" />
                </div>
              </Col>
            </Row>
            <p className="mt-3 mb-0 text-sm">
              <span className="text-nowrap">Since last month</span>
            </p>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

NotificationQueueCardsHeaderRow.defaultProps = {
  totalCount: 0,
  sentCount: 0,
  errorCount: 0,
};

NotificationQueueCardsHeaderRow.propTypes = {
  totalCount: PropTypes.number,
  sentCount: PropTypes.number,
  errorCount: PropTypes.number,
};

export default NotificationQueueCardsHeaderRow;
