/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import NotificationAlert from "react-notification-alert";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap";
import TenantConfigurationService from "../../../services/tenantConfiguration";
import { DataTypeConstant } from "../../../util/TenantConstant";

class TenantConfigurationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
    this.notify = this.notify.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    TenantConfigurationService.list().then((response) => {
      console.log(response);
      this.setState({ list: response });
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    TenantConfigurationService.update({ key: target.name, value: value }).then(
      (response) => {
        TenantConfigurationService.list().then((response) => {
          console.log(response);
          this.setState({ list: response });
        });
        console.log(response);
      }
    );
  }

  notify = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    let obj = this;
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <SimpleHeader
          name="List"
          parentName="Tenant Configuration"
          heading="ClashMate"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Tenant Configuration</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        #
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Name
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {this.state.list.map(function (value, index) {
                      return (
                        <tr key={index}>
                          <td className="budget">{index + 1}</td>
                          <td className="budget">{value.description}</td>
                          <td>
                            {value.dataType === DataTypeConstant.BOOLEAN ? (
                              <label className="custom-toggle">
                                <Input
                                  defaultChecked={value.value === "true"}
                                  name={value.key}
                                  type="checkbox"
                                  onChange={obj.handleChange}
                                />
                                <span
                                  className="custom-toggle-slider rounded-circle"
                                  data-label-off="No"
                                  data-label-on="Yes"
                                />
                              </label>
                            ) : (
                              <Input
                                name={value.key}
                                type="text"
                                onChange={obj.handleChange}
                                defaultValue={value.value || ""}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default TenantConfigurationList;
