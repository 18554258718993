/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
    Button,
    Card,
    CardHeader,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
} from "reactstrap";
import MeetingReportService from "../../../services/meetingReportService";
import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";
import FormInput from "../../../components/common/form/FormInput";
import _ from "lodash";

class ReportListByCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            mainList:[],
            alert: null,
            companyUid : this.props.match.params.uniqueId
        };
        this.delete = this.delete.bind(this);
        this.notify = this.notify.bind(this);
        this.onChange = this.onChange.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.filterMeetingReports = this.filterMeetingReports.bind(this);
    }

    componentDidMount() {
        MeetingReportService.list().then((response) => {
            console.log(response);
            this.setState({list: response, mainList: response});
            let filteredReports = _.filter(response, obj => _.get(obj, 'company.uniqueId') === this.state.companyUid)
            this.setState({list: filteredReports, mainList: filteredReports});
        });
    }

    onChange(event) {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }

    clearFilters(event) {
        this.setState({dateFrom:"", dateTo: "", countryName :"", companyName :"", list:this.state.mainList})
    }


    hideDeleteAlert = () => {
        this.setState({
            alert: null
        });
    };

    confirmDeleteAlert = (uniqueId) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{display: "block"}}
                    title="Are you sure?"
                    onConfirm={() => this.delete(uniqueId)}
                    onCancel={this.hideDeleteAlert}
                    showCancel
                    confirmBtnBsStyle="danger"
                    confirmBtnText="Yes, delete it!"
                    cancelBtnBsStyle="secondary"
                    cancelBtnText="Cancel"
                    btnSize=""
                >
                    You won't be able to revert this!
                </ReactBSAlert>
            )
        });
    };

    delete(uniqueId) {
        this.setState({alert: null})
        MeetingReportService.delete(uniqueId).then((response) => {
            console.log(response);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            //TODO: TO be fixed with redux implementation
            //   this.props.history.push("/admin/dashboard");
            MeetingReportService.list().then((response) => {
                console.log(response);
                this.setState({list: response});
            });
        });
    }

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    filterMeetingReports = () => {
        console.log("Hi from filterMeetingReports")
        let filteredList =  _.filter(this.state.mainList, (report) => {
            const { countryName, companyName, dateFrom, dateTo } = this.state;
            console.log(this.state)
            const reportCompany = _.get(report, 'company', {});
            const reportDate = new Date(_.get(report, 'dateCreated', null));

            // Check if the country matches
            const countryMatch = countryName ? reportCompany?.country.toLowerCase().includes(countryName.toLowerCase()) : true;

            // Check if the company name matches
            const companyNameMatch = companyName ? reportCompany?.legalName.toLowerCase().includes(companyName.toLowerCase()) : true;

            // Check if the dateCreated falls between fromDate and toDate
            const dateMatch = dateFrom && dateTo ? (reportDate >= new Date(dateFrom) && reportDate <= new Date(dateTo)) : true;

            // Return true only if all criteria match
            return countryMatch && companyNameMatch && dateMatch;
        });

        this.setState({list: filteredList});
    };

    render() {
        let obj = this;
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <SimpleHeader
                    name="List"
                    parentName="Meeting Report"
                    heading="XareGrwoth"
                    newBtnUrl="/admin/meetingReport/create"
                    newBtnText="Create Meeting Report"
                />
                {this.state.alert}
                <Container className="mt--6" fluid>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Meeting Report List</h3>
                                            <Row>
                                                <Col sm={2}>
                                                    <FormInput
                                                        name="dateFrom"
                                                        className="form-control-label"
                                                        id="date"
                                                        placeholder="inputPlaceHolder.meetingReport.date"
                                                        type="date"
                                                        onChange={this.onChange}
                                                        value={this.state.dateFrom || ""}
                                                        label="Date From"
                                                    />
                                                </Col>
                                                <Col sm={2}>
                                                    <FormInput
                                                        name="dateTo"
                                                        className="form-control-label"
                                                        id="date"
                                                        placeholder="inputPlaceHolder.meetingReport.date"
                                                        type="date"
                                                        onChange={this.onChange}
                                                        value={this.state.dateTo || ""}
                                                        label="Date To"
                                                    />
                                                </Col>
                                                <Col md="2">
                                                    <FormInput
                                                        name="companyName"
                                                        className="form-control-label"
                                                        id="companyName"
                                                        placeholder="Company Name"
                                                        type="text"
                                                        onChange={this.onChange}
                                                        label="Company"
                                                        value={this.state.companyName || ""}
                                                        // errorMsg={this.props.errorObj?.name || ""}
                                                    />
                                                </Col>
                                                <Col md="2">
                                                    <FormInput
                                                        name="countryName"
                                                        className="form-control-label"
                                                        id="countryName"
                                                        placeholder="Country Name"
                                                        type="text"
                                                        onChange={this.onChange}
                                                        label="Country"
                                                        value={this.state.countryName || ""}
                                                        // errorMsg={this.props.errorObj?.name || ""}
                                                    />
                                                </Col>
                                                <Col sm={3}>
                                                    <Button color="success" size="sm" outline type="button" onClick={this.filterMeetingReports}>
                                                        Apply Filter
                                                    </Button>
                                                    <br/>
                                                    <br/>
                                                    <Button onClick={this.clearFilters} color="danger"  size="sm" outline type="button">
                                                        Clear Filter
                                                    </Button>
                                                </Col>
                                            </Row>


                                        </div>
                                    </Row>
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th className="sort" data-sort="name" scope="col">
                                            #
                                        </th>
                                        <th className="sort" data-sort="budget" scope="col">
                                            Created On
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Date
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Company
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Country
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            EventType
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Attendees
                                        </th>
                                        <th scope="col"/>
                                    </tr>
                                    </thead>
                                    <tbody className="list">
                                    {this.state.list.map(function (value, index) {
                                        return (
                                            <tr key={index}>
                                                <td className="budget">{index + 1}</td>
                                                <td className="budget">{value.dateCreated}</td>
                                                <td className="budget">{value.date}</td>
                                                <td className="budget">{value?.company?.legalName}</td>
                                                <td className="budget">{value?.company?.country}</td>
                                                <td className="budget">{value.eventType}</td>
                                                <td className="budget">{value.attendees.map(function(value,index){
                                                    return <h4 key={index} className="badge badge-lg badge-primary">{value.fullName}</h4>
                                                })}</td>
                                                <td className="text-right">
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle
                                                            color=""
                                                            size="sm"
                                                            className="btn-icon-only text-light"
                                                        >
                                                            <i className="fas fa-ellipsis-v"/>
                                                        </DropdownToggle>
                                                        <DropdownMenu
                                                            className="dropdown-menu-arrow"
                                                            right
                                                        >
                                                            <DropdownItem
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    obj.props.history.push(
                                                                        `/admin/meetingReport/show/${value.uniqueId}`
                                                                    );
                                                                }}
                                                            >
                                                                Details
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    obj.props.history.push(
                                                                        `/admin/meetingReport/edit/${value.uniqueId}`
                                                                    );
                                                                }}
                                                            >
                                                                Edit
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    obj.confirmDeleteAlert(`${value.uniqueId}`);
                                                                }}
                                                            >
                                                                Delete
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default ReportListByCompany;
