/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import _ from "lodash";
import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Row,
} from "reactstrap";
import ExaminationResultService from "../../../../services/examinationResultService";
import UtilService from "../../../../services/utilService";

class StudentExaminationResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      examinationResultData: {
        onlineExaminationDTO: {},
        examinationResultDataDTOList: [],
      },
    };
    this.fetchAnswer = this.fetchAnswer.bind(this);
  }

  componentDidMount() {
    const { onlineExaminationUID } = this.props.match.params;
    this.setState({ onlineExaminationUID: onlineExaminationUID });
    ExaminationResultService.result(onlineExaminationUID).then((response) => {
      console.log(response);
      this.setState({ examinationResultData: response.data });
    });
  }

  fetchAnswer(answerList, answerUID) {
    console.log(answerList);
    console.log(answerUID);
    let answerObj = _.find(answerList, (answer) => {
      return answer.uniqueId === answerUID;
    });
    if (_.isEmpty(answerObj)) {
      return "No Answer Selected";
    } else {
      return answerObj.answerText;
    }
  }

  render() {
    const examinationData = this.state.examinationResultData
      .onlineExaminationDTO;
    const questionListData = this.state.examinationResultData
      .examinationResultDataDTOList;
    let obj = this;
    return (
      <>
        <SimpleHeader
          name="Result"
          parentName="Examination"
          heading="ClashMate"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col-12">
                      <h3 className="mb-0">Examination Result</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          Title
                        </label>
                        <p>
                          {examinationData.examinationTitle} -{" "}
                          {examinationData.title}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          Date Attempted
                        </label>
                        <p>{this.state.examinationResultData.dateCreated}</p>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          Total Questions
                        </label>
                        <p>{examinationData.questionCount}</p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          Start Date
                        </label>
                        <p>{examinationData.startDateTime}</p>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          End Date
                        </label>
                        <p>{examinationData.endDateTime}</p>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          Duration(min)
                        </label>
                        <p>{examinationData.duration}</p>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {questionListData.map(function (value, index) {
              return (
                <Col xl="12" key={UtilService.uuidv4()}>
                  <Card>
                    <CardHeader>
                      <CardTitle className="mb-0" tag="h3">
                        {value.questionDTO.questionText}
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col xl="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Selected Answer
                            </label>
                            <p>
                              {obj.fetchAnswer(
                                value.questionDTO.answerDTOList,
                                value.selectedAnswer
                              )}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col xl="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Correct Answer
                            </label>
                            <p>
                              {obj.fetchAnswer(
                                value.questionDTO.answerDTOList,
                                value.questionDTO.correctAnswerUID
                              )}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </>
    );
  }
}

export default StudentExaminationResult;
