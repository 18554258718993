import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

class Paginations extends React.Component {
    render() {
        const { totalItems, pageSize, currentPage, onPageChange } = this.props;
        const pageCount = Math.ceil(totalItems / pageSize);
        if (pageCount === 1) return null; // No pagination if only one page

        const pages = Array.from({ length: pageCount }, (_, i) => i + 1);

        return (
            <>
                <nav aria-label="Page navigation example">
                    <Pagination className="pagination justify-content-center">
                        {/* Previous Page Arrow */}
                        <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (currentPage > 1) onPageChange(currentPage - 1);
                                }}
                                tabIndex="-1"
                            >
                                <i className="fa fa-angle-left" />
                                <span className="sr-only">Previous</span>
                            </PaginationLink>
                        </PaginationItem>

                        {/* Page Numbers */}
                        {pages.map((page) => (
                            <PaginationItem key={page} active={page === currentPage}>
                                <PaginationLink
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onPageChange(page);
                                    }}
                                >
                                    {page}
                                </PaginationLink>
                            </PaginationItem>
                        ))}

                        {/* Next Page Arrow */}
                        <PaginationItem disabled={currentPage === pageCount}>
                            <PaginationLink
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (currentPage < pageCount) onPageChange(currentPage + 1);
                                }}
                            >
                                <i className="fa fa-angle-right" />
                                <span className="sr-only">Next</span>
                            </PaginationLink>
                        </PaginationItem>
                    </Pagination>
                </nav>
            </>
        );
    }
}

export default Paginations;
