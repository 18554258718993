/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import AdminService from "../../../../services/adminService";
import AdminDetailsDummy from "../../admin/DetailsDummy";
import PermissionListTable from "../../permission/ListTable";

class TenantAdminDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adminData: {
        permissionList: [],
      },
    };
  }

  componentDidMount() {
    const { adminUID } = this.props.match.params;
    AdminService.fetch(adminUID).then((response) => {
      console.log(response.data);
      this.setState({ adminData: response.data });
    });
  }

  render() {
    const { adminData } = this.state;
    let obj = this;
    return (
      <>
        <SimpleHeader name="Details" parentName="Admin" heading="ClashMate" />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card className="mb-4">
                <CardHeader>
                  <h3 className="mb-0">{adminData.fullName}</h3>
                </CardHeader>
                <CardBody>
                  <AdminDetailsDummy adminData={adminData} />
                </CardBody>
              </Card>
            </Col>
            <Col xl="12">
              <PermissionListTable
                permissionList={adminData.permissionList}
                adminComponentObj={obj}
                cardTitle="Permission List"
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default TenantAdminDetails;
