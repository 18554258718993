/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import _ from "lodash";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Container,
  Row,
} from "reactstrap";

class AdminCardsHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-info pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    {this.props.name}
                  </h6>{" "}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <i className="fas fa-home" />
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        {this.props.parentName}
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      {this.props.name}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col className="text-right" lg="6" xs="5">
                  {this.newButton()}
                </Col>
              </Row>
              {this.props.cardsHeaderRow}
            </div>
          </Container>
        </div>
      </>
    );
  }

  newButton() {
    if (
      !_.isUndefined(this.props.newBtnUrl) &&
      !_.isUndefined(this.props.newBtnText)
    ) {
      return (
        <Button
          className="btn-neutral"
          color="default"
          size="sm"
          onClick={(e) => {
            e.preventDefault();
            this.props.history.push(this.props.newBtnUrl);
          }}
        >
          {this.props.newBtnText}
        </Button>
      );
    }
  }
}

AdminCardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  cardsHeaderRow: PropTypes.element,
};

export default AdminCardsHeader;
