import axiosInstance from "../api/api";

const TimeTableService = {
    list: function () {
        return axiosInstance({
            url: "/api/timeTable/",
            method: "GET",
        });
    },
    listStudentTimeTable: function () {
        return axiosInstance({
            url: "/api/timeTable/student",
            method: "GET",
        });
    },
    listTeacherTimeTable: function () {
        return axiosInstance({
            url: "/api/timeTable/teacher",
            method: "GET",
        });
    },
    fetch: function (uniqueId) {
        return axiosInstance({
            url: `/api/timeTable/${uniqueId}`,
            method: "GET",
        });
    },
    delete: function (uniqueId) {
        return axiosInstance({
            url: `/api/timeTable/${uniqueId}`,
            method: "DELETE",
        });
    },
    create: function (formData) {
        return axiosInstance({
            url: `/api/timeTable/`,
            method: "POST",
            data: formData,
        });
    },
    update: function (formData) {
        return axiosInstance({
            url: `/api/timeTable/`,
            method: "PATCH",
            data: formData,
        });
    },
};
export default TimeTableService;
