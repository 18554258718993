/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
} from "reactstrap";
import LessonPlanService from "../../../services/lessonPlanService";
import TeacherService from "../../../services/teacherService";
import LessonPlanForm from "./Form";

class LessonPlanCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorData: {},
      editorState: EditorState.createEmpty(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleContentChange = this.handleContentChange.bind(this);
  }

  componentDidMount() {
    TeacherService.subject().then((response) => {
      this.setState({ subjectList: response });
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    console.log(this.state);
  }

  handleContentChange(editorState) {
    this.setState({ editorState });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { editorState } = this.state;
    let formData = {
      content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      title: this.state.title,
      courseSectionSubjectUID: this.state.courseSectionSubjectUID,
    };
    LessonPlanService.create(formData).then((response) => {
      console.log(response.data);
      if (response.responseStatus === "SUCCESS") {
        this.props.history.push("/teacher/lessonPlan/list");
      } else {
        this.setState({ errorData: response.data });
        console.error("Error    ==>     " + response.message);
      }
    });
  }

  render() {
    return (
      <>
        <SimpleHeader
          name="Create"
          parentName="LessonPlan"
          heading="ClashMate"
          newBtnUrl="/teacher/lessonPlan/create"
          newBtnText="Create Lesson Plan"
        />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <h3 className="mb-0">Create Lesson Plan</h3>
            </CardHeader>
            <CardBody>
              <Form role="form" onSubmit={this.handleSubmit}>
                <LessonPlanForm
                  onChange={this.handleChange}
                  valueObj={this.state}
                  errorObj={this.state.errorData}
                  isEdit={false}
                  obj={this}
                />
                <div className="text-center">
                  <Button className="my-4" color="info" type="submit">
                    Submit
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

export default LessonPlanCreate;
