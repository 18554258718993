import React from "react";

class ContentForm extends React.Component {
    render() {
        return (
            <>

            </>
        );
    }
}

export default ContentForm;
