import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import { Card, CardBody, Col, Container, FormGroup, Row } from "reactstrap";
import LedgerService from "../../../../services/ledgerService";

class LedgerDetailsStudent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ledgerData: {},
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ remark: event.target.value });
  }

  async componentDidMount() {
    const { uniqueId } = this.props.match.params;
    await LedgerService.fetch(uniqueId).then((response) => {
      let ledgerData = response.data;
      this.setState({ ledgerData: ledgerData });
    });
  }

  render() {
    const { ledgerData } = this.state;
    return (
      <>
        <SimpleHeader
          name="Details"
          parentName="Ledger"
          heading="ClashMate"
          // newBtnUrl="/admin/section/create"
          // newBtnText="Create Section"
        />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Date Created
                    </label>
                    <p>{ledgerData.dateCreated}</p>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      Ledger Type
                    </label>
                    <p>{ledgerData.type}</p>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Amount
                    </label>
                    <p>₹{ledgerData.amount}</p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols4Input"
                    >
                      Payer
                    </label>
                    <p>{ledgerData.payer}</p>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols5Input"
                    >
                      Payee
                    </label>
                    <p>{ledgerData.payee}</p>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols6Input"
                    >
                      Created By
                    </label>
                    <p>{ledgerData.createdBy}</p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols7Input"
                    >
                      Marked Paid By
                    </label>
                    <p>{ledgerData.paidMarkedBy || "N/A"}</p>
                  </FormGroup>
                </Col>
                {/*Todo:NotDispalyingData*/}
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols8Input"
                    >
                      Due Date
                    </label>
                    <p>{ledgerData.dueDate}</p>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols10Input"
                    >
                      Duration Type
                    </label>
                    <p>{ledgerData.ledgerDurationType}</p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols2Input"
                    >
                      Payment Status
                    </label>
                    <p>{ledgerData.paymentStatus}</p>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols11Input"
                    >
                      Payment Term
                    </label>
                    <p>{ledgerData.paymentTerm || "N/A"}</p>
                  </FormGroup>
                </Col>
                {/*Todo:NotDisplayingData*/}
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols9Input"
                    >
                      Description
                    </label>
                    <p>{ledgerData.description}</p>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

export default LedgerDetailsStudent;
