import React, { Component } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    Card,
    CardBody,
    CardTitle,
    CardImg, CardSubtitle, CardText, Button, Col, Row
} from 'reactstrap';


const controlStyle = {
    backgroundColor: 'black',
    color: 'white',
};

class CompaniesCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            animating: false,
        };
        this.itemsPerSlide =4; // Adjust the number of items per slide as needed
        this.items = [
            { id: 1, name: "Company 1" },
            { id: 2, name: "Company 2" },
            { id: 3, name: "Company 3" },
            { id: 4, name: "Company 4" },
            { id: 5, name: "Company 5" },
            { id: 6, name: "Company 6" },
            { id: 7, name: "Company 7" },
        ];
        this.companies = [
            { id: 1, name: "EGA Master", url: "https://upload.wikimedia.org/wikipedia/en/b/be/EGA_Master_logo.svg" },
            { id: 2, name: "Panther", url: "https://www.mixcoindustry.com/cdn/shop/collections/logo_05d133b3-7824-466e-b1c4-69a9debe10c3_1024x.jpg?v=1583117557" },
            { id: 3, name: "Irudek", url: "https://i.ytimg.com/vi/zIzyaX8Rybs/hqdefault.jpg" },
            { id: 4, name: "ACE Safety", url: "https://cdn.store-assets.com/s/369545/f/4923228.png?width=350&format=webp" },
            { id: 5, name: "Bondflex", url: "https://static.wixstatic.com/media/257e98_ebe3fc401c644e52a1b8550bd5e71d3a~mv2.png/v1/fill/w_2500,h_1162,al_c/257e98_ebe3fc401c644e52a1b8550bd5e71d3a~mv2.png" },
            { id: 6, name: "Eyevex", url: "https://eyevexonline.com/cdn/shop/files/shopify_eyevex_logo_1500x750.png?v=1692188998" },
            { id: 7, name: "Eligere", url: "https://eligere.ai/wp-content/uploads/2021/08/eligere-logo.png" },
            { id: 8, name: "KS Tools", url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAtbinOzA0-M2d1Lyy_LCqamYVCBXikGsjsA&s" },
            { id: 9, name: "Submer", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/07/Submer_logo.png/1200px-Submer_logo.png" },
            { id: 10, name: "Brix Engineering", url: "https://brixengg.com/wp-content/uploads/2022/05/Brix-New-Logo.png" },
            { id: 11, name: "Nabakem", url: "https://www.nabakem.com/images/common/logo.png" },
            { id: 12, name: "Viridian", url: "https://viridian-nutrition.com/cdn/shop/files/header-logo.png?v=1674663394" },
            { id: 13, name: "USA Group", url: "https://usagroup.es/wp-content/uploads/2023/12/apple-touch-icon.png" },
            { id: 14, name: "Cypet", url: "https://cypet.eu/wp-content/uploads/2020/11/CYPET.png" }
        ];

    }

    next = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === this.getSlides().length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    };

    previous = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.getSlides().length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    };

    onExiting = () => {
        this.setState({ animating: true });
    };

    onExited = () => {
        this.setState({ animating: false });
    };

    getSlides() {
        const slides = [];
        for (let i = 0; i < this.companies.length; i += this.itemsPerSlide) {
            slides.push(this.companies.slice(i, i + this.itemsPerSlide));
        }
        return slides;
    }

    renderCards(items) {
        return items.map((item) => (
            <Card key={item.id} style={{ margin: "10px", width: "20%" , height: "40%", backgroundColor:"white"}}>
                {/*<CardImg*/}
                {/*    style={{ width: '100%', height: '200px', objectFit: 'cover' }}*/}
                {/*    top width="100%" src={item.url} alt="Card image cap" />*/}
                <CardImg
                    style={{ width: '100%', height: '170px', objectFit: 'contain' }}
                    src={item.url}
                    alt={item.name}
                />
                {/*<CardBody>
                    <CardTitle>{item.name}</CardTitle>
                    <CardSubtitle>Company Details</CardSubtitle>
                </CardBody>*/}
            </Card>
        ));
    }

    render() {
        const { activeIndex } = this.state;
        const slides = this.getSlides().map((group, index) => (
            <CarouselItem
                onExiting={this.onExiting}
                onExited={this.onExited}
                key={index}
            >
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                    {this.renderCards(group)}
                </div>
            </CarouselItem>
        ));

        return (
            <section>
                <hr  />

                <Row>
                    <Col md="12">
                        <div>
                            <br/>
                            <h1 style={{ textAlign: "center", marginBottom: "20px", marginTop: "20px" }}>They have already trusted us... will you?</h1>

                            <Carousel style={controlStyle} activeIndex={activeIndex} next={this.next} previous={this.previous}>
                                <CarouselIndicators
                                    items={this.getSlides()}
                                    activeIndex={activeIndex}
                                    onClickHandler={(newIndex) => {
                                        if (this.state.animating) return;
                                        this.setState({ activeIndex: newIndex });
                                    }}
                                />
                                {slides}
                                <CarouselControl className="custom-carousel-control-prev" direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                <CarouselControl className="custom-carousel-control-next" style={controlStyle} direction="next" directionText="Next" onClickHandler={this.next} />
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </section>
        );
    }
}

export default CompaniesCarousel;
