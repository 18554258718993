import axiosInstance from "../api/api";

const ContentDocumentService = {
    list: function (contentUid) {
        return axiosInstance({
            url: `/api/contentDocument/?contentUid=${contentUid}`,
            method: "GET",
        });
    },
    fetch: function (uniqueId) {
        return axiosInstance({
            url: `/api/contentDocument/${uniqueId}`,
            method: "GET",
        });
    },
    delete: function (uniqueId) {
        return axiosInstance({
            url: `/api/contentDocument/${uniqueId}`,
            method: "DELETE",
        });
    },
    create: function (formData) {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        return axiosInstance({
            url: `/api/contentDocument/`,
            method: "POST",
            data: formData,
            config
        });
    },
    update: function (formData) {
        return axiosInstance({
            url: `/api/contentDocument/`,
            method: "PATCH",
            data: formData,
        });
    },
};
export default ContentDocumentService;
