/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {Card, CardBody, CardHeader, Col, Container, FormGroup, Row,} from "reactstrap";
import StudyMaterialService from "../../../../services/studyMaterialService";
import AuthService from "../../../../services/authService";
import ContentService from "../../../../services/contentService";
import _ from "lodash"
import Alert from "reactstrap/es/Alert";
import Collapse from "reactstrap/es/Collapse";
import ListGroup from "reactstrap/es/ListGroup";
import ListGroupItem from "reactstrap/es/ListGroupItem";

class StudyMaterialDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            studyMaterialData: {},
            contentList: [],
            parentContentList: [],
            openedCollapses: []
        };
    }

    fetchContentList = async () => {
        let self = this;
        let studyMaterialUID = this.props.match.params.uniqueId;
        await ContentService.list(studyMaterialUID).then((response) => {
            let parentContentList = _.filter(response.data, content => content.parentUID === null);
            self.setState({contentList: response.data});
            self.setState({parentContentList: parentContentList});
        })
    };

    fetchStudyMaterial = async () => {
        const {uniqueId} = this.props.match.params;
        await StudyMaterialService.fetch(uniqueId).then((response) => {
            console.log(response.data);
            this.setState({studyMaterialData: response.data});
        });
    };

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: []
            });
        } else {
            this.setState({
                openedCollapses: [collapse]
            });
        }
    };

    fetchChildContents = (parentUID) => {
        let childContents = _.filter(this.state.contentList, content => content.parentUID === parentUID);
        console.log(childContents);
        return childContents;
    };

    viewContentDetails = (uniqueId) => {
        this.props.history.push(`/${AuthService.getBaseURLByRole()}/content/show/${uniqueId}`)
    };

    renderContents = () => {
        let self = this;
        let parentContentList = _.filter(this.state.contentList, content => content.parentUID === null);
        if (this.state.contentList.length === 0) {
            return (
                <>
                    <Alert color="warning">
                        <strong>Warning!</strong> There is no content created yet!!
                    </Alert>
                </>
            );
        } else {
            return (
                <>
                    <div className="accordion">
                        {parentContentList.map(parentContent => {
                            return <>
                                <Card className="card-plain" key={parentContent.uniqueId}>
                                    <CardHeader
                                        role="tab"
                                        onClick={() => this.collapsesToggle(parentContent.uniqueId)}
                                        aria-expanded={self.state.openedCollapses.includes(
                                            parentContent.uniqueId
                                        )}
                                    >
                                        <h5 className="mb-0">{parentContent.title}</h5>
                                    </CardHeader>
                                    <Collapse
                                        role="tabpanel"
                                        isOpen={self.state.openedCollapses.includes(parentContent.uniqueId)}
                                    >
                                        <CardBody>
                                            <ListGroup>
                                                <ListGroupItem
                                                    className="list-group-item-action active"
                                                    href="#pablo"
                                                    onClick={() => self.viewContentDetails(parentContent.uniqueId)}
                                                    tag="a"
                                                >{parentContent.title}</ListGroupItem>
                                            </ListGroup>
                                            {self.fetchChildContents(parentContent.uniqueId).map(content => {
                                                return (
                                                    <>
                                                        <ListGroupItem key={content.uniqueID}
                                                                       className="list-group-item-action"
                                                                       href="#pablo"
                                                                       onClick={() => self.viewContentDetails(content.uniqueId)}
                                                                       tag="a"
                                                        >{content.title}</ListGroupItem>
                                                    </>
                                                );
                                            })}
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </>
                        })}
                    </div>
                </>
            );
        }


    };


    componentDidMount() {
        this.fetchStudyMaterial();
        this.fetchContentList();
    }

    render() {
        const {studyMaterialData} = this.state;
        return (
            <>
                <SimpleHeader
                    name="Details"
                    parentName="Study Material"
                    heading="ClashMate"
                    newBtnUrl={`/${AuthService.getBaseURLByRole()}/content/create/${this.state.studyMaterialData.uniqueId}`}
                    newBtnText="Create Content"
                />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">{studyMaterialData.title}</h3>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            Title
                                        </label>
                                        <p>{studyMaterialData.title}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            Description
                                        </label>
                                        <p>{studyMaterialData.description}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols2Input"
                                        >
                                            Date Created
                                        </label>
                                        <p>{studyMaterialData.dateCreated}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols2Input"
                                        >
                                            Status
                                        </label>
                                        <p>{studyMaterialData.studyMaterialStatus}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols2Input"
                                        >
                                            Published Globally
                                        </label>
                                        <p>{studyMaterialData.isPublishedGlobally === true ? "YES" : "NO"}</p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols2Input"
                                        >
                                            Date Created
                                        </label>
                                        <p>{studyMaterialData.dateCreated}</p>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">Contents</h3>
                        </CardHeader>
                        <CardBody>
                            {this.renderContents()}
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}

export default StudyMaterialDetails;
