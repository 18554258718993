/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import OnlineExaminationService from "../../../services/onlineExaminationService";
import QuestionService from "../../../services/questionService";
import ManualQuestionAssignmentModal from "./ManualQuestionAssignmentModel";
import _ from "lodash";
import StudentListModal from "./student/StudentListModel";

class OnlineExaminationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      onlineExaminationData: {
        questionDTOList: [],
        studentDTOList: [],
      },
      isStudentListModalOpen: false,
      studentList: [],
      studentUIDList: [],
      assignedStudentList: [],
      assignedQuestionUIDList: [],
      questionList: [],
    };
    this.delete = this.delete.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onModalOpened = this.onModalOpened.bind(this);
    this.handleQuestionAssignment = this.handleQuestionAssignment.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleStudentListModal = this.toggleStudentListModal.bind(this);
    this.onStudentListModalOpened = this.onStudentListModalOpened.bind(this);
    this.handleStudentAssignmentChange = this.handleStudentAssignmentChange.bind(
      this
    );
    this.handleAssignedStudentRemoval = this.handleAssignedStudentRemoval.bind(
      this
    );
  }

  componentDidMount() {
    const { uniqueId, examinationUID } = this.props.match.params;
    this.setState({ examinationUID: examinationUID, uniqueId: uniqueId });
    OnlineExaminationService.fetch(uniqueId).then((response) => {
      console.log(response);
      this.setState({ onlineExaminationData: response.data });
      let assignedQuestionUIDList = this.state.assignedQuestionUIDList;
      _.forEach(this.state.onlineExaminationData.questionDTOList, function (
        questionObj
      ) {
        assignedQuestionUIDList.push(questionObj.uniqueId);
      });
      let studentUIDList = this.state.studentUIDList;
      _.forEach(this.state.onlineExaminationData.studentDTOList, function (
        studentObj
      ) {
        studentUIDList.push(studentObj.uniqueId);
      });
      this.setState({
        assignedQuestionUIDList: assignedQuestionUIDList,
        studentUIDList: studentUIDList,
      });
    });
    QuestionService.listByExamination(examinationUID).then((response) => {
      this.setState({ questionList: response.data });
    });
    console.log(this.state);
  }

  handleQuestionAssignment(event) {
    const value = event.target.value;
    const isChecked = event.target.checked;
    let assignedQuestionUIDList = this.state.assignedQuestionUIDList;
    if (isChecked) {
      assignedQuestionUIDList.push(value);
    } else {
      assignedQuestionUIDList = assignedQuestionUIDList.filter(
        (item) => item !== value
      );
    }
    this.setState({ assignedQuestionUIDList: assignedQuestionUIDList });
    console.log(assignedQuestionUIDList);
  }

  handleSubmit(event) {
    event.preventDefault();
    const formData = {
      studentUIDList: this.state.studentUIDList,
      assignedQuestionUIDList: this.state.assignedQuestionUIDList,
      uniqueId: this.state.uniqueId,
    };
    OnlineExaminationService.update(formData).then((response) => {
      console.log(response.data);
      this.props.history.push("/teacher/examination/list");
    });
  }

  toggleModal() {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  onModalOpened() {
    console.log("Manual Question Assignment Modal Opened");
  }

  delete(onlineExaminationUID, questionUID) {
    OnlineExaminationService.deleteQuestion(
      onlineExaminationUID,
      questionUID
    ).then((response) => {
      console.log(response);
      //TODO: TO be fixed with redux implementation
      //   this.props.history.push("/admin/dashboard");
      OnlineExaminationService.fetch(onlineExaminationUID).then((response) => {
        console.log(response);
        this.setState({ onlineExaminationData: response.data });
      });
    });
  }

  toggleStudentListModal() {
    this.setState({
      isStudentListModalOpen: !this.state.isStudentListModalOpen,
    });
  }

  onStudentListModalOpened() {
    console.log("Student List Assignment Modal Opened");
    OnlineExaminationService.assignedStudentList(this.state.uniqueId).then(
      (response) => {
        console.log(response);
        this.setState({ assignedStudentList: response.data });
      }
    );
  }

  handleStudentAssignmentChange(event) {
    console.log("CHANGED");
    const value = event.target.value;
    const isChecked = event.target.checked;
    let studentUIDList = this.state.studentUIDList;
    if (isChecked) {
      studentUIDList.push(value);
    } else {
      studentUIDList = studentUIDList.filter((item) => item !== value);
    }
    this.setState({ studentUIDList: studentUIDList });
    console.log(studentUIDList);
  }

  handleAssignedStudentRemoval(event, studentUID) {
    let studentUIDList = this.state.studentUIDList;
    _.remove(studentUIDList, function (studentObjUID) {
      return studentObjUID === studentUID;
    });
    this.setState({ studentUIDList: studentUIDList });
    this.handleSubmit(event);
  }

  render() {
    let obj = this;
    return (
      <>
        <StudentListModal
          isOpen={this.state.isStudentListModalOpen}
          toggleModal={this.toggleStudentListModal}
          onModalOpened={this.onStudentListModalOpened}
          size="lg"
          assignedStudentList={this.state.assignedStudentList}
          handleStudentAssignmentChange={this.handleStudentAssignmentChange}
          handleStudentAssignmentSubmit={this.handleSubmit}
          valueObj={this.state}
        />
        <ManualQuestionAssignmentModal
          isOpen={this.state.isModalOpen}
          toggleModal={this.toggleModal}
          onModalOpened={this.onModalOpened}
          size="lg"
          questionList={this.state.questionList}
          assignedQuestionUIDList={this.state.assignedQuestionUIDList}
          handleQuestionAssignment={this.handleQuestionAssignment}
          handleQuestionAssignmentSubmit={this.handleSubmit}
        />
        <SimpleHeader
          name={this.state.onlineExaminationData.title}
          parentName="Online Examination"
          heading="ClashMate"
          newBtnUrl={`/teacher/onlineExamination/list/${this.state.examinationUID}`}
          newBtnText="Online Examination List"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col-10">
                      <h3 className="mb-0">Question List</h3>
                    </div>
                    <div className="col-2">
                      <div className="text-right">
                        <Button
                          size="sm"
                          color="secondary"
                          type="button"
                          onClick={() => this.setState({ isModalOpen: true })}
                        >
                          Add Question
                        </Button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        #
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Date Created
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Question Text
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Difficulty
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {this.state.onlineExaminationData.questionDTOList.map(
                      function (value, index) {
                        return (
                          <tr key={index}>
                            <td className="budget">{index + 1}</td>
                            <td className="budget">{value.dateCreated}</td>
                            <td className="budget" id={`questionText_${index}`}>
                              {value.abbreviatedQuestionText}
                            </td>
                            <td className="budget">{value.difficultyLevel}</td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  color=""
                                  size="sm"
                                  className="btn-icon-only text-light"
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <DropdownItem
                                    onClick={(e) => {
                                      e.preventDefault();
                                      obj.props.history.push(
                                        `/teacher/question/show/${value.uniqueId}`
                                      );
                                    }}
                                  >
                                    Details
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={(e) => {
                                      e.preventDefault();
                                      obj.delete(
                                        `${obj.state.uniqueId}`,
                                        `${value.uniqueId}`
                                      );
                                    }}
                                  >
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                            <UncontrolledTooltip
                              delay={0}
                              placement="bottom"
                              target={`questionText_${index}`}
                            >
                              {value.questionText}
                            </UncontrolledTooltip>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              </Card>
            </Col>

            <Col xl="12">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col-10">
                      <h3 className="mb-0">Student List</h3>
                    </div>
                    <div className="col-2">
                      <div className="float-right">
                        <Button
                          size="sm"
                          color="secondary"
                          type="button"
                          onClick={() =>
                            this.setState({ isStudentListModalOpen: true })
                          }
                        >
                          Assign Student
                        </Button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        #
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Date Created
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Name
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Email Address
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {this.state.onlineExaminationData.studentDTOList.map(
                      function (value, index) {
                        return (
                          <tr key={index}>
                            <td className="budget">{index + 1}</td>
                            <td className="budget">{value.dateCreated}</td>
                            <td className="budget">{value.fullName}</td>
                            <td className="budget">{value.emailAddress}</td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  color=""
                                  size="sm"
                                  className="btn-icon-only text-light"
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <DropdownItem
                                    onClick={(e) => {
                                      e.preventDefault();
                                      obj.handleAssignedStudentRemoval(
                                        e,
                                        `${value.uniqueId}`
                                      );
                                    }}
                                  >
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default OnlineExaminationDetails;
