import axiosInstance from "../api/api";

const TenantService = {
  list: function () {
    return axiosInstance({
      url: "/api/tenant/",
      method: "GET",
    });
  },
  fetch: function (uniqueId) {
    return axiosInstance({
      url: `/api/tenant/${uniqueId}`,
      method: "GET",
    });
  },
  delete: function (uniqueId) {
    return axiosInstance({
      url: `/api/tenant/${uniqueId}`,
      method: "DELETE",
    });
  },
  deletePermanently: function (uniqueId) {
    return axiosInstance({
      url: `/api/tenant/deletePermanently/${uniqueId}`,
      method: "DELETE",
    });
  },
  create: function (formData) {
    return axiosInstance({
      url: `/api/tenant/`,
      method: "POST",
      data: formData,
    });
  },
  update: function (formData) {
    return axiosInstance({
      url: `/api/tenant/`,
        method: "PATCH",
        data: formData,
    });
  },
    uploadBootstrapData: function (formData) {
        return axiosInstance({
            url: `/api/upload/bulk/`,
            method: "POST",
            data: formData,
        });
    },
    fetchBulkUploadData: function () {
        return axiosInstance({
            url: `/api/upload/bulk/`,
            method: "GET",
        });
    },
    deleteBulkUpload: function (uniqueId) {
        return axiosInstance({
            method: "DELETE",
            url: `/api/upload/bulk/${uniqueId}`,
        });
    }
};
export default TenantService;
