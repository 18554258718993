import axiosInstance from "../api/api";
import _ from "lodash";
import SecurityRole from "../util/securityConstant";

const AuthService = {
  login: function (username, password) {
    return axiosInstance({
      url: "/api/auth/login",
      method: "POST",
      data: {
        username: username,
        password: password,
      },
    });
  },
  changePassword: function (formData) {
    return axiosInstance({
      url: "/api/auth/password/change",
      method: "POST",
      data: formData,
    });
  },
  getBaseURLByRole: function () {
    const roleList = JSON.parse(localStorage.getItem("CLASHMATE_ROLE_LIST"));
    if (_.includes(roleList, SecurityRole.ROLE_STUDENT)) {
      return "student";
    } else if (_.includes(roleList, SecurityRole.ROLE_TEACHER)) {
      return "teacher";
    } else if (_.includes(roleList, SecurityRole.ROLE_ADMIN) || _.includes(roleList, SecurityRole.ROLE_TENANT_ADMIN)) {
      return "admin";
    } else if (_.includes(roleList, SecurityRole.ROLE_PARENT)) {
      return "parent";
    } else {
      return "superAdmin";
    }
  },
  isCurrentUserRoleMatch: function (userRole) {
    const roleList = JSON.parse(localStorage.getItem("CLASHMATE_ROLE_LIST"));
    return _.includes(roleList, userRole);
  },
};
export default AuthService;
