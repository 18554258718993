/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
	faComments,
	faCommentSlash,
	faPaperPlane,
	faUsersSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "assets/css/chat.css";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import _ from "lodash";
import React from "react";
import {
	Button,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	UncontrolledDropdown,
} from "reactstrap";
import FormInputSelect from "../../../components/common/form/FormInputSelect";
import ChatGroupService from "../../../services/chatGroupService";
import ChatMessageService from "../../../services/chatMessageService";
import ChatParticipantService from "../../../services/chatParticipantService";
import CourseSectionSubjectService from "../../../services/courseSectionSubjectService";
import CourseSectionSubjectStudentService from "../../../services/courseSectionSubjectStudentService";
import CourseSectionSubjectTeacherService from "../../../services/courseSectionSubjectTeacherService";
import WebsocketService from "../../../services/websocketService";
import ChatMessage from "./ChatMessage";
import CourseSectionSubjectUserListModal from "./CourseSectionSubjectUserListModal";

class ChatPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			groupList: [],
			chatMessageList: [],
			isCourseSectionSubjectUserListModalOpen: false,
			courseSectionSubjectUserList: [],
			participantList: [],
		};
		this.handleChange = this.handleChange.bind(this);
		this.onModalOpened = this.onModalOpened.bind(this);
		this.changeRecipient = this.changeRecipient.bind(this);
		this.handleWSChatMessage = this.handleWSChatMessage.bind(this);
		this.sendChatMessage = this.sendChatMessage.bind(this);
		this.submitSendChatMessage = this.submitSendChatMessage.bind(this);
		this.handleCourseSectionSubjectUserSelectSubmit = this.handleCourseSectionSubjectUserSelectSubmit.bind(
			this
		);
		this.fetchRecipientName = this.fetchRecipientName.bind(this);
		this.toggleCourseSectionSubjectUserListModal = this.toggleCourseSectionSubjectUserListModal.bind(
			this
		);
		this.onParticipantUserListModalOpened = this.onParticipantUserListModalOpened.bind(
			this
		);
		this.loadChatDataForCourseSectionSubject = this.loadChatGroupAndParticipantForCourseSectionSubject.bind(
			this
		);
		this.scrollToMessageBottom = this.scrollToMessageBottom.bind(this);
	}

	componentDidMount() {
		CourseSectionSubjectService.fetchCourseSectionSubjectsForCurrentUser().then(
			(response) => {
				const subjectList = response.data;
				this.setState({
					subjectList: subjectList,
					courseSectionSubjectUID: subjectList[0].uniqueId,
				});
				this.loadChatGroupAndParticipantForCourseSectionSubject(
					subjectList[0].uniqueId
				);
				ChatParticipantService.currentUserParticipant().then(
					(currentUserParticipantResponse) => {
						this.setState({
							senderParticipantDetails: currentUserParticipantResponse.data,
						});
					}
				);
				WebsocketService.subscribe(["/user/topic/chat"], this.handleWSChatMessage);
			}
		);
	}

	scrollToMessageBottom() {
		if (this.messagesEnd) {
			this.messagesEnd.scrollIntoView({
				block: "end",
				inline: "nearest",
			});
		}
	}

	loadChatGroupAndParticipantForCourseSectionSubject(courseSectionSubjectUID) {
		ChatGroupService.list(courseSectionSubjectUID).then((responseChat) => {
			const groupList = responseChat.data;
			this.setState({ groupList: groupList });
		});
		ChatParticipantService.list(courseSectionSubjectUID).then((responseChat) => {
			const participantList = responseChat.data;
			console.log(participantList);
			this.setState({ participantList: participantList });
		});
	}

	onParticipantUserListModalOpened() {
		let courseSectionSubjectUserList = [];
		CourseSectionSubjectTeacherService.fetchTeachers(
			this.state.courseSectionSubjectUID
		).then((responseTeacher) => {
			_.forEach(responseTeacher.data, function (teacherDTO) {
				courseSectionSubjectUserList.push(teacherDTO.teacher);
			});
			CourseSectionSubjectStudentService.fetchStudents(
				this.state.courseSectionSubjectUID
			).then((response) => {
				_.forEach(response.data, function (studentDTO) {
					courseSectionSubjectUserList.push(studentDTO.student);
				});
				let senderParticipantDetailList = [this.state.senderParticipantDetails];
				const participantList = _.concat(
					senderParticipantDetailList,
					this.state.participantList
				);
				_.remove(
					courseSectionSubjectUserList,
					function (courseSectionSubjectUserObj) {
						return _.find(participantList, {
							userDTO: { uniqueId: courseSectionSubjectUserObj.uniqueId },
						});
					}
				);
				this.setState({
					courseSectionSubjectUserList: courseSectionSubjectUserList,
				});
			});
		});
	}

	toggleCourseSectionSubjectUserListModal() {
		this.setState({
			isCourseSectionSubjectUserListModalOpen: !this.state
				.isCourseSectionSubjectUserListModalOpen,
		});
	}

	handleWSChatMessage(message) {
		const messageBody = JSON.parse(message.body);
		if (
			messageBody.courseSectionSubjectUID === this.state.courseSectionSubjectUID
		) {
			let chatMessageList = this.state.chatMessageList;
			chatMessageList.push(messageBody);
			this.setState({ chatMessageList: chatMessageList });
			this.scrollToMessageBottom();
		}
	}

	sendChatMessage(messageType) {
		let chatMessageData = {
			chatMessage: this.state.chatMessage,
			messageType: messageType,
			courseSectionSubjectUID: this.state.courseSectionSubjectUID,
			chatGroupUID: this.state.isGroup ? this.state.selectedRecipientUID : null,
			recipientUID: this.state.isGroup ? null : this.state.selectedRecipientUID,
		};
		this.setState({ chatMessage: "" });
		WebsocketService.send("/app/chat", JSON.stringify(chatMessageData));
	}

	submitSendChatMessage(event) {
		event.preventDefault();
		this.sendChatMessage("USER");
	}

	onModalOpened() {
		console.log("Manual Question Assignment Modal Opened");
	}

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
		if (event.target.name === "courseSectionSubjectUID") {
			this.setState({ selectedRecipientUID: null });
			this.loadChatGroupAndParticipantForCourseSectionSubject(event.target.value);
		}
	}

	fetchRecipientName() {
		const isGroup = this.state.isGroup;
		const selectedRecipientUID = this.state.selectedRecipientUID;
		if (isGroup) {
			const groupList = this.state.groupList;
			const currentGroupObj = _.filter(groupList, function (groupObj) {
				return groupObj.uniqueId === selectedRecipientUID;
			});
			return currentGroupObj[0].name;
		} else {
			const participantList = this.state.participantList;
			const currentParticipantObj = _.filter(
				participantList,
				function (participantObj) {
					return participantObj.uniqueId === selectedRecipientUID;
				}
			);
			return currentParticipantObj[0].userDTO.fullName;
		}
	}

	changeRecipient(recipientUID, isGroup) {
		this.setState({ selectedRecipientUID: recipientUID, isGroup: isGroup });
		let formData = {
			courseSectionSubjectUID: this.state.courseSectionSubjectUID,
			chatGroupUID: isGroup ? recipientUID : null,
			recipientUID: isGroup ? null : recipientUID,
		};
		ChatMessageService.list(formData).then((response) => {
			if (response.responseStatus === "SUCCESS") {
				this.setState({ chatMessageList: response.data });
				this.scrollToMessageBottom();
			} else {
				this.setState({ errorData: response.data });
				console.error("Error    ==>     " + response.message);
			}
		});
	}

	handleCourseSectionSubjectUserSelectSubmit(event) {
		event.preventDefault();
		const selectedCourseSectionSubjectUsertUID = this.state
			.selectedCourseSectionSubjectUsertUID;
		let formData = {
			userUID: selectedCourseSectionSubjectUsertUID,
		};
		ChatParticipantService.create(formData).then((response) => {
			if (response.responseStatus === "SUCCESS") {
				const participantData = response.data;
				let participantList = this.state.participantList;
				participantList.push(participantData);
				this.setState({
					chatMessage: "It's the beginnning of your chat.",
					selectedRecipientUID: response.data.uniqueId,
					isGroup: false,
					isStudentListModalOpen: false,
					participantList: participantList,
				});
				this.sendChatMessage("SYSTEM");
			} else {
				this.setState({ errorData: response.data });
				console.error("Error    ==>     " + response.message);
			}
		});
	}

	render() {
		let obj = this;
		return (
			<>
				<CourseSectionSubjectUserListModal
					isOpen={this.state.isCourseSectionSubjectUserListModalOpen}
					toggleModal={this.toggleCourseSectionSubjectUserListModal}
					onModalOpened={this.onParticipantUserListModalOpened}
					size="lg"
					courseSectionSubjectUserList={this.state.courseSectionSubjectUserList}
					handleChange={this.handleChange}
					handleCourseSectionSubjectUserSelectSubmit={
						this.handleCourseSectionSubjectUserSelectSubmit
					}
				/>
				<SimpleHeader
					name="Welcome"
					parentName="Chat"
					newFormSelectInput={
						<FormInputSelect
							name="courseSectionSubjectUID"
							className="form-control-label"
							id="courseSectionSubjectUID"
							onChange={this.handleChange}
							optionList={this.state.subjectList}
							optionKey="uniqueId"
							optionValue="title"
							value={this.state.courseSectionSubjectUID}
							isNakedSelectInput={true}
							inputClassName={"form-control-sm"}
						/>
					}
				/>
				<Container className="mt--6" fluid>
					<Container>
						<div className="content-wrapper">
							<div className="row gutters">
								<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
									<div className="card m-0 container-height">
										<div className="row no-gutters">
											<div className="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-3">
												<div className="users-container">
													<UncontrolledDropdown className="create-btn">
														<DropdownToggle caret color="primary">
															Create New
														</DropdownToggle>
														<DropdownMenu>
															<DropdownItem
																href="#pablo"
																onClick={() =>
																	this.setState({
																		isCourseSectionSubjectUserListModalOpen: true,
																	})
																}
															>
																Message
															</DropdownItem>
														</DropdownMenu>
													</UncontrolledDropdown>
													<ul className="users ul-chat">
														{_.isEmpty(this.state.groupList) ? (
															<div className="no-participant-text">
																<FontAwesomeIcon icon={faUsersSlash} size="5x" />
															</div>
														) : (
															this.state.groupList.map(function (value, index) {
																return (
																	<li
																		className={`person ${
																			obj.state.selectedRecipientUID === value.uniqueId
																				? "active-user"
																				: ""
																		}`}
																		id={value.uniqueId}
																		onClick={(e) => {
																			e.preventDefault();
																			obj.changeRecipient(value.uniqueId, true);
																		}}
																	>
																		<div className="user">
																			<img
																				src="https://www.bootdey.com/img/Content/avatar/avatar3.png"
																				alt="Retail Admin"
																			/>
																			{/* <span className="status busy"></span> */}
																		</div>
																		<p className="name-time">
																			<span className="name">{value.name}</span>
																		</p>
																	</li>
																);
															})
														)}

														{this.state.participantList.map(function (value, index) {
															return (
																<li
																	className={`person ${
																		obj.state.selectedRecipientUID === value.uniqueId
																			? "active-user"
																			: ""
																	}`}
																	id={value.uniqueId}
																	onClick={(e) => {
																		e.preventDefault();
																		obj.changeRecipient(value.uniqueId, false);
																	}}
																>
																	<div className="user">
																		<img
																			src="https://www.bootdey.com/img/Content/avatar/avatar3.png"
																			alt="Retail Admin"
																		/>
																		{/* <span className="status busy"></span> */}
																	</div>
																	<p className="name-time">
																		<span className="name">{value.userDTO.fullName}</span>
																	</p>
																</li>
															);
														})}
													</ul>
												</div>
											</div>
											{this.state.selectedRecipientUID ? (
												<div className="col-xl-8 col-lg-8 col-md-8 col-sm-9 col-9">
													<div className="selected-user">
														<span>
															<span className="name">{obj.fetchRecipientName()}</span>
														</span>
													</div>
													<div className="chat-container">
														<ul className="chat-box chatContainerScroll ul-chat">
															{_.isEmpty(this.state.chatMessageList) ? (
																<div className="no-chat-recipient-text">
																	<FontAwesomeIcon icon={faCommentSlash} size="10x" />
																</div>
															) : (
																this.state.chatMessageList.map(function (value, index) {
																	const isSenderMessage =
																		value.senderParticipantDTO.uniqueId ===
																		obj.state.senderParticipantDetails.uniqueId;
																	return (
																		<>
																			<ChatMessage
																				time={value.dateCreated}
																				messageText={value.chatText}
																				messageClass={isSenderMessage ? "chat-right" : "chat-left"}
																				participantName={
																					value.senderParticipantDTO.userDTO.fullName
																				}
																			/>
																			<div
																				style={{ float: "left", clear: "both" }}
																				ref={(el) => {
																					obj.messagesEnd = el;
																				}}
																			></div>
																		</>
																	);
																})
															)}
														</ul>
														<div className="form-group mt-3 mb-0">
															<FormGroup>
																<InputGroup>
																	<Input
																		name="chatMessage"
																		placeholder="Enter message here..."
																		type="text"
																		onChange={this.handleChange}
																		value={this.state.chatMessage}
																	/>
																	<InputGroupAddon addonType="append">
																		<Button
																			className="btn-icon btn-2"
																			color="primary"
																			type="button"
																			onClick={this.submitSendChatMessage}
																		>
																			<span className="btn-inner--icon">
																				<FontAwesomeIcon icon={faPaperPlane} />
																			</span>
																		</Button>
																	</InputGroupAddon>
																</InputGroup>
															</FormGroup>
														</div>
													</div>
												</div>
											) : (
												<div className="no-chat-text">
													<FontAwesomeIcon icon={faComments} size="10x" />
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</Container>
				</Container>
			</>
		);
	}
}

export default ChatPage;
