import axiosInstance from "../api/api";

const NextStepsService = {
    list: function () {
        return axiosInstance({
            url: "/api/nextSteps/",
            method: "GET",
        });
    },
    fetchBySummary : function(uniqueId){
        return axiosInstance({
            url: `/api/nextSteps/fetchBySummary/${uniqueId}`,
            method: "GET",
        });
    },
    fetch: function (uniqueId) {
        return axiosInstance({
            url: `/api/nextSteps/${uniqueId}`,
            method: "GET",
        });
    },
    delete: function (uniqueId) {
        return axiosInstance({
            url: `/api/nextSteps/${uniqueId}`,
            method: "DELETE",
        });
    },
    markClosed: function (uniqueId) {
        return axiosInstance({
            url: `/api/nextSteps/markClosed/${uniqueId}`,
            method: "PATCH",
        });
    },
    create: function (formData) {
        return axiosInstance({
            url: `/api/nextSteps/`,
            method: "POST",
            data: formData,
        });
    },
    update: function (formData) {
        return axiosInstance({
            url: `/api/nextSteps/`,
            method: "PATCH",
            data: formData,
        });
    },
};
export default NextStepsService;
