/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Alert from "reactstrap/es/Alert";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileAlt, faFileAudio, faFilePdf, faFileVideo, faImage} from "@fortawesome/free-regular-svg-icons";


class MarketAttachment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentDocument: this.props.contentDocument,
            size: "1"
        };
    }

    fetchIcon = contentDocument => {
        let icon = null;
        let contentType = contentDocument?.document?.contentType;
        if (/image\/*/.test(contentType)) {
            icon = <FontAwesomeIcon onClick={() => this.props.onIconClick} icon={faImage}
                                    size={`${this.state.size}x`}
                                    color="#11cdef"/>
        } else if (/video\/*/.test(contentType)) {
            icon = <FontAwesomeIcon onClick={() => this.props.onIconClick} icon={faFileVideo}
                                    size={`${this.state.size}x`}
                                    color="#11cdef"/>
        } else if (/audio\/*/.test(contentType)) {
            icon = <FontAwesomeIcon onClick={() => this.props.onIconClick} icon={faFileAudio}
                                    size={`${this.state.size}x`}
                                    color="red"/>
        } else if (/application\/pdf/.test(contentType)) {
            icon = <FontAwesomeIcon onClick={() => this.props.onIconClick} icon={faFilePdf}
                                    size={`${this.state.size}x`}
                                    color="crimson"/>
        } else if (/application\/*/.test(contentType)) {
            icon = <FontAwesomeIcon onClick={() => this.props.onIconClick} icon={faFileAlt}
                                    size={`${this.state.size}x`}
                                    color="cadetblue"/>
        } else {
            icon = <FontAwesomeIcon onClick={() => this.props.onIconClick} icon={faFileAlt}
                                    size={`${this.state.size}x`}
                                    color="#11cdef"/>
        }
        return icon;

    };

    fetchLabel = contentDocument => {
        let label = null;
        let contentType = contentDocument?.document?.contentType;
        if (/image\/*/.test(contentType)) {
            label = " Image"
        } else if (/video\/*/.test(contentType)) {
            label = " Video"
        } else if (/audio\/*/.test(contentType)) {
            label = " Audio"
        } else if (/application\/pdf/.test(contentType)) {
            label = " PDF"
        } else if (/application\/*/.test(contentType)) {
            label = " Download"
        } else {
            label = " Other"
        }
        return label;

    };

    componentDidMount() {

    }

    render() {
        let obj = this;
        return (
            <>
                <Alert color={"secondary"}>
                    {obj.fetchIcon(this.state.contentDocument)} {" "}
                    <span
                        className="alert-inner--text"><strong>{obj.fetchLabel(this.state.contentDocument)} {": "} </strong> {this.state?.contentDocument?.name}
                    </span>
                </Alert>
            </>
        );
    }
}

export default MarketAttachment;
