import axiosInstance from "../api/api";

const UserPermissionService = {
  listByUser: function (userUID) {
    return axiosInstance({
      url: `/api/userPermission/?userUID=${userUID}`,
      method: "GET",
    });
  },
  create: function (formData) {
    return axiosInstance({
      url: "/api/userPermission/",
      method: "POST",
      data: formData,
    });
  },
  delete: function (uniqueId) {
    return axiosInstance({
      url: `/api/userPermission/${uniqueId}`,
      method: "DELETE",
    });
  },
};
export default UserPermissionService;
