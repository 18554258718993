/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/pages/dashboards/TeacherDashboard.jsx";
import QuestionList from "../views/pages/question/List";
import QuestionCreate from "../views/pages/question/Create";
import QuestionDetails from "../views/pages/question/Details";
import ExaminationList from "../views/pages/examination/List";
import ExaminationCreate from "../views/pages/examination/Create";
import ExaminationDetails from "../views/pages/examination/Details";
import OnlineExaminationCreate from "../views/pages/onlineExamnation/Create";
import OnlineExaminationList from "../views/pages/onlineExamnation/List";
import OnlineExaminationDetails from "../views/pages/onlineExamnation/Details";
import TeacherTimeTable from "../views/pages/timetable/teacher/TeacherTimeTable";
import AttendanceCreate from "../views/pages/attendance/AttendanceCreate";
import AttendanceTeacherList from "../views/pages/attendance/AttendanceTeacherList";
import LedgerListTeacher from "../views/pages/finance/teacher/LedgerListTeacher";
import LedgerDetailsTeacher from "../views/pages/finance/teacher/LedgerDetailsTeacher";
import TeacherProfile from "../views/pages/profile/teacher/TeacherProfile";
import TeacherSettings from "../views/pages/settings/TeacherSettings";
import LessonPlanCreate from "../views/pages/lessonPlan/Create";
import LessonPlanList from "../views/pages/lessonPlan/List";
import LessonPlanDetail from "../views/pages/lessonPlan/Details";
import LessonPlanEdit from "../views/pages/lessonPlan/Edit";
import MeetingList from "../views/pages/communication/video/List";
import MeetingCreate from "../views/pages/communication/video/Create";
import MeetingDetails from "../views/pages/communication/video/Details";
import MeetingStart from "../views/pages/communication/video/Start";
import Feeds from "../views/pages/social/post/Feeds";
import PostDetails from "../views/pages/social/post/PostDetails";
import CreatePost from "../views/pages/social/post/CreatePost";
import PostEdit from "../views/pages/social/post/Edit";
import NoticeDetails from "../views/pages/social/noticeboard/NoticeDetails";
import Notices from "../views/pages/social/noticeboard/Notices";
import StudyMaterialCreate from "../views/pages/marketplace/studymaterial/Create";
import StudyMaterialList from "../views/pages/marketplace/studymaterial/List";
import StudyMaterialEdit from "../views/pages/marketplace/studymaterial/Edit";
import StudyMaterialDetails from "../views/pages/marketplace/studymaterial/Details";
import UserNotificationQueueList from "../views/pages/notification/queue/user/List";
import UserNotificationQueueDetails from "../views/pages/notification/queue/user/Details";
import ContentCreate from "../views/pages/marketplace/content/Create";
import ContentEdit from "../views/pages/marketplace/content/Edit";
import ContentDetails from "../views/pages/marketplace/content/Details";
import ChatPage from "../views/pages/chat";

const teacherRoutes = [
	{
		path: "/dashboard",
		name: "Dashboard",
		icon: "ni ni-shop text-primary",
		component: Dashboard,
		layout: "/teacher",
	},
	{
		path: "/question/list",
		name: "Question",
		icon: "ni ni-shop text-primary",
		component: QuestionList,
		layout: "/teacher",
	},
	{
		path: "/question/create",
		name: "Question",
		icon: "ni ni-shop text-primary",
		component: QuestionCreate,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/question/show/:uniqueId",
		name: "Question",
		icon: "ni ni-shop text-primary",
		component: QuestionDetails,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/examination/list",
		name: "Examination",
		icon: "ni ni-shop text-primary",
		component: ExaminationList,
		layout: "/teacher",
	},
	{
		path: "/examination/create",
		name: "Examination",
		icon: "ni ni-shop text-primary",
		component: ExaminationCreate,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/examination/show/:uniqueId",
		name: "Examination",
		icon: "ni ni-shop text-primary",
		component: ExaminationDetails,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/onlineExamination/create/:examinationUID",
		name: "Online Examination",
		icon: "ni ni-shop text-primary",
		component: OnlineExaminationCreate,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/onlineExamination/list/:examinationUID",
		name: "Online Examination",
		icon: "ni ni-shop text-primary",
		component: OnlineExaminationList,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/onlineExamination/show/:examinationUID/:uniqueId",
		name: "Online Examination",
		icon: "ni ni-shop text-primary",
		component: OnlineExaminationDetails,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/timeTable",
		name: "Time Table",
		icon: "ni ni-shop text-primary",
		component: TeacherTimeTable,
		layout: "/teacher",
		invisible: false,
	},
	{
		path: "/attendance/assign",
		name: "Attendance",
		icon: "ni ni-shop text-primary",
		component: AttendanceCreate,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/attendance",
		name: "Attendance",
		icon: "ni ni-shop text-primary",
		component: AttendanceTeacherList,
		layout: "/teacher",
		invisible: false,
	},
	{
		path: "/ledger/list",
		name: "Ledger",
		icon: "ni ni-shop text-primary",
		component: LedgerListTeacher,
		layout: "/teacher",
		invisible: false,
	},
	{
		path: "/ledger/show/:uniqueId",
		name: "Ledger",
		icon: "ni ni-shop text-primary",
		component: LedgerDetailsTeacher,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/settings",
		name: "Settings",
		icon: "ni ni-shop text-primary",
		component: TeacherSettings,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/profile",
		name: "Profile",
		icon: "ni ni-shop text-primary",
		component: TeacherProfile,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/lessonPlan/create",
		name: "Lesson Plan",
		icon: "ni ni-shop text-primary",
		component: LessonPlanCreate,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/lessonPlan/list",
		name: "Lesson Plan",
		icon: "ni ni-shop text-primary",
		component: LessonPlanList,
		layout: "/teacher",
	},
	{
		path: "/lessonPlan/show/:uniqueId",
		name: "Lesson Plan",
		icon: "ni ni-shop text-primary",
		component: LessonPlanDetail,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/lessonPlan/edit/:uniqueId",
		name: "Lesson Plan",
		icon: "ni ni-shop text-primary",
		component: LessonPlanEdit,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/meeting/list",
		name: "Meeting",
		icon: "ni ni-shop text-primary",
		component: MeetingList,
		layout: "/teacher",
	},
	{
		path: "/meeting/create",
		name: "Meeting",
		icon: "ni ni-shop text-primary",
		component: MeetingCreate,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/meeting/show/:uniqueId",
		name: "Meeting",
		icon: "ni ni-shop text-primary",
		component: MeetingDetails,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/meeting/start/:uniqueId",
		name: "Meeting",
		icon: "ni ni-shop text-primary",
		component: MeetingStart,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/post/feeds",
		name: "Feeds",
		icon: "ni ni-shop text-primary",
		component: Feeds,
		layout: "/teacher",
		invisible: false,
	},
	{
		path: "/post/create",
		name: "Social",
		icon: "ni ni-shop text-primary",
		component: CreatePost,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/post/show/:uniqueId",
		name: "TeacherPostDetails",
		icon: "ni ni-shop text-primary",
		component: PostDetails,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/post/edit/:uniqueId",
		name: "EditPost",
		icon: "ni ni-shop text-primary",
		component: PostEdit,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/notice/",
		name: "Notices",
		icon: "ni ni-shop text-primary",
		component: Notices,
		layout: "/teacher",
		invisible: false,
	},
	{
		path: "/notices/show/:uniqueId",
		name: "View notice",
		icon: "ni ni-shop text-primary",
		component: NoticeDetails,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/studyMaterial/create",
		name: "Study Material",
		icon: "ni ni-shop text-primary",
		component: StudyMaterialCreate,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/studyMaterial/list",
		name: "Study Material",
		icon: "ni ni-shop text-primary",
		component: StudyMaterialList,
		layout: "/teacher",
		invisible: false,
	},
	{
		path: "/studyMaterial/edit/:uniqueId",
		name: "Study Material",
		icon: "ni ni-shop text-primary",
		component: StudyMaterialEdit,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/studyMaterial/show/:uniqueId",
		name: "Study Material",
		icon: "ni ni-shop text-primary",
		component: StudyMaterialDetails,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/content/create/:uniqueId",
		name: "Content",
		icon: "ni ni-shop text-primary",
		component: ContentCreate,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/content/edit/:uniqueId",
		name: "Content",
		icon: "ni ni-shop text-primary",
		component: ContentEdit,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/content/show/:uniqueId",
		name: "Content",
		icon: "ni ni-shop text-primary",
		component: ContentDetails,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/user/notificationQueue/list",
		name: "Notification Queue",
		icon: "ni ni-shop text-primary",
		component: UserNotificationQueueList,
		layout: "/teacher",
	},
	{
		path: "/user/notificationQueue/show/:uniqueId",
		name: "Notification Queue",
		icon: "ni ni-shop text-primary",
		component: UserNotificationQueueDetails,
		layout: "/teacher",
		invisible: true,
	},
	{
		path: "/chat/list",
		name: "Chat Page",
		icon: "ni ni-shop text-primary",
		component: ChatPage,
		layout: "/teacher",
		invisible: false,
	},
];

export default teacherRoutes;
