/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
    Button,
    Card,
    CardHeader,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
} from "reactstrap";
import ContactService from "../../../services/contactService";
import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";
import FormInput from "../../../components/common/form/FormInput";
import _ from "lodash";
import {ContactPermissions} from "../../../services/permissions";

class ContactList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            alert: null,
            filterName : "",
            filterSurname :"",
            filterCompany : "",
            filteredList : []
        };
        this.delete = this.delete.bind(this);
        this.notify = this.notify.bind(this);
    }

    componentDidMount() {
        ContactService.list().then((response) => {
            console.log(response);
            this.setState({list: response, filteredList : response});
        });
    }

    onChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }

    applyFilter = () => {
        const { list, filterName, filterSurname, filterCompany } = this.state;

        const filteredList = _.filter(list, (contact) => {
            const nameMatches = filterName ? _.includes(_.toLower(contact.firstName), _.toLower(filterName)) : true;
            const surnameMatches = filterSurname ? _.includes(_.toLower(contact.lastName), _.toLower(filterSurname)) : true;
            const companyMatches = filterCompany ? _.includes(_.toLower(contact.company.legalName), _.toLower(filterCompany)) : true;

            return nameMatches && surnameMatches && companyMatches;
        });

        this.setState({ filteredList : filteredList });
    }

    clearFilter = () => {
        this.setState({filteredList : this.state.list, filterName : "", filterSurname : "", filterCompany : "" })
    }

    hideDeleteAlert = () => {
        this.setState({
            alert: null
        });
    };

    confirmDeleteAlert = (uniqueId) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{display: "block"}}
                    title="Are you sure?"
                    onConfirm={() => this.delete(uniqueId)}
                    onCancel={this.hideDeleteAlert}
                    showCancel
                    confirmBtnBsStyle="danger"
                    confirmBtnText="Yes, delete it!"
                    cancelBtnBsStyle="secondary"
                    cancelBtnText="Cancel"
                    btnSize=""
                >
                    You won't be able to revert this!
                </ReactBSAlert>
            )
        });
    };

    delete(uniqueId) {
        this.setState({alert: null})
        ContactService.delete(uniqueId).then((response) => {
            console.log(response);
            this.notify(
                response.responseStatus.toLowerCase(),
                response.responseStatus,
                response.message
            );
            //TODO: TO be fixed with redux implementation
            //   this.props.history.push("/admin/dashboard");
            ContactService.list().then((response) => {
                console.log(response);
                this.setState({list: response});
            });
        });
    }

    notify = (type, title, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    render() {
        let obj = this;
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <SimpleHeader
                    name="List"
                    parentName="Contact"
                    heading="XareGrwoth"
                    newBtnUrl="/admin/contact/create"
                    newBtnText="Create Contact"
                />
                {this.state.alert}
                <Container className="mt--6" fluid>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Contacts List</h3>
                                        </div>
                                    </Row>
                                    <Row>
                                        <p style={{ fontStyle: "italic", color: "gray" }}>
                                            Use the filters below to narrow down your search results.
                                        </p>
                                    </Row>
                                    <Row>
                                        {/*<Col sm={2}>
                        <FormInput
                            name="dateFrom"
                            className="form-control-label"
                            id="date"
                            placeholder="inputPlaceHolder.meetingReport.date"
                            type="date"
                            onChange={this.onChange}
                            value={this.state.dateFrom || ""}
                            label="Date From"
                        />
                      </Col>
                      <Col sm={2}>
                        <FormInput
                            name="dateTo"
                            className="form-control-label"
                            id="date"
                            placeholder="inputPlaceHolder.meetingReport.date"
                            type="date"
                            onChange={this.onChange}
                            value={this.state.dateTo || ""}
                            label="Date To"
                        />
                      </Col>*/}
                                        <Col md="2">
                                            <FormInput
                                                name="filterName"
                                                className="form-control-label"
                                                id="name"
                                                placeholder="Name"
                                                type="text"
                                                onChange={this.onChange}
                                                label="Name"
                                                value={this.state?.filterName || ""}
                                                // errorMsg={this.props.errorObj?.name || ""}
                                            />
                                        </Col>
                                        <Col md="2">
                                            <FormInput
                                                name="filterSurname"
                                                className="form-control-label"
                                                id="countryName"
                                                placeholder="Surname"
                                                type="text"
                                                onChange={this.onChange}
                                                label="Surname"
                                                value={this.state?.filterSurname || ""}
                                                // errorMsg={this.props.errorObj?.name || ""}
                                            />
                                        </Col>
                                        <Col md="2">
                                            <FormInput
                                                name="filterCompany"
                                                className="form-control-label"
                                                id="companyName"
                                                placeholder="Company Name"
                                                type="text"
                                                onChange={this.onChange}
                                                label="Company Name"
                                                value={this.state?.filterCompany || ""}
                                                // errorMsg={this.props.errorObj?.name || ""}
                                            />
                                        </Col>
                                        <Col md={"2"}></Col>
                                        <Col sm={3} style={{marginTop: "30px"}}>
                                            <Button  size="md" outline color="default" type="button"
                                            onClick={this.applyFilter}
                                            >
                                                Apply Filter
                                            </Button>
                                            <Button  color="default"  size="md" outline type="button"
                                            onClick={this.clearFilter}
                                            >
                                                Clear Filter
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th className="sort" data-sort="name" scope="col">
                                            #
                                        </th>
                                        <th className="sort" data-sort="budget" scope="col">
                                            Date Created
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Title
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            First Name
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Last Name
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Company Name
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Contact Role
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Email Address
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Contact Number
                                        </th>
                                        <th scope="col"/>
                                    </tr>
                                    </thead>
                                    <tbody className="list">
                                    {this.state.filteredList.map(function (value, index) {
                                        return (
                                            <tr key={index} style={{display: ContactPermissions.read()}}>
                                                <td className="budget">{index + 1}</td>
                                                <td className="budget">{value.dateCreated}</td>
                                                <td className="budget">{value.title}</td>
                                                <td className="budget">{value.firstName}</td>
                                                <td className="budget">{value.lastName}</td>
                                                <td className="budget">{value.company.legalName}</td>
                                                <td className="budget">{value.contactRole}</td>
                                                <td className="budget">{value.email || "N/A"}</td>
                                                <td className="budget">{value.number || "N/A"}</td>
                                                <td className="text-right">
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle
                                                            color=""
                                                            size="sm"
                                                            className="btn-icon-only text-light"
                                                        >
                                                            <i className="fas fa-ellipsis-v"/>
                                                        </DropdownToggle>
                                                        <DropdownMenu
                                                            className="dropdown-menu-arrow"
                                                            right
                                                        >
                                                            <DropdownItem
                                                                style={{display:ContactPermissions.read()}}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    obj.props.history.push(
                                                                        `/admin/contact/show/${value.uniqueId}`
                                                                    );
                                                                }}
                                                            >
                                                                Details
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                style={{display:ContactPermissions.update(value.createdBy)}}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    obj.props.history.push(
                                                                        `/admin/contact/edit/${value.uniqueId}`
                                                                    );
                                                                }}
                                                            >
                                                                Edit
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                style={{display:ContactPermissions.delete()}}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    obj.confirmDeleteAlert(`${value.uniqueId}`);
                                                                }}
                                                            >
                                                                Delete
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default ContactList;
