/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form
} from "reactstrap";
import AdminService from "../../../../services/adminService";
import AdminForm from "../../admin/Form";

class TenantAdminCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorData: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    console.log(this.state);
  }

  handleSubmit(event) {
    event.preventDefault();
    const { tenantUID } = this.props.match.params;
    this.setState({ tenantUID: tenantUID });
    console.log(this.state);
    AdminService.create(this.state).then(response => {
      console.log(response.data);
      if (response.responseStatus === "SUCCESS") {
        this.props.history.push(`/superAdmin/tenant/show/${tenantUID}`);
      } else {
        this.setState({ errorData: response.data });
        console.error("Error    ==>     " + response.message);
      }
    });
  }

  render() {
    return (
      <>
        <SimpleHeader
          name="Create Admin"
          parentName="Super Admin"
          heading="ClashMate"
        />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <h3 className="mb-0">Create Admin</h3>
            </CardHeader>
            <CardBody>
              <Form role="form" onSubmit={this.handleSubmit}>
                <AdminForm
                  onChange={this.handleChange}
                  valueObj={this.state}
                  errorObj={this.state.errorData}
                  isEdit={false}
                  isSuperAdminView={true}
                />
                <div className="text-center">
                  <Button className="my-4" color="info" type="submit">
                    Submit
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

export default TenantAdminCreate;
