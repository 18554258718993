import axiosInstance from "../api/api";

const CourseSectionSubjectStudentService = {
  list: function () {
    return axiosInstance({
      url: `/api/courseSectionSubjectStudent/`,
      method: "GET",
    });
  },
  fetchStudents: function (uniqueId) {
    return axiosInstance({
      url: `/api/courseSectionSubjectStudent/courseSectionSubject/${uniqueId}`,
      method: "GET",
    });
  },
  fetchCourseSectionSubjects: function (uniqueId) {
    return axiosInstance({
      url: `/api/courseSectionSubjectStudent/student/${uniqueId}`,
      method: "GET",
    });
  },
  fetchCourseSectionSubjectsForCurrentStudent: function (uniqueId) {
    return axiosInstance({
      url: "/api/courseSectionSubjectStudent/student/",
      method: "GET",
    });
  },
  delete: function (formData) {
    return axiosInstance({
      url: "/api/courseSectionSubjectStudent/",
      method: "DELETE",
      data: formData,
    });
  },
  create: function (formData) {
    return axiosInstance({
      url: "/api/courseSectionSubjectStudent/",
      method: "POST",
      data: formData,
    });
  },
};
export default CourseSectionSubjectStudentService;
