import axiosInstance from "../api/api";

const AdminService = {
  list: function (roleName) {
    return axiosInstance({
      url: `/api/admin/?roleName=${roleName}`,
      method: "GET",
    });
  },
  fetch: function (uniqueId) {
    return axiosInstance({
      url: `/api/admin/${uniqueId}`,
      method: "GET",
    });
  },
  delete: function (uniqueId) {
    return axiosInstance({
      url: `/api/admin/${uniqueId}`,
      method: "DELETE",
    });
  },
  create: function (formData) {
    return axiosInstance({
      url: `/api/admin/`,
      method: "POST",
      data: formData,
    });
  },
  update: function (formData) {
    return axiosInstance({
      url: `/api/admin/`,
      method: "PATCH",
      data: formData,
    });
  },
  uploadLogo: function (formData) {
    return axiosInstance({
      url: `/api/admin/uploadLogo/`,
      method: "POST",
      data: formData,
    });
  },
};
export default AdminService;
