/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Form
} from "reactstrap";
import ContentService from "../../../services/xgContentService";
import ContentForm from "./Form";
import {contentCategory} from "../../../util/contentCategory";
import {contentArticleType} from "../../../util/contentArticleType";
import {contentType} from "../../../util/contentType";
import _ from "lodash";
import UtilService from "../../../services/utilService";
import {value} from "lodash/seq";
import MeetingReportService from "../../../services/meetingReportService";
import MeetingForm from "./Form";
import CompanyService from "../../../services/companyService";
import ContactService from "../../../services/contactService";

class MeetingEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorData: {},
            eventType: 'CALL',
            attendees: [],
            companySuggestions: [],
            companyList: [], // This should be populated with company data from your database
            contacts: [], // This should be populated based on the selected company
            companySelectKey: UtilService.uuidv4(),
            attendeesSelectKey: UtilService.uuidv4(),
            attendesForSelection : [],
            allContacts : [],
            selectedEventType:{},
            selectedAttendees: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);



        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleAttendeesChange = this.handleAttendeesChange.bind(this);
        this.handleEventTypeChange = this.handleEventTypeChange.bind(this);
    }

    async componentDidMount() {
        const {uniqueId} = this.props.match.params;
        CompanyService.list().then((response) => {
            console.log(response);
            this.setState({companyList: response, companySelectKey :UtilService.uuidv4() });
        });
        ContactService.list().then((response) => {
            console.log(response);
            this.setState({allContacts: response, attendeesSelectKey :UtilService.uuidv4() });
        });
        const response = await MeetingReportService.fetch(uniqueId)
        console.log(response.data);
        const eventType = response.data.eventType;
        this.setState({selectedEventType : {key:eventType, value:eventType}});
        this.setState(response.data);
        this.setState({uniqueId: uniqueId});
        this.setState({selectedCompany: response.data.company});
        this.setState({selectedAttendees: response.data.attendees});
        this.setState({selectKey: UtilService.uuidv4()});
        console.log(this.state);

    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    }

    handleSubmit(event) {
        event.preventDefault();
        MeetingReportService.update(this.state).then(response => {
            console.log(response.data);
            if (response.responseStatus === "SUCCESS") {
                this.props.history.push("/admin/meetingReport/list");
            } else {
                this.setState({errorData: response.data});
                console.error("Error    ==>     " + response.message);
            }
        });
    }

    handleCompanyChange = (valueObj, event) => {
        console.log(valueObj);
        if (_.isNull(valueObj)) {
            this.setState({selectedCompany: {}, companyId :"", attendees : []});
        } else {
            const filteredContacts = _.filter(this.state.allContacts, (contact) => contact.company.uniqueId === valueObj.uniqueId);
            this.setState({selectedCompany: valueObj,
                companyUID : valueObj.uniqueId,
                attendesForSelection: filteredContacts,
                attendees : [],
                selectedAttendees : [],
                attendeesSelectKey: UtilService.uuidv4()
            });
        }
        this.setState({ companyName: valueObj.legalName });

    };


    handleEventTypeChange = (valueObj,event) => {
        if (_.isNull(valueObj)) {
            this.setState({selectedEventType : {}, eventType:  ""});
        } else {
            this.setState({ selectedEventType : valueObj, eventType: valueObj.key});
        }
    }

    handleAttendeesChange = (valueObj,event) => {
        if (_.isNull(valueObj)) {
            this.setState({selectedAttendees: []});
        } else {
            this.setState({selectedAttendees: valueObj});
            const attendees = _.map(valueObj, "uniqueId")
            this.setState({attendees: attendees});
        }
    };

    render() {
        return (
            <>
                <SimpleHeader
                    name="Edit"
                    parentName="Meeting Report"
                    heading="XareGrowth"
                    newBtnUrl="/admin/meetingReport/create"
                    newBtnText="Create Meeting Report"
                />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3 className="mb-0">Edit Meeting report</h3>
                        </CardHeader>
                        <CardBody>
                            <Form role="form" onSubmit={this.handleSubmit}>
                                <MeetingForm
                                    onChange={this.handleChange}
                                    valueObj={this.state}
                                    errorObj={this.state.errorData}

                                    companySelectKey={ this.state.companySelectKey}
                                    handleCompanyChange={this.handleCompanyChange}
                                    selectedCompany = {this.state.selectedCompany}
                                    companyList = {this.state.companyList}

                                    attendeesSelectkey={this.state.attendeesSelectKey}
                                    handleAttendeesChange={this.handleAttendeesChange}
                                    selectedAttendees={this.state.selectedAttendees}
                                    attendesForSelection={this.state.attendesForSelection}

                                    handleEventTypeChange={this.handleEventTypeChange}
                                    selectedEventType={this.state.selectedEventType}


                                />
                                <div className="text-center">
                                    <Button className="my-4" color="default" outline type="submit">
                                        Update
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}

export default MeetingEdit;
