import axiosInstance from "../api/api";

const ChatMessageService = {
	list: function (formData) {
		return axiosInstance({
			url: "/api/chatMessage/",
			method: "POST",
			data: formData,
		});
	},
};
export default ChatMessageService;
