import axiosInstance from "../api/api";

const ChatGroupService = {
	create: function (formData) {
		return axiosInstance({
			url: `/api/chatGroup/`,
			method: "POST",
			data: formData,
		});
	},
	list: function (courseSectionSubjectUID) {
		return axiosInstance({
			url: `/api/chatGroup/${courseSectionSubjectUID}`,
			method: "GET",
		});
	},
};
export default ChatGroupService;
