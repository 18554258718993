import _ from "lodash";
import React from "react";
import {
  Alert,
  Button,
  Card,
  CardHeader,
  Col,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import CreatePermissionModal from "./CreateModal";
import PermissionService from "../../../services/permissionService";
import { PermissionRole } from "../../../util/PermissionConstant";
import UserPermissionService from "../../../services/userPermissionService";

class PermissionListTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      permissionList: [],
      permissionStateList: [],
      assignedPermissionUIDList: [],
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.onModalOpened = this.onModalOpened.bind(this);
    this.handlePermissionChange = this.handlePermissionChange.bind(this);
    this.submitPermissionState = this.submitPermissionState.bind(this);
    this.delete = this.delete.bind(this);
  }

  toggleModal() {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  onModalOpened() {
    const userUID = this.props.adminUID;
    PermissionService.listByAssignee(PermissionRole.ADMIN).then((response) => {
      this.setState({ permissionList: response.data });
      UserPermissionService.listByUser(userUID).then((response) => {
        const assignedPermissionUIDList = _.map(response.data, "permissionUID");
        this.setState({
          assignedPermissionUIDList: assignedPermissionUIDList,
        });
      });
    });
  }

  handlePermissionChange(event) {
    const value = event.target.value;
    const isChecked = event.target.checked;
    let permissionStateList = this.state.permissionStateList;
    if (isChecked) {
      permissionStateList.push(value);
    } else {
      permissionStateList = permissionStateList.filter(
        (item) => item !== value
      );
    }
    this.setState({ permissionStateList: permissionStateList });
  }

  delete(uniqueId) {
    UserPermissionService.delete(uniqueId).then((response) => {
      console.log(response);
      this.props.onFormSubmitSuccess();
    });
  }

  submitPermissionState(event) {
    event.preventDefault();
    UserPermissionService.create({
      permissionStateList: this.state.permissionStateList,
      userUID: this.props.adminUID,
    }).then((response) => {
      console.log(response.data);
      if (response.responseStatus === "SUCCESS") {
        this.toggleModal();
        this.props.onFormSubmitSuccess();
      } else {
        this.setState({ errorData: response.data });
        console.error("Error    ==>     " + response.message);
      }
    });
  }

  render() {
    const obj = this;
    return (
      <>
        <CreatePermissionModal
          isOpen={this.state.isModalOpen}
          toggleModal={this.toggleModal}
          onModalOpened={this.onModalOpened}
          size="lg"
          permissionList={this.state.permissionList}
          onCheckboxChange={this.handlePermissionChange}
          onModalSubmit={this.submitPermissionState}
          assignedPermissionUIDList={this.state.assignedPermissionUIDList}
        />
        <Card>
          <CardHeader className="border-0">
            <Row className="align-items-center">
              <div className="col-6">
                <h3 className="mb-0">{this.props.cardTitle}</h3>
              </div>
              <div className="col-6">
                <Button
                  color="primary"
                  type="button"
                  size="sm"
                  className="fa-pull-right"
                  onClick={() => this.setState({ isModalOpen: true })}
                >
                  Permission Configuration
                </Button>
              </div>
            </Row>
          </CardHeader>
          {_.size(this.props.userPermissionList) > 0 ? (
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th className="sort" data-sort="name" scope="col">
                    #
                  </th>
                  <th className="sort" data-sort="budget" scope="col">
                    Date Created
                  </th>
                  <th className="sort" data-sort="status" scope="col">
                    Permission
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody className="list">
                {this.props.userPermissionList.map(function (value, index) {
                  return (
                    <tr key={index}>
                      <td className="budget">{index + 1}</td>
                      <td className="budget">
                        {value.permissionDTO.dateCreated}
                      </td>
                      <td className="budget">{value.permissionDTO.value}</td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            color=""
                            size="sm"
                            className="btn-icon-only text-light"
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              onClick={(e) => {
                                e.preventDefault();
                                obj.delete(`${value.uniqueId}`);
                              }}
                            >
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <Col xl="12">
              <Alert color="info">
                <strong>Info!</strong> No permission is assigned yet.
              </Alert>
            </Col>
          )}
        </Card>
      </>
    );
  }
}

export default PermissionListTable;
