const countries = [
    { key: "Mexico", value: "Mexico" },
    { key: "Chile", value: "Chile" },
    { key: "Colombia", value: "Colombia" },
    { key: "Argentina", value: "Argentina" },
    { key: "Peru", value: "Peru" },
    { key: "Brasil", value: "Brasil" },
    { key: "Spain", value: "Spain" },
    { key: "Portugal", value: "Portugal" },
    { key: "France", value: "France" },
    { key: "UK", value: "UK" },
    { key: "Belgium", value: "Belgium" },
    { key: "Netherlands", value: "Netherlands" },
    { key: "Germany", value: "Germany" },
    { key: "Italy", value: "Italy" },
    { key: "Saudi Arabia", value: "Saudi Arabia" },
    { key: "United Arab Emirates (UAE)", value: "United Arab Emirates (UAE)" },
    { key: "Qatar", value: "Qatar" },
    { key: "Kuwait", value: "Kuwait" },
    { key: "Oman", value: "Oman" },
    { key: "Saudi Arabia", value: "Saudi Arabia" },
    { key: "Singapore", value: "Singapore" },
    { key: "Malaysia", value: "Malaysia" },
    { key: "Thailand", value: "Thailand" },
    { key: "Indonesia", value: "Indonesia" },
    { key: "Vietnam", value: "Vietnam" },
    { key: "Philippines", value: "Philippines" },
    { key: "South Korea", value: "South Korea" },
    { key: "Japan", value: "Japan" },
    { key: "Ivory Coast", value: "Ivory Coast" },
    { key: "Nigeria", value: "Nigeria" },
    { key: "South Africa", value: "South Africa" },
    { key: "Kenia", value: "Kenia" },
    { key: "Tanzania", value: "Tanzania" },
    { key: "Rwanda", value: "Rwanda" },
    { key: "Ethiopia", value: "Ethiopia" },
    { key: "Jordan", value: "Jordan" },
    { key: "Morocco", value: "Morocco" },
    { key: "Algeria", value: "Algeria" },
    { key: "Angola", value: "Angola" },
    { key: "Cameroon", value: "Cameroon" },
    { key: "Tunisia", value: "Tunisia" },
    { key: "Egypt", value: "Egypt" },
    { key: "Mauritania", value: "Mauritania" },
    { key: "Senegal", value: "Senegal" },
    { key: "Ghana", value: "Ghana" },
    { key: "Guinea", value: "Guinea" }
];

export { countries };
