/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import React from "react";
import {
    Badge,
    Card,
    CardHeader,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Row,
    Table,
    UncontrolledDropdown,
} from "reactstrap";
import AuthService from "../../../../services/authService";
import MeetingService from "../../../../services/meetingService";
import {EventStatus} from "../../../../util/eventConstant";

class MeetingList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            meetingListStatus: "ACTIVE",
            list: [],
        };
        this.delete = this.delete.bind(this);
        this.meetingTableStatusChanged = this.meetingTableStatusChanged.bind(this);
        this.setActiveLabel = this.setActiveLabel.bind(this);
    }

    componentDidMount() {
        this.meetingTableStatusChanged(EventStatus.ACTIVE);
    }

    meetingTableStatusChanged(meetingListStatus) {
        MeetingService.list(meetingListStatus).then((response) => {
            console.log(response);
            this.setState({list: response});
        });
    }

    delete(uniqueId) {
        MeetingService.delete(uniqueId).then((response) => {
            //TODO: TO be fixed with redux implementation
            MeetingService.list(EventStatus.ACTIVE).then((responseList) => {
                console.log(responseList);
                this.setState({list: responseList});
            });
        });
    }

    setActiveLabel(startDateTime, endDateTime) {
        const currentDateTime = new Date();
        if (
            new Date(startDateTime) <= currentDateTime &&
            new Date(endDateTime) >= currentDateTime
        ) {
            return (
                <Badge color="primary" pill>
                    Active
                </Badge>
            );
        }
    }

    render() {
        let obj = this;
        const currentDateTime = new Date();
        return (
            <>
                <SimpleHeader
                    name="List"
                    parentName="Meeting"
                    heading="ClashMate"
                    newBtnUrl="/teacher/meeting/create"
                    newBtnText="Create Meeting"
                />
                <Container className="mt--6" fluid>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <Row>
                                                <Col md="10">
                                                    <h3 className="mb-0">Meeting List</h3>
                                                </Col>
                                                <Col md="2">
                                                    <Input
                                                        id="exampleFormControlSelect1"
                                                        type="select"
                                                        className="form-control-sm"
                                                        value={this.state.meetingListStatus}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            obj.setState({
                                                                meetingListStatus: e.target.value,
                                                            });
                                                            obj.meetingTableStatusChanged(e.target.value);
                                                        }}
                                                    >
                                                        <option value={EventStatus.ACTIVE}>Active</option>
                                                        <option value={EventStatus.COMPLETED}>Completed</option>
                                                    </Input>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th className="sort" data-sort="name" scope="col">
                                            #
                                        </th>
                                        <th className="sort" data-sort="budget" scope="col">
                                            Start Date Time
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Title
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            End Date Time
                                        </th>
                                        <th scope="col"/>
                                    </tr>
                                    </thead>
                                    <tbody className="list">
                                    {this.state.list.map(function (value, index) {
                                        const isMeetingActive =
                                            new Date(value.startDateTime) <= currentDateTime &&
                                            new Date(value.endDateTime) >= currentDateTime;
                                        return (
                                            <tr key={index}>
                                                <td className="budget">{index + 1}</td>
                                                <td className="budget">{value.startDateTime}</td>
                                                <td className="budget">
                                                    {value.title}
                                                    {obj.setActiveLabel(value.startDateTime, value.endDateTime)}
                                                </td>
                                                <td className="budget">{value.endDateTime}</td>
                                                <td className="text-right">
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle
                                                            color=""
                                                            size="sm"
                                                            className="btn-icon-only text-light"
                                                        >
                                                            <i className="fas fa-ellipsis-v"/>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                                            {isMeetingActive ? (
                                                                <DropdownItem
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        obj.props.history.push(
                                                                            `/${AuthService.getBaseURLByRole()}/meeting/start/${
                                                                                value.uniqueId
                                                                            }`
                                                                        );
                                                                    }}
                                                                >
                                                                    Start
                                                                </DropdownItem>
                                                            ) : null}
                                                            <DropdownItem
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    obj.props.history.push(
                                                                        `/${AuthService.getBaseURLByRole()}/meeting/show/${
                                                                            value.uniqueId
                                                                        }`
                                                                    );
                                                                }}
                                                            >
                                                                Details
                                                            </DropdownItem>
                                                            {isMeetingActive ? (
                                                                <>
                                                                    <DropdownItem
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            obj.props.history.push(
                                                                                `/teacher/meeting/edit/${value.uniqueId}`
                                                                            );
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </DropdownItem>
                                                                    <DropdownItem
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            obj.delete(`${value.uniqueId}`);
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </DropdownItem>
                                                                </>
                                                            ) : null}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default MeetingList;
