/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/pages/dashboards/Dashboard.jsx";
import LoginForm from "views/pages/auth/Login.jsx";
import Profile from "views/pages/auth/Profile.jsx";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-shop text-primary",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-shop text-primary",
    component: Dashboard,
    layout: "/superAdmin"
  },
  {
    collapse: true,
    name: "Authentication",
    icon: "ni ni-ungroup text-orange",
    state: "authenticationCollapse",
    views: [
      {
        path: "/login",
        name: "Login",
        component: LoginForm,
        layout: "/auth"
      },
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
        layout: "/admin"
      }
    ]
  }
];

export default routes;
