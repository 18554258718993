import _ from 'lodash'

const contentCategory = [
    { key: "INTERNATIONAL_EXPANSION", value: "INTERNATIONAL_EXPANSION" },
    { key: "PRODUCTIVITY_IMPROVEMENTS", value: "PRODUCTIVITY_IMPROVEMENTS" },
    { key: "SALES_EFFECTIVENESS", value: "SALES_EFFECTIVENESS" },
    { key: "GLOBAL_SOURCING", value: "GLOBAL_SOURCING" },
];

function findByKeyOrValue(array, searchTerm) {
    return _.find(array, obj => obj.key === searchTerm || obj.value === searchTerm);
}

export { contentCategory };