import React from "react";
import { Col, Row } from "reactstrap";
import FormInput from "../../../components/common/form/FormInput";

class TenantForm extends React.Component {
  showField() {
    return this.props.isEdit === true ? "hidden" : "";
  }

  render() {
    return (
      <>
        <Row>
          <Col md="4">
            <FormInput
              name="firstName"
              className="form-control-label"
              id="firstName"
              placeholder="inputPlaceHolder.tenant.firstName"
              type="text"
              onChange={this.props.onChange}
              label="inputLabel.tenant.firstName"
              value={this.props.valueObj.firstName || ""}
              errorMsg={this.props.errorObj.firstName || ""}
            />
          </Col>
          <Col md="4">
            <FormInput
              name="middleName"
              className="form-control-label"
              id="middleName"
              placeholder="inputPlaceHolder.tenant.middleName"
              type="text"
              onChange={this.props.onChange}
              label="inputLabel.tenant.middleName"
              value={this.props.valueObj.middleName || ""}
              errorMsg={this.props.errorObj.middleName || ""}
            />
          </Col>
          <Col md="4">
            <FormInput
              name="lastName"
              className="form-control-label"
              id="lastName"
              placeholder="inputPlaceHolder.tenant.lastName"
              type="text"
              onChange={this.props.onChange}
              label="inputLabel.tenant.lastName"
              value={this.props.valueObj.lastName || ""}
              errorMsg={this.props.errorObj.lastName || ""}
            />
          </Col>
        </Row>
        <Row>
          <Col md="4" sm="6">
            <FormInput
              name="emailAddress"
              className="form-control-label"
              id="emailAddress"
              placeholder="inputPlaceHolder.tenant.emailAddress"
              type="email"
              onChange={this.props.onChange}
              label="inputLabel.tenant.emailAddress"
              value={this.props.valueObj.emailAddress || ""}
              errorMsg={this.props.errorObj.emailAddress || ""}
            />
          </Col>
          <Col md="4" sm="6">
            <FormInput
              name="phoneNumber"
              className="form-control-label"
              id="phoneNumber"
              placeholder="inputPlaceHolder.tenant.phoneNumber"
              type="text"
              onChange={this.props.onChange}
              label="inputLabel.tenant.phoneNumber"
              value={this.props.valueObj.phoneNumber || ""}
              errorMsg={this.props.errorObj.phoneNumber || ""}
            />
          </Col>
          <Col md="4" sm="6">
            <FormInput
              name="domainName"
              className="form-control-label"
              id="domainName"
              placeholder="inputPlaceHolder.tenant.domainName"
              type="text"
              onChange={this.props.onChange}
              label="inputLabel.tenant.domainName"
              value={this.props.valueObj.domainName || ""}
              errorMsg={this.props.errorObj.domainName || ""}
            />
          </Col>
        </Row>
        <Row>
          <Col md="4" sm="6">
            <FormInput
              name="secondaryDomainName"
              className="form-control-label"
              id="secondaryDomainName"
              placeholder="inputPlaceHolder.tenant.secondaryDomainName"
              type="text"
              onChange={this.props.onChange}
              label="inputLabel.tenant.secondaryDomainName"
              value={this.props.valueObj.secondaryDomainName || ""}
              errorMsg={this.props.errorObj.secondaryDomainName || ""}
            />
          </Col>
          <Col md="4" sm="6">
            <FormInput
              name="address"
              className="form-control-label"
              id="address"
              placeholder="inputPlaceHolder.tenant.address"
              type="textarea"
              onChange={this.props.onChange}
              label="inputLabel.tenant.address"
              value={this.props.valueObj.address || ""}
              errorMsg={this.props.errorObj.address || ""}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default TenantForm;
