import axiosInstance from "../api/api";

const ParticipantService = {
  videoHangup: function (formData) {
    return axiosInstance({
      url: `/api/participant/video/hangup/`,
      method: "PATCH",
      data: formData,
    });
  },
};
export default ParticipantService;
