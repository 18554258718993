import React from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// reactstrap components
import { Button, CustomInput, Modal, Table } from "reactstrap";

class StudentListModal extends React.Component {
	render() {
		let obj = this;
		return (
			<>
				<Modal
					className="modal-dialog-centered"
					isOpen={this.props.isOpen}
					toggle={this.props.toggleModal}
					size={this.props.size}
					onOpened={this.props.onModalOpened}
				>
					<div className="modal-header">
						<h5 className="modal-title" id="studentListModalLabel">
							Student List
						</h5>
						<button
							aria-label="Close"
							className="close"
							data-dismiss="modal"
							type="button"
							onClick={this.props.toggleModal}
						>
							<span aria-hidden={true}>×</span>
						</button>
					</div>
					<div className="modal-body">
						<Table className="align-items-center table-flush" responsive>
							<thead className="thead-light">
								<tr>
									<th scope="col" />
									<th className="sort" data-sort="name" scope="col">
										#
									</th>
									<th className="sort" data-sort="status" scope="col">
										Name
									</th>
									<th className="sort" data-sort="status" scope="col">
										Email Address
									</th>
									<th className="sort" data-sort="status" scope="col">
										Examination Set
									</th>
								</tr>
							</thead>
							<tbody className="list">
								{this.props.assignedStudentList.map(function (value, index) {
									return (
										<tr key={index}>
											<td>
												<CustomInput
													type="checkbox"
													id={value.studentDTO.uniqueId}
													value={value.studentDTO.uniqueId}
													htmlFor={value.studentDTO.uniqueId}
													onChange={obj.props.handleStudentAssignmentChange}
													disabled={
														(value.uniqueId &&
															value.uniqueId !== obj.props.valueObj.onlineExaminationUID) ||
														false
													}
												/>
											</td>
											<td className="budget">{index + 1}</td>
											<td className="budget">{value.studentDTO.fullName}</td>
											<td className="budget">{value.studentDTO.emailAddress}</td>
											<td className="budget">{value.onlineExaminationTitle}</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
					<div className="modal-footer">
						<Button
							color="primary"
							data-dismiss="modal"
							type="button"
							onClick={obj.props.handleStudentAssignmentSubmit}
						>
							Save changes
						</Button>
					</div>
				</Modal>
			</>
		);
	}
}

export default StudentListModal;
